import React, { useState, useEffect } from 'react';
import "./leaderboardUser.component.style.less";
import EsgService from '../../../../services/EsgServices/esg.service';
import { getLocalStorage, USER_PROFILE_ESG } from '../../../../localStorageMobxStore/localStorageUserProfile';
import { Coins } from '../../../../assets/images/index'
import { useTranslation } from 'react-i18next'

/**
 * LeaderBoard component
 * @class
 * @returns This component is used in User profile component to get 
 * all players where their total point from multiplayer mode can be seen.
 */
const LeaderBoard = () => {
    const { t } = useTranslation('common')
    const esgService = new EsgService()
    const [players, setPlayers] = useState(null)
    const [matchedUser, setMatchedUser] = useState(false)

    useEffect(() => {
        const userId = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        esgService.getLeaders().then((res) => {
            const allPlayers = res.data
            setPlayers(allPlayers)

            const championship = userId.budgets.filter((x) => {
                if (x.mode === "championship") {
                    return x
                }
            })
            allPlayers.filter((y) => {
                if (championship.length > 0 && y.budget && y.budget._id === championship[0].budget._id) {
                    setMatchedUser(y.budget)
                }
            })
        })
    }, [])


    return (
        <div className="leaderboard">
            <div className="table-top-players">
                <div className="thead">
                    <p className="t-large">{t("common:users")}</p>
                    <p className="t-medium">{t("common:points")}</p>
                </div>

                {players && players.map((x, index) => {
                    return (
                        <div className={x.budget === matchedUser ? 'players-list-matched' : 'players-list'} key={x._id}>
                            <p><span className="number-list">{index + 1}</span>{x.email}</p>
                            <p className="right-links">
                                <span>{x.points} <Coins /></span>
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default LeaderBoard;