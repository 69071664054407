import { observable, action } from "mobx";


export class UserStore {

    @observable userInfoEsg = null
    @observable industry = null
    @observable companyInfo = null
    @observable budget = null
    @observable building = null
    @observable employees = null
    @observable employeesProfession = null
    @observable hiredEmployees = null

    @action updateUserInfoEsg = (userInfo) => {
        this.userInfoEsg = userInfo
    }
    @action updateIndustry = (industry) => {
        this.industry = industry
    }
    @action updateCompanyInfo = (companyInfo) => {
        this.companyInfo = companyInfo
    }
    @action updateBudget = (budget) => {
        this.budget = budget
    }
    @action updateEmployees = (employees) => {
        this.employees = employees
    }
    @action updateBuilding = (building) => {
        this.building = building
    }
    @action updateEmployeesProfession = (employeesProfession) => {
        this.employeesProfession = employeesProfession
    }
    @action updateHiredEmployees = (hiredEmployees) => {
        this.hiredEmployees = hiredEmployees
    }
}

export default new UserStore()