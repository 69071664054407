import React, { useState, useEffect } from 'react';
import "./generalInfo.component.style.less";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, ALL_EQUIPMENT, EMPLOYEES, BUDGET } from '../../../../localStorageMobxStore/localStorageUserProfile';
import { Avatar, avatarArr } from '../../../../assets/images';
import { EmployeAvatar, HardwareEquipment, BuildingType } from '../../../../assets/images/index'
import { Rate } from 'antd';
import InfoModalEmployee from '../../../../components/Modal/ModalInfoEmployee/modalInfoEmployee.component'
import { Coins } from '../../../../assets/images/index'
import ModalInfoOffice from '../../../../components/Modal/ModalInfoOffice/modalInfoOffice.component'
import { useTranslation } from "react-i18next"
import i18n from 'i18next';
import PdfBhs from '../../../../assets/pdf/bizventurepojmovnik.pdf'
import PdfEn from '../../../../assets/pdf/bizventureglossary.pdf'
import { Table } from 'antd';
import EsgService from '../../../../services/EsgServices/esg.service'

const esgService = new EsgService()


// import EquipmentItem from '../../../../containers/UserSelectSection/PayEquipment/EqupmentItem/equipmentItem.component'
/**
 * GeneralInfo component
 * @class
 * @param {Object} properties (languageStore)
 * @returns This component is used in User Profile component to display user data:
 * profile image, username, startup name, industry type, legal administrative aspect, yout team, office, 
 * equimpent, donwload button for glossary and your earning
 */
const GeneralInfo = ({ languageStore }) => {
    const { t } = useTranslation("user-profile")
    const [userEsg, setUserEsg] = useState(null)
    const [userImage, setUserImage] = useState(null)
    const [userName, setUserName] = useState(null)
    const [company, setUserCompany] = useState(null)
    const [userIndustryType, setUserIndustryType] = useState(null)
    const [allEquipment, setAllEquipment] = useState(null)
    const [equipmentList, setEquipmentList] = useState(null)
    const [allEmployyes, setallEmployyes] = useState('')
    const [chosenBuilding, setChosenBuilding] = useState(null)
    const [legalAspects, setLegalAspects] = useState(null)
    const [companyInfo, setCompanyInfo] = useState(null)
    const [rent, setRent] = useState(null)
    const [salaries, setSalaries] = useState(null)
    const [tax, setTax] = useState(null)
    const [equipment, setEquipment] = useState(null)
    const [income, setUserIncome] = useState(null)
    const [outcome, setUserOutcome] = useState(null)
    const [total,setTotal] = useState(null)
    const [buyedEquipment,setBuyedEquipment] = useState(null)
    const [budget,setBudget] = useState(null)


    useEffect(() => {
        const getAllData = getLocalStorage(COMPANY_INFO).COMPANY_INFO
        if (getAllData) {
            setCompanyInfo(getAllData)
            setRent(getAllData.monthlyExpense.rent)
            setSalaries(getAllData.monthlyExpense.salaries)
            setTax(getAllData.monthlyExpense.tax)
            setEquipment(getAllData.monthlyExpense.equipment)
        } else {
            setCompanyInfo(0)
            setRent(0)
            setSalaries(0)
            setTax(0)
            setEquipment(0)
        }
    }, [])

    useEffect(() => {
        //Get Budget
        const getBudget = getLocalStorage(BUDGET).BUDGET
        //Get User data from localStorage
        const getUserData = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        // set User Image state from user profile data
        setUserEsg(getUserData.avatarUrl)
        // set User name state from user profile data
        setUserName(getUserData.userName)
        //Get Company Info data from localStorage
        esgService.getCompanyInfo(getBudget.budget.company).then((res)=>{
            const buyedEq = res.data.equipment.map(x => x.amount * x.item.price).reduce((x, y) => x + y, 0)
            setBuyedEquipment(buyedEq)
            setTotal(res.data.total)
            setUserIncome(res.data.sequenceIncome)
            setUserOutcome(res.data.sequenceOutcome)
        })
        const getAllData = getLocalStorage(COMPANY_INFO).COMPANY_INFO
        // set Company Name state from company info
        setUserCompany(getAllData.name) 
        // set Industry Type state from company info
        setUserIndustryType(getAllData.industry)
        // set Legal Aspects state from company info
        setLegalAspects(legalAspectMutator(getAllData.legalAspect))
        //Get all Equipment from localStorage
        setAllEquipment(getLocalStorage(ALL_EQUIPMENT).ALL_EQUIPMENT)
        const res = getLocalStorage(ALL_EQUIPMENT).ALL_EQUIPMENT

        const employes = getAllData.team.teamMembers.slice(1)
        setallEmployyes(employes)

        const equipmentList = getAllData.equipment
        setEquipmentList(equipmentList)

        const building = getAllData.building
        setChosenBuilding(building)
    }, []);

    // set states if we have one of the following data in localStorage
    useEffect(() => {
        if (userEsg || company && userName && userIndustryType) {
            setUserImage(userEsg)
            setUserCompany(company)
            setUserIndustryType(userIndustryType)
            setUserName(userName)
        }
    }, [userEsg, company, userName, userIndustryType])

    // filtering User Image (avatar)
    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }

    const selectLanguage = (x) => {
        if (languageStore.language === "en") {
            return x.item.en.name
        } else if (languageStore.language === 'bhs') {
            return x.item.bhs.name
        } else {
            return x.item.en.name
        }
    }

    const selectLanguageBuild = (x) => {
        if (languageStore.language === "en") {
            return x.en.name
        } else if (languageStore.language === 'bhs') {
            return x.bhs.name
        } else {
            return x.en.name
        }
    }

    /**
     * Mutate legal aspect to correct values
     * 
     * @param {*} aspect 
     * @returns 
     */
    const legalAspectMutator = (aspect) => {
        const lang = i18n.language ?? 'en';

        if (lang === 'en') {
            switch (aspect) {
                case 'SoleTrader':
                    return 'Sole trader'
                case 'LTD':
                    return 'LTD'
                default:
                    return aspect
            }
        } else {
            switch (aspect) {
                case 'SoleTrader':
                    return 'Samostalni preduzetnik / obrt'
                case 'LTD':
                    return 'D.o.o. - Društvo s ograničenom odgovornošću'
                default:
                    return aspect
            }
        }
    }

    const columnsIncome = [
        {
            title: languageStore.language === 'bhs' ? 'Prihodi' :  'Income',
            dataIndex: 'name',
        },
        {
            title: '',
            dataIndex: 'chinese',
        },
    ];

    const columnsOutcome = [
        {
            title: languageStore.language === 'bhs' ? 'Rashodi' :  'Outcome',
            dataIndex: 'name',
        },
        {
            title: '',
            dataIndex: 'chinese',
        },
    ];

    const dataIncome = [
        {
            key: '1',
            name: languageStore.language === 'bhs' ? 'Prihodi od BizCoins' :   'Bizcoins Earning',
            chinese: income,
        },
    ];
    const dataOutcome = [
        {
            key: '1',
            name: languageStore.language === 'bhs' ? 'Troškovi Plata' : 'Salary Expenses',
            chinese: total?.salaries,
        },
        {
            key: '2',
            name: languageStore.language === 'bhs' ? 'Porezi' : 'Taxes',
            chinese: total?.tax,
        },
        {
            key: '3',
            name:languageStore.language === 'bhs' ? 'Kredit' :  'Loan',
            chinese: total?.loan ? total?.loan : 0,
        },
        {   
            key: '4',
            name:languageStore.language === 'bhs' ? 'Oprema' :  'Equipment',
            chinese: total?.equipment ? total?.equipment : buyedEquipment,
        },
        {
            key: '5',
            name:languageStore.language === 'bhs' ? 'Najam' :  'Rent',
            chinese: total?.rent,
        },
        {
            key: '6',
            name:languageStore.language === 'bhs' ? 'Trošak BizCoins' :  'BizCoins Expenditure',
            chinese: outcome,
        },
    ];

    const userProfileImage = utilityFilter(avatarArr, userImage)

    return (
        <div className="general-info">
            {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
            <div className="startup-name">
                <p>{t("user-profile:username")}</p>
                <span>{userName} </span>
            </div>
            <div className="startup-name">
                <p>{t("user-profile:startup")}</p>
                <span>{company} </span>
            </div>
            <div className="startup-name">
                <p>{t("user-profile:industry")}</p>
                <span>{userIndustryType} </span>
            </div>
            <div className="startup-name">
                <p>{t("user-profile:las")}</p>
                <span>{legalAspects} </span>
            </div>

            <div className="emplyeesList">
                <h2>{t("user-profile:team")}</h2>
                <div className="inline-holder">
                    {allEmployyes?.length ? allEmployyes.map((x) => {
                        return (
                            <div className="raising">
                                <EmployeAvatar key={x.employee._id} avatarUrl={x.employee.avatarUrl} />
                                <span className="money-count">
                                    <Rate disabled value={x.employee.skill} allowHalf={true} />
                                </span>
                                <InfoModalEmployee
                                    avatarUrl={x.employee.avatarUrl}
                                    languages={x.employee.languages}
                                    education={x.employee.education}
                                    experience={x.employee.experience}
                                    name={x.employee.firstName}
                                    skills={x.employee.skills}
                                    competencies={x.employee.competencies}
                                    salary={x.employee.salary}
                                    role={x.role}
                                />
                            </div>
                        )
                    })
                        :
                        <div>
                            <h2 className="red">{t("user-profile:not-chossen-team")}</h2>
                        </div>
                    }
                </div>

            </div>

            <div className="buildingList">
                <h2>{t("user-profile:office")}</h2>
                {chosenBuilding ?
                    <div className="building-holder">
                        <BuildingType imageUrl={chosenBuilding.imageUrl} ecoFriendly={chosenBuilding.ecoLevel} />
                        <div className="building-text">
                            <h2>{selectLanguageBuild(chosenBuilding)}</h2>
                            <p className="green">
                                {chosenBuilding.price}<Coins />
                            </p>
                        </div>
                        <div className="more-info-building">
                            <ModalInfoOffice
                                imageUrl={chosenBuilding.imageUrl}
                                contentHeader={selectLanguageBuild(chosenBuilding)}
                                contentPrice={chosenBuilding.price}
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <h2 className="red">{t("user-profile:not-chossen-office")}</h2>
                    </div>
                }
            </div>

            <div className="equipmentList">
                <h2>{t("user-profile:equipment")}</h2>
                {equipmentList?.length ? equipmentList.map((x) => {
                    return (
                        <div className="equipmentListHolder">
                            <HardwareEquipment key={x.item._id} imageUrl={x.item.imageUrl} />
                            <p className="device-per-eqi">{selectLanguage(x)}</p>
                            <p className="device-per-eqi bolded">X{x.amount}</p>
                        </div>
                    )
                })
                    :
                    <div>
                        <h2 className="red">{t("user-profile:not-chossen-equipment")}</h2>
                    </div>
                }
            </div>

            <div className="filePreview">
                <span>{t("user-profile:gl-preview")}</span>
                {languageStore.language === 'bhs' ?
                    <a className="signUp" href={PdfBhs} download="bizventurepojmovnik.pdf"> Pojmovnik</a>
                    :
                    <a className="signUp" href={PdfEn} download="bizventureglossary.pdf"> Glossary </a>
                }
            </div>

            <div className="filePreviewTable">
                <Table title={() => t("user-profile:earning")} columns={columnsIncome} dataSource={dataIncome} pagination={false} />
                <Table columns={columnsOutcome} dataSource={dataOutcome} pagination={false} />
            </div>
        </div >
    )

}

export default withRouter(inject('languageStore')(observer(GeneralInfo)));