import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './notification.component.style.less';
import { notification } from 'antd';

const openNotification = (icon, message, description) => {
    notification.config({
        placement: 'topRight',
        duration: 10,
    });
    notification.open({
        message,
        description,
        icon,
    });
};

export default openNotification;