import React, { useEffect, useState } from 'react'
import Modal from '../modal.component'
import './modalViewProfile.component.style.less'
import { Tabs, Radio } from 'antd';
import Championship from './Championship/championship.component'
import SingleplayerAcc from './SingleplayerAcc/singleplayerAcc.component'
import SingleplayerNoAcc from './SingleplayerNoAcc/singleplayerNoAcc.component'
import SurveyAdmin from './Survey/surveyAdmin.component'
import EsgService from '../../../services/EsgServices/esg.service'
import SequenceService from '../../../services/EsgServices/sequence.service'
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs;

const ModalViewProfile = ({ player }) => {
    const { t } = useTranslation("admin")
    const [size, setSize] = useState('small')
    const [visible, setVisible] = useState(false);
    const [userEsg, setUserEsg] = useState(null)
    const [budgetAccelerator, setBudgetAccelerator] = useState(null)
    const [budgetWitoutAccelator, setBudgetWitoutAccelator] = useState(null)
    const [championshipBudget, setChampionshipBudget] = useState(null)
    const [companyAccelerator, setCompanyAccelerator] = useState(null)
    const [companyWithoutAccelator, setCompanyWithoutAccelerator] = useState(null)
    const [compionshipCompany, setChampionshipCompany] = useState(null)
    const [bmcAccelator, setBmcAccelator] = useState(null)
    const [bmcWithoutAccelerator, setBmcWithoutAccelerator] = useState(null)
    const [bmcChampionship, setBmcChampionship] = useState(null)
    const esgService = new EsgService()
    const sequnceService = new SequenceService

    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }

    useEffect(() => {
        if (visible) {
            esgService.getUserProfile(player.userId).then((res) => {
                const userProfileEsg = res.data
                setUserEsg(res.data)
                const budgets = res.data.budgets
                budgets.map((x) => {
                    //Infos without accelerator
                    if (x.mode === "single-player" && x.accelerator === false) {
                        const userMode = {
                            userId: player.userId,
                            mode: x.mode,
                            accelerator: x.accelerator
                        }
                        esgService.selectMode(userMode).then((budgetResponse) => {
                            const withoutAcceleratorBudget = budgetResponse.data.budget
                            setBudgetWitoutAccelator(budgetResponse.data.budget)
                            if (withoutAcceleratorBudget.hasOwnProperty('company')) {
                                esgService.getCompanyInfo(withoutAcceleratorBudget.company).then((companyResponse) => {
                                    const withoutAcceleratorCompany = companyResponse.data
                                    setCompanyWithoutAccelerator(companyResponse.data)
                                    sequnceService.getBmcs(withoutAcceleratorCompany.id, 'single-player').then((bmcRes) => {
                                        setBmcWithoutAccelerator(bmcRes.data)
                                    })
                                })
                            }
                        })
                    }
                    //Infos with accelerator
                    if (x.mode === "single-player" && x.accelerator === true) {
                        const userMode = {
                            userId: player.userId,
                            mode: x.mode,
                            accelerator: x.accelerator
                        }
                        esgService.selectMode(userMode).then((budgetResponse) => {
                            setBudgetAccelerator(budgetResponse.data.budget)
                            const acceleratorBudget = budgetResponse.data.budget
                            if (acceleratorBudget.hasOwnProperty('company')) {
                                esgService.getCompanyInfo(acceleratorBudget.company).then((companyResponse) => {
                                    const withAcceleratorCompany = companyResponse.data
                                    setCompanyAccelerator(companyResponse.data)
                                    sequnceService.getBmcs(withAcceleratorCompany.id, 'single-player-accelerator').then((bmcRes) => {
                                        setBmcAccelator(bmcRes.data)
                                    })
                                })
                            }
                        })
                    }
                    //Infos championship
                    if (x.mode === 'championship') {
                        const userMode = {
                            userId: player.userId,
                            mode: x.mode,
                            accelerator: x.accelerator
                        }
                        esgService.selectMode(userMode).then((budgetResponse) => {
                            setChampionshipBudget(budgetResponse.data.budget)
                            const championshipBudget = budgetResponse.data.budget
                            if (championshipBudget.hasOwnProperty('company')) {
                                esgService.getCompanyInfo(championshipBudget.company).then((companyResponse) => {
                                    const companyChampionship = companyResponse.data
                                    setChampionshipCompany(companyResponse.data)
                                    sequnceService.getBmcs(companyChampionship.id, 'championship').then((bmcRes) => {
                                        setBmcChampionship(bmcRes.data)
                                    })
                                })
                            }
                        })
                    }
                })
                esgService.getUserSurvey(player.userId).then((resSurvey) => {
                })
            })
        }
    }, [visible])

    return (
        <Modal
            modalStyleTest='modal-content-view'
            closable={true}
            okBtnText="Close"
            footer={true}
            btnText={t("admin:view-profile")}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
        >
            <Tabs defaultActiveKey="1" type="card" size={size}>
                
                <TabPane tab={t("admin:single")} key="1">
                    {companyAccelerator ?
                        <SingleplayerAcc userEsg={userEsg} budgetAccelerator={budgetAccelerator} companyAccelerator={companyAccelerator} bmcAccelator={bmcAccelator} />
                        : <div>{t("admin:notPLay")}</div>}
                </TabPane>

                <TabPane tab={t("admin:singleAcc")} key="2">
                    {companyWithoutAccelator ?
                        <SingleplayerNoAcc userEsg={userEsg} budgetWitoutAccelator={budgetWitoutAccelator} companyWithoutAccelator={companyWithoutAccelator} bmcWithoutAccelerator={bmcWithoutAccelerator} />
                        : <div>{t("admin:notPLay")}</div>}
                </TabPane>

                <TabPane tab={t("admin:cahmp")} key="3">
                    {compionshipCompany ?
                        <Championship userEsg={userEsg} championshipBudget={championshipBudget} compionshipCompany={compionshipCompany} bmcChampionship={bmcChampionship} />
                        : <div>{t("admin:notPLay")}</div>}
                </TabPane>
{/* 
                <TabPane tab="Survey" key="4">
                    <SurveyAdmin />
                </TabPane> */}
            </Tabs>
        </Modal>
    )
}

export default ModalViewProfile