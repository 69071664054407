import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import './onboardingInfo.component.style.less';
import Button from '../../../components/Button/button.component';
import { Avatar, avatarArr, Mentor, mentorArr } from '../../../assets/images';
import { getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from "react-i18next"

/**
 * Onboarding Info component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.Selected image of mentor avatar and on boarding info text from mentor. 
 * Text and image of in-game avatar that user selected and question text.
 * Selected image of mentor avatar and answer text from mentor.
 * And two buttons : Contiue button redirect him to next page Select Mode and
 * Back button for going back to previous page. 
 */
const OnboardingInfo = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "onboardinginfo"])
  const [userEsg, setUserEsg] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userImage, setUserImage] = useState(null)
  const [mentorImage, setMentorImage] = useState(null)
  const [mentorName, setMentorName] = useState(null)
  const ref = React.createRef();
  const refMentorSecond = React.createRef();
  const refUserSecond = React.createRef();
  const refMentorSecondNd = React.createRef();
  const refMentorThirdFirst = React.createRef();
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  const handleScrollToUser = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToMentorSecond = () =>
    refMentorSecond.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToMentorSecondNd = () =>
    refMentorSecondNd.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToUserSecond = () =>
    refUserSecond.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToMentorThirdFirst = () =>
    refMentorThirdFirst.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    progressBarStore.setProgress(72, 0, 0);
  }, []);

  useEffect(() => {

    if (userEsg) {
      setUserStore(userEsg)
      setUserName(userEsg.userName)
      setUserImage(userEsg.avatarUrl)
      setMentorImage(userEsg.mentor.avatarUrl)
      setMentorName(userEsg.mentor.name)
    }
  }, [userEsg])
  /**
   * Function that takes one parameter userEsg 
   * @param {Object} userEsg properties for user ingame profile
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with Object
   */
  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }
  /**Array with one Object element of mentor image chose by user in game */
  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
  /**Array with one Object element of user image chose by user in game */
  const userProfileImage = utilityFilter(avatarArr, userImage)
  /**
   * Redict function that is used on Continue button
   * @returns Redirect user to selectmode page
   */
  const redirect = () => {
    history.push('selectmode')
  }

  return (
    <div className="board-info app">
      <div className="board-info-section">
        <h2>{t("onboardinginfo:before-start")}</h2>
        <div className="board-info-holder">
          <div className='board-info-text'>
            {/* <h3>{mentorName}</h3> */}
            <div className="text-info">
              <div className="board-info-talk-mentor">
                {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                <p className="hi-msg">{t("onboardinginfo:mentor-text-first")}</p>
                <p className="expl-msg" ref={ref} onAnimationStart={() => handleScrollToUser()}>{t("onboardinginfo:mentor-text-second")}</p>
              </div>
              <div className="board-info-talk-user">
                <p className="hi-msg-user" ref={refMentorSecond} onAnimationStart={() => handleScrollToMentorSecond()}>{t("onboardinginfo:player-text-first")}</p>
                {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
              </div>
              <div className="infoTalkMentor2">
                {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                <p className="hi-msg fadeLeft" ref={refMentorSecondNd} onAnimationStart={() => handleScrollToMentorSecondNd()}>{t("onboardinginfo:mentor-text-singleplayer")} {t("onboardinginfo:mentor-text-championship")}</p>
              </div>
            </div>
          </div>
          {/* <div className="board-info-text2">
            <h3 className="fadeLeft">{t("onboardinginfo:singleplayer.singleplayer-name")}</h3>
            <p className="fadeLeft" ref={refUserSecond} onAnimationStart={() => handleScrollToUserSecond()}>{t("onboardinginfo:singleplayer.singleplayer-description")}</p>
            <br />
            <h3 className="fadeRight">{t("onboardinginfo:championship.championship-name")}</h3>
            <p className="fadeRight" ref={refMentorThirdFirst} onAnimationStart={() => handleScrollToMentorThirdFirst()}>{t("onboardinginfo:championship.championship-description")}</p>
          </div> */}
        </div>
        <div className="button-landing">
          <Button additionalStyle="signIn" text={t("common:landing-back")} customBtn={true} onClick={() => {
            history.goBack();
          }} />
          <Button
            additionalStyle="buttonMakeAcc"
            text={t("common:landing-continue")}
            customBtn={true}
            onClick={() => { redirect(); playSound(likeAudio); }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(OnboardingInfo)));
