import React from 'react'
import Modal from '../modal.component'
import './hint.modal.component.style.less'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

const HintModal = ({languageStore, question, closable, btnText, okBtnText, visible, trigerVisible, footer, handleOk, maskClosable, icon }) => {
        const textHintBhs = question.locales[0].question_content.textHint
        const textHintEn = question.locales[1].question_content.textHint
        const selectLanguage = () => {
            if(languageStore.language === 'bhs'){
                return textHintBhs
            }else if (languageStore.language === 'en'){
                return textHintEn
            }else {
                return textHintEn
            }
        }
    return (
        <Modal
            modalStyleTest='modal-content-equipment'
            icon={icon}
            closable={closable}
            okBtnText={okBtnText}
            footer={footer}
            btnText={btnText}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
            maskClosable={maskClosable}
            disabled={selectLanguage() === "-" || !selectLanguage() ?true:false}
        >
            <p className="hint-help" dangerouslySetInnerHTML={{ __html:selectLanguage()}}></p>
        </Modal>
    )
}

export default inject('languageStore')(observer(HintModal))