import React from 'react';

import {
    FacebookShareButton,
    LinkedinShareButton,
    FacebookIcon,
    LinkedinIcon,
} from "react-share";

import { FacebookShareCount } from "react-share";

const ShareComponent = () => {
    const title = 'BizVenture'
    const shareUrl = window.location.href ;

    return <div className="network-sharing">
        <FacebookShareButton
            url={shareUrl}
            quote={title}
            hashtag="#bizVenture"
            className="network-sharing-button"
        >
            <FacebookIcon size={50} round={true} />
        </FacebookShareButton>

        <div>
            <FacebookShareCount url={shareUrl} className="network-sharing-count">
                {count => count}
            </FacebookShareCount>
        </div>
        <LinkedinShareButton
            url={shareUrl}
            quote={title}
            hashtag="#bizVenture"
            className="network-sharing-button"
        >
            <LinkedinIcon size={50} round={true} />
        </LinkedinShareButton>
    </div>
};

export default ShareComponent;