import React, { useState } from 'react'
import Modal from '../modal.component'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { Coins } from '../../../assets/images/index';
import './modalInfoEquipment.component.style.less'
import { HardwareEquipment } from '../../../assets/images/index';
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'


const ModalInfoEquipment = ({ contentHeader, contentPrice, imageUrl, soundsStore, contentDescription }) => {
    const { t } = useTranslation(["common", "equipment"])
    const [visible, setVisible] = useState(false);
    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }

    const likeAudio = new Audio(ClickSound);

    const playSound = audioFile => {
        if (soundsStore.sounds) {
            audioFile.play();
        }
    }

    return (
        <Modal
            modalStyleTest='modal-content-equipment'
            closable={false}
            okBtnText={t('common:close-button')}
            footer={true}
            btnText={t('common:more-info')}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={() => { handleOk(); playSound(likeAudio); }}
        >
            <div className="contentImage">
                <HardwareEquipment imageUrl={imageUrl} />
            </div>
            <div className="contentHeader"><h3>{contentHeader}</h3></div>
            <div className="contentPrice">{contentPrice}<Coins /></div>
            <div className="contentDescription">{contentDescription}</div>
        </Modal>
    )
}

export default withRouter(inject('soundsStore')(observer(ModalInfoEquipment)));