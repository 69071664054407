import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import "./index.style.less";
import App from "./containers/App/App";
import userStore from "./store/user.store";
import sequenceStore from "./store/sequnces.store";
import languageStore from "./store/language.store";
import soundsStore from "./store/sound.store";
import progressBarStore from "./store/progress-bar.store";
import userProfile from "./store/userProfile.store";

import * as serviceWorkerRegister from "./serviceWorkerActivation.js";

const stores = {
    userStore,
    sequenceStore,
    languageStore,
    soundsStore,
    progressBarStore,
    userProfile,
};

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// pwa
navigator.serviceWorker.getRegistrations().then(registrations => {
  if (registrations.length < 1 && "serviceWorker" in navigator && "SyncManager" in window) {
    serviceWorkerRegister.register();
  }
});