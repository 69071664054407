import React, { useState, useEffect } from 'react';
import './industrySelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from "mobx-react";
import { observer } from 'mobx-react-lite'
import EsgService from '../../../services/EsgServices/esg.service'
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, COMPANY_NAME, BUDGET, INDUSTRY_SELECT } from '../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../components/Button/button.component';
import { Itindustry, companytypeArr } from '../../../assets/images/index.js';
import ModalInfoIndustry from '../../../components/Modal/ModalInfoIndustry/modalInfoIndustry.component'
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'
import Animation6 from '../../../assets/video/animation6.mp4'
import VideoLoad from '../../../components/Video/video.component'

/**
 * Industry Type component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title and list of industry's .Where every industry have title , picture and info button
 * from where modal is opent with text and Close button(that close modal) where user can read information about industry
 * And two buttons : Contiue button that redirect user to company name.
 * Back button for going back to previous page. 
 */
const IndustryType = ({ userStore, history, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "industry"])
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [industry, setIndustry] = useState(null)
  const [budget, setBudget] = useState(null)
  const [videoEnd, setVideoEnd] = useState(false)

  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setIndustry(getLocalStorage(INDUSTRY_SELECT).INDUSTRY_SELECT)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    progressBarStore.setProgress(100, 8, 0)
  }, [])

  useEffect(() => {
    if (userEsg || companyInfo || industry || budget) {
      setUserStore(userEsg, companyInfo, industry, budget)
    }
  }, [userEsg, companyInfo, budget])

  /**
   * Function that two parametars userEsg , singlePlayer ,companyInfo , industry
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @param {String} industry industry type that user selected 
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value and 
   * userStore value and budget state with of budget value
   */
  const setUserStore = (userEsg, companyInfo, industry, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateIndustry(industry)
    userStore.updateBudget(budget)
  }
  /**
   * Function onChangeIndustry
   * @param {String} industry 
   * @returns on every user select of diferent type of industry new 
   * value of industry state is selected and stored.
   */
  const onChangeIndustry = (industry) => {
    setIndustry(industry.key);
  };
  /**
   * Submit Industry function withoud any parameters
   * @returns Store INDUSTRY_SELECT in local storage with industry state and 
   * save in mobix userStore.industry state with state of industry from this component.
   * After that redirect user to company name component.
   */
  const submitIndustry = () => {
    setLocalStorage(industry, INDUSTRY_SELECT);
    userStore.updateIndustry(industry);
    history.push('/companyname');
  }

  /**
   * Language Name Select function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} value Object with properties descriptionBhs,descriptionEn,nameBhs,nameEn,src and key
   * @returns Name of industry in BHS language or EN language
   */
  const languageNameSelect = (value) => {
    if (languageStore.language === 'bhs') {
      return value.nameBhs
    } else if (languageStore.language === 'en') {
      return value.nameEn
    } else {
      return value.nameEn
    }
  }
  /**
   * Language Description Select function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} value Object with properties descriptionBhs,descriptionEn,nameBhs,nameEn,src and key
   * @returns Description of industry in BHS language or EN language
   */
  const languageDescriptionSelect = (value) => {
    if (languageStore.language === 'bhs') {
      return value.descriptionBhs
    } else if (languageStore.language === 'en') {
      return value.descriptionEn
    } else {
      return value.descriptionEn
    }
  }
  return (
    <div className="industry-type app">
      {!videoEnd ? <VideoLoad url={Animation6} setVideoEnd={setVideoEnd} /> :
        <div className="industry-type-section">
          <h2>{t('industry:industry-header')}</h2>
          <div className="industry-type-holder">
            {companytypeArr.map((x, index) => (
              <div className="company-holder" key={x.key}>
                <h2>{languageNameSelect(x)}</h2>
                <Itindustry
                  key={x.key}
                  src={x.src}
                  selected={x.key === industry}
                  handleclick={() => { onChangeIndustry(x); playSound(likeAudio); }}
                />
                <ModalInfoIndustry
                  contentHeader={languageNameSelect(x)}
                  contentDesc={languageDescriptionSelect(x)}
                // contentPrice={building.price}
                />
              </div>
            ))}
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => { history.goBack() }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              disabled={industry ? false : true}
              onClick={() => { submitIndustry(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject("userStore", 'soundsStore', 'languageStore', 'progressBarStore')(observer(IndustryType)));
