import HttpClient from '../core/http';
import confing from '../../config/config.local';

class EsgService {

    constructor() {
        this.http = new HttpClient(confing.API_BASE_URL_ESG);
    }
    async getUserProfile(userId, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/${userId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async addAvatar(data,userId, query = {}) {
        const { http } = this;
        const body = {
            avatarUrl:data
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/${userId}/avatar`);
        const url = http.combine(path, queryString);
        return this.http.patch(url, body)
    }
    async addCharName(data,userId, query = {}) {
        const { http } = this;
        const { name } = data
        const body = {
            userName:name
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/${userId}/username`);
        const url = http.combine(path, queryString);
        return this.http.patch(url, body)
    }
    // async getSurveys( query = {}) {
    //     const { http } = this;
    //     const queryString = http.qs.stringify(query);
    //     const path = http.url.build(`/survey`);
    //     const url = http.combine(path, queryString);
    //     return this.http.get(url)
    // }
    // async postSurvey(data, query = {}) {
    //     const {userId,surveyId,response} = data
    //     const body = {
    //         userId,
    //         surveyId,
    //         response
    //     }
    //     const { http } = this;
    //     const queryString = http.qs.stringify(query);
    //     const path = http.url.build(`/survey/response`);
    //     const url = http.combine(path, queryString);
    //     return this.http.post(url,body)
    // }

    async getSurveys( query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/survey`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async postSurvey(data, query = {}) {
        const {userId,surveyId,responses} = data
        const body = {
            userId,
            surveyId,
            responses
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/survey/response`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }
    async getUserSurvey(userId, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/survey/user/${userId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async selectMode(data, query = {}) {
        const { http } = this;
        const { mode,accelerator,userId } = data
        const body = {
            mode,
            accelerator,
            userId
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/mode-selector`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async createCompany(data , query = {}) {
        const { http } = this;
        const {name,industry,owner,accelerator,mode,budgetId} = data
        const body = {
            name,
            owner,
            industry,
            accelerator,
            mode,
            budgetId
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async addIndustry(data,userId, query = {}) {
        const { http } = this;
        const { key } = data
        const body = {
            industry:key
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/company/${userId}/industry`);
        const url = http.combine(path, queryString);
        return this.http.patch(url, body)
    }
    async getBudget(budgetId, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/budgets/${budgetId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async getCompanyInfo(companyId, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies/${companyId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async getMentors(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/mentors`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async updateUserProfileMentor(mentorId, userId, query = {}) {
        const { http } = this;
        const body = {
        id: mentorId,
        };
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/${userId}/mentor`);
        const url = http.combine(path, queryString);
        return this.http.patch(url, body);
    }

    async getEmployees (query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/employees/roles`);
        const url = http.combine(path, queryString);
        const response = await this.http.get(url);
        return response.data
    }

    async getCustomEmployees(industry,query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/employees/industry/custom`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async hireTeam(data, query = {}) {
        const { http } = this;
        const {companyId,employeeIds } = data
        const body = {
            companyId,
            employeeIds
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/teams`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async hireCustomTeam(data, query = {}) {
        const { http } = this;
        const {companyId,employees } = data
        const body = {
            companyId,
            employees
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/teams/custom`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async getBuildings(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/buildings`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async buyRentBuilding(data,paymantType, query = {}) {
        const { http } = this;
        const { userId,buildingId, companyId } = data
        const body = {
            userId,
            buildingId,
            companyId
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/buildings/${paymantType}`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async getEquipment(itemsType,query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/equipment-items/categories/${itemsType}`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async rentBuygetEquipment(data, paymantType ,query = {}) {
        const { http } = this;
        const { companyId , items } = data
        const body = {
            companyId,
            items
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/equipment-items/${paymantType}`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async updateUserProfile(values, userId, query = {}) {
        const { http } = this;
        const { name, city, gender } = values
        const body = {
            name,
            city,
            gender
        };
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/${userId}`);
        const url = http.combine(path, queryString);
        return this.http.patch(url, body);
    }
    async founderSalary(data ,query = {}) {
        const { http } = this;
        const { companyId , keep, half } = data
        const body = {
            keep,
            half
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies/founder-salary/${companyId}`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async legalAspects(companyId,legal ,query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies/legal/${companyId}/${legal}`);
        const url = http.combine(path, queryString);
        return this.http.post(url)
    }
    async getLeaders(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/budgets/leaderboard`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async getAllPlayers(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/budgets/all-users`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }
    async getGeneralStats(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies/stats`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }

    async postElevatorPitch(userId, link, query = {}) {
        // const {userId,link} = data
        const body = {
            userId,
            url: link
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/elevator-pitch`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }


    async getBussinesModels(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/user-profiles/business-models`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }

}

export default EsgService;
