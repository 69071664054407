import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import "./logedInUser.style.less";
import logo from ".././logo.png";
import { Avatar, avatarArr } from "../../../assets/images/index.js";
import * as token from "../../../services/core/token";

import LoggedAvatar from "../../../assets/images/logged-avatar.png";
import { Menu, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  Coins,
  SoundOn,
  SoundOff,
  BhsLang,
  EnLang,
} from "../../../assets/images/index";
import ModalChartPie from "../../../components/Modal/ModalChartPie/modalChartPie.component";
import sound from "../../../assets/audio/BS.mp3";

import { openDB } from "idb";
const { Option } = Select;

/* For pwa */
let dbPromise;
let idbBudget;

// Define indexDB
if ("indexedDB" in window) {
  dbPromise = openDB("esgDB", 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (!db.objectStoreNames.contains("sync_requests")) {
        db.createObjectStore("sync_requests" /* , {keyPath: 'id'} */);
      }

      if (!db.objectStoreNames.contains("monthlyExpenses")) {
        db.createObjectStore("monthlyExpenses" /* , {keyPath: 'id'} */);
      }

      if (!db.objectStoreNames.contains("sequence")) {
        db.createObjectStore("sequence" /* , {keyPath: 'id'} */);
      }

      if (!db.objectStoreNames.contains("progres")) {
        db.createObjectStore("progres" /* , {keyPath: 'id'} */);
      }

      if (!db.objectStoreNames.contains("fetchLoader")) {
        db.createObjectStore("fetchLoader" /* , {keyPath: 'id'} */);
      }

      if (!db.objectStoreNames.contains("res")) {
        db.createObjectStore("res" /* , {keyPath: 'id'} */);
      }
    },
    blocked() {
      // …
    },
    blocking() {
      // …
    },
    terminated() {
      // …
    },
  });
}

const { SubMenu } = Menu;
const OPEN_KEYS = ["sub1"];
/**
 * Check Header component
 * @class
 * @param {Object} properties ( userStore, languageStore, soundsStore, history, progressBarStore )
 * @returns Icon of BiZVenture with logo . Sound on and sound off buttons . Two selectable  icons
 * of languages EN/BHS. Icon of avater that when is clicked open dropdown menu with UserProfile,
 * Bisnis Model Canvas and Logout optios , ingame budget and when budget is clicked open ModalChartPie.
 */
const HeaderUserLogedIn = ({
  userStore,
  languageStore,
  soundsStore,
  history,
  progressBarStore,
}) => {
  const { language } = languageStore;
  const [activePage, setActivePage] = useState("/selectmode");

  const { progressOne } = progressBarStore;

  const { t, i18n } = useTranslation("header");
  const [openKeys, setOpenKeys] = useState(OPEN_KEYS);
  const [avatar, setAvatar] = useState(null);
  const tokenValue = token.get();
  const [budget, setBudget] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [audio] = useState(new Audio(sound));

  /**
   * Function Music On.Check if mobix soundStore.sounds is false value if it is
   * enable sound and sounds wil be played
   */
  const musicOn = () => {
    if (!soundsStore.sounds) {
      audio.play();
      audio.loop = true;
      soundsStore.enableSounds();
    }
  };
  /**
   * Function Music On.Check if mobix soundStore.sounds is true value if it is
   * disble sound and sounds wont be played
   */
  const musicOf = () => {
    if (soundsStore.sounds) {
      audio.pause();
      soundsStore.disableSounds();
    }
  };

  useEffect(() => {
    if (language === "bhs") {
      document.querySelector(".bosnian").classList.add("active");
    } else {
      document.querySelector(".english").classList.add("active");
    }
  });

  const onOpenChange = (openKeys) => setOpenKeys([...OPEN_KEYS, ...openKeys]);

  useEffect(() => {
    switch (progressOne) {
      case 11:
        setActivePage("/avatars");
        break;
      case 22:
        setActivePage("/charctername");
        break;
      case 34:
        setActivePage("/mentorselect");
        break;
      case 47:
        setActivePage("/initsurvey");
        break;
      case 60:
        setActivePage("/meetmentor");
        break;
      case 72:
        setActivePage("/onboardinginfo");
        break;
      default:
        setActivePage("/selectmode");
    }
  }, [progressOne]);

  useEffect(() => {
    if (userStore.userInfoEsg || userStore.budget || userStore.companyInfo) {
      const { avatarUrl } = userStore.userInfoEsg;
      const chosenAvatar = avatarArr.find((avatar) => avatar.key === avatarUrl);
      setAvatar(chosenAvatar);
      const { budget, companyInfo } = userStore;
      setBudget(budget);
      setCompanyInfo(companyInfo);
    }
  }, [userStore.userInfoEsg, userStore.budget, userStore.companyInfo]);

  /**
   * Function Clear Storage . Function that set all values of mobix userStore
   * to null , remove all items from local storage and redirect user to "/" route.
   */
  const clearStorage = () => {
    userStore.updateUserInfoEsg(null);
    userStore.updateCompanyInfo(null);
    userStore.updateBudget(null);
    localStorage.clear();
    token.remove(tokenValue);
    history.push("/");
  };

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.alt);
  };
  /**
   * Select English function.Set in language store state of language to 'EN'
   * and select active icon to be English
   */
  const selectEnglish = () => {
    languageStore.setEnglishLanguage();
    document.querySelector(".bosnian").classList.remove("active");
    document.querySelector(".english").classList.add("active");
  };
  /**
   * Select BHS function.Set in language store state of language to 'BHS'
   * and select active icon to be BHS
   */
  const selectBhs = () => {
    languageStore.setBhsLanguage();
    document.querySelector(".english").classList.remove("active");
    document.querySelector(".bosnian").classList.add("active");
  };
  /**
   * Helper function for redircting user
   * @param {String} value Locaction where is user redirected
   * @returns redirect user to value route
   */
  const redirectHelperFunc = (value) => {
    history.push(`${value}`);
  };
  const [visible, setVisible] = useState(false);

  /**
   * Triger Visible function changed the state of visible dependig what was previus value
   */
  const trigerVisible = () => {
    setVisible(!visible);
  };
  /**
   * Handle Ok function activete trigerVisible functions
   */
  const handleOk = () => {
    trigerVisible();
  };
  /**
   * Alow User Profile function
   * @returns Alow user to click on userProfile on dropdown menu if value is true
   */
  const alowUserProfile = () => {
    if (companyInfo) {
      if (companyInfo.team && navigator.onLine) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  /**
   * In Sequnce function
   * @returns Alow user to to click on BMC on user profile drop down menu value is true
   */
  const inSequnce = () => {
    if (companyInfo) {
      if (
        companyInfo.building &&
        companyInfo.team &&
        companyInfo.equipment &&
        companyInfo.legalAspect &&
        navigator.onLine
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="header-main">
      <div className="header-holder">
        <div className="left-corner">
          <img
            onClick={() => history.push("/selectmode")}
            className="logo-place"
            src={logo}
            alt="logo"
          />
        </div>
        {companyInfo && companyInfo.hasOwnProperty("monthlyExpense") ? (
          <div className="budget-section">
            <ModalChartPie
              visible={visible}
              trigerVisible={trigerVisible}
              handleOk={handleOk}
            />
          </div>
        ) : null}

        <div className="game-sound">
          <button className="onmusic" onClick={musicOn}>
            <SoundOn />
          </button>
          <button className="offmusic" onClick={musicOf}>
            {" "}
            <SoundOff />
          </button>
        </div>

        <div className="right-corner">
          <div id="lng-switch" onClick={(e) => changeLanguage(e)}>
            {/* <span>{t("header:drop-menu.chooseLanguage")}:</span> */}
            <div className="inline">
              <EnLang selectEnglish={selectEnglish} />
              <BhsLang selectBhs={selectBhs} />
            </div>
          </div>

          <div onClick={trigerVisible} key="0">
            {budget && budget.budget.hasOwnProperty("remaining") ? (
              <div className="budget">
                {`${budget.budget.remaining}/${budget.budget.totalAmount}`}
                <Coins />
              </div>
            ) : (
              <div className="no-see"></div>
            )}
          </div>

          <Menu
            // onClick={handleClick}
            style={{ width: 256 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="horizontal"
            triggerSubMenuAction="click"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            <SubMenu
              key="sub4"
              title={
                !avatar ? (
                  <img
                    className="logged-icon"
                    src={LoggedAvatar}
                    alt="logged"
                  />
                ) : (
                  <div className="logged-icon" src={LoggedAvatar} alt="logged">
                    <Avatar src={avatar.src} />{" "}
                  </div>
                )
              }
            >
              <Menu.Item
                disabled={alowUserProfile() ? false : true}
                onClick={() => redirectHelperFunc("userProfile")}
                className="after-arrow"
                key="2"
              >
                {t("header:drop-menu.userProfile")}
              </Menu.Item>

              <Menu.Item
                disabled={inSequnce() ? false : true}
                onClick={() => redirectHelperFunc("businessModelCanvas")}
                key="3"
              >
                {t("header:drop-menu.bmc")}
              </Menu.Item>

              <Menu.Item
                disabled={inSequnce() ? false : true}
                onClick={() => redirectHelperFunc("bonuschest")}
                key="4"
              >
                {t("header:drop-menu.bonusChest")}
              </Menu.Item>

              <Menu.Item key="5" onClick={clearStorage}>
                {t("header:logout")}
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  inject(
    "userStore",
    "languageStore",
    "soundsStore",
    "progressBarStore"
  )(observer(HeaderUserLogedIn))
);
