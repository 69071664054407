import React, { useState, useEffect, useRef } from 'react'
import AnswerModal from '../../../../../../components/Modal/AnswerModal/answer.modal.component'
import './answer.component.style.less'

import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Avatar, Mentor, avatarArr, Smiley, Tugy, mentorArr, Coins } from '../../../../../../assets/images/index';
import { setLocalStorage, RANDOM_EVENT_VALUE, INCREMENT_CURRENT_QUESTION_INDEX, SELECTED, getLocalStorage, removeLocalStorage, ANSWER_MODAL, USER_PROFILE_ESG, RANDOM_EVENT_MODAL } from '../../../../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../../../../components/Button/button.component'
import { randomEventsHp } from '../../../../../../helperFunctions/helperFunctions'
import { useTranslation } from 'react-i18next'
/**
 * Answer component
 * @class 
 * @param {Object} properties (  history, languageStore, answers, setAnswerValue, hanleNextQuestion, mentorUsed,
 * nextQuestionModal, answerCorrectnessModal, closeQuestionModal, question,
 * setAnswerCorrectnessModal, setRandomEventModal, sequence, currentQuestionIndex, companyInfo, answerValue)
 * @returns Answer CorrectnessModal with Picture of mentor text and images of smile and tugy face  , Picture of 
 * mentor and text of question. List of Answers.Two buttons Continue that redict to correcntes modal/next question
 * and bmc depending of sequnce state .And Back to office button that redirect user to office
 */
const Answer = ({ history, languageStore, answers, setAnswerValue, hanleNextQuestion, mentorUsed,
    nextQuestionModal, answerCorrectnessModal, closeQuestionModal, question,
    setAnswerCorrectnessModal, setRandomEventModal, sequence, currentQuestionIndex, companyInfo, answerValue }) => {
    const { t } = useTranslation(['common', 'sequences'])
    const [selected, setSelected] = useState(null)
    const [userEsg, setUserEsg] = useState(null)
    const [userName, setUserName] = useState(null)
    const [userImage, setUserImage] = useState(null)
    const [mentorImage, setMentorImage] = useState(null)
    const refStart = React.createRef();
    const corectAnswer = answers.filter(x => x.correct)[0]
    
    /**
  * Function Handle Scroll One
  * @returns When message is shown scrooling page in to  center of user viewport on screen.
  */
    const handleScrollToOne = () =>
        refStart.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center'
        });

    useEffect(() => {
        const res = getLocalStorage(SELECTED).SELECTED
        setSelected(res)
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    }, [])

    useEffect(() => {

        if (userEsg) {
            setUserImage(userEsg.avatarUrl)
            setMentorImage(userEsg.mentor.avatarUrl)
        }
    }, [userEsg])

    /**
     * Function that close modal with submiteted answer and open new question and set selected answer
     * to null if answer dont have have random event.
     * @param {Object} selected Selected Answer(id,answer,correct)
     * @returns in case answer is selected and selected answer have random event set to local storage
     * RANDOM_EVENT_VALUE to true and RANDOM_EVENT_VALUE value with selected.randomEvent set state of 
     * answerCorrrectenessModal to false , state of randomEventModal to true and state of answeValue 
     * to selected (answer selected) in case there is not random event calls from props method hanleNextQuestion and pass
     * parameters selected , mentorUsed and question._id and calls from props method closeQuestionModal and 
     * pass argument selected (answer selected)
     */
    const onOkModal = (selected) => {
        const specialRandomEvent = randomEventsHp(sequence, selected, currentQuestionIndex, companyInfo)
        if (selected) {
            if (selected.hasOwnProperty('randomEvent') && selected.randomEvent.length > 0 && specialRandomEvent) {
                setLocalStorage(selected.randomEvent, RANDOM_EVENT_VALUE)
                setLocalStorage(true, RANDOM_EVENT_MODAL)
                setAnswerCorrectnessModal(false)
                setRandomEventModal(true)
                closeQuestionModal(selected)
                setAnswerValue(selected)
                // hanleNextQuestion(selected, mentorUsed, question._id)
            } else {
                hanleNextQuestion(selected, mentorUsed, question._id)
                closeQuestionModal(selected)
            }
        } else {
            closeQuestionModal(selected)
            // hanleNextQuestion(selected, mentorUsed, question._id)
        }
        // removeLocalStorage(ANSWER_MODAL)

        /* Check if there are any questions left in sequence by current and if there is not
        random event in last answer.
        question index and redirect user to fill BMC if there is one
        in sequnce if not redirect user to Montly event */
        // if (currentQuestionIndex + 1 === quiz.questions.length && !selected.hasOwnProperty('randomEvent')) {
        //     
        //     setLocalStorage(0, INCREMENT_CURRENT_QUESTION_INDEX)
        //     if (!sequence.summary) {
        //         setLocalStorage('monltyEvent', SEQUENCE_PROGGRES)
        //         setSequnceProgres('monltyEvent')
        //     } else {
        //         setLocalStorage('bmc', SEQUENCE_PROGGRES)
        //         setSequnceProgres('bmc')
        //     }
        // }
    }
    /**
     * Method for setting selected answer, comparing by id's of answers array and clicked answer
     * @param {String} id Id of selected answer
     * @returns selected state set value of x(answer) set state of asnwer value of x(answer)
     * selLolac storage SELECTED with value x(answer)
     */
    const selectedAnswer = (id) => {
        answers.filter((x) => {
            if (x._id === id) {
                setSelected(x)
                setAnswerValue(x)
                setLocalStorage(x, SELECTED)
            }
        })
    }
   /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }
    const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
    const userProfileImage = utilityFilter(avatarArr, userImage)

    /**
     * Select Languege function
     * @returns Returns bhs/en answer depending on current language
     */
    const selectLanguage = (answer) => {
        const { language } = languageStore;

        let AnswerContent;

        answer.locales.map(item => {
            if(item.locale === language) {
                AnswerContent = item.answer_content.answerText;
            }
        })

        return AnswerContent;

    }
    return (
        <div className="answer-section" ref={refStart} onAnimationStart={() => handleScrollToOne()}>
            {!answerCorrectnessModal ?
                <div className="answer-fade">{answers.map((x) => {
                    return <div className={!(selected === x) ? "answer-style" : "answer-style-selected"}
                        onClick={() => selectedAnswer(x._id)}
                        key={x._id}>

                        {selectLanguage(x)}

                    </div>
                })}</div>
                :
                <div className='anwer-correct'>
                    <div className="sequence-board-info-talk-user">
                        <p className="hi-msg-user">{t('sequences:sequence-answer')} {selectLanguage(answerValue)}</p>
                        {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
                    </div>
                    <div className="sequence-board-info-talk-mentor">
                        {answerValue.correct
                            ?
                            <div className="answerTrueFalse"> <Smiley /><p className="coinsAchivement" style={{ display: 'flex' }}> <span style={{ color: 'green' }}>+{answerValue.points}</span> <Coins /></p></div>
                            :
                            <div className="answerTrueFalse"> <Tugy /><p className="coinsAchivement" style={{ display: 'flex' }}> <span style={{ color: 'red' }}>{answerValue.points}</span> <Coins /></p></div>

                        }
                    </div>
                    { question.explanation ?
                    <div className="sequence-board-info-talk-mentor2">
                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                         <p className="hi-msg">{languageStore.language == 'bhs' ? <span>{question.explanation.bhs}</span> : <span>{question.explanation.en}</span>}</p>
                    </div>
                    : null }
                </div>
            }
            <div className="button-landing">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('sequences:sequence-back')}
                    customBtn={true}
                    onClick={() => history.push('office')}
                />
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('sequences:sequence-next')}
                    customBtn={true}
                    disabled={selected ? false : true}
                    onClick={!answerCorrectnessModal ?
                        () => { setAnswerCorrectnessModal(true); setLocalStorage(true, ANSWER_MODAL) }
                        : () => onOkModal(selected)}
                />

            </div>
        </div>
    )
}

export default withRouter(inject('languageStore')(observer(Answer)))