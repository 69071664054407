import axios from 'axios';

import * as qs from './qs';
import * as url from './url';
// import * as configuration from '../../config';
import * as token from './token';

const getToken = () => {
    return token.get();
};

const registerInterceptor = (service) => {
    service.interceptors.request.use(
        config => {
            config.headers["x-eusi-bucket-id"] = "de4e8f80-07ce-11eb-a7de-094af951edae"
            config.headers["x-eusi-bucket-secret"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJidWNrZXRJZCI6ImRlNGU4ZjgwLTA3Y2UtMTFlYi1hN2RlLTA5NGFmOTUxZWRhZSIsInR5cGUiOiJNQU5BR0VNRU5UIiwiaWF0IjoxNjAxOTg3MDY0fQ.j6u6eKc4OtJKrmcjRzh_90X8XrPkg4ILjwhK65wDpOI"
            config.headers["x-eusi-user-auth-token"] = getToken();
            config.headers["Authorization"] = "Bearer " + getToken();
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );
};

class HttpClient {

    constructor(baseURL) {
        
        // const config = configuration.get();
        const service = axios.create({
            timeout: 200000,
            baseURL        
        });    

        registerInterceptor(service);
        this.service = service;
        this.qs = qs;
        this.url = url;
    }

    async get(url, config) {
        const { service } = this;
        return service.get(url, config);
    }

    async delete(url, config) {
        const { service } = this;
        return service.delete(url, config);
    }

    async head(url, config) {
        const { service } = this;
        return service.head(url, config);
    }

    async post(url, data, config) {
        const { service } = this;
        return service.post(url, data, config);
    }

    async put(url, data, config) {
        const { service } = this;
        return service.put(url, data, config);
    }

    async patch(url, data, config) {
        const { service } = this;
        return service.patch(url, data, config);
    }

    combine(url, queryString) {
        return [url, queryString].join('?')
    }
}

export default HttpClient;
