import React from 'react'
import * as token from '../../services/core/token'
import {Route,Redirect} from 'react-router-dom'

const getToken = () => {
    return token.get();
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = !!getToken()
    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        />
    )
};

export default PrivateRoute;