import React from 'react' 
import '../payEquipment.component.style.less';
import { HardwareEquipment } from '../../../../assets/images/index';
import { Coins } from '../../../../assets/images';


const EquipmentItem = ({item}) => {
    return (
      <div className="device-per-eqi">
        <HardwareEquipment imageUrl={item.equipment.imageUrl}  />
        <p className="second-child">{item.equipment.name}<span className="green">{item.equipment.price} <Coins/></span></p> 
        <span className="right-number">X{item.amount}</span>
      </div>
    )
}

export default EquipmentItem