import React, { useState, useEffect } from 'react';
import './payEquipment.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import Button from '../../../components/Button/button.component';
import EquipmentItem from './EqupmentItem/equipmentItem.component'
import { Coins } from '../../../assets/images';
import { getLocalStorage, ALL_EQUIPMENT, USER_PROFILE_ESG, COMPANY_INFO, BUDGET, setLocalStorage } from '../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../services/EsgServices/esg.service'
import VideoTwo from '../../../assets/video/office-video-itgirls.mp4'
import VideoTwoBhs from '../../../assets/video/randomEvent2BHS.mp4'
import Conversation_about_reusable_and_plastic_dishes_BHS from '../../../assets/video/Conversation_about_reusable_and_plastic_dishes_BHS.mp4'
import Conversation_about_reusable_and_plastic_dishes_ENG from '../../../assets/video/Conversation_about_reusable_and_plastic_dishes_ENG.mp4'
import VideoLoad from '../../../components/Video/video.component'
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'

/**
 * Equipment Payment component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with video and Skip button covering component. After modal is closed.
 * Title and list od equipment selected is rendered.Under that we have two text area with price of total buy
 * or rent/mount equipment Continue button that redirect user to legalaspects component.
 * * Back button for going back to previous page. 
 */
const EquipmentPayment = ({ history, userStore, soundsStore, progressBarStore, languageStore }) => {
  const { t } = useTranslation(["common", "payment"])
  const { language } = languageStore;

  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [allEquipment, setAllEquipment] = useState(null)
  const [equipmentList, setEquipmentList] = useState(null)
  const [totalRent, setTotalRent] = useState(null)
  const [totalCost, setTotalCost] = useState(null)
  const [selectBuy, setselectBuy] = useState(null);
  const [selectRent, setselectRent] = useState(null);
  const [videoEnd, setVideoEnd] = useState(false)
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  /**
   * Function that set  state of selctBuy to true and state of 
   * selecteRent to false
   */
  const setChoosedBuy = () => {
    setselectBuy(true);
    setselectRent(false);
  };
  /**
   * Function that set  state of selctBuy to false and state of 
   * selecteRent to true
   */
  const setChoosedRent = () => {
    setselectRent(true);
    setselectBuy(false);
  };

  useEffect(() => {

    //Get userProfile,companyInfo,budgde from localStorage
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setBudget(getLocalStorage(BUDGET).BUDGET)

    //Get all Equipment from localStorage
    setAllEquipment(getLocalStorage(ALL_EQUIPMENT).ALL_EQUIPMENT)
    const res = getLocalStorage(ALL_EQUIPMENT).ALL_EQUIPMENT

    //Filter items that are choosed at least one time
    let filteredItems;
    if (res) {
      filteredItems = res.filter((x) => {
        return x.amount > 0
      })
      setEquipmentList(filteredItems)

      //Calculate price user rent equipment
      const totalRent = filteredItems.map(x => x.amount * x.equipment.rentPrice).reduce((x, y) => x + y, 0)
      setTotalRent(totalRent)

      //Calculate price of all choosed  items
      const total = filteredItems.map(x => x.amount * x.equipment.price).reduce((x, y) => x + y, 0)
      setTotalCost(total)
    }

    progressBarStore.setProgress(100, 90, 0);
  }, [])

  //Update user store
  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])

   /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }
  /**Submit payment function that take no parameters.First we create var allItems list we 
   * check is payment type is buy or rent.New data objectet is created with companyId and items.
   * EsgService is called with method rentBuygetEquipment data and paymantType are passed as function params.
   * After that esgService is called with getCompanyInfo function that take one parameted id and after 
   * backend return response res.data is stored in LocalStorage in COMPANY_INFO and in mobix userStore
   * in company info with parameter res.data.User is redirected to legalaspects route.
   */
  const submitPayment = () => {

    //Create new array with items amount and items id's
    const allItems = allEquipment.map(item => ({ amount: item.amount, item: item.equipment._id })).filter((y) => y.amount > 0)
    let paymantType;
    if (selectBuy) {
      paymantType = 'buy'
    } else if (selectRent) {
      paymantType = 'rent'
    }

    const { id } = userStore.companyInfo

    //Data object with allItems array and companyId
    const data = {
      companyId: id,
      items: allItems
    }
    //Call service with data object and type of payment
    esgService.rentBuygetEquipment(data, paymantType).then((res) => {

    }).then(() => {
      esgService.getCompanyInfo(id).then((res) => {
        setLocalStorage(res.data, COMPANY_INFO)
        userStore.updateCompanyInfo(res.data)
        history.push('legalaspects')
      })
    })
  }
  return (
    <div className="checkout app">
      {!videoEnd ? <VideoLoad url={language === 'bhs' ? Conversation_about_reusable_and_plastic_dishes_BHS : Conversation_about_reusable_and_plastic_dishes_ENG} setVideoEnd={setVideoEnd} /> :
        <div className="checkout-section">
          <h2>{t('payment:checkout')}</h2>
          <div className="button-checkout-holder fadeInBottom-checkout">
            <div className="checkout-text">
              <h4>{t('payment:checkout')}</h4>
              <div className="devices-complete-holder">
                <p>{t('payment:devices')}</p>
                {equipmentList && equipmentList.map((x) => {
                  return <EquipmentItem item={x} key={x.equipment._id} />
                })}
              </div>
            </div>
          </div>
          <div className="counting-price">
            <div className="totalCount fadeInBottom-total" onClick={() => { setChoosedBuy(); playSound(likeAudio); }}>
              <p className={
                selectBuy
                  ? 'total-count-text-selected'
                  : 'total-count-text'
              } >{t('payment:total-buy')}</p>
              <p className="total-count-number">
                {totalCost} <Coins />
              </p>
            </div>
            <div className="totalCount rent fadeInBottom-total" onClick={() => { setChoosedRent(); playSound(likeAudio); }}>
              <p className={
                selectRent
                  ? 'total-count-text-selected'
                  : 'total-count-text'
              }>{t('payment:total-rent')}</p>
              <p className="total-count-number">
                {totalRent} / {t('payment:month')} <Coins />
              </p>
            </div>
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }}
            />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              disabled={selectBuy || selectRent ? false : true}
              onClick={() => { submitPayment(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore', 'languageStore')(observer(EquipmentPayment)));
