import React, { useState } from 'react'
import Modal from '../modal.component'
import './modalSequence.component.style.less'
import { Coins } from '../../../assets/images/index'


const ModalSequence = () => {
  const [visible, setVisible] = useState(true);

  const trigerVisible = () => {
    setVisible(!visible)
  }

  return (
    <Modal
      modalStyleTest='modal-content-sequence'
      closable={false}
      okBtnText='Close'
      footer={true}
      btnText="more info"
      additionalStyle="signUp"
      visible={visible}
      trigerVisible={trigerVisible}
    >
      <div className="modal-sequence-section">
        <div className="button-modal-sequence">
          <h2>Correct answer</h2>
          <h2 className="class-coins">You earned 750 <Coins /></h2>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSequence;