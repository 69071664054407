import React, { useState, useEffect } from "react";
import "./landing.page.style.less";
import Button from "../../components/Button/button.component";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Avatar, avatarArr } from "../../assets/images/index.js";
import { LandingAbout } from "../../assets/images/index.js";
import { FeaturesB } from "../../assets/images/index.js";
import { FooterLanding } from "../../assets/images/index.js";
import {
    LeftBubble,
    RightBubble,
    SectionLogoLanding,
} from "../../assets/images/index.js";
import Register from "../LogIn/registration.form";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import { Cookies } from "../../assets/images/index";

import { Redirect } from "react-router-dom";

/**
 * Check Landing component
 * @class
 * @param {Object} properties ( history, progressBarStore )
 * @returns Ladnign page which is made from more components: LogoGame, Features, 
 * About, AvatarHome, FixedLogoName and CookieConsent
 */

const Landing = ({ history, progressBarStore }) => {
    const [cookeieConsent, setCookieConsent] = useState(false);
    const { t } = useTranslation(["landing", "registration", "cookie", "common"]);

    const { setProgress } = progressBarStore;

    useEffect(() => {
        setProgress(0, 0, 0);
        setTimeout(() => {
            setCookieConsent(true);
        }, 2000);
    }, []);
    

    /** Method that leads to login page */
    const redirectLogin = () => {
        history.push("/login");
    };

    /** Method that leads to registration page */
    const redirectRegistration = () => {
        history.push("./registration");
    };

    /** Method that takes 3 random avatars */
    const sixAvatars = avatarArr.slice(3, 10);

    const AvatarHome = () => {
        return (
            <div className="section app">
                <div className="section-one">
                    <h2>{t("landing:heading-text")}</h2>
                    <div className="avatar-holder">
                        {sixAvatars.map((avatar, index) => (
                            <Avatar src={avatar.src} />
                        ))}
                    </div>
                    <div className="button-landing">
                        <Button
                            additionalStyle="signIn"
                            text={t("registration:sign-in")}
                            customBtn={true}
                            onClick={redirectLogin}
                        />
                        <Button
                            additionalStyle="buttonMakeAcc"
                            text={t("registration:sign-up")}
                            customBtn={true}
                            onClick={redirectRegistration}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const About = () => {
        return (
            <div className="section app">
                <div className="section-two">
                    <div className="left-part-about">
                        <h2>{t("landing:about-game.about-heading")}</h2>
                        <p>{t("landing:about-game.about-p1")}</p>
                        {/* <p>{t("landing:about-game.about-p2")}</p> */}
                    </div>
                    <div className="right-part-about">
                        <LandingAbout />
                    </div>
                </div>
            </div>
        );
    };

    const Features = () => {
        return (
            <div className="section app">
                <div className="section-three">
                    <div className="left-image-features">
                        <FeaturesB />
                    </div>
                    <div className="features-images">
                        {/* <h2>{t("landing:game-features.feature-heading")}</h2> */}
                        <div className="text-features">
                            <span>✔️</span>{" "}
                            <p>{t("landing:game-features.feature-one")}</p>
                        </div>
                        <div className="text-features">
                            <span>✔️</span>{" "}
                            <p>{t("landing:game-features.feature-two")}</p>
                        </div>
                        <div className="text-features">
                            <span>✔️</span>{" "}
                            <p>{t("landing:game-features.feature-three")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LandingRegistration = () => {
        return (
            <div className="section app">
                <div className="section-four">
                    <Register />
                </div>
            </div>
        );
    };

    const FixedLogoName = () => {
        return (
            <div className="section-five">
                <p>{t("common:supported")}</p>
                <FooterLanding />
            </div>
        );
    };

    const LogoGame = () => {
        return (
            <>
                <div>
                    <LeftBubble />
                    <RightBubble />
                </div>
                <div className="section-logo">
                    <SectionLogoLanding />
                </div>
            </>
        );
    };

    if (navigator.onLine) {
        return (
            <div>
                <div className="">
                    <LogoGame />
                    <Features />
                    <About />
                    <AvatarHome />
                    {/* <LandingRegistration /> */}
                    <FixedLogoName />
                    {cookeieConsent ? (
                        <CookieConsent
                            location="none"
                            buttonText={t("common:accept")}
                            declineButtonText={t("common:decline")}
                            cookieName="bizVenture"
                            style={{
                                background: "#fff",
                                alignItems: "center",
                                padding: "0 100px",
                            }}
                            buttonStyle={{
                                background: "#5EA625",
                                color: "#fff",
                                fontSize: "18px",
                                border: "1px solid #5EA625",
                                borderRadius: "8px",
                                padding: "5px 10px",
                            }}
                            declineButtonStyle={{
                                background: "#fff",
                                color: "#767676",
                                fontSize: "18px",
                                border: "1px solid #767676",
                                borderRadius: "8px",
                                padding: "5px 10px",
                            }}
                            expires={30}
                            enableDeclineButton
                            flipButtons
                        >
                            <div className="cookie-content">
                                <Cookies />
                                <p className="cookie-policy">{t("cookie:content")} <a onClick={() => history.push('/privacy-policy')}>{t("cookie:cookie-policy")}</a></p>
                            </div>
                        </CookieConsent>
                    ) : null}
                </div>
            </div>
        );
    } else {
        // if we are offline, then redirect to this url
        return <Redirect to="/game" />;
    }
};

// export default withRouter(Landing);
export default withRouter(inject("progressBarStore")(observer(Landing)));
