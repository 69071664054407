import React, { useState, useEffect } from 'react';
import "./elevatorPitch.component.style.less";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next"
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../../services/EsgServices/esg.service';
import { ElevatorP } from "../../../../assets/images/index"

/**
 * Elevator pitch component
 * @class
 * @param {Object} properties (userStore)
 * @returns This component is used in User Profile component where you can add your youtube link
 */
const BussinesPlan = ({ userStore }) => {
    const { t } = useTranslation("user-profile")
    const [upload, setUpload] = useState('')
    const [userEsg, setUserEsg] = useState(null)
    const esgService = new EsgService()

    const userId = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG._id

    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    }, [])

    const setUserStore = (userEsg) => {
        userStore.updateUserInfoEsg(userEsg)
    }

    useEffect(() => {
        if (userEsg) {
            setUserStore(userEsg)
        }
        if (userStore.userInfoEsg) {
            setUpload(userStore.userInfoEsg.elevatorPitchUrl)
        }
    }, [userEsg])

    useEffect(() => {
        const userAll = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        userAll.elevatorPitchUrl = upload;
        setLocalStorage(userAll, USER_PROFILE_ESG)
    }, [upload])


    const onSubmit = (e) => {
        setUpload(upload)
        esgService.postElevatorPitch(userId, upload).then((res) => {
            setLocalStorage(res.data.elevatorPitchUrl, USER_PROFILE_ESG.elevatorPitchUrl)
            userStore.updateUserInfoEsg(res.data)
        })
    }



    return (
        <>
            <div className="form-link">
                <span className="explanation">{t("user-profile:link-yt-video")}</span>

                <input onChange={(e) => setUpload(e.target.value)} id="input-data" className="link-holder" type="text" placeholder={t("user-profile:link-yt-video-here")} onFocus={(e) => e.target.placeholder = ""}
                />
                <div className="inline-preview">
                    <button onClick={(e) => onSubmit(e)} className="button-save"> {t("user-profile:save")} </button>
                    {upload ? <a className="link-preview" target="_blank" href={upload}>{t("user-profile:link-preview")}</a> : null}
                </div>
            </div>
            <div className="imageOnHover elevator-pitch">
                <div className="onHoverText"><span>{t("user-profile:elevator-expl")}</span></div>
                <div className="nd"><ElevatorP /></div>
            </div>
        </>
    );
}

export default withRouter(inject("userStore")(observer(BussinesPlan)));