import React, { useState, useEffect } from "react";
import "./awardCoins.component.style.less";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import {
  setLocalStorage,
  getLocalStorage,
  COMPANY_INFO,
  BUDGET,
  USER_PROFILE_ESG,
} from "../../../localStorageMobxStore/localStorageUserProfile";
import Button from "../../../components/Button/button.component";
import EsgService from "../../../services/EsgServices/esg.service";
import { Coins } from "../../../assets/images/index.js";
import { useTranslation } from "react-i18next";
import IntroOfficeModal from "../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component";
import ClickSound from "../../../assets/audio/button22.wav";

/**
 * Award Coins component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Text area and button Continuo iside the modal.After modal is close render component with
 * Title and text. And two buttons : Contiue button that redirect user to industry type.
 * Back button for going back to previous page.
 */
const AwardCoins = ({ userStore, history, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "awardcoins"]);
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService();
  const [userEsg, setUserEsg] = useState(null);
  const [budget, setBudget] = useState(null);
  const [testModal, setTestModal] = useState(true);
  const [visible, setVisible] = useState(true);
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which
   * can be either attached to a document for the user to interact with and/or listen to,
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = (audioFile) => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  };
  /**
   * Function that hide modal with introducing  info content and
   * set state of modal and visibe to false value
   */
  const toggleContent = () => {
    setTestModal(false);
    setVisible(false);
  };

  useEffect(() => {
    setBudget(getLocalStorage(BUDGET).BUDGET);
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
    progressBarStore.setProgress(100, 1, 0);
  }, []);

  useEffect(() => {
    if (userEsg || budget) {
      setUserStore(userEsg, budget);
    }
  }, [userEsg, budget]);
  /**
   * Function that two parametars userEsg , singlePlayer
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value and
   * userStore value and budget state with of budget value
   */
  const setUserStore = (userEsg, budget) => {
    userStore.updateUserInfoEsg(userEsg);
    userStore.updateBudget(budget);
  };
  /**
   * Function that called on clicking Continue button.Its ceck if user have company if he have
   * redirect him to industrytype , companyname or chooseemployees depending what did he selecte in game
   * in case user dont/didnt create company redirct him to industrytype
   */
  const selectCompany = () => {
    if (userStore.budget.budget.hasOwnProperty("company")) {
      esgService
        .getCompanyInfo(userStore.budget.budget.company)
        .then((response) => {
          if (!response.data.hasOwnProperty("industry")) {
            history.push("industrytype");
          } else if (!response.data.hasOwnProperty("name")) {
            history.push("companyname");
          } else {
            setLocalStorage(response.data, COMPANY_INFO);
            userStore.updateCompanyInfo(response.data);
            history.push("chooseemployees");
          }
        });
    } else {
      history.push("industrytype");
    }
  };
  return (
    <div className="coin-awards app">
      {testModal ? (
        <IntroOfficeModal
          contentMsgOne={t("awardcoins:mentor-talk.mentor-msg-one")}
          // contentMsgTwo={
          //   <div>
          //     <p>{t('awardcoins:mentor-talk.mentor-msg-two')}</p>
          //     <p>{t('awardcoins:mentor-talk.mentor-msg-three')}</p>
          //     <p>{t('awardcoins:mentor-talk.mentor-msg-four')}</p>
          //     <p>{t('awardcoins:mentor-talk.mentor-msg-five')}</p>
          //     <p>{t('awardcoins:mentor-talk.mentor-msg-six')}</p>
          //   </div>
          // }
          visible={visible}
          onOk={() => {
            toggleContent();
            playSound(likeAudio);
          }}
        />
      ) : (
        <div className="coin-section-awards">
          <div className="text-holder box">
            <h2>{t("awardcoins:awards-headline")}</h2>
            <p>
              {t("awardcoins:awards-text-line1")}
              {budget.budget.totalAmount} <Coins />.
              {t("awardcoins:awards-text-line2")}
            </p>
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t("common:landing-back")}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }}
            />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t("common:landing-continue")}
              customBtn={true}
              disabled={false}
              onClick={() => {
                selectCompany();
                playSound(likeAudio);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  inject("userStore", "soundsStore", "progressBarStore")(observer(AwardCoins))
);
