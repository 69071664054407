import React, { useState } from 'react';
import './mentorSelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import { Mentor, mentorArr } from '../../../assets/images/index.js';
import Button from '../../../components/Button/button.component';
import EsgService from '../../../services/EsgServices/esg.service';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'
import ModalInfoMentor from '../../../components/Modal/ModalInfoMentor/modalInfoMentor.component'

/**
 * Meeet mentor component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.List of mentors with picture and name.Also more info button is included under every mentor where 
 * on clic that button modal is opened with information about that mentor and close button that close that modal.
 * And two buttons : Contiue button after user selected avatar buttun is enabled to submit save user select of avatar and 
 * redirect him to next page Initial survey and
 * Back button for going back to previous page. 
 */
const MentorSelect = ({ history, userStore, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "mentors"])
  /** Variable type Object where we set information and store about user in game.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)
  /**
   * This is variable type of String where we set and store information about what mentor user selected.
   * @type {Object} selectedAvatar String with name of mentor
   * Default value is null .
   */
  const [selectedMentor, setSelectedMentor] = useState(null);
  /**
   * This is variable type of Array where we set and store information about all mentors that 
   * are defind in base.
   * @type {Array} mentors Array of objects with data of mentors
   * Default value is null .
   */
  const [mentors, setMentors] = useState(null);
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService();

   /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);

  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }
  /**
  *Component did mount.Setting up userEsg from localStoraga with method getLocalStorage with parmeter USER_PROFILE_ESG.
  *Setting up progressBarStore with parameters (34,0,0).EsgService getMenors method is called where we 
  *fatch data from backend about list of mentors and we set it in mentors state.
  */
  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    esgService.getMentors().then((res) => {
      setMentors(res.data);
      progressBarStore.setProgress(34, 0, 0)
    });
  }, []);

  /**
    * Component did update.First check if userEsg have value and if it have set that value to userStore.
    * After that check if userStore.userInfoEsg have value and userStore.userInfoEsg have property
    * mentor and if it have set selectedMentor value with value from mobxstore userStore.userInfoEsg.mentor.avatarUrl.
  */
  useEffect(() => {
    if (userEsg) {
      setUserStore(userEsg)
    }
    if (userStore.userInfoEsg && userStore.userInfoEsg.hasOwnProperty('mentor')) {
      setSelectedMentor(userStore.userInfoEsg.mentor.avatarUrl)
    }
  }, [userEsg])
  /**
   * Function that takes one parametar userEsg 
   * @param {Object} userEsg properties user ingame profile
   * @returns Updating userStore userInfoEsg state with new updated value
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Function that taken one paramet mentor - new selected mentor
   * @param {String} mentor 
   * @returns Updated selectedMentor state
   */
  const onChangeMentor = (mentor) => {
    setSelectedMentor(mentor);
  };
  /**
   * Select Mentor is submit function that is called 
   * after user select mentor for the game and click on continue.
   * UserId is destructured from userStore userInfoEsg.Mentors array is maped and if
   * mentor.avatarUrl is like selected Mentor we call backend esgService with method to
   * update UserProfileMentor passing two parameters mentorId and userId . After response come 
   * from backend new update UserEsgProfile is update in LocalStorage and userStore.
   * At the end of this function we redirect user to inisurvey page.
   */
  const selectMentor = () => {
    const { userId } = userStore.userInfoEsg
    mentors.map((mentor) => {
      if (mentor.avatarUrl === selectedMentor) {
        esgService
          .updateUserProfileMentor(
            mentor._id,
            userId,
          )
          .then((res) => {
            setLocalStorage(res.data, USER_PROFILE_ESG)
            userStore.updateUserInfoEsg(res.data);
            history.push('initsurvey');
          });
      }
    });
  };
  /**
   * Function that taken one paramet mentor - new selected mentor . 
   * @param {Object} mentor with property's of mentor 
   * @returns First check what languege user is currently using Function return description
   * of mentor in moreinfo modal about mentor on that language
   */
  const selectLanguage = (mentor) => {
    if (languageStore.language === "en") {
      return mentor.descEn
    } else if (languageStore.language === 'bhs') {
      return mentor.descBhs
    } else {
      return mentor.descBhs
    }
  }

  return (
    <div className="mentor-selection app">
      <div className="mentor-selection-section">
        <div className="mentor-holder">
          <div dangerouslySetInnerHTML={{ __html: t('mentors:mentor-select-headline') }} />
          <div className="all-mentor-holder">
            {mentorArr.map((mentor, index) => (
              <div className="mentor-image-holder" key={index}>
                <Mentor
                  key={mentor.key}
                  selected={mentor.key === selectedMentor}
                  mentorIndex={mentor.key}
                  src={mentor.src}
                  handleclick={() => { onChangeMentor(mentor.key); playSound(likeAudio) }}
                />
                <p>{mentor.name}</p>
                <ModalInfoMentor
                  name={mentor.name}
                  avatarUrl={mentor.src}
                  mentorDescription={selectLanguage(mentor)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="signIn"
          text={t("common:landing-back")}
          customBtn={true}
          onClick={() => {
            history.goBack();
          }}
        />
        <Button
          additionalStyle="buttonMakeAcc"
          text={t('common:landing-continue')}
          customBtn={false}
          disabled={selectedMentor ? false : true}
          onClick={() => { selectMentor(); playSound(likeAudio); }}
        />
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'languageStore', 'progressBarStore')(observer(MentorSelect)));
