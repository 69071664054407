import HttpClient from "./core/http";
import confing from '../config/config.local'
import * as token from './core/token'


class AuthService {
    
    constructor() {
        this.http = new HttpClient(confing.API_BASE_URL_AUTH);          
    }
    async login(data, query = {}) {
        const { email, password } = data;
        const tempData = {
            email,
            password,
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/auth/login`);
        const url = http.combine(path, queryString);
        const response = this.http.post(url,tempData);
        response.then(res=>{
            token.set(res.data.token)
        }).catch((err)=>{
            token.set(err)
        })
        return response;
    }

    async adminLogin(data, query = {}) {
        const { http } = this;
        const {email,password} = data
        // const { email , token} = data
        const body = {
            email,
            password
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/auth/master`);
        const url = http.combine(path, queryString);
        const response = this.http.post(url, body)
        response.then(res=>{
            token.set(res.data.token)
        }).catch((err)=>{
            token.set(err)
        })
        return response;
    }

    async googleLogin(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/auth/google`);
        const url = http.combine(path, queryString);
        return this.http.get(url);
    }

    async mailVerification(data, query = {}) {
        const { http } = this;
        const { email , token} = data
        const body = {
            email,
            token
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/auth/verify`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }


    
}

export default AuthService;