import { observable, action } from "mobx";

export class ProgressBar {
    @observable progressOne = 0;
    @observable progressTwo = 0;
    @observable progressThree = 0;

    @action setProgress = (value1, value2, value3) => {
        this.progressOne = value1;
        this.progressTwo = value2;
        this.progressThree = value3;
    };
}

export default new ProgressBar();
