import React, { useState, useEffect } from 'react';
import "./bussinesPlan.component.style.less";
import { useTranslation } from "react-i18next"
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../../localStorageMobxStore/localStorageUserProfile';
import { Upload, message } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import PdfBhs from '../../../../assets/pdf/bussinesplanbhs.pdf'
import PdfEn from '../../../../assets/pdf/bussinesplanen.pdf'

const { Dragger } = Upload;


/**
 * BussinesPlan component
 * @class
 * @param {Object} properties (languageStore)

 * @returns This component is used in User Profile component where you can download and upload you Bussines Plan
 */
const BussinesPlan = ({ userStore, languageStore }) => {
    const { t } = useTranslation("user-profile")
    const [link, setLink] = useState('');

    const userId = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG._id

    useEffect(() => {
        const userAll = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        setLink(userAll.businessPlanUrl)
    }, [])

    useEffect(() => {
        const userAll = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        userAll.businessPlanUrl = link;
        setLocalStorage(userAll, USER_PROFILE_ESG)
    }, [link])


    const props = {
        name: 'file',
        multiple: true,
        accept: ".txt, .csv, .png, .pdf, .jpg",
        action: `${process.env.REACT_APP_API_BASE_URL_ESG}/user-profiles/azure/business-plan/${userId}`,
        headers: {
            authorization: ('Access-Control-Allow-Origin', process.env.REACT_APP_API_BASE_URL_ESG + '/#/'),
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                message.success(`${info.file.name} ` + t("user-profile:success"));
                setLink(info.file.response.businessPlanUrl)
            } else if (status === 'error') {
                message.error(`${info.file.name} ` + t("user-profile:failed"));
            }
        },
    };

    return (
        <div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">{t("user-profile:upload-bp")}</p>
            </Dragger>

            <div className="filePreview">
                <span>{t("user-profile:bp-preview")}</span>
                {languageStore.language === 'bhs' ?
                    <a className="signUp" href={PdfBhs} download="bussinesplanbhs.pdf"> Preuzmi</a>
                    :
                    <a className="signUp" href={PdfEn} download="bussinesplanen.pdf"> Download </a>
                }
            </div>
        </div>
    );
}

export default withRouter(inject('userStore', 'languageStore')(observer(BussinesPlan)));;