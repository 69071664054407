import React, { useState, useEffect } from 'react';
import './sider.component.style.less';
import { Layout, Menu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingFilled,
    VideoCameraFilled,
    CalendarFilled,
    InfoCircleFilled,
    TrophyFilled
} from '@ant-design/icons';
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

const { Header, Sider } = Layout;

const SiderBar = ({ setSelectedContent, userProfile }) => {
    const { t } = useTranslation("user-profile")
    const [collapsed, setCollapsed] = useState(false)

    const { setActiveIndex, activeIndex } = userProfile;

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const click = (value) => {
        setSelectedContent(value)
    }

    useEffect(() => {
        click(activeIndex.toString());

        return () => {
            setActiveIndex(1)
        }
    },[])

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: toggle,
                        })}
                    </Header>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={activeIndex.toString()} >
                    <Menu.Item key="1" icon={<SettingFilled />} onClick={() => click('1')}>
                        {t("user-profile:account-settings")}
                    </Menu.Item>
                    <Menu.Item key="2" icon={<InfoCircleFilled />} onClick={() => click('2')}>
                        {t("user-profile:general-info")}
                    </Menu.Item>
                    <Menu.Item key="3" icon={<VideoCameraFilled />} onClick={() => click('3')}>
                        {t("user-profile:elevator-pitch")}
                    </Menu.Item>
                    <Menu.Item key="4" icon={<CalendarFilled />} onClick={() => click('4')}>
                        {t("user-profile:business-plan")}
                    </Menu.Item>
                    <Menu.Item key="5" icon={<TrophyFilled />} onClick={() => click('5')}>
                        {t("user-profile:leaderboard")}
                    </Menu.Item>
                </Menu>
            </Sider>
        </Layout>
    );
}

export default withRouter(inject("userProfile")(observer(SiderBar)));
