import React from 'react'
import Modal from '../modal.component'
import './mentor.modal.component.style.less'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';


const MentorModal = ({languageStore, question, closable, btnText, okBtnText, visible, trigerVisible, footer, handleOk, maskClosable }) => {
    const mentorTextBhs = question.locales[0].question_content.mentorText
    const mentorTextEn = question.locales[1].question_content.mentorText
    const selectLanguage = () => {
        if(languageStore.language === 'bhs'){
            return mentorTextBhs
        }else if (languageStore.language === 'en'){
            return mentorTextEn
        }else {
            return mentorTextEn
        }
    }
    return (
        <Modal
            modalStyleTest='modal-content-equipment'
            closable={closable}
            okBtnText={okBtnText}
            footer={footer}
            btnText={btnText}
            additionalStyle={selectLanguage() === "-" || !selectLanguage() ?"signUpDisbled":"signUp"}
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
            maskClosable={maskClosable}
            disabled={selectLanguage() === "-" || !selectLanguage() ?true:false}
        >
            <p className="mentor-help" dangerouslySetInnerHTML={{ __html:selectLanguage()}}></p>
        </Modal>
    )
}

export default inject('languageStore')(observer(MentorModal))