import React, { useState } from 'react';
import './officeBuySelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET, BUILDING } from '../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../components/Button/button.component';
import EsgService from '../../../services/EsgServices/esg.service'
import { BuildingType } from '../../../assets/images/index.js';
import { Coins } from '../../../assets/images/index.js';
import { Tabs } from 'antd';
import { useEffect } from 'react';
import ModalInfoOffice from '../../../components/Modal/ModalInfoOffice/modalInfoOffice.component'
import IntroOfficeModal from '../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component'
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'

const { TabPane } = Tabs;
/**
 * Buy Ofice component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with text and Contionue button covering compoented. After modal is closed.
 * We have title, two tabs rent and buy.Rent tab have three pictures with name , cost and info modal.Buy tab 
 * have two pictures with name ,cost and info modal.Two buttons aer also included. Continue button 
 * that redirect user to legalaspects or hardwere component depending if he bougth hardwer or not.
 * * Back button for going back to previous page. 
 */
const BuyOffice = ({ history, userStore, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "office"])
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [buildingMarked, setbuildingMarked] = useState(null);
  const [orderedBuilding, setOrderedBuilding] = useState([]);
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()

  const [testModal, setTestModal] = useState(true)
  const [visible, setVisible] = useState(true)
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }
  /**Function that close inital modal that is shown on top of page.
    */
  const toggleContent = () => {
    setTestModal(false)
    setVisible(false)
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    const lsBuilding = getLocalStorage(BUILDING).BUILDING
    if (lsBuilding) {
      setbuildingMarked(lsBuilding.building)
    }

    esgService.getBuildings().then((res) => {
      const retval = []
      const groupBy = 'paymentType';
      const allBuildings = res.data
      allBuildings.forEach((building) => {
        const buildingGroupByValue = building[groupBy];
        const retvalValue = retval.find(empObj => {
          if (empObj.key === buildingGroupByValue)
            return true;
          return false;
        })
        if (retvalValue) {
          retvalValue.buildingsArr.push(building);
        }
        else {
          retval.push({ key: buildingGroupByValue, buildingsArr: [building] })
        }
      })
      setOrderedBuilding(retval)
    })
    progressBarStore.setProgress(100, 45, 0)
  }, [])

  useEffect(() => {
    if (userEsg || companyInfo || budget || buildingMarked) {
      setUserStore(userEsg, companyInfo, budget, buildingMarked)
    }
  }, [userEsg, companyInfo, budget, buildingMarked])
   /**
   * Function that four parametars userEsg , singlePlayer ,companyInfo , buildingMarked
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @param {Object} buildingMarked Object with propery of building
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo and 
   * buildingMarked with new value of buildingMarked
   */
  const setUserStore = (userEsg, companyInfo, budget, buildingMarked) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
    userStore.updateBuilding(buildingMarked)
  }
  /**
   * On Change Building function with one parameter building
   * @param {Object} building 
   * @returns updated state of buildingMarked with value of building
   */
  const onChangeBuilding = (building) => {
    setbuildingMarked(building);
  };
  /**
   * Buy building function is called after user click continue button.First check if user choosed
   * rent or buy typeOfPayment.Body object is createt with parameters userId, buildingId and companyId.
   * EsgService is called with method buyRentBuilding with passing parameters body and type of paymnet.
   * Local storage Building and userStore building is updated with res.data.After that esgService is called
   * with method getCompanyInfo passing parameter companyId.After response come we check if user selected
   * equipment for company if not he is redirected to legalaspects rout. If not user is redirected to
   * hardware.In bouth cases COMPANY_INFO local storage is updated with res.data.
   */
  const buyBuilding = () => {
    const { userId } = userStore.userInfoEsg
    const companyId = userStore.companyInfo.id
    const { _id, paymentType } = buildingMarked
    let typeOfPayment;
    if (paymentType === 'Rent') {
      typeOfPayment = 'rent'
    } else {
      typeOfPayment = 'buy'
    }
    const body = {
      userId,
      buildingId: _id,
      companyId: companyId
    }

    esgService.buyRentBuilding(body, typeOfPayment).then((res) => {
      setLocalStorage(res.data, BUILDING)
      userStore.updateBuilding(res.data)
    }).then(() => {
      esgService.getCompanyInfo(companyId).then((res) => {
        if (res.data.equipment.length > 0) {
          setLocalStorage(res.data, COMPANY_INFO)
          history.push('legalaspects')
        } else {
          setLocalStorage(res.data, COMPANY_INFO)
          userStore.updateCompanyInfo(res.data)
          history.push('hardware')
        }
      })
    })
  }
  /**
   * Select Language function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} building 
   * @returns Name of building in BHS language or EN language for paragraph
   */
  const selectLanguage = (building) => {
    if (languageStore.language === "en") {
      return building.en.name
    } else if (languageStore.language === 'bhs') {
      return building.bhs.name
    } else {
      return building.en.name
    }
  }

  /**
   * Select Language Description function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} value 
   * @returns Description of building in BHS language or EN language for paragraph
   */
  const getPaymentTypeName = (key) => {
    let valueName = "";
    if (languageStore.language === "en") {
      switch (key) {
        case 'Rent':
          valueName = "Rent";
          break;
        default:
          valueName = "Buyout";
          break;
      }
    } else {
      switch (key) {
        case 'Rent':
          valueName = "Iznajmi";
          break;
        default:
          valueName = "Kupi";
          break;
      }
    }
    return valueName;
  }

  /**
   * Language Description Select function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} value Object with properties descriptionBhs,descriptionEn
   * @returns Office description in BHS language or EN language
   */
  const selectLanguageDescription = (value) => {
    if (languageStore.language === "en") {
      return (
        <p dangerouslySetInnerHTML={{ __html: value.en.description }}></p>
      )
    } else if (languageStore.language === 'bhs') {
      return (
        <p dangerouslySetInnerHTML={{ __html: value.bhs.description }}></p>
      )
    } else {
      return (
        <p dangerouslySetInnerHTML={{ __html: value.en.description }}></p>
      )
    }
  }
  /**
   * Functional Building Component.
   * @param {Object} building Destructured property 
   * @returns Picture of building , name/title of building ,price, ecoFriendly type.
   * Button information is included . Clicking on it open modal with picture , price ,link 
   * and close button that close modal.
   */
  const BuildingComponent = ({ building }) => {
    const typeBuild = building.paymentType;
    return (
      <div className="office-holder fadeInBottom-building" key={building}>
        <div className="forHover">
          <BuildingType
            selected={buildingMarked ? building._id === buildingMarked._id : null}
            handleclick={() => { onChangeBuilding(building); playSound(likeAudio); }}
            imageUrl={building.imageUrl}
            ecoFriendly={building.ecoLevel}
          />
        </div>
        {typeBuild === "Rent" ? <p className="tooltiptext">
          {t("office:office-rent-expl")}
        </p> : <p className="tooltiptext">
          {t("office:office-buy-expl")}
        </p>}
        <h2>{selectLanguage(building)}</h2>
        <p className="green">
          {building.price} /
          {typeBuild === "Rent" ? t("office:month") : null}
          <Coins />
        </p>
        <ModalInfoOffice
          imageUrl={building.imageUrl}
          contentHeader={selectLanguage(building)}
          contentPrice={building.price}
          contentDescription={selectLanguageDescription(building)}
        />
      </div>
    )
  }
  return (
    <div className="office-buy app">
      {testModal ?
        <IntroOfficeModal
          contentMsgOne={t("office:mentor-talk.msg-one")}
          contentMsgTwo={t("office:mentor-talk.msg-two")}
          contentMsgThree={t("office:mentor-talk.msg-three")}
          visible={visible}
          title="Ovde ce biti mentor"
          onOk={() => { toggleContent(); playSound(likeAudio); }}
        /> :
        <div className="office-buy-section">
          <h2>{t('office:office-headline')}</h2>
          <Tabs>
            {orderedBuilding.length > 0 && orderedBuilding.map((val) => {
              return <TabPane key={val.key} tab={getPaymentTypeName(val.key)}>
                <div className="office-buy-holder fadeInBottom-building" key={val.key}>
                  {val.buildingsArr.map((building) => {
                    return <BuildingComponent building={building} key={building._id} />
                  })}
                </div>
              </TabPane>
            })}
          </Tabs>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t("common:landing-back")}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t("common:landing-continue")}
              customBtn={true}
              disabled={buildingMarked ? false : true}
              onClick={() => { buyBuilding(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'languageStore', 'progressBarStore')(observer(BuyOffice)));
