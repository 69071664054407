import React, { useState, useEffect } from 'react';
import './hardwareSelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET, EQUIPMENT, HARDWARE } from '../../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../../components/Button/button.component';
import EsgService from '../../../../services/EsgServices/esg.service'
import { HardwareEquipment, Coins } from '../../../../assets/images/index';
import Counter from '../CounterComponent/counter.component'
import ModalInfoEquipment from '../../../../components/Modal/ModalInfoEquipment/modalInfoEquipment.component'
import IntroOfficeModal from '../../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component'
import ClickSound from '../../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'


const inventoryType = 'it'

/**
 * Hardware Select component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with text and Contionue button covering component After modal is closed.
 * We have title, text content with info message how much of equpment user need to select to continue.
 * List of equipment where every equipment have picture, counter that have minus button for decreasing number of equipment select ,
 * input for number of times user selected that equpment and plus button to add equipment.Price and Info button that
 * when is clicked open modal with picture , text information about equipment and close button  for closing modal.
 * Continue button that redirect user to tables component.
 * * Back button for going back to previous page. 
 */
const HarwareSelect = ({ history, userStore, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "equipment"])
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [approved, setApproved] = useState(null)
  const [equipment, setEquipment] = useState([])
  const [officeEquipment, setOfficeEquipment] = useState([])
  const [hardWareSelected, setHardWareSelected] = useState([])
  const [desktops, setDesktops] = useState({
    amount: 1,
    equipment: {}
  })
  const [laptops, setLaptops] = useState({
    amount: 2,
    equipment: {}
  })
  const [tablets, setLamps] = useState({
    amount: 2,
    equipment: {}
  })
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  const [testModal, setTestModal] = useState(true)
  const [visible, setVisible] = useState(true)
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  /**Function that close initial modal that is shown on top of page.
    */
  const toggleContent = () => {
    setTestModal(false)
    setVisible(false)
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    const resBudgetLS = getLocalStorage(BUDGET).BUDGET
    esgService.getBudget(resBudgetLS.budget._id).then((res) => {
      const budget = {
        budget: res.data
      }
      setBudget(budget)
      setLocalStorage(budget, BUDGET)
    })
    setEquipment(getLocalStorage(EQUIPMENT).EQUIPMENT)
    const resLS = getLocalStorage(HARDWARE).HARDWARE
    if (resLS) {
      setDesktops(resLS[0])
      setLaptops(resLS[1])
      setLamps(resLS[2])
    }
    esgService.getEquipment(inventoryType).then((res) => {
      setDesktops(desktops => ({
        ...desktops,
        equipment: res.data[0]
      }))
      setLaptops(laptops => ({
        ...laptops,
        equipment: res.data[1]
      }))
      setLamps(tablets => ({
        ...tablets,
        equipment: res.data[2]
      }))
      setOfficeEquipment(res.data)
    })
    progressBarStore.setProgress(100, 53, 0);
  }, [])
  useEffect(() => {
    const equipmentArr = [desktops, laptops, tablets]
    const amounts = equipmentArr.map(arr => arr.amount).reduce((a, b) => a + b, 0)
    if (amounts >= 5) {
      setApproved(true)
    } else {
      setApproved(false)
    }
    setHardWareSelected(equipmentArr)
  }, [desktops, laptops, tablets, approved])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])
  /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }
  /**
   * Counter click function
   * @param {Nubmer} value 
   * @param {Nubmer} index 
   * @param {Object} x Hardwere object value
   * @returns Set state value and amout of desktops , laptops  and lamps
   */
  const counertClicked = (value, index, x) => {
    if (index === 0) {
      setDesktops({
        amount: value,
        equipment: x
      })
    } else if (index === 1) {
      setLaptops({
        amount: value,
        equipment: x
      })

    } else if (index === 2) {
      setLamps({
        amount: value,
        equipment: x
      })
    }
  }
  /**
   * Select Language function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} x hardware 
   * @returns Name of hardware in BHS language or EN language for paragraph
   */
  const selectLanguage = (x) => {
    if (languageStore.language === "en") {
      return x.en.name
    } else if (languageStore.language === 'bhs') {
      return x.bhs.name
    } else {
      return x.en.name
    }
  }
  /**
   * Select Language function that takes one parametar value check what language we are 
   * currently using from mobx languageStore.language
   * @param {Object} x hardware description
   * @returns Description of hardware in BHS language or EN language for paragraph
   */
  const languageDescriptionSelect = (value) => {
    if (languageStore.language === 'bhs') {
      return value.bhs.description
    } else if (languageStore.language === 'en') {
      return value.en.description
    } else {
      return value.en.description
    }
  }
  /**
   * Equopment Check function with parameter equipment
   * @param {Object} equipment 
   * @returns Check what type of equipment is and returns on that case amount of that equipment
   */
  const equipmentCheck = (equipment) => {
    if (equipment.en.name === "Desktop") {
      return desktops.amount
    } else if (equipment.en.name === "Laptop") {
      return laptops.amount
    } else if (equipment.en.name === "Tablet") {
      return tablets.amount
    }
  }
  /**
   * Sumbit Selected Equipment
   * @returns setLocalStorage HARDWARE with value hardWareSelected
   * and redirect user to tables rout
   */
  const sumbitSelectedEquipment = () => {
    setLocalStorage(hardWareSelected, HARDWARE)
    history.push('tables')
  }

  return (

    <div className="office-equipment app">
      {testModal ?
        <IntroOfficeModal
          contentMsgOne={t('equipment:mentor-talk.msg-one')}
          contentMsgTwo={t('equipment:mentor-talk.msg-two')}
          contentMsgThree={t('equipment:mentor-talk.msg-three')}
          contentMsgFour={t('equipment:mentor-talk.msg-four')}
          contentMsgFive={t('equipment:mentor-talk.msg-five')}
          visible={visible}
          title="Ovde ce biti mentor"
          onOk={() => { toggleContent(); playSound(likeAudio); }}
        /> :
        <div className="office-equipment-section">
          <h2>{t('equipment:equipment-header-hardware')}</h2>
          <p className={approved ? "allow-p" : "alert-p"}>{t('equipment:equipment-selection-hardware')}</p>
          <div className="office-equipment-holder">
            {officeEquipment.length > 0 && officeEquipment.map((x, index) => {
              return (
                <div className="equipment-holder" key={x.name}>
                  <HardwareEquipment
                    imageUrl={x.imageUrl}
                    selected={equipmentCheck(x)}
                  />
                  <Counter value={equipmentCheck(x)} handleChange={(value) => { counertClicked(value, index, x); playSound(likeAudio); }} />
                  <h2>{selectLanguage(x)}</h2>
                  <p className="green">
                    {x.price}<Coins />
                  </p>
                  <ModalInfoEquipment
                    imageUrl={x.imageUrl}
                    contentHeader={selectLanguage(x)}
                    contentPrice={x.price}
                    contentDescription={languageDescriptionSelect(x)}
                  />
                </div>
              )
            })}
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              disabled={approved ? false : true}
              onClick={() => { sumbitSelectedEquipment(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', "soundsStore", "languageStore", 'progressBarStore')(observer(HarwareSelect)));
