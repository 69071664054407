import HttpClient from "./core/http";
import confing from '../config/config.local'

class IndetityService {

    constructor() {
        this.http = new HttpClient(confing.API_BASE_URL_IDENTITY);
    }

    async userRegistration(data,query) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/users`);
        const url = http.combine(path, queryString);
        return this.http.post(url, data)
    }

    async validateUserToken(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/users/current`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }



}

export default IndetityService;
