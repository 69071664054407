import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (ecoFriendly, otherNoEco)
 * @returns This component is used in chartEco
 * to download Excel file based on the data form props
 */
const Download = ({ ecoFriendly, otherNoEco }) => {
    const { t } = useTranslation("admin")
    const dataSet1 = [
        {
            name: t("admin:excels-eco.ecoF"),
            amount: ecoFriendly ? ecoFriendly : 0
        },
        {
            name: t("admin:excels-eco.ecoFn"),
            amount: otherNoEco ? otherNoEco : 0
        },
    ];
    return (
        <ExcelFile filename='ecoFriendly' element={<button className="buttonData">{t("admin:excels-eco.download")}</button>}>
            <ExcelSheet data={dataSet1} name={t("admin:excels-eco.nameA")}>
                <ExcelColumn label={t("admin:excels-eco.lblN")} value="name" />
                <ExcelColumn label={t("admin:excels-eco.lblU")} value="amount" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default Download;