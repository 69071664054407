import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import LocalStorageBackend from 'i18next-localstorage-backend' //primary use cache

i18n
  .use(Backend)
  .use(initReactI18next)
  //.use(LanguageDetector)
  .use(Cache)
  .init({
    backend: {
      backends: [
        LocalStorageBackend  // primary
      ],
      loadPath: '/translations/{{lng}}/{{ns}}.json', // translation file path 
      crossDomain: true
    },
    fallbackLng: 'bhs',
    lng: "bhs",
    debug: !process.env.NODE_ENV==='production',
    /* We can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    defaultNS: 'common',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    Cache: {
      enabled: "true",
      prefix: 'translation_',
      expirationTime: Infinity,
      Version: {},
      store: window.localStorage,
      defaultVersion: 'bhs',
    },
    react: {
      wait: true
    },
  })

export default i18n