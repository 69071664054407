import React, { useState, useEffect } from 'react';
import './billsPayment.component.style.less';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { setLocalStorage, getLocalStorage, SEQUENCE_PROGGRES, SEQUENCE_INDEX, BUDGET, USER_PROFILE_ESG, END_GAME_MODAL } from '../../../localStorageMobxStore/localStorageUserProfile'
import SequnceService from '../../../services/EsgServices/sequence.service'
import Button from '../../../components/Button/button.component';
import { Coins, Offline, Calendar } from '../../../assets/images/index.js';
import { useTranslation } from 'react-i18next'
import { modeGame } from '../../../helperFunctions/helperFunctions'
import LoanModal from './../../../components/Modal/ModalLoan/modalLoan.component'
import ResetGameModal from './../../../components/Modal/ModalReset/modalReset.component'
import { budgetUpdateHeplfunc, budgetUpdateHeplfuncSw, changeBckg } from '../../../helperFunctions/helperFunctions'
import openNotification from '../../../components/Notification/notifications.component'


// pwa
import { openDB } from "idb";

let dbPromise;
let swRegistered = false;

// Define indexDB
if ("indexedDB" in window) {
  dbPromise = openDB("esgDB");
};
/**
 * Bill Payment component
 * @class 
 * @param {Object} properties (history, userStore, sequenceIndex, setSequenceIndex, online, setSequnceProgres, monthlyExpenses, setUpdateProgress,
 * budget, companyInfo, sequence, setCurrentQuestionIndex, setSequence, progressBarStore)
 * @returns Title, list of mountly expences:Equipment,Buildng,Tax and Selaries and field with total of all
 * mountly expenses.Continue button 
 * that on submit redirect user quiz page.And Back to office button that redirect user to office.
 */
const BillPayment = ({ history, userStore, sequenceIndex, setSequenceIndex, online, setSequnceProgres, monthlyExpenses, setUpdateProgress,
  budget, companyInfo, sequence, setCurrentQuestionIndex, setSequence, progressBarStore }) => {
  const { t } = useTranslation(['billpayment', 'common', 'offlineNotification'])
  const [clicked, setClicked] = useState(false)
  const [endGameModal, setEndGameModal] = useState(false)
  /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
    * service where are esg services sequnce methods for calling api's are stored.
    */
  const sequenceService = new SequnceService()

  useEffect(() => {

    setLocalStorage('monltyEvent', SEQUENCE_PROGGRES)

    // pwa
    navigator.serviceWorker.getRegistrations().then(registrations => {
      if (registrations.length > 0) {
        swRegistered = true;
      }
    });

    const endGameModalLs = getLocalStorage(END_GAME_MODAL).END_GAME_MODAL
    if (endGameModalLs) {
      setEndGameModal(endGameModalLs)
    }

    //  set progressBar
    if (sequence) {
      const oneQuestionPercentage = 10 / (sequence.quiz.questions.length + 2);  // value of one question in percentage
      const questionProgress = sequence.number * 10 - oneQuestionPercentage;
      progressBarStore.setProgress(100, 100, questionProgress)
    }

  }, [sequence])

  /**
   * Fetch New Seqence Number
   * @param {Number} sequenceNr 
   * @param {String} gameMode 
   * @returns SequenceService is called with method playerProgresCreate one parameter is passed 
   * createProgresData ,SequenceService with method getSequnce, state is updated with value
   * firstSequence ,currentQuestionIndexand  state is set to 0,SEQUENCE_PROGGRES is set to 
   * 'quiz' ,sequnceProgres stat is set to value 'quiz'and user is redirected to office route.
   */
  const fetchNewSequence = async (sequenceNr, gameMode) => {
    const data = {
      sequenceNrs: [sequenceNr + 1, sequenceNr + 2]
    }

    //Array of two ids of sequnces
    const ids = await sequenceService.getIdsOfSequnces(data)
    // Body data for creating progress
    const createProgresData = {
      companyId: companyInfo.id,
      sequenceId: ids.data[0]._id,
      gameMode
    }

    const createProgres = await sequenceService.playerProgresCreate(createProgresData)

    const firstSequnceId = ids.data[0]._id

    const firstSequencePromise = sequenceService.getSequnce(firstSequnceId)
    const firstSequence = (await firstSequencePromise).data;
    setSequence(firstSequence)
    setCurrentQuestionIndex(0)
    setLocalStorage('quiz', SEQUENCE_PROGGRES)
    setSequnceProgres('quiz')
    changeBckg(firstSequence.number);

    let secondSequence;

    if (ids.data[1]) {
      const secondSequnceId = ids.data[1]._id;
      const secondSequencePromise = sequenceService.getSequnce(secondSequnceId)
      secondSequence = (await secondSequencePromise).data;
    };

    if ('indexedDB' in window && swRegistered) {
      // create firstSequence in indexDB
      dbPromise
        .then((db) => {
          const tx = db.transaction('sequence', "readwrite");
          const store = tx.objectStore('sequence');
          store.put(firstSequence, 'firstSequence');
          return tx.done;
        });

      // create secondSequence in indexDB
      if (secondSequence) {
        dbPromise
          .then((db) => {
            const tx = db.transaction('sequence', "readwrite");
            const store = tx.objectStore('sequence');
            store.put(secondSequence, 'secondSequence');
            return tx.done;
          })
          .then(() => {
            dbPromise
              .then((db) => {
                const tx = db.transaction('progres', "readwrite");
                const store = tx.objectStore('progres');
                store.put(secondSequence, 'progres');
                return tx.done;
              })
          })
      }
    };

    history.push('office')
  }
  /**
   * Game over function set state value of endGameModal to true
   */
  const gameOver = () => {
    setEndGameModal(true)
  }
  /**
   * Restart game function set state of endGameModal to false.SequenceService is called with method
   * reset game two parameters are passed companyId,budgetId.Local storage is cleared.Mobix userStore
   * company info and budget are set to null,local storage USER_PROFILE_ESG is updtaed with res value 
   * and user is redirected to selectmode route.
   */
  const restartGame = () => {
    const companyId = companyInfo.id
    const budgetId = budget.budget._id
    setEndGameModal(false)
    sequenceService.resetGame(companyId, budgetId).then((response) => {
      const res = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
      localStorage.clear()
      userStore.updateCompanyInfo(null)
      userStore.updateBudget(null)
      setLocalStorage(res, USER_PROFILE_ESG)
      history.push('/selectmode')
    })
  }
  /**
   * Loan Chose function.SequenceService is called with method takeLoand two parameters are passed
   * budgetId and sequnceNr. After response come from backend budgeObject is updated local storage
   * BUDGET is updated with budgetObj value , mobix userStore budget is updated with budgeObj and 
   * state of endgame modal is set to false as the localstorage state of END_GAME_MODAL is set to false
   */
  const loanChosen = () => {
    const gameMode = modeGame(budget);
    const sequenceNr = sequence.number
    const budgetId = budget.budget._id
    sequenceService.takeLoan(budgetId, sequenceNr).then((res) => {
      let budgetObj = Object.assign(budget, {})
      budgetObj.budget.remaining = res.data.currentBudget
      budgetObj.budget.loan = res.data.loanAmount
      setLocalStorage(budgetObj, BUDGET)
      userStore.updateBudget(budgetObj)
      setEndGameModal(false)
      setLocalStorage(false, END_GAME_MODAL)
      // fetchNewSequence(sequenceNr, gameMode)

    })
  }

  /**
   * Check if user is online.If he is.Redirect him to new Quiz component.If he is
   * not check sequence index if sequnce index is 0 set sequince index to 1 and 
   * redirect him to quiz component . If sequence index is 1 . Pop up modal with
   * message to come online to continue playing.
  */
  /**
   * Redirect function.SequenceService is called with method postMontlyEvent one parametar data 
   * is passed.When response from backend come first check if budge is still above 0 if it is not
   * gameOver function is called if it is checkot if player finshed sequnce number 10 redirecti him to
   * gameFinsh rout and sequnce nuber is not 10 budgetUpdateHeplfunc,fetchNewSequence are called 
   * sequence index is set to 0 and local storage SEQUENCE_INDEX is set to 0 and user is redirect to quiz.
   */
  const redirect = () => {
    const gameMode = modeGame(budget);
    const data = {
      sequenceId: sequence.id,
      companyId: companyInfo.id,
      budgetId: budget.budget._id,
      gameMode: gameMode
    };

    const montlyEvent = data;

    if ((budget.budget.remaining - monthlyExpenses.total) <= 0) {
      if (navigator.onLine) {
        // if you want to display modal after refresh, uncomment this line below
        // setLocalStorage(true, END_GAME_MODAL)
        gameOver()
      } else {
        openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
      }
    } else {
      if ("serviceWorker" in navigator && "SyncManager" in window && swRegistered && !navigator.online) {
        dbPromise
          .then(db => {
            const tx = db.transaction('res', 'readonly');
            const store = tx.objectStore('res');
            return store.get('monthlyExpenses');
          })
          .then(data => {
            if (data) {
              dbPromise
                .then((db) => {
                  const tx = db.transaction('res', "readwrite");
                  const store = tx.objectStore('res');
                  return store.delete('monthlyExpenses');
                })
            }
          });

        navigator.serviceWorker.ready.then((sw) => {
          if ("monthlyExpenses" && sequenceIndex === 0) {
            dbPromise
              .then(db => {
                const tx = db.transaction('monthlyExpenses', "readwrite");
                const store = tx.objectStore('monthlyExpenses');
                store.put(montlyEvent, 'monthlyExpenses');
                return tx.done;
              })
              .then(() => {
                dbPromise.then((db) => {
                  const tx = db.transaction('sync_requests', "readonly");
                  const store = tx.objectStore('sync_requests');
                  return store.getAll();
                })
                  .then(data => {
                    const registermontlyEvent = () => {
                      //start
                      dbPromise
                        .then(db => {
                          const tx = db.transaction('sync_requests', "readwrite");
                          const store = tx.objectStore('sync_requests');
                          store.put(montlyEvent, data.length + 1);
                          return tx.done;
                        })
                        .then(() => {
                          return sw.sync.register("sync_requests");
                        })
                        .then(() => {
                          setClicked(true);

                        })
                      //end
                    };

                    if (navigator.onLine) {
                      registermontlyEvent();

                      dbPromise
                        .then((db) => {
                          const tx = db.transaction('res', "readwrite");
                          const store = tx.objectStore('res');
                          return store.delete('monthlyExpenses');
                        })

                    } else /* offfline */ {
                      dbPromise
                        .then((db) => {
                          const tx = db.transaction('sequence', "readonly");
                          const store = tx.objectStore('sequence');
                          return store.get('firstSequence');
                        })
                        .then(firstSequence => {

                          changeBckg(firstSequence.number + 1);

                          // let budget = getLocalStorage(BUDGET).BUDGET;
                          const remainingBudget = budget.budget.remaining - monthlyExpenses.total;

                          if (remainingBudget <= 0) {
                            openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                          } else if (firstSequence.number === 10) {
                            openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:endGame.title")}</b></p></div>, <div><p>{t("offlineNotification:endGame.content")}</p></div>);
                          } else {
                            registermontlyEvent();

                            let budgetObj = Object.assign(budget, {})
                            budgetObj.budget.remaining = budget.budget.remaining - monthlyExpenses.total;
                            setLocalStorage(budgetObj, BUDGET)
                            userStore.updateBudget(budgetObj)
                            setSequenceIndex(1)
                            setLocalStorage(1, SEQUENCE_INDEX)
                            setLocalStorage('quiz', SEQUENCE_PROGGRES)
                            setSequnceProgres('quiz')
                          }
                        })
                    }
                  })
              })
              .then(() => {
                if (navigator.onLine) {
                  const postMontlyEventSw = () => {
                    dbPromise
                      .then(db => {
                        const tx = db.transaction('res', 'readonly');
                        const store = tx.objectStore('res');
                        return store.get('monthlyExpenses');
                      })
                      .then(data => {
                        if (data) {
                          if (data.remainingBudget <= 0) {
                            gameOver()
                          } else if (data.progress.sequenceNr === 10) {
                            budgetUpdateHeplfuncSw(data, budget, setLocalStorage, BUDGET, userStore)
                            setLocalStorage('gameFinish', SEQUENCE_PROGGRES)
                            setSequnceProgres('gameFinish')
                          } else {
                            budgetUpdateHeplfuncSw(data, budget, setLocalStorage, BUDGET, userStore)
                            fetchNewSequence(data.progress.sequenceNr, gameMode)
                          }
                        } else {
                          setTimeout(() => {
                            postMontlyEventSw()
                          }, 100)
                        }
                      })
                      .then(() => {
                        dbPromise
                          .then((db) => {
                            const tx = db.transaction('res', "readwrite");
                            const store = tx.objectStore('res');
                            return store.delete('monthlyExpenses');
                          })
                      })
                  }
                  postMontlyEventSw();

                };
              })
              .catch(err => console.log('sync err ==>', err))

          } else if (!navigator.onLine) {
            openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:online.title")}</b></p></div>, <div><p>{t("offlineNotification:online.content")}</p></div>);
          } else {
            sequenceService.postMontlyEvent(data).then((res) => {
              if (res.data.remainingBudget <= 0) {
                gameOver()
              } else if (res.data.progress.sequenceNr === 10) {
                budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
                setLocalStorage('gameFinish', SEQUENCE_PROGGRES)
                setSequnceProgres('gameFinish')
              } else {
                budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
                fetchNewSequence(res.data.progress.sequenceNr, gameMode)

                setSequenceIndex(0)
                setLocalStorage(0, SEQUENCE_INDEX)
              }
            })
          }
        })
      } else /* if browser doesn't support serviceWorker */ {
        // if((budget.budget.remaining - monthlyExpenses.total)< 0){
        //   setLocalStorage(true,END_GAME_MODAL)
        //   gameOver()
        // } else {
          sequenceService.postMontlyEvent(data)
          .then((res) => {
            setClicked(true);
            if (res.data.progress.sequenceNr === 10) {
              budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
              setLocalStorage('gameFinish', SEQUENCE_PROGGRES)
              setSequnceProgres('gameFinish')  
            } else {
              budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
              fetchNewSequence(res.data.progress.sequenceNr, gameMode)
              openNotification(<div><Coins /></div>, <div><b>Side Quest</b></div>, <div><p>Otključao si novi Side Quest.</p><p>Klikni na <b>Avatar</b> ➜ <b>Side Quest</b></p></div>);
            }  
          })
        // }
      }
    }

  }
  return (
    <div className="select-bill app">
      {budget && budget.budget.loan ?
        <ResetGameModal
          visible={endGameModal}
          restartGame={restartGame}
          okBtnText="Start from scratch"
          footer={true}
        />
        :
        <LoanModal
          visible={endGameModal}
          loanChosen={loanChosen}
          restartGame={restartGame}
          okBtnText="Take loan"
          footer={true}
          cancelBtnText="Start game again"
        />}
      {monthlyExpenses ?
        <div className="select-bill-section">
          <h2>{t("billpayment:bill-payment")} </h2>

          <div className="calendar">
            <Calendar />
            <p className="current-month">{t(`billpayment:months:${sequence.number}`)}</p>
          </div>

          <div className="button-bill-holder fadeInBottom-payment">
            <div className="bill-text">
              <h3>{t("billpayment:bill")}</h3>
              <p>{t("billpayment:equipment")}</p>
              <h3 className="nexth3">{t("billpayment:amount")}</h3>
              <p className="green">
                {monthlyExpenses.expenses.equipment} <Coins />
              </p>
            </div>
            <div className="bill-text">
              <h3>{t("billpayment:bill")}</h3>
              <p>{t("billpayment:rent")}</p>
              <h3 className="nexth3">{t("billpayment:amount")}</h3>
              <p className="green">
                {monthlyExpenses.expenses.rent} <Coins />
              </p>
            </div>
            <div className="bill-text">
              <h3>{t("billpayment:bill")}</h3>
              <p>{t("billpayment:tax")}</p>
              <h3 className="nexth3">{t("billpayment:amount")}</h3>
              <p className="green">
                {monthlyExpenses.expenses.tax} <Coins />
              </p>
            </div>
            <div className="bill-text">
              <h3>{t("billpayment:bill")}</h3>
              <p>{t("billpayment:salaries")}</p>
              <h3 className="nexth3">{t("billpayment:amount")}</h3>
              <p className="green">
                {monthlyExpenses.expenses.salaries} <Coins />
              </p>
            </div>
            {monthlyExpenses.loanRate ?
              <div className="bill-text">
                <h3>{t("billpayment:bill")}</h3>
                <p>{t("billpayment:loan-rate")}</p>
                <h3 className="nexth3">{t("billpayment:amount")}</h3>
                <p className="green">
                  {monthlyExpenses.loanRate} <Coins />
                </p>
              </div> : null}
          </div>
          <div className="totalCount fadeInBottom-payment">
            <p className="total-count-text">{t("billpayment:total")}</p>
            <p className="total-count-number">
              {monthlyExpenses.total} <Coins />
            </p>
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="buttonMakeAcc"
              text={t("billpayment:pay")}
              customBtn={true}
              disabled={false}
              onClick={redirect}                
              disabled={clicked ? true : false}
            />
          </div>
        </div>
        : null}
    </div>
  );
};

export default withRouter(inject('userStore', 'progressBarStore')(observer(BillPayment)));
