import React from 'react';
import { Modal as AntModal } from 'antd';
import PropTypes from 'prop-types';
import './modal.style.less';
import 'antd/dist/antd.css';

import Button from '../Button/button.component';

const Modal = ({
    title,
    onOk,
    handleOk,
    loading,
    okBtnText,
    cancelBtnText,
    customBtn,
    btnShape,
    btnSize,
    btnType,
    btnText,
    footer,
    ghost,
    btnIcon,
    showBack,
    onBackClick,
    additionalStyle,
    trigerVisible,
    visible,
    closable,
    modalStyleTest,
    maskClosable,
    disabled,
    ...props
}) => {
    //  const [visible, setVisible] = useState(false);

    return (
        <div>
            <Button additionalStyle={additionalStyle} icon={btnIcon} customBtn={customBtn} shape={btnShape} size={btnSize} type={btnType}
                text={btnText} ghost={ghost} onClick={trigerVisible} disabled={disabled} />

            <AntModal
                scrollable={true}
                wrapClassName={modalStyleTest}
                closable={closable}
                visible={visible}
                title={showBack}
                onOk={handleOk}
                maskClosable={maskClosable}
                onCancel={trigerVisible}
                footer={footer ? [
                    <Button key="submit" text={okBtnText} type="primary" shape="round" size="large" loading={loading}
                        onClick={handleOk} />,
                    <Button key="back" text={cancelBtnText} type="action" shape="round" size="large"
                        onClick={trigerVisible} />
                ] : null}
            >
                {props.children}
            </AntModal>
        </div>
    )
};

Modal.defaultProps = {
    title: '',
    onOk: () => {
    },
    handleOk: () => {
    },
    loading: false,
    okBtnText: '',
    cancelBtnText: '',
    customBtn: false,
    btnShape: '',
    btnSize: '',
    btnType: '',
    btnText: '',
    footer: false,
    ghost: false,
    btnIcon: null,
    showBack: false,
    visible: false,
    closable: true,
    modalStyleTest: '',
    onBackClick: () => { },
    maskClosable: true,
    disabled: false
}

Modal.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onOk: PropTypes.func,
    handleOk: PropTypes.func,
    loading: PropTypes.bool,
    okBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    customBtn: PropTypes.bool,
    btnShape: PropTypes.string,
    btnSize: PropTypes.string,
    btnType: PropTypes.string,
    btnText: PropTypes.string,
    footer: PropTypes.bool,
    ghost: PropTypes.bool,
    showBack: PropTypes.bool,
    onBackClick: PropTypes.func,
    visible: PropTypes.bool,
    closable: PropTypes.bool,
    modalStyleTest: PropTypes.string,
    maskClosable: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Modal;
