import React, { useState, useEffect } from 'react'
import Modal from '../modal.component'
import './modalMentor.component.style.less'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile'
import { Avatar, avatarArr, Mentor, mentorArr } from '../../../assets/images'
import { useTranslation } from 'react-i18next'


const ModalMentor = ({ history, userStore }) => {
  const { t } = useTranslation("common")
  const [userEsg, setUserEsg] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userImage, setUserImage] = useState(null)
  const [mentorImage, setMentorImage] = useState(null)
  const [mentorName, setMentorName] = useState(null)
  const [visible, setVisible] = useState(true);

  const trigerVisible = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
  }, []);

  useEffect(() => {

    if (userEsg) {
      setUserStore(userEsg)
      setUserName(userEsg.userName)
      setUserImage(userEsg.avatarUrl)
      setMentorImage(userEsg.mentor.avatarUrl)
      setMentorName(userEsg.mentor.name)
    }
  }, [userEsg])
  const handleOk = () => {
    trigerVisible()
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
  }, []);

  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }

  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }

  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
  const userProfileImage = utilityFilter(avatarArr, userImage)


  return (
    <Modal
      modalStyleTest='modal-content-mentor-s'
      closable={false}
      okBtnText='Close'
      footer={true}
      btnText="more info"
      additionalStyle="signUp"
      visible={visible}
      trigerVisible={trigerVisible}
    >
      <div className="meet-mentor-section">
        <div className="button-mentor-holder">
          <div className='mentor-text'>
            <h3 className="contentHeader">{mentorName}</h3>
            <div className="text-speach">
              <div className="mentor-talk">
                {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                <p className="hi-msg">{t('common:hi')} {userName}!</p>
                <p className="expl-msg">Sometimes company leaders decide to give up on their own salary to benefit the company on the long therm, what do you wish to do?</p>
              </div>
              <div className="user-talk">
                <p className="hi-msg-user">{t('common:hi')} {mentorName}! Ugh, not sure, give me a moment to think.</p>
                {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(inject('userStore')(observer(ModalMentor)));