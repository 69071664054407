import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import './meetMentor.component.style.less';
import Button from '../../../components/Button/button.component';
import { Avatar, avatarArr, Mentor, mentorArr } from '../../../assets/images';
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'

/**
 * Meet Mentor component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.Selected image of mentor avatar and text. 
 * Text and image of in-game avatar that user selected.
 * Continue button for redirecting user to onboardinginfo page. 
 */
const MeetMentor = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["mentors", "common"])
  /** Variable type Object where we set information and store about user in game for avatars selection page.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)
  /**Variable type String userName where we set information and store about ingame name of user */
  const [userName, setUserName] = useState(null)
  /**Variable type String userImage where we set information and store about 
   * user image selected ingame avatar */
  const [userImage, setUserImage] = useState(null)
  /**Variable type String userImage where we set information and store about 
   * mentor image selected ingame mentor avatar */
  const [mentorImage, setMentorImage] = useState(null)
   /**Variable type String userImage where we set information and store about 
   * mentor name selected ingame menor */
  const [mentorName, setMentorName] = useState(null)
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }


  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    progressBarStore.setProgress(60, 0, 0);
  }, []);

  useEffect(() => {

    if (userEsg) {
      setUserStore(userEsg)
      setUserName(userEsg.userName)
      setUserImage(userEsg.avatarUrl)
      setMentorImage(userEsg.mentor.avatarUrl)
      setMentorName(userEsg.mentor.name)
    }
  }, [userEsg])
  /**
   * Function that takes one parameter userEsg 
   * @param {Object} userEsg properties for user ingame profile
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }
  /**Array with one Object element of mentor image chose by user in game */
  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
  /**Array with one Object element of user image chose by user in game */
  const userProfileImage = utilityFilter(avatarArr, userImage)
  /**
   * Redict function that is used on Continue button
   * @returns Redirect user to onboardinginfo page
   */
  const redirect = () => {
    history.push('onboardinginfo')
  }

  return (
    <div className="meet-mentor app">
      <div className="meet-mentor-section">
        <h2>
          {t("mentors:meet-mentor.meet-mentor-headline")}
        </h2>
        <div className="button-mentor-holder fadeInBottom-mentor">
          <div className='mentor-text'>
            {/* <h3>{mentorName}</h3> */}
            <div className="text-speach">
              <div className="mentor-talk">
                {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                <p className="hi-msg">{t("mentors:meet-mentor.mentor-hello")} {userName}!</p>
                <p className="expl-msg">
                  {t("mentors:meet-mentor.mentor-message-first")}
                </p>
              </div>
              <div className="user-talk">
                <p className="hi-msg-user">
                  {t("mentors:meet-mentor.player-hello")} {mentorName}, {t("mentors:meet-mentor.player-message-hello")} </p>
                {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
              </div>
            </div>
          </div>
        </div>
        <div className="button-landing fadeInlast">
          <Button
            additionalStyle="buttonMakeAcc"
            text={t("common:landing-continue")}
            customBtn={true}
            onClick={() => { redirect(); playSound(likeAudio); }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(MeetMentor)));