import React, { useState } from 'react'
import Modal from '../modal.component'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import './modalInfoIndustry.component.style.less'
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'


const ModalInfoIndustry = ({ contentHeader, contentDesc, soundsStore }) => {
    const { t } = useTranslation(["common", "industry"])
    const [visible, setVisible] = useState(false);

    const likeAudio = new Audio(ClickSound);

    const playSound = audioFile => {
        if (soundsStore.sounds) {
            audioFile.play();
        }
    }

    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }

    return (
        <Modal
            modalStyleTest='modal-content-officeSelect'
            closable={false}
            okBtnText={t('common:close-button')}
            footer={true}
            btnText={t('common:more-info')}
            additionalStyle="signIn"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={() => { handleOk(); playSound(likeAudio); }}
        >
            <div className="contentHeader"><h3>{contentHeader}</h3></div>
            {/* <div className="contentPrice">INDUSTRY</div> */}
            <div className="contentDescription">{contentDesc}</div>
        </Modal>
    )
}

export default withRouter(inject('soundsStore')(observer(ModalInfoIndustry)));