import "./office.less";
import office from "../../assets/images/office.png";
import React, { useState, useEffect } from "react";
import {
    getLocalStorage,
    USER_PROFILE_ESG,
    BUDGET,
    COMPANY_INFO,
} from "../../localStorageMobxStore/localStorageUserProfile";
import EsgSequenceService from "../../services/EsgServices/sequence.service";
import { modeGame } from "../../helperFunctions/helperFunctions";
import VideoLoad from "../../components/Video/video.component";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next'

// image
import lightBulb from '../../assets/images/light-bulb.svg';
// Videos
import video1 from "../../assets/video/office-1.mp4";
import video1Bhs from "../../assets/video/office-1-bhs.mp4";
import video2 from "../../assets/video/office-2.mp4";
import video2Bhs from "../../assets/video/office-2-bhs.mp4";
import video3 from "../../assets/video/office-3.mp4";
import video3Bhs from "../../assets/video/office-3-bhs.mp4";

/**
 * Equipment Payment component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , userProfile,soundStore , progressBarStore)
 * @returns First modal is opened with video and Skip button covering component. After modal is closed.
 * Picture of the office is displayed with five buttons and light ball in top left corner.Buttons leads to Biznis Plan,
 * Elevator Pitch,Sequence,Budget and BMC.Clicking on light ball leads to UserProfile component.
 */
const Office = ({ history, userProfile, progressBarStore, userStore, languageStore }) => {
    const [activeSequence, setActiveSequence] = useState(null);
    const [loader, setLoader] = useState(false);
    const [videoEnd, setVideoEnd] = useState(false);
    const [activeVideo, setActiveVideo] = useState(null);

    const { t } = useTranslation(["office3d"])

    const { setActiveIndex, activeIndex } = userProfile;
    const { progressThree, setProgress } = progressBarStore;
    const { language } = languageStore;
    
    const budgetStatus = document.querySelector(".budget");
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
     * service where are esg services sequnce methods for calling api's are stored.
     */
    const esgSequenceService = new EsgSequenceService();

    const resBudget = getLocalStorage(BUDGET).BUDGET;
    const companyInfo = getLocalStorage(COMPANY_INFO).COMPANY_INFO;
    const gameMode = modeGame(resBudget);
    const userEsg = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG;
    const budget = getLocalStorage(BUDGET).BUDGET;

    //Player progres body data
    const progressData = {
        companyId: companyInfo.id,
        mode: gameMode,
    };

    let playerProgress = null;
    /**
     * Fetch Sequnces function fetch player progges in sequence. First esgSequenceService is called 
     * with function getProgress progressData parameter is passed. After that state of active sequence 
     * is updated with activeDataSequence.sequenceNr and loader is set to false.
     */
    const fetchSequences = async () => {
        try {
            playerProgress = await esgSequenceService.getProgress(progressData);
            const playerData = playerProgress.data;
            const activeDataSequence = playerData[playerData.length - 1];
            setActiveSequence(activeDataSequence.sequenceNr);
            setLoader(false);
        } catch (e) {
            setActiveSequence(1);
            setLoader(false);
        }
    };
    /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
    const setUserStore = (userEsg, companyInfo, budget) => {
        userStore.updateUserInfoEsg(userEsg)
        userStore.updateCompanyInfo(companyInfo)
        userStore.updateBudget(budget)
    }

    useEffect(() => {
        
        setLoader(true);
        fetchSequences();
        switch (companyInfo.building.en.name) {
            case "Property 1":
                language === 'bhs' ? setActiveVideo(video1Bhs) : setActiveVideo(video1) 
                break;
            case "Property 2":
                language === 'bhs' ? setActiveVideo(video2Bhs) : setActiveVideo(video2) 
                break;
            default:
                language === 'bhs' ? setActiveVideo(video3Bhs) : setActiveVideo(video3) 
        }

        if(progressThree < 1) {
            setProgress(100, 100, 0)
        }

        if (userEsg || companyInfo || budget) {
            setUserStore(userEsg, companyInfo, budget)
        }
    }, []);

    return (
        <div className="office">
            {!videoEnd ? (
                <VideoLoad url={activeVideo} setVideoEnd={setVideoEnd} />
            ) : (
                <div className="img-holder">
                    <img className="office-img" src={office} alt="office" />

                    <div onClick={() => history.push("/game")}
                        className={`animation ${ activeSequence < 5 ? "sequence-" + activeSequence : "sequence-other" }`}>
                        {activeSequence > 4 && activeSequence}
                        <div className="title">{t('office3d:sequences')}</div>
                    </div>

                    {budgetStatus ? (
                        <div onClick={() => budgetStatus.click()} className={`animation budget`}>
                            <div className="title">{t('office3d:budget')}</div>
                        </div>
                    ) : null}

                    <div onClick={() => history.push("/businessModelCanvas")} className={`animation bmc`}>
                        <div className="title">{t('office3d:bmc')}</div>
                    </div>

                    <div onClick={() => {setActiveIndex(4); history.push("/userProfile")}} className={`animation buisiness-plan`}>
                        <div className="title">{t('office3d:business-plan')}</div>
                    </div>

                    <div onClick={() => {setActiveIndex(3); history.push("/userProfile")}} className={`animation elevator-pitch`}>
                        <div className="title">{t('office3d:elevator-pitch')}</div>
                    </div>

                    <div className={loader ? "spinner-back" : "spinner-non-back"}>
                        <Spin size="large" spinning={loader} />
                    </div>

                </div>
            )}

            <div onClick={() => {setActiveIndex(2); history.push("/userProfile")}} className="light-bulb">
                <img src={lightBulb} alt="light bulb" />
            </div>
        </div>
    );
};

export default withRouter(inject("userProfile", "userStore", "progressBarStore", "languageStore")(observer(Office)));
