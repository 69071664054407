import React, { useState, useEffect } from 'react'
import Modal from '../modal.component'
import './random.event.modal.style.less'
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import { Avatar, avatarArr, Mentor, mentorArr } from '../../../assets/images';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import video1 from './video1.mov'
import BgNeg from '../../../assets/images/bg-neg.png'
import BgPos from '../../../assets/images/bg-pos.png'
import {
    RandEventPositive, RandEventNegative, Ilustration1, Ilustration2, Ilustration3, Ilustration4, Ilustration5,
    Ilustration6, Ilustration7, Ilustration8, Ilustration9
} from '../../../assets/images/index'
import VideoLoad from '../../Video/video.component'
import Video1 from '../../../assets/video/office-video-itgirls.mp4'
import Video2 from '../../../assets/video/randomEvent2.mp4'
import Video2BHS from '../../../assets/video/randomEvent2BHS.mp4'
import Video3 from '../../../assets/video/randomEvent3.mp4'
import Video3BHS from '../../../assets/video/TeamBHS.mp4'
import Video4 from '../../../assets/video/randomEvent4.mp4'
import Video4BHS from '../../../assets/video/AnimacijaConversation_about_founding_a_company_BHS.mp4'
import Video4EN from '../../../assets/video/AnimacijaConversation_about_fou_ding_a_company_EN.mp4'
import { useTranslation } from 'react-i18next'


const RandomEventModal = ({ languageStore, closable, okBtnText, visible, footer, maskClosable, answerValue, userStore, sequence, companyInfo, submitRandomEvent, currentQuestionIndex, setRandomEventModal }) => {
    const { t } = useTranslation(['common', 'sequences'])

    const [userEsg, setUserEsg] = useState(null)
    const [userName, setUserName] = useState(null)
    const [userImage, setUserImage] = useState(null)
    const [mentorImage, setMentorImage] = useState(null)
    const [mentorName, setMentorName] = useState(null)
    const [randomEvent, setRandomEvent] = useState(null)
    const [videoEnd, setVideoEnd] = useState(false)


    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    }, []);

    useEffect(() => {

        if (userEsg) {
            setUserStore(userEsg)
            setUserName(userEsg.userName)
            setUserImage(userEsg.avatarUrl)
            setMentorImage(userEsg.mentor.avatarUrl)
            setMentorName(userEsg.mentor.name)
        }
    }, [userEsg])

    const setUserStore = (userEsg) => {
        userStore.updateUserInfoEsg(userEsg)
    }
    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }
    const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
    const userProfileImage = utilityFilter(avatarArr, userImage)


    const randomEventsHp = (sequence) => {
        if (answerValue && answerValue.randomEvent.length > 0) {
            if (sequence.number === 1 && currentQuestionIndex === 0) {
                if (answerValue.randomEvent[1].flag === "buy_property_1" && ((companyInfo.building.en.name === "Property 1") && companyInfo.building.paymentType === "Buyout")) {
                    return answerValue.randomEvent[1]
                } else if (answerValue.randomEvent[0].flag === "buy_property_2" && ((companyInfo.building.en.name === "Property 2") && companyInfo.building.paymentType === "Buyout")) {
                    return answerValue.randomEvent[0]
                } else if (answerValue.randomEvent[2].flag === "rent_property_1" && ((companyInfo.building.en.name === "Property 1") && companyInfo.building.paymentType === "Rent")) {
                    return answerValue.randomEvent[2]
                } else if (answerValue.randomEvent[3].flag === "rent_property_2" && ((companyInfo.building.en.name === "Property 2") && companyInfo.building.paymentType === "Rent")) {
                    return answerValue.randomEvent[3]
                } else if (answerValue.randomEvent[4].flag === "use_coworking" && ((companyInfo.building.en.name === "Co-working space") && companyInfo.building.paymentType === "Rent")) {
                    return answerValue.randomEvent[4]
                }
            } else if (sequence.number === 2 && currentQuestionIndex === 0) {
                const consumables = companyInfo.equipment.filter((x) => x.item.en.name === "Cutlery (plastic)") === true
                const laptops = companyInfo.equipment.filter((x) => x.item.en.name === "Laptop") === true
                if (answerValue.randomEvent[1].flag === "use_laptop" && laptops) {
                    return answerValue.randomEvent[1]
                } else if (answerValue.randomEvent[0].flag === "use_consumables" && consumables) {
                    return answerValue.randomEvent[0]
                } else {
                    return null
                }
            } else if (sequence.number === 3 && currentQuestionIndex === 0) {
                const twoStars = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.skill === 2)
                const fourStars = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.skill === 4)
                const femaleTeam = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.gender === "female")
                let membersCategory = companyInfo.team.teamMembers.slice(1).map(x => x.role)
                const sameCategoryMembers = new Set(membersCategory).size !== membersCategory.length

                if (answerValue.randomEvent[0].flag === "2_star_member" && twoStars.length > 1) {
                    return answerValue.randomEvent[0]
                }
                else if (answerValue.randomEvent[2].flag === "4_star_member" && fourStars.length >= 3) {
                    return answerValue.randomEvent[2]
                }
                else if (answerValue.randomEvent[1].flag === "female_team" && (femaleTeam.length === 2 || femaleTeam.length === 3)) {
                    return answerValue.randomEvent[1]
                } else if (answerValue.randomEvent[3].flag === "same_category_members" && sameCategoryMembers) {
                    return answerValue.randomEvent[3]
                } else {
                    return null
                }
            } else if (sequence.number === 4 && currentQuestionIndex === 0) {
                if (answerValue.randomEvent[0].flag === "sole_trader" && ((companyInfo.legalAspect === "SoleTrader"))) {
                    return answerValue.randomEvent[0]
                } else if (answerValue.randomEvent[1].flag === "ltd" && (companyInfo.legalAspect === "LTD")) {
                    return answerValue.randomEvent[1]
                } else {
                    return null
                }
            }
            else {
                return answerValue.randomEvent[0]
            }
        } else {
            return null
        }

    }

    const selectLanguage = (value) => {
        if (languageStore.language === 'bhs') {
            return value.bhs.description
        } else {
            return value.en.description
        }
    }

    const RandomEventVideo = ({ sequence, videoNr }) => {
        if (videoNr) {
            if (sequence.number === 1 && videoNr === 5) {
                return (
                    <div>
                        <video loop controls={false} autoPlay={true} muted style={{ width: "100%" }}>
                            <source src={languageStore.language === 'bhs' ? Video2BHS : Video2} style={{ width: "100%" }} type="video/mp4" />
                        </video>
                    </div>
                )
            } else if (sequence.number === 2 && videoNr === 2) {
                return (
                    <div>
                        <video loop controls={false} autoPlay={true} muted style={{ width: "100%" }}>
                            <source src={languageStore.language === 'bhs' ? Video2BHS : Video2} style={{ width: "100%" }} type="video/mp4" />
                        </video>
                    </div>
                )
            } else if (sequence.number === 3) {
                return (
                    <div>
                        <video loop controls={false} autoPlay={true} muted style={{ width: "100%" }}>
                            <source src={languageStore.language === 'bhs' ? Video3BHS : Video3} style={{ width: "100%" }} type="video/mp4" />
                        </video>
                    </div>
                )
            } else if (sequence.number === 4) {
                return (
                    <div>
                        <video loop controls={false} autoPlay={true} muted style={{ width: "100%" }}>
                            <source src={languageStore.language === 'bhs' ? Video4BHS : Video4EN} style={{ width: "100%" }} type="video/mp4" />
                        </video>
                    </div>
                )
            }
        }
    }
    const RandomEventIlustration = ({ illustrationNr }) => {
        switch (illustrationNr) {
            case 1:
                return <Ilustration1 />
            case 2:
                return <Ilustration2 />
            case 3:
                return <Ilustration3 />
            case 4:
                return <Ilustration4 />
            case 5:
                return <Ilustration5 />
            case 6:
                return <Ilustration6 />
            case 7:
                return <Ilustration7 />
            case 8:
                return <Ilustration8 />
            case 9:
                return <Ilustration9 />
        }
    }
    const RandomEventContent = ({ answerValue, sequence, videoNr, illustrationNr }) => {
        if (answerValue && randomEventsHp(sequence)) {
            if ((randomEventsHp(sequence).positive === true || randomEventsHp(sequence).positive === false) && videoNr.videoNr) {
                return (
                    <div className="positive-feedback">
                        <div className="image-holder-random">
                            <RandomEventVideo sequence={sequence} videoNr={videoNr.videoNr} />
                        </div>
                        <div className="mentor-vision">
                            <div className='mentor-text-random'>
                                <div className="text-speach-random">
                                    <div className="mentor-talk-random">
                                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                                        <p className="hi-msg-random">{t('common:hi')} {userName}!</p>
                                        <p className="expl-msg-random">{selectLanguage(randomEventsHp(sequence))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if ((randomEventsHp(sequence).positive === true || randomEventsHp(sequence).positive === false) && illustrationNr.illustrationNr) {
                return (
                    <div className="positive-feedback">
                        <div className="image-holder-random">
                            <RandomEventIlustration illustrationNr={illustrationNr.illustrationNr} />
                        </div>
                        <div className="mentor-vision">
                            <div className='mentor-text-random'>
                                <div className="text-speach-random">
                                    <div className="mentor-talk-random">
                                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                                        <p className="hi-msg-random">{t('common:hi')} {userName}!</p>
                                        <p className="expl-msg-random">{selectLanguage(randomEventsHp(sequence))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (randomEventsHp(sequence).positive === true) {
                return (
                    <div className="positive-feedback">
                        <div className="image-holder-random">
                            <RandEventPositive />
                        </div>
                        <div className="mentor-vision">
                            <div className='mentor-text-random'>
                                <div className="text-speach-random">
                                    <div className="mentor-talk-random">
                                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                                        <p className="hi-msg-random">{t('common:hi')} {userName}!</p>
                                        <p className="expl-msg-random">{selectLanguage(randomEventsHp(sequence))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (randomEventsHp(sequence).positive === false) {
                return (
                    <div className="negative-feedback">
                        <div className="image-holder-random">
                            <RandEventNegative />
                        </div>
                        <div className="mentor-vision">
                            <div className='mentor-text-random'>
                                <div className="text-speach-random">
                                    <div className="mentor-talk-random">
                                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                                        <p className="hi-msg-random">{t('common:hi')} {userName}!</p>
                                        <p className="expl-msg-random">{selectLanguage(randomEventsHp(sequence))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    return (
        <Modal
            modalStyleTest='modal-content-equipment'
            closable={closable}
            okBtnText={t('common:landing-continue')}
            footer={footer}
            additionalStyle="signUp"
            visible={visible}
            handleOk={() => submitRandomEvent(randomEventsHp(sequence))}
            maskClosable={maskClosable}
        >
            <RandomEventContent answerValue={answerValue} sequence={sequence} videoNr={randomEventsHp(sequence)} illustrationNr={randomEventsHp(sequence)} />

        </Modal>
    )
}

export default withRouter(inject('userStore', 'languageStore')(observer(RandomEventModal)));