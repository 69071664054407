import React, { useState, useEffect } from 'react'
import './question.component.style.less';
import Answer from './Answer/answer.component'
import Timer from './Timer/timer.component'
import MentorModal from '../../../../../components/Modal/MentorModal/mentor.modal.component'
import HintModal from '../../../../../components/Modal/HintModal/hint.modal.component'
import RandomEventModal from '../../../../../components/Modal/RandomEventModal/random.event.modal'
import { Avatar, avatarArr, Mentor, mentorArr, TimerPrev, HintPrev, HelpPrev, PauseButton } from '../../../../../assets/images/index';
import { ANSWER_MODAL, getLocalStorage, MENTOR_USED, SELECTED, setLocalStorage, removeLocalStorage, TIMES_UP, BUDGET, USER_PROFILE_ESG } from '../../../../../localStorageMobxStore/localStorageUserProfile'
import LoanModal from './../../../../../components/Modal/ModalLoan/modalLoan.component'
import ResetGameModal from '../../../../../components/Modal/ModalReset/modalReset.component'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next'

// Images
import background from '../../../../../assets/images/hint.png';
import hintDisabled from '../../../../../assets/images/hintDisabled.png'
import userStore from '../../../../../store/user.store';

/**
 * Sequnce component
 * @class 
 * @param {Object} properties (  history, languageStore, sequence, quiz, question, hanleNextQuestion,
 * currentQuestionIndex, submitRandomEvent, randomEventModal,
 * timeStoped, setTimerStoped, answerValue, setSequnceProgres,
 * endGameModal, restartGame, loanChosen, budget, answerCorrectnessModal, setAnswerCorrectnessModal, companyInfo, setRandomEventModal, setAnswerValue, setMentorUsed, mentorUsed)
 * @returns List of Answers.Hint modal component with text and Continue button.Mentor modal component with text and Continue button.
 * Pause button. Random Event modal.Loan modal and Reset Game modal.
 */
const Question = ({ history, languageStore, sequence, quiz, question, hanleNextQuestion,
    currentQuestionIndex, submitRandomEvent, randomEventModal,
    timeStoped, setTimerStoped, answerValue, setSequnceProgres,
    endGameModal, restartGame, loanChosen, budget, answerCorrectnessModal, setAnswerCorrectnessModal, companyInfo, setRandomEventModal, setAnswerValue, setMentorUsed, mentorUsed }) => {
    const { t } = useTranslation(['common', 'sequences'])
    const [mentorModal, setMentorModal] = useState(false)
    const [hintModal, setHintModal] = useState(false)
    const [mentorImage, setMentorImage] = useState(null)
    const [userEsg, setUserEsg] = useState(null)
    const [pause, setPause] = useState("pauseButton")
    /**
     * Use Effect for fatching data from local storage in case user
     * refreshed the page and answered/or not the question before
     * modal will be opened and check if mentore is used or not
     * also for case he refreshed the page 
     */
    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
        const res = getLocalStorage(ANSWER_MODAL).ANSWER_MODAL
        setAnswerCorrectnessModal(res)
        const mentorUsedLocalStorage = getLocalStorage(MENTOR_USED).MENTOR_USED
        if (mentorUsedLocalStorage) {
            setMentorUsed(mentorUsedLocalStorage)
        }
    }, [])

    useEffect(() => {

        if (userEsg) {
            //   setUserStore(userEsg)
            //   setUserName(userEsg.userName)
            //   setUserImage(userEsg.avatarUrl)
            setMentorImage(userEsg.mentor.avatarUrl)
            //   setMentorName(userEsg.mentor.name)
        }
    }, [userEsg])


    /**
     * Methot for open/close mentor modal also set state of timer to stop and state of mentor that is used
     * and set mentor is used to local storage
     */
    const trigerMentorModal = () => {
        setMentorModal(true)
        // setTimerStoped(true)
        setMentorUsed(true)
        setLocalStorage(true, MENTOR_USED)
    }

    /**
     * Methot for open/close hint modal also set state of timer to stop
     */
    const trigerHint = () => {
        setHintModal(true)
        // setTimerStoped(true)
    }

    /**
     * Methot for close mentor modal also set state of timer to start
     */
    const handleOk = () => {
        setMentorModal(false)
        // setTimerStoped(false)
    }

    /**
     * Methot for close hint modal also set state of timer to start
     */
    const hintActivated = () => {
        setHintModal(false)
        // setTimerStoped(false)
    }

    /**
     * Image return for HINT
     */
    const HintImage = () => {
        return <img src={background} alt="background" />;
    }
    const HintDisabled = () => {
        return <img src={hintDisabled} alt="hintDisabled" />;
    }


    /**
     * Method for stoping/starting time and opening,closing correct/incorect modal alose
     * set selected answer value to local storage , answermodal that is open and 
     * that time is elapsed
     * @param {Object} selected Selected answer
     */
    const nextQuestionModal = (selected) => {
        setLocalStorage(selected, SELECTED)
        setLocalStorage(true, ANSWER_MODAL)
        setLocalStorage(true, TIMES_UP)
        setAnswerCorrectnessModal(true)
        // setTimerStoped(true)
    }

    /**
     * Method for closing Question modal also check if ther is selected 
     * answer and if it is check if answer have
     * random event and set time of timer to start if there is not random event. 
     * In case there is random event stops time of timer.Remove timesUp , selected
     * and mentorUsed values from local storage
     * @param {Object} selected Selected answer
     */
    const closeQuestionModal = (selected) => {
        if (!selected) {
            // setTimerStoped(false)
            removeLocalStorage(TIMES_UP)
            setMentorUsed(false)
            removeLocalStorage(ANSWER_MODAL, MENTOR_USED)
        } else {
            if (budget.budget.remaining + selected.points > 0) {
                if (selected.randomEvent.length <= 0) {
                    removeLocalStorage(SELECTED, TIMES_UP)
                    setMentorUsed(false)
                    removeLocalStorage(ANSWER_MODAL, MENTOR_USED)
                } else {
                    // setTimerStoped(true)
                    setMentorUsed(false)
                    removeLocalStorage(ANSWER_MODAL, MENTOR_USED)
                }
            }
        }
    }
   /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }
    const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
    /**
     * Select Languege function
     * @returns Returns bhs/en answer depending on current language
     */
    const selectLanguage = () => {
        const { locales } = question
        const { language } = languageStore

        let qustionContent;
        
        locales.map(item => {
            if(item.locale === language) {
                qustionContent = item.question_content.questionText;
            }
        })
        
        return qustionContent;
    }
    
    /**
     * Select Language Hint function
     * @returns Returns bhs/en hint depending on current language
     */
    const selectLanguageHint = () => {
        const { language } = languageStore;

        let hintContent;

        question.locales.map(item => {
            if(item.locale === language) {
                hintContent = item.question_content.textHint;
            }
        })

        return hintContent;


    }

    const toggleClass = () => {
        setPause('clickedPause')
    }

    return (
        <div className="sequence-holder">
            <div>
                {budget.budget.loan ?
                    <ResetGameModal
                        visible={endGameModal}
                        restartGame={restartGame}
                        okBtnText="Start from scratch"
                        footer={true}
                    />
                    :
                    <LoanModal
                        visible={endGameModal}
                        loanChosen={loanChosen}
                        restartGame={restartGame}
                        okBtnText="Take loan"
                        footer={true}
                        cancelBtnText="Start game again"
                    />}
            </div>
            <RandomEventModal
                closable={false}
                okBtnText="Continue"
                visible={randomEventModal}
                footer={true}
                submitRandomEvent={submitRandomEvent}
                setAnswerValue={setAnswerValue}
                currentQuestionIndex={currentQuestionIndex}
                maskClosable={false}
                answerValue={answerValue}
                question={question}
                sequence={sequence}
                companyInfo={companyInfo}
                setRandomEventModal={setRandomEventModal} />
            <div>


                <div className='questions-for-all'>
                    {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                    <div className="question-holder"
                        dangerouslySetInnerHTML={{ __html: selectLanguage() }}>
                    </div>
                </div>
                {!answerCorrectnessModal ?
                    <div className="three-items">
                        <div className='hint-modal-holder'>
                            <span className={pause} onClick={() => toggleClass()}>
                                <PauseButton />
                            </span>
                            <HintModal
                                btnText={selectLanguageHint() === "-" || !selectLanguageHint() ? <HintDisabled /> : <HintImage />}
                                okBtnText={t('common:landing-continue')}
                                closable={false}
                                visible={hintModal}
                                trigerVisible={trigerHint}
                                footer={true}
                                handleOk={hintActivated}
                                maskClosable={false}
                                question={question} />
                        </div>
                        <div className='mentor-modal-holder'>
                            <MentorModal
                                closable={false}
                                btnText="!"
                                okBtnText={t('common:landing-continue')}
                                visible={mentorModal}
                                trigerVisible={trigerMentorModal}
                                footer={true}
                                handleOk={handleOk}
                                maskClosable={false}
                                question={question} />
                        </div>
                    </div>
                    : null
                }
                <div>
                    <Answer
                        sequence={sequence}
                        question={question}
                        answers={question.answers}
                        hanleNextQuestion={hanleNextQuestion}
                        mentorUsed={mentorUsed}
                        currentQuestionIndex={currentQuestionIndex}
                        quiz={quiz}
                        nextQuestionModal={nextQuestionModal}
                        answerCorrectnessModal={answerCorrectnessModal}
                        closeQuestionModal={closeQuestionModal}
                        setSequnceProgres={setSequnceProgres}
                        setAnswerCorrectnessModal={setAnswerCorrectnessModal}
                        setAnswerValue={setAnswerValue}
                        setRandomEventModal={setRandomEventModal}
                        companyInfo={companyInfo}
                        answerValue={answerValue} />
                </div>
            </div>
        </div >
    )
}

export default withRouter(inject('languageStore')(observer(Question)))
