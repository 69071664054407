import React, { useState, useEffect } from 'react';
import './userProfile.component.style.less';
import SiderBar from '../UserProfile/SiderComponent/sider.component'
import Table from '../UserProfile/TableComponent/table.component'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Redirect, withRouter } from 'react-router-dom';
import { getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET } from '../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../components/Button/button.component'
import { useTranslation } from "react-i18next"

/**
 * UserData component
 * @class
 * @param {Object} properties (history, userStore, progressBarStore)
 * @returns This component is used in AdminPanel component to get 
 * all players where their total point from multiplayer mode can be seen.
 */
const UserData = ({ history, userStore, progressBarStore }) => {
    const { t } = useTranslation("common")
    const [selectedContent, setSelectedContent] = useState('1');
    const [userEsg, setUserEsg] = useState(null)
    const [budget, setBudget] = useState(null)
    const [companyInfo, setCompanyInfo] = useState(null)

    const { progressTwo } = progressBarStore;


    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
        setBudget(getLocalStorage(BUDGET).BUDGET)
        setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    }, [])

    useEffect(() => {
        if (userEsg || companyInfo || budget) {
            setUserStore(userEsg, companyInfo, budget)
        }
    }, [userEsg, companyInfo, budget])

    const setUserStore = (userEsg, companyInfo, budget) => {
        userStore.updateUserInfoEsg(userEsg)
        userStore.updateCompanyInfo(companyInfo)
        userStore.updateBudget(budget)
    }

    /** Method that retuns boolean value depends on are you in sequence or not **/
    const inSequnce = () => {
        if (companyInfo) {
            if (companyInfo.building && companyInfo.team && companyInfo.equipment && companyInfo.legalAspect) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    /** Method that redirects to game if you are in sequence or to previous page **/
    const redirect = () => {
        if (inSequnce()) {
            history.push('game')
        } else {
            history.push(history.goBack())
        }
    }

    return (
        <div className="test">
            <SiderBar setSelectedContent={setSelectedContent} selectedContent={selectedContent} />
            <Table selectedContent={selectedContent} />
            <div className="button-landing">
                {progressTwo === 100 && <Button
                    additionalStyle="buttonMakeAcc"
                    text={t("common:back-to-office")}
                    customBtn={true}
                    onClick={() => history.push('office')}
                />}
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t("common:back-game")}
                    customBtn={true}
                    onClick={redirect}
                />
            </div>
        </div>
    )
}

export default withRouter(inject('userStore', 'progressBarStore')(observer(UserData)));