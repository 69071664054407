import React, { useState, useEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import EsgService from '../../../../../services/EsgServices/esg.service';
import './chartBuilding.component.style.less'
import { useTranslation } from "react-i18next"
import Download from './excel';

/**
 * ChartBuildings component
 * @class
 * @returns This component is used in gameStats component to display 
 * all players data represeted in charts for building
 */
const ChartBuildings = () => {
    const { t } = useTranslation("admin")
    const isReady = React.useRef(false);

    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()
    const [buildingTypeNew, setBuildingTypeNew] = useState(null)
    const [buildingTypeOld, setBuildingTypeOld] = useState(null)
    const [buildingTypeCoworking, setBuildingTypeCoworking] = useState(null)

    useEffect(() => {
        fetch();
        return () => {
            isReady.current = true;
        };
    }, [])

    /**
     *Function that is used in component did mount to get all necessary data that we need to display
    */
    const fetch = () => {
        esgService.getGeneralStats().then((res) => {
            if (!isReady.current) {
                const allStatsBuilding = res.data.buildings
                setBuildingTypeNew(allStatsBuilding.new)
                setBuildingTypeOld(allStatsBuilding.old)
                setBuildingTypeCoworking(allStatsBuilding.coworking)
            }
        });
    }

    return (
        <div className='chart-comp-bld'>
            <h2>{t("admin:charts.buildings")}</h2>
            <Chart
                width={600}
                height={300}
                chartType="PieChart"
                loader={<div>{t("admin:charts.loading")}</div>}
                data={[
                    [t("admin:charts.buildings"), t("admin:charts.selected")],
                    [t("admin:charts.new"), buildingTypeNew],
                    [t("admin:charts.old"), buildingTypeOld],
                    [t("admin:charts.cowork"), buildingTypeCoworking],
                ]}
                options={{
                    // title: 'My Daily Activities',
                    colors: ['#7b35df', '#16a085', '#34495e']
                }}
                rootProps={{ 'data-testid': '1' }}
            />

            <div>
                <Download
                    buildingTypeNew={buildingTypeNew}
                    buildingTypeOld={buildingTypeOld}
                    buildingTypeCoworking={buildingTypeCoworking}
                />
            </div>
        </div>
    );
};

export default ChartBuildings;
