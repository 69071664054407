import React from "react";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import "./logedOutUser.style.less";
import logo from ".././logo.png";
import Button from "../../../components/Button/button.component";
import { useTranslation } from "react-i18next";
import { BhsLang, EnLang } from "../../../assets/images/index";
/**
 * Loged Out User component
 * @class
 * @param {Object} properties (history, languageStore)
 * @returns Icon with logo of BizVenture that when is clicked redirect to
 * landing page.Two buttons Sign In and Sign Out
 */
const HeaderUserLogedOut = ({ history, languageStore }) => {
  const { t, i18n } = useTranslation("header");
  const { language } = languageStore;

  /**
   * Redirect user to login page
   */
  const redirectLogin = () => {
    history.push("/login");
  };
  /**
   * Redirect user to registration page
   */
  const redirectRegistration = () => {
    history.push("./registration");
  };
  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.alt);
  };
  /**
   * Select English function.Set in language store state of language to 'EN'
   * and select active icon to be English
   */
  const selectEnglish = () => {
    languageStore.setEnglishLanguage();
    document.querySelector(".bosnian").classList.remove("active");
    document.querySelector(".english").classList.add("active");
  };
  /**
   * Select BHS function.Set in language store state of language to 'BHS'
   * and select active icon to be BHS
   */
  const selectBhs = () => {
    languageStore.setBhsLanguage();
    document.querySelector(".english").classList.remove("active");
    document.querySelector(".bosnian").classList.add("active");
  };

  return (
    <div className="header-main">
      <div className="header-holder">
        <div className="left-corner">
          <img
            onClick={() => history.push("/")}
            className="logo-place"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="right-corner">
          <div id="lng-switch" onClick={(e) => changeLanguage(e)}>
            <div className="inline">
              <EnLang selectEnglish={selectEnglish} />
              <BhsLang selectBhs={selectBhs} />
            </div>
          </div>
          <Button
            additionalStyle="signIn"
            text={language === "bhs" ? "Prijavi se" : "Sign In"}
            customBtn={true}
            onClick={redirectLogin}
          />
          <Button
            additionalStyle="signUp"
            text={language === "bhs" ? "Registruj se" : "Sign Up"}
            customBtn={true}
            onClick={redirectRegistration}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  inject("userStore", "languageStore")(observer(HeaderUserLogedOut))
);
