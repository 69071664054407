import React, { useState, useEffect } from 'react';
import './chairsSelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET, CHAIRS } from '../../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../../components/Button/button.component';
import EsgService from '../../../../services/EsgServices/esg.service'
import { HardwareEquipment, Coins } from '../../../../assets/images/index';
import Counter from '../CounterComponent/counter.component'
import ModalInfoEquipment from '../../../../components/Modal/ModalInfoEquipment/modalInfoEquipment.component'
import ClickSound from '../../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'


const inventoryType = 'chair'

/**
 * Chairs Select component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with text and Contionue button covering component After modal is closed.
 * We have title, text content with info message how much of equpment user need to select to continue.
 * List of equipment where every equipment have picture, counter that have minus button for decreasing number of equipment select ,
 * input for number of times user selected that equpment and plus button to add equipment.Price and Info button that
 * when is clicked open modal with picture , text information about equipment and close button  for closing modal.
 * Continue button that redirect user to other equipment component.
 * * Back button for going back to previous page. 
 */
const ChairsSelect = ({ history, userStore, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "equipment"])
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [approved, setApproved] = useState(null)
  const [equipment, setEquipment] = useState([])
  const [officeEquipment, setOfficeEquipment] = useState([])
  const [chairsSelected, setChairsSelected] = useState([])
  const [tables, setTables] = useState({
    amount: 2,
    equipment: {}
  })
  const [chairs, setChairs] = useState({
    amount: 2,
    equipment: {}
  })
  const [lamps, setLamps] = useState({
    amount: 1,
    equipment: {}
  })
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    setEquipment(getLocalStorage(CHAIRS).CHAIRS)
    const resLS = getLocalStorage(CHAIRS).CHAIRS
    if (resLS) {
      setTables(resLS[0])
      setChairs(resLS[1])
      setLamps(resLS[2])
    }
    esgService.getEquipment(inventoryType).then((res) => {
      setTables(tables => ({
        ...tables,
        equipment: res.data[0]
      }))
      setChairs(chairs => ({
        ...chairs,
        equipment: res.data[1]
      }))
      setLamps(lamps => ({
        ...lamps,
        equipment: res.data[2]
      }))
      setOfficeEquipment(res.data)
    })
    progressBarStore.setProgress(100, 69, 0);
  }, [])
  useEffect(() => {
    const equipmentArr = [tables, chairs, lamps]
    const amounts = equipmentArr.map(arr => arr.amount).reduce((a, b) => a + b, 0)
    if (amounts >= 5) {
      setApproved(true)
    } else {
      setApproved(false)
    }
    setChairsSelected(equipmentArr)
  }, [tables, chairs, lamps, approved])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])
  /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }
  /**
   * Counter click function
   * @param {Nubmer} value 
   * @param {Nubmer} index 
   * @param {Object} x Hardwere object value
   * @returns Set state value and amout of tables , chairs  and lamps
   */
  const counertClicked = (value, index, x) => {
    if (index === 0) {
      setTables({
        amount: value,
        equipment: x
      })
    } else if (index === 1) {
      setChairs({
        amount: value,
        equipment: x
      })

    } else if (index === 2) {
      setLamps({
        amount: value,
        equipment: x
      })
    }
  }
  /**
   * Select Language function that takes one parametar value check what language we are 
   * currently using from mobx languageStore.language
   * @param {Object} x chairs 
   * @returns Name of chairs in BHS language or EN language for paragraph
   */
  const selectLanguage = (x) => {
    if (languageStore.language === "en") {
      return x.en.name
    } else if (languageStore.language === 'bhs') {
      return x.bhs.name
    } else {
      return x.en.name
    }
  }
  /**
   * Select Language function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} x chairs description
   * @returns Description of chairs in BHS language or EN language for paragraph
   */
  const equipmentCheck = (equipment) => {
    if (equipment.en.name === "Chair no. 1") {
      return tables.amount
    } else if (equipment.en.name === "Chair no.2 – locally produced") {
      return chairs.amount
    } else if (equipment.en.name === "Chair no. 3") {
      return lamps.amount
    }
  }
  /**
   * Sumbit Selected Equipment
   * @returns setLocalStorage TABLES with value tablesSelected
   * and redirect user to otherequipment rout
   */
  const sumbitSelectedEquipment = () => {
    setLocalStorage(chairsSelected, CHAIRS)
    history.push('otherequipment')
  }
  return (
    <div className="office-equipment app">
      <div className="office-equipment-section">
        <h2>{t('equipment:equipment-header-chairs')}</h2>
        <p className={approved ? "allow-p" : "alert-p"}>{t('equipment:equipment-selection-chairs')}</p>
        <div className="office-equipment-holder">
          {officeEquipment.length > 0 && officeEquipment.map((x, index) => {
            return (
              <div className="equipment-holder" key={x.name}>
                {x.en.name === 'Chair no. 1'?
                  <HardwareEquipment
                  imageUrl={x.imageUrl}
                  selected={equipmentCheck(x)}
                  ecoFriendly={5}/>:<HardwareEquipment
                  imageUrl={x.imageUrl}
                  selected={equipmentCheck(x)}
                  ecoFriendly={0}/>}
                <Counter value={equipmentCheck(x)} handleChange={(value) => { counertClicked(value, index, x); playSound(likeAudio); }} />
                <h2>{selectLanguage(x)}</h2>
                <p className="green">
                  {x.price}<Coins />
                </p>
                <ModalInfoEquipment
                  imageUrl={x.imageUrl}
                  contentHeader={selectLanguage(x)}
                  contentPrice={x.price}
                />
              </div>
            )
          })}
        </div>
        <div className="button-landing">
          <Button
            additionalStyle="signIn"
            text={t('common:landing-back')}
            customBtn={true}
            onClick={() => {
              history.goBack();
            }} />
          <Button
            additionalStyle="buttonMakeAcc"
            text={t('common:landing-continue')}
            customBtn={true}
            disabled={approved ? false : true}
            onClick={() => { sumbitSelectedEquipment(); playSound(likeAudio); }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'languageStore', 'progressBarStore')(observer(ChairsSelect)));
