import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { message, Form, Input } from "antd";
import { withRouter } from "react-router-dom";
import {
  setLocalStorage,
  USER_PROFILE_ESG,
} from "../../localStorageMobxStore/localStorageUserProfile";
import * as token from "../../services/core/token";
import AuthService from "../../services/auth.service";
import IndetityService from "../../services/identity.service";
import EsgService from "../../services/EsgServices/esg.service";
import Button from "../../components/Button/button.component";
import "./signIn.from.style.less";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Spin } from "antd";
// import fb from "../../assets/images/fb.svg";
import google from "../../assets/images/google.svg";
// import linkedin from "../../assets/images/linkedin.svg";
import config from "../../config/config.local";

const authRoot =
  process.env.NODE_ENV === "production"
    ? config.API_BASE_URL_AUTH + "/auth"
    : config.API_BASE_URL_AUTH;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SignIn = ({ userStore, history }) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation("registration");
  const [form] = Form.useForm();
  const authService = new AuthService();
  const indetityService = new IndetityService();
  const esgService = new EsgService();

  const toggleLoader = (value) => {
    setLoader({ loader: value });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (
          values.email === "bizventure@admin.com" &&
          values.password === "rRTpJh5Cn7H8wQqL"
        ) {
          const data = {
            email: values.email,
            password: values.password,
          };
          authService.adminLogin(data).then((res) => {
            const adminToken = res.data.token;
            token.set(adminToken);
            history.replace("admin");
          });
        } else {
          setLoader(!loader);
          authService
            .login(values)
            .then((loginResponse) => {
              const userToken = loginResponse.data.token;
              indetityService.validateUserToken().then((userEuisResponse) => {
                if (userEuisResponse.status === 200) {
                  token.set(userToken);
                  const userEusiId = userEuisResponse.data.id;
                  esgService
                    .getUserProfile(userEusiId)
                    .then((userEsgResponse) => {
                      setLocalStorage(userEsgResponse.data, USER_PROFILE_ESG);
                      userStore.updateUserInfoEsg(userEsgResponse.data);

                      if (
                        !userEsgResponse.data.hasOwnProperty("avatarUrl") ||
                        userEsgResponse.data.avatarUrl === null
                      ) {
                        history.replace("/avatars");
                      } else if (
                        !userEsgResponse.data.hasOwnProperty("userName")
                      ) {
                        history.replace("/charctername");
                      } else if (
                        !userEsgResponse.data.hasOwnProperty("mentor")
                      ) {
                        history.replace("/mentorselect");
                      } else {
                        history.replace("/selectmode");
                      }
                    });
                }
              });
            })
            .catch((e) => {
              message
                .error({
                  content: e.response.data.message,
                  duration: 3,
                  className: "custom-error",
                  style: {
                    marginTop: "20vh",
                  },
                })
                .then(() => setLoader(loader));
            });
        }
      })
      .catch((err) => {
        console.log(err, "ERR");
      });
  };

  return (
    <div className="forma-reg-signIn">
      <div className={loader ? "spinner-back" : "spinner-non-back"}>
        <Spin size="large" spinning={loader} />
      </div>

      <h1>{t("registration:sign-in")}</h1>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          label={t("registration:email")}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: t("registration:email-message"),
            },
          ]}
        >
          <Input placeholder={t("registration:email-message")} />
        </Form.Item>

        <Form.Item
          label={t("registration:password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("registration:password-message-login"),
            },
          ]}
        >
          <Input.Password
            placeholder={t("registration:password-message-login")}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            additionalStyle="signIn"
            text={t("registration:sign-in")}
            customBtn={true}
            onClick={onFinish}
            toggleLoader={toggleLoader}
          />
        </Form.Item>
      </Form>
      <div className="sc-networks">
        <p className="sc-networks-p">{t("registration:existing-account")}</p>
        <div className="sc-networks-images">
          <div>
            <a
              href={`${authRoot}/google`}
              target="_blank"
              style={{ marginRight: "15px" }}
            >
              <img src={google} alt="google_account" />
            </a>
            {/* <a href={`${authRoot}/facebook`} target="_blank" style={{ marginRight: "15px" }}><img src={fb} /></a> */}
            {/* <a href={`${authRoot}/linkedin`} target="_blank" style={{ marginRight: "15px" }}><img src={linkedin} /></a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject("userStore")(observer(SignIn)));
