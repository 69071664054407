import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_NAME, INDUSTRY_SELECT, COMPANY_INFO, BUDGET } from '../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../components/Button/button.component';
import { Form, Input } from 'antd';
import { ItindustryPrev } from '../../../assets/images/index.js';
import EsgService from '../../../services/EsgServices/esg.service'
import './companyName.component.style.less';
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'

/**
 * Industry Type component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title , picture and input field. Where user can type in name of company.
 * And two buttons : Contiue button that redirect user to chooseproffesions page.
 * Back button for going back to previous page. 
 */
const IndustryName = ({ userStore, history, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "company"])
  const [form] = Form.useForm();
  const [userEsg, setUserEsg] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [budget, setBudget] = useState(null)
  const [companyName, setcompanyName] = useState(null);
  const [industry, setIndustry] = useState(null)
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()

  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }


  useEffect(() => {

    const companyInfo = getLocalStorage(COMPANY_INFO).COMPANY_INFO
    if (companyInfo) {
      setCompanyInfo(companyInfo)
      form.setFieldsValue({ name: companyInfo.name });
    }
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setIndustry(getLocalStorage(INDUSTRY_SELECT).INDUSTRY_SELECT)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    progressBarStore.setProgress(100, 12, 0)
  }, [])

  useEffect(() => {
    if (userEsg || companyInfo || industry || budget) {
      setUserStore(userEsg, companyInfo, industry, budget)
    }
  }, [userEsg, companyInfo, industry, budget])
  /**
   * Function that four parametars userEsg , singlePlayer ,companyInfo , industry
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @param {String} industry industry type that user selected 
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo and 
   * industry with new value of industry
   */
  const setUserStore = (userEsg, companyInfo, industry, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateIndustry(industry)
    userStore.updateBudget(budget)
  }
  /**
   * Function OnFinish that is called on Continue button when user enter the name of company.
   * First check validation of form.After that create object companyName that is passe to esgService
   * with method creatCompany and send it to backend . Response is save to localStorage and mobix 
   * userStore.Form field is reseted and user is redirected to chooseproffesions rout.
   */
  const onFinish = () => {
    form.validateFields().then((values) => {

      const { _id, accelerator, mode, owner } = userStore.budget.budget
      const { industry } = userStore
      const companyName = values.name
      const companyDetail = {
        industry: industry,
        name: companyName,
        budgetId: _id,
        mode,
        accelerator,
        owner
      }
      esgService.createCompany(companyDetail).then((res) => {
        setLocalStorage(res.data, COMPANY_INFO)
        userStore.updateCompanyInfo(res.data)
        form.resetFields();
        history.push('chooseproffesions')
      })
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  /**
   * Function that take one paremetar value of input that user typed in in input field
   * @param {String} value Value of input field
   * @returns Set value of capanyName with value of input
   */
  const onStartInput = (value) => {
    setcompanyName(value);
  };

  return (
    <div className="name-industry app">
      <div className="name-section-industry">
        <h2>{t("company:company-name.company-name-headline")}</h2>
        <div className="name-industry-holder">
          <ItindustryPrev />
        </div>
        <div className="name-input-holder-industry">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t("company:company-name.company-name-message"),
                },
              ]}
            >
              <Input
                value={companyName}
                onChange={(e) => onStartInput(e.target.value)}
                placeholder={t("company:company-name.company-name-message")}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="button-landing">
          <Button
            additionalStyle="signIn"
            text={t("common:landing-back")}
            customBtn={true}
            onClick={() => {
              history.goBack();
            }}
          />
          <Button
            additionalStyle="buttonMakeAcc"
            text={t("common:landing-continue")}
            customBtn={true}
            disabled={companyName === ''}
            onClick={() => { onFinish(); playSound(likeAudio); }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(IndustryName)));
