import React from 'react';
import "./gameStats.component.style.less";
import ChartBuildings from './ChartBuildings/chartBuilding.component'
import ChartEmployees from './ChartEmployees/chartEmployees.component'
import ChartAccelerator from './ChartAccelerator/chartAccelerator.component'
import ChartEco from './ChartEco/chartEco.component'
import UsersInfo from './UsersInfo/usersInfo.component';

/**
 * GameStats component
 * @class
 * @returns This component is used in TableAdmin component to display 
 * all players data in ChartBuildings, ChartEmployees, ChartAccelerator and ChartEco component
 */
const GameStats = () => {

    return (
        <div className="game-statistics">
            <div className="stats">
                <ChartBuildings />
                <ChartEmployees />
                <ChartAccelerator />
                <ChartEco />
                <UsersInfo/>
            </div>
        </div>
    );
}

export default GameStats;