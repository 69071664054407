const USER_PROFILE_ESG = "USER_PROFILE_ESG" //Player profile
const COMPANY_NAME = "COMPANY_NAME" // Company name
const COMPANY_INFO = 'COMPANY_INFO' // Company info
const BUDGET = 'BUDGET' // Budget of player
const EMPLOYEES = 'EMPLOYEES' //Eployees that player hired
const BUILDING = 'BUILDING' //Building for work buy/rent
const HARDWARE = 'HARDWARE' //Hardware that user bought for company
const TABLES = 'TABLES' //Tables that user bought for company
const CHAIRS = 'CHAIRS' //Chairs that user bought for company
const OTHER_EQUIPMENT = 'OTHER_EQUIPMENT' //Other equipment that user bought for company
const EQUIPMENT = 'EQUIPMENT' //Equipment that user bought/rent for company
const INVENTORY = 'INVENTORY' //Inventory that user bought/rent for company 
const ALL_EQUIPMENT = "ALL_EQUIPMENT" //All equipment that user chooosed to buy for company
const EMPLOYEES_PROFESSION = 'EMPLOYEES_PROFESSION' //Proffesion of hired employees
const INDUSTRY_SELECT = "INDUSTRY_SELECT" //Type of indystry selected

const SEQUENCE_PROGGRES = 'SEQUENCE_PROGGRES' // Proggres of sequnce what component is rendered
const TIMES_UP = 'TIMES_UP' //Boolean Time of time in sequince have end 
const SELECTED = 'SELECTED' //Selected answer
const INCREMENT_CURRENT_QUESTION_INDEX = 'INCREMENT_CURRENT_QUESTION_INDEX' //Nubmer/index of question in quiz/sequence incrementCurrentQuestionIndex
const ANSWER_MODAL = 'ANSWER_MODAL' //Boolean.Set if modal after given asnwer is activated.
const MENTOR_USED = 'MENTOR_USED' //Boolean.Set if mentor in question is used.
const RANDOM_EVENT_VALUE = 'RANDOM_EVENT_VALUE' //Value of random event. randomEventValue
const RANDOM_EVENT_MODAL = 'RANDOM_EVENT_MODAL' //Boolean. Set if random eventom modal is visible. randomEventModal
const SEQUENCE_INDEX = 'SEQUENCE_INDEX' //Number Sequence Index

const MONTHLYEXPENSES = 'MONTHLYEXPENSES'; // object - for offline mod, to get data after refreshz
const END_GAME_MODAL = 'END_GAME_MODAL'


/**
 * Set item to local storage
 * @param {Oject} value Value for storing to local storage
 * @param {String} name Key for storing item to local storage
 */
const setLocalStorage = (value,name) => {
    return localStorage.setItem(`${name}`,JSON.stringify(value))
}

//TODO This not work as should be but need to test in case of getting multy items
/**
 * Get item/itmes from local storage pass array,return Array of objects.
 * @param  {Array} args Array of items that need to be get from local storage
 */
const getLocalStorage = (...args) => {    
    return Object.assign({},...args.map((x)=> {
        return Object.assign({[x]:JSON.parse(localStorage.getItem(`${x}`))})
    }))
}
/**
 * Remove one or more items from local storage
 * @param {Array} args Array of items that need to be removed from Local Storage
 */
const removeLocalStorage = (...args) => {
    return args.map((x)=>{
        localStorage.removeItem(`${x}`)
    })
}

export { 
         setLocalStorage,
         getLocalStorage,
         removeLocalStorage,
         USER_PROFILE_ESG,
         COMPANY_INFO,
         EMPLOYEES,
         BUILDING,
         BUDGET,
         HARDWARE,
         EQUIPMENT,
         TABLES,
         CHAIRS,
         OTHER_EQUIPMENT,
         INVENTORY,
         EMPLOYEES_PROFESSION,
         INDUSTRY_SELECT,
         COMPANY_NAME,
         ALL_EQUIPMENT,
         SEQUENCE_PROGGRES,
         TIMES_UP,
         SELECTED,
         INCREMENT_CURRENT_QUESTION_INDEX,
         ANSWER_MODAL,
         MENTOR_USED,
         RANDOM_EVENT_VALUE,
         RANDOM_EVENT_MODAL,
         SEQUENCE_INDEX,
         MONTHLYEXPENSES,
         END_GAME_MODAL
        }