import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (males, females, others)
 * @returns This component is used in chartEmployees
 * to download Excel file based on the data form props
 */
const Download = ({ males, females, others }) => {
    const { t } = useTranslation("admin")
    const dataSet1 = [
        {
            name: t("admin:excels-gender.male"),
            amount: males ? males : 0
        },
        {
            name: t("admin:excels-gender.female"),
            amount: females ? females : 0
        },
        {
            name: t("admin:excels-gender.other"),
            amount: others ? others : 0
        },
    ];
    return (
        <ExcelFile filename='employees' element={<button className="buttonData">{t("admin:excels-gender.download")}</button>}>
            <ExcelSheet data={dataSet1} name={t("admin:excels-gender.empl")}>
                <ExcelColumn label={t("admin:excels-gender.lblN")} value="name" />
                <ExcelColumn label={t("admin:excels-gender.lblU")} value="amount" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default Download;