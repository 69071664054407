import qs from 'qs';

const stringify = (object) => {
    return  decodeURIComponent(qs.stringify(object));
};

const parse = (value) => {
    return qs.parse(value);
};

export {
    stringify,
    parse
}

