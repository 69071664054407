import React from "react";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as token from "../../services/core/token";
import "./header.container.style.less";
import HeaderUserLogedOut from "./LogedOutUser/logedOutUser";
import HeaderUserLogedIn from "./LogedInUser/logedInUser";
import { useTranslation } from 'react-i18next'
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

/**
 * Check Header component
 * @class
 * @param {Object} properties ( userStore, history )
 * @returns Check if user is loged in and render compoent HeaderUserLogedIn and ProgresBar component 
 * if not render just HeaderUserLogedOut component
 */
const CheckHeader = ({ userStore, history }) => {
    const getToken = () => {
        return token.get();
    };
    const isAuthenticated = !!getToken();
    if (isAuthenticated) {
        return <HeaderUserLogedIn history={history} />;
    } else {
        return <HeaderUserLogedOut history={history} />;
    }
};
const HeaderNavigation = ({ userStore, progressBarStore, history }) => {

  const {progressOne, progressTwo, progressThree} = progressBarStore;
  const { t } = useTranslation(['progressBar'])


    return (
        <div>
            <CheckHeader history={history} userStore={userStore} />
            {progressOne !== 0 ?
            <div className="progress-bars">
                <div className={`progress ${progressOne === 100 && 'filled'} ${progressTwo < 1 ? 'active' : ''}`}>
                    <ProgressBar
                        className='test'
                        percent={progressOne}
                        filledBackground="linear-gradient(to right, #9f41c2, #a463bc)"
                        text={t("progressBar:introduction")}
                    />
                </div>
                <div className={`progress ${progressTwo === 100 ? 'filled' : ''} ${(progressOne === 100 && progressThree === 0) ? 'active' : ''}`}>
                    <ProgressBar
                        percent={progressTwo}
                        filledBackground="linear-gradient(to right, #a463bc, #732ada)"
                        text={t("progressBar:building-the-startup")}
                    />
                </div>
                <div className={`progress ${progressThree === 100 ? 'filled' : ''} ${(progressOne === 100 && progressTwo === 100) ? 'active' : ''}`}>
                    <ProgressBar
                        percent={progressThree}
                        filledBackground="linear-gradient(to right, #732ada, #9f41c2)"
                        text={t("progressBar:startup-journey")}
                    />
                </div>
            </div> : null}
        </div>
    );
};

export default withRouter(inject("userStore", "progressBarStore")(observer(HeaderNavigation)));
