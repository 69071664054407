import React, { useState, useEffect } from 'react';
import './otherEquipmentSelect.component.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET, OTHER_EQUIPMENT, HARDWARE, TABLES, CHAIRS, ALL_EQUIPMENT } from '../../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../../components/Button/button.component';
import EsgService from '../../../../services/EsgServices/esg.service'
import { HardwareEquipment, Coins } from '../../../../assets/images/index';
import Counter from '../CounterComponent/counter.component'
import ModalInfoEquipment from '../../../../components/Modal/ModalInfoEquipment/modalInfoEquipment.component'
import IntroOfficeModal from '../../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component'
import ClickSound from '../../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'

const inventoryType = 'extras'
/**
 * Other Equipment Select component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with text and Contionue button covering component After modal is closed.
 * We have title, text content with info message how much of equpment user need to select to continue.
 * List of equipment where every equipment have picture, counter that have minus button for decreasing number of equipment select ,
 * input for number of times user selected that equpment and plus button to add equipment.Price and Info button that
 * when is clicked open modal with picture , text information about equipment and close button  for closing modal.
 * Continue button that redirect user to pay equipment component.
 * * Back button for going back to previous page. 
 */
const OtherEquipmentSelect = ({ history, userStore, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "equipment"])
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [approved, setApproved] = useState(null)
  const [hardWare, setHardWare] = useState([])
  const [tables, setTables] = useState([])
  const [chairs, setChairs] = useState([])
  const [equipment, setEquipment] = useState([])
  const [officeEquipment, setOfficeEquipment] = useState([])
  const [inventorySelected, setInventorySelected] = useState([])
  const [Ionizer, setIonizer] = useState({
    amount: 1,
    equipment: {}
  })
  const [mouseKeyboard, setMouseKeyboard] = useState({
    amount: 3,
    equipment: {}
  })
  const [plates, setPlates] = useState({
    amount: 4,
    equipment: {}
  })
  const [plasticPlates, setPlasticPlates] = useState({
    amount: 2,
    equipment: {}
  })
  const [earHearings, setEarHearings] = useState({
    amount: 2,
    equipment: {}
  })
  const [plant, setPlant] = useState({
    amount: 5,
    equipment: {}
  })
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  const [testModal, setTestModal] = useState(true)
  const [visible, setVisible] = useState(true)
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }
  /**Function that close initial modal that is shown on top of page.
    */
  const toggleContent = () => {
    setTestModal(false)
    setVisible(false)
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    setEquipment(getLocalStorage(OTHER_EQUIPMENT).OTHER_EQUIPMENT)
    setHardWare(getLocalStorage(HARDWARE).HARDWARE)
    setTables(getLocalStorage(TABLES).TABLES)
    setChairs(getLocalStorage(CHAIRS).CHAIRS)
    const resLS = getLocalStorage(OTHER_EQUIPMENT).OTHER_EQUIPMENT
    if (resLS) {
      setEarHearings(resLS[0])
      setMouseKeyboard(resLS[1])
      setIonizer(resLS[2])
      setPlant(resLS[3])
      setPlasticPlates(resLS[4])
      setPlates(resLS[5])

    }
    esgService.getEquipment(inventoryType).then((res) => {
      setIonizer(Ionizer => ({
        ...Ionizer,
        equipment: res.data[2]
      }))
      setMouseKeyboard(mouseKeyboard => ({
        ...mouseKeyboard,
        equipment: res.data[1]
      }))
      setPlates(plates => ({
        ...plates,
        equipment: res.data[5]
      }))
      setPlasticPlates(plasticPlates => ({
        ...plasticPlates,
        equipment: res.data[4]
      }))
      setPlant(plant => ({
        ...plant,
        equipment: res.data[3]
      }))
      setEarHearings(earHearings => ({
        ...earHearings,
        equipment: res.data[0]
      }))
      setOfficeEquipment(res.data)
    })
    progressBarStore.setProgress(100, 77, 0);
  }, [])
  useEffect(() => {
    const equipmentArr = [earHearings, mouseKeyboard, Ionizer, plant, plasticPlates, plates]
    const amounts = equipmentArr.map(arr => arr.amount).reduce((a, b) => a + b, 0)
    if (amounts >= 5) {
      setApproved(true)
    } else {
      setApproved(false)
    }
    setInventorySelected(equipmentArr)
  }, [earHearings, mouseKeyboard, Ionizer, plant, plasticPlates, plates, approved])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])
   /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }
  /**
   * Counter click function
   * @param {Nubmer} value 
   * @param {Nubmer} index 
   * @param {Object} x Hardwere object value
   * @returns Set state value and amout of EarHearings , MouseKeyboard ,
   * Ionizer,Plant and Plates
   */
  const counertClicked = (value, index, x) => {
    if (index === 0) {
      setEarHearings({
        amount: value,
        equipment: x
      })
    } else if (index === 1) {
      setMouseKeyboard({
        amount: value,
        equipment: x
      })
    } else if (index === 2) {
      setIonizer({
        amount: value,
        equipment: x
      })
    } else if (index === 3) {
      setPlant({
        amount: value,
        equipment: x
      })
    } else if (index === 4) {
      setPlasticPlates({
        amount: value,
        equipment: x
      })
    } else if (index === 5) {
      setPlates({
        amount: value,
        equipment: x
      })
    }
  }
  /**
   * Select Language function that takes one parametar value check what language we are 
   * currently using from mobx languageStore.language
   * @param {Object} x other equipment 
   * @returns Name of other equipment in BHS language or EN language for paragraph
   */
  const selectLanguage = (x) => {
    if (languageStore.language === "en") {
      return x.en.name
    } else if (languageStore.language === 'bhs') {
      return x.bhs.name
    } else {
      return x.en.name
    }
  }
  /**
   * Select Language function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} x other equipment
   * @returns Description of other equipment in BHS language or EN language for paragraph
   */
  const equipmentCheck = (equipment) => {
    if (equipment.en.name === "Headphones") {
      return earHearings.amount
    } else if (equipment.en.name === "Mouse and Keyboard") {
      return mouseKeyboard.amount
    } else if (equipment.en.name === "Ionizer") {
      return Ionizer.amount
    } else if (equipment.en.name === "Plant") {
      return plant.amount
    } else if (equipment.en.name === "Plastic dishes") {
      return plasticPlates.amount
    } else if (equipment.en.name === "Ceramic dishes") {
      return plates.amount
    }
  }

  /**
   * Submit Selected Equipment function . Create new function with one parameter args.
   * Store in local storage OTHER_EQUIPMENT with value inventorySelected .Create new var allEquipment
   * call concatAllEquipment fucnticon with parameters hardWare,tables,chairs,inventorySelected.
   * Store in localStorage ALL_EQUIPMENT with value allEquipment and OTHER_EQUIPMENT with value
   * inventorySelected.Redirect user to equipmentpayment route.
   */
  const sumbitSelectedEquipment = () => {
    const concatAllEquipment = (...args) => args.reduce((x, y) => x.concat(y), [])
    setLocalStorage(inventorySelected, OTHER_EQUIPMENT)
    const allEquipment = concatAllEquipment(hardWare, tables, chairs, inventorySelected)
    setLocalStorage(allEquipment, ALL_EQUIPMENT)
    setLocalStorage(inventorySelected, OTHER_EQUIPMENT)
    history.push('equipmentpayment')

  }
  return (
    <div className="office-equipment app">
      {testModal ?
        <IntroOfficeModal
          contentMsgOne={t('equipment:mentor-talk-other.msg-one')}
          visible={visible}
          title="Ovde ce biti mentor"
          onOk={() => { toggleContent(); playSound(likeAudio); }}
        /> :
        <div className="office-equipment-section">
          <h2>{t('equipment:equipment-header-other')}</h2>
          <div className="office-equipment-holder">
            {officeEquipment.length > 0 && officeEquipment.map((x, index) => {
              return (
                <div className="equipment-holder" key={x.name}>
                  {x.en.name === 'Ionizer' || x.en.name === 'Plant' || x.en.name === 'Ceramic dishes' ?
                    <HardwareEquipment
                      imageUrl={x.imageUrl}
                      selected={equipmentCheck(x)}
                      ecoFriendly={5} /> : <HardwareEquipment
                      imageUrl={x.imageUrl}
                      selected={equipmentCheck(x)}
                      ecoFriendly={0} />}

                  <Counter value={equipmentCheck(x)} handleChange={(value) => { counertClicked(value, index, x); playSound(likeAudio); }} />
                  <h2>{selectLanguage(x)}</h2>
                  <p className="green">
                    {x.price}<Coins />
                  </p>
                  <ModalInfoEquipment
                    imageUrl={x.imageUrl}
                    contentHeader={selectLanguage(x)}
                    contentPrice={x.price}
                  />
                </div>
              )
            })}
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              // disabled={approved ? false : true}
              onClick={() => { sumbitSelectedEquipment(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', "soundsStore", 'languageStore', 'progressBarStore')(observer(OtherEquipmentSelect)));
