import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (buildingTypeNew, buildingTypeOld, buildingTypeCoworking)
 * @returns This component is used in chartBuilding
 * to download Excel file based on the data form props
 */
const Download = ({ buildingTypeNew, buildingTypeOld, buildingTypeCoworking }) => {
    const { t } = useTranslation("admin")
    const dataSet1 = [
        {
            name: t("admin:excels-build.buildN"),
            amount: buildingTypeNew ? buildingTypeNew : 0
        },
        {
            name: t("admin:excels-build.buildS"),
            amount: buildingTypeOld ? buildingTypeOld : 0
        },
        {
            name: t("admin:excels-build.buildC"),
            amount: buildingTypeCoworking ? buildingTypeCoworking : 0
        },
    ];
    return (
        <ExcelFile filename='buildings' element={<button className="buttonData">{t("admin:excels-build.download")}</button>}>
            <ExcelSheet data={dataSet1} name={t("admin:excels-build.nameA")}>
                <ExcelColumn label={t("admin:excels-build.lblN")} value="name" />
                <ExcelColumn label={t("admin:excels-build.lblU")} value="amount" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default Download;