import React, { useState, useEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import EsgService from '../../../../../services/EsgServices/esg.service';
import './chartEmployees.component.style.less'
import { useTranslation } from "react-i18next"
import Download from './excel'

/**
 * ChartEmployees component
 * @class
 * @returns This component is used in gameStats component to display 
 * all players data represeted in charts
 */
const ChartEmployees = () => {
    const { t } = useTranslation("admin")

    /** useRef can be used keep track of variables without causing re-render of the component. 
    * And give a solution on how to enforce re-render without affecting the performance of a component.
    */
    const isReady = React.useRef(false);

    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()
    const [males, setMales] = useState(null)
    const [females, setFemales] = useState(null)
    const [others, setOthers] = useState(null)

    useEffect(() => {
        fetch();
        return () => {
            isReady.current = true;
        };
    }, [])

    /**
     *Function that is used in component did mount to get all necessary data that we need to display
    */
    const fetch = () => {
        esgService.getGeneralStats().then((res) => {
            if (!isReady.current) {
                const allStatsGenders = res.data.genders
                setMales(allStatsGenders.males)
                setFemales(allStatsGenders.females)
                setOthers(allStatsGenders.others)
            }
        });
    }

    return (
        <div className='chart-comp-empl'>
            <h2>{t("admin:charts.employees")}</h2>

            <Chart
                width={600}
                height={300}
                chartType="ColumnChart"
                loader={<div>{t("admin:charts.loading")}</div>}
                data={[
                    [t("admin:charts.gender"), t("admin:charts.males"), t("admin:charts.females"), t("admin:charts.others")],
                    ["", males, 0, 0],
                    ["", 0, females, 0],
                    ["", 0, 0, others],
                ]}
                options={{
                    chartArea: { width: '50%' },
                    hAxis: {
                        minValue: 0,
                    },
                    colors: ['#3498db', '#e74c3c', '#f1c40f']
                }}

            />

            <div>
                <Download
                    males={males}
                    females={females}
                    others={others}
                />
            </div>
        </div>
    );
};

export default ChartEmployees;
