import React, { useState, useEffect } from "react";
import EsgService from "../../../../../services/EsgServices/esg.service";
import "./usersInfo.component.style.less";
import { useTranslation } from "react-i18next";
import Download from "./excel";

/**
 * ChartAccelerator component
 * @class
 * @returns This component is used in gameStats component to display
 * all players data represeted in charts
 */
const UsersInfo = () => {
  const { t } = useTranslation("admin");
  const isReady = React.useRef(false);

  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService();
  const [usersData, setUsersData] = useState(null);
  const [surveyQuestions, setSurveyQuestions] = useState(null);

  useEffect(() => {
    fetch();
    return () => {
      isReady.current = true;
    };
  }, []);

  /**
   *Function that is used in component did mount to get all necessary data that we need to display
   */
  const fetch = () => {
    esgService.getGeneralStats().then((res) => {
      if (!isReady.current) {
        const allStatsUsers = res.data.player_progress;
        setUsersData(allStatsUsers);
      }
    });
    esgService.getSurveys().then((res) => {
      if (!isReady.current) {
        const surveyQuestions = res.data;
        setSurveyQuestions(surveyQuestions);
      }
    });
  };

  return (
    <div className="chart-comp-acc">
      <h2>{t("admin:charts.users-info")}</h2>
      <div>
        <Download usersData={usersData} surveyQuestions={surveyQuestions} />
      </div>
    </div>
  );
};

export default UsersInfo;
