import React, { useEffect, useState } from "react";
import "./chooseProfession.component.style.less";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Button from "../../../components/Button/button.component";
import {
  setLocalStorage,
  getLocalStorage,
  EMPLOYEES_PROFESSION,
  COMPANY_INFO,
  USER_PROFILE_ESG,
} from "../../../localStorageMobxStore/localStorageUserProfile";
import { Form, Input } from "antd";
import IntroOfficeModal from "../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component";
import ClickSound from "../../../assets/audio/button22.wav";
import { useTranslation } from "react-i18next";
// import Animation7 from '../../../assets/video/animation7.mp4'
// import VideoLoad from '../../../components/Video/video.component'

/**
 * Choose Proffesion component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Modal with text and button continue . After modal is closed we have title and four input
 * fields where three are disable and only last is enabled in case user selected(It ,Tourism Metallurgical)
 * industy in case user selected other industry all four input fields are enabled.
 * And two buttons : Contiue button that redirect user to chooseemployees page if all input fields are populated.
 * Back button for going back to previous page.
 */
const ChooseProffesion = ({
  history,
  userStore,
  soundsStore,
  progressBarStore,
  languageStore,
}) => {
  const { t } = useTranslation(["common", "proffesions"]);
  const [form] = Form.useForm();
  const [userEsg, setUserEsg] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [employesProffesion, setEmployesProffesion] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [required, setRequired] = useState(true);
  // const [videoEnd, setVideoEnd] = useState(false)

  const [firstProffesion, setFirstProffesion] = useState("");
  const [secondProffesion, setSecondProffesion] = useState("");
  const [thirdProffesion, setThirdProffesion] = useState("");
  const [fourthProffesion, setFourthProffesion] = useState("");
  const [testModal, setTestModal] = useState(true);
  const [visible, setVisible] = useState(true);
  const [threeMsgVisible, setThreeMsgVisible] = useState(false);
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which
   * can be either attached to a document for the user to interact with and/or listen to,
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);

  const metal =
    languageStore.language === "bhs"
      ? "Stručnjak/inja za metalnu industriju"
      : "Metal industry expert";
  const turizam =
    languageStore.language === "bhs"
      ? "Stručnjak/inja za turizam"
      : "Tourism expert";
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = (audioFile) => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  };
  /**Function that close initial modal that is shown on top of page.
   */
  const toggleContent = () => {
    setTestModal(false);
    setVisible(false);
  };

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO);
    setEmployesProffesion(
      getLocalStorage(EMPLOYEES_PROFESSION).EMPLOYEES_PROFESSION
    );
    progressBarStore.setProgress(100, 20, 0);
  }, []);

  useEffect(() => {
    if (userEsg || companyInfo || employesProffesion) {
      setUserStore(userEsg, companyInfo, employesProffesion);
    }
    if (employesProffesion) {
      setFirstProffesion(employesProffesion.professionOne);
      setSecondProffesion(employesProffesion.professionTwo);
      setThirdProffesion(employesProffesion.professionThree);
      setFourthProffesion(employesProffesion.professionFour);
    }
    if (companyInfo && companyInfo.industry === "it") {
      setDisabled(true);
      setRequired(false);
      form.setFieldsValue({
        professionOne: t("proffesions:proffesion-list.proffesion-software"),
        professionTwo: t("proffesions:proffesion-list.proffesion-bussines"),
        professionThree: t("proffesions:proffesion-list.proffesion-marketing"),
        professionFour: fourthProffesion,
      });
    } else if (companyInfo && companyInfo.industry === "metal") {
      setDisabled(true);
      setRequired(false);
      form.setFieldsValue({
        professionOne: metal,
        professionTwo: t("proffesions:proffesion-list.proffesion-bussines"),
        professionThree: t("proffesions:proffesion-list.proffesion-marketing"),
        professionFour: fourthProffesion,
      });
    } else if (companyInfo && companyInfo.industry === "turizam") {
      setDisabled(true);
      setRequired(false);
      form.setFieldsValue({
        professionOne: turizam,
        professionTwo: t("proffesions:proffesion-list.proffesion-bussines"),
        professionThree: t("proffesions:proffesion-list.proffesion-marketing"),
        professionFour: fourthProffesion,
      });
    } else if (companyInfo && companyInfo.industry === "ostalo") {
      setDisabled(false);
      setRequired(true);
      form.setFieldsValue({
        professionOne: firstProffesion,
        professionTwo: secondProffesion,
        professionThree: thirdProffesion,
        professionFour: fourthProffesion,
      });
    } else {
    }
  }, [
    languageStore.language,
    firstProffesion,
    secondProffesion,
    thirdProffesion,
    fourthProffesion,
    employesProffesion,
    companyInfo,
  ]);

  /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, employesProffesion) => {
    userStore.updateUserInfoEsg(userEsg);
    userStore.updateCompanyInfo(companyInfo);
    userStore.updateEmployeesProfession(employesProffesion);
  };
  /**
   * On Finish function called on Contiune button after form validation is passed .
   * Values of input from form are passed to loacal storage for EMPLOYEES_PROFESSION.
   * And user is redirected to chooseemployees rout .
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setLocalStorage(values, EMPLOYEES_PROFESSION);
        history.push("chooseemployees");
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
  };

  return (
    <div className="select-proffesion app">
      <div>
        {testModal ? (
          <IntroOfficeModal
            contentMsgOne={t("proffesions:mentor-talk.mentor-msg-one")}
            contentMsgTwo={t("proffesions:mentor-talk.mentor-msg-two")}
            visible={visible}
            onOk={() => {
              toggleContent();
              playSound(likeAudio);
            }}
          />
        ) : (
          <div className="select-proffesion-section">
            <h2>{t("proffesions:proffesion-headline")}</h2>
            <div className="button-proffesion-holder fadeInBottom-proffesion">
              <div className="proffesion-text">
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  initialValues={{
                    remember: true,
                  }}
                >
                  <div className="colored">
                    <Form.Item
                      label={t("proffesions:proffesion-form-one")}
                      name="professionOne"
                      rules={[
                        {
                          required: required,
                          message: t("proffesions:proffesion-form-one-enter"),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("proffesions:proffesion-form-enter")}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("proffesions:proffesion-form-two")}
                      name="professionTwo"
                      rules={[
                        {
                          required: required,
                          message: t("proffesions:proffesion-form-one-enter"),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("proffesions:proffesion-form-enter")}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("proffesions:proffesion-form-three")}
                      name="professionThree"
                      rules={[
                        {
                          required: required,
                          message: t("proffesions:proffesion-form-one-enter"),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("proffesions:proffesion-form-enter")}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("proffesions:proffesion-form-four")}
                      name="professionFour"
                      rules={[
                        {
                          required: true,
                          message: t("proffesions:proffesion-form-one-enter"),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("proffesions:proffesion-form-enter")}
                        disabled={false}
                      />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <div className="button-landing">
              <Button
                additionalStyle="signIn"
                text={t("common:landing-back")}
                customBtn={true}
                onClick={() => {
                  history.goBack();
                }}
              />
              <Button
                additionalStyle="buttonMakeAcc"
                text={t("common:landing-continue")}
                customBtn={true}
                disabled={false}
                onClick={() => {
                  onFinish();
                  playSound(likeAudio);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(
  inject(
    "userStore",
    "soundsStore",
    "progressBarStore",
    "languageStore"
  )(observer(ChooseProffesion))
);
