import React, { useState } from 'react';
import './salaryGiveup.component.style.less';
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import EsgService from '../../../services/EsgServices/esg.service';
import IntroOfficeModal from '../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component'
import { getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET } from '../../../localStorageMobxStore/localStorageUserProfile';
import { useEffect } from 'react';
import ClickSound from '../../../assets/audio/button22.wav'

/**
 * Salary Give Up component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with text and Contionue button covering compoented. After modal is cloed.
 * Title and three buttons are displeyed.Contiue button is also included that redirect
 * user to officeselect .
 */
const SalaryGiveup = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "salary"])
  const [keepSalary, setKeepSalary] = useState(null);
  const [halfSalary, setHalfSalary] = useState(false);
  const [testModal, setTestModal] = useState(true)
  const [visible, setVisible] = useState(true)
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  const [activeIndex, setActiveIndex] = useState(null);
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    progressBarStore.setProgress(100, 35, 0);
  }, [])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])
  /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget, allEmployes) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
    userStore.updateHiredEmployees(allEmployes)
  }
  /**Function that close initial modal that is shown on top of page.
    */
  const toggleContent = () => {
    setTestModal(false)
    setVisible(false)
  }

  /**
   * Function salary choice that takes two parameters keepSalary and halfSalary
   * @param {Boolean} keepSalary 
   * @param {Boolean} halfSalary 
   * @returns update state of keepSalary (true/false) and halfSalary(true/false)
   */
  const salaryChoose = (keepSalary, halfSalary) => {
    setKeepSalary(keepSalary);
    setHalfSalary(halfSalary);
  }

  const salaryButtons = [
    {
      name: t('salary:give-up'),
      method: () => salaryChoose(false, false),
    },
    {
      name: t('salary:give-up-50'),
      method: () => salaryChoose(true, true),
    },
    {
      name: t('salary:keep'),
      method: () => salaryChoose(true, false),
    },
  ];

  /**
   * Submit function without parameters.Called on click Continue button. First object
   * data is created with parameter companyId,keep,half.After that esgService is called 
   * with method founderSalary and data is passed as parameter.At the end user is redirect to 
   * officeselect route.
   */
  const submit = () => {
    const companyId = companyInfo.id
    const data = {
      companyId,
      keep: keepSalary,
      half: halfSalary
    }
    esgService.founderSalary(data).then((res) => {
      history.push('officeselect')
    })
  }


  return (
    <div className="salary-mode app">
      {testModal ?
        <IntroOfficeModal
          contentMsgOne={t('salary:mentor-talk.msg-one')}
          visible={visible}
          onOk={() => { toggleContent(); playSound(likeAudio); }}
        /> :
        <div className="salary-mode-section">
          <div className="button-salary-holder fadeInBottom-award">
            <div dangerouslySetInnerHTML={{ __html: t('salary:salary-headline') }} />

            {salaryButtons.map((button, index) => {
              const active = index === activeIndex ? 'button-selected' : 'signUp';
              return (
                <Button
                  additionalStyle={`${active} margin-b`}
                  text={button.name}
                  customBtn={true}
                  onClick={() => { button.method(); setActiveIndex(index); playSound(likeAudio); }}
                />
              )

            })}

          </div>
          <div className="button-landing">
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              onClick={() => { submit(); playSound(likeAudio); }}
              customBtn={true}
              disabled={activeIndex !== null ? false : true}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(SalaryGiveup)));
