import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import './onboardingSequence.component.style.less';
import Button from '../../../components/Button/button.component';
import { Avatar, avatarArr, Mentor, mentorArr, TimerPrev, HintPrev, HelpPrev } from '../../../assets/images';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import Animation8 from '../../../assets/video/animation8.mp4'
import VideoLoad from '../../../components/Video/video.component'
import ClickSound from '../../../assets/audio/button22.wav'
import { useTranslation } from 'react-i18next'


/**
 * On Boarding Sequence component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with video and Skip button covering component. After modal is closed.
 * Title , picture of mentor and text , images and explenation of hint and mentor and text describing game.
 * Continue button that redirect user to office component.Back button for going back to previous page. 
 */
const OnboardingSequence = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "onboardingsequence"])
  const [userEsg, setUserEsg] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userImage, setUserImage] = useState(null)
  const [mentorImage, setMentorImage] = useState(null)
  const [mentorName, setMentorName] = useState(null)
  const [videoEnd, setVideoEnd] = useState(false)
  const refOne = React.createRef();
  const refTwo = React.createRef();
  const refThree = React.createRef();
  const refFourth = React.createRef();
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }
  /**
  * Function Handle Scroll One
  * @returns When message is shown scrooling page in to  center of user viewport on screen.
  */
  const handleScrollToOne = () =>
    refOne.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  /**
  * Function Handle Scroll Two
  * @returns When message is shown scrooling page in to  center of user viewport on screen.
  */
  const handleScrollToTwo = () =>
    refTwo.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  /**
  * Function Handle Scroll Three
  * @returns When message is shown scrooling page in to  center of user viewport on screen.
  */
  const handleScrollToThree = () =>
    refThree.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
    /**
  * Function Handle Scroll Fourth
  * @returns When message is shown scrooling page in to  center of user viewport on screen.
  */
  const handleScrollToFourth = () =>
    refFourth.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    progressBarStore.setProgress(100, 100, 1);
  }, []);

  useEffect(() => {

    if (userEsg) {
      setUserStore(userEsg)
      setUserName(userEsg.userName)
      setUserImage(userEsg.avatarUrl)
      setMentorImage(userEsg.mentor.avatarUrl)
      setMentorName(userEsg.mentor.name)
    }
  }, [userEsg])
  /**
   * Function where we call updateUserInfoEsg from userStore mobix user store and update there update 
   * userInfoEsg.
   * @param {Object} userEsg  
   * @returns userEsg
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }
  /**Array with one Object element of mentor image chose by user in game */
  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
  const userProfileImage = utilityFilter(avatarArr, userImage)
  /**
   * Redirect function that is called on Continue button. 
   * Redirect user to office route.
   */
  const redirect = () => {
    // history.push('game')
    history.push('office')
  }

  return (
    <div className="sequence-board-info app">
      {!videoEnd ? <VideoLoad url={Animation8} setVideoEnd={setVideoEnd} /> :
        <div className="sequence-board-info-section">
          <h2 className="">{t('onboardingsequence:before')}</h2>
          <div className="sequence-board-info-holder fadeInBottom-sequence">
            <div className='sequence-board-info-text'>
              {/* <h3>{mentorName}</h3> */}
              <div className="text-info">
                <div className="sequence-board-info-talk-mentor">
                  {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                  <p className="hi-msg fadeFirst">{t('onboardingsequence:hi')} {userName}!</p>
                  <p className="expl-msg fadeSecond">{t('onboardingsequence:mentor-msg')}</p>
                </div>
              </div>
            </div>
            <div className="sequence-board-info-text2">
              <div className="info-timer fadeFourth" ref={refTwo} onAnimationEnd={() => handleScrollToTwo()}>
                <div className="timer-image">
                  <HintPrev />
                </div>
                <div className="timer-text">
                  <h3>{t('onboardingsequence:hint.text')}</h3>
                  <p>{t('onboardingsequence:hint.description')}</p>
                </div>
              </div>
              <div className="info-timer fadeFifth" ref={refThree} onAnimationStart={() => handleScrollToThree()}>
                <div className="timer-image">
                  <HelpPrev />
                </div>
                <div className="timer-text" >
                  <h3>{t('onboardingsequence:mentor.text')}</h3>
                  <p>{t('onboardingsequence:mentor.description')}</p>
                </div>
              </div>
              <p className="fadeSixs" ref={refFourth} onAnimationEnd={() => handleScrollToFourth()}>{t('onboardingsequence:text-down1')}</p>
              <br />
              <p className="fadeSixs" ref={refFourth} onAnimationEnd={() => handleScrollToFourth()}>{t('onboardingsequence:text-down2')}</p>
              <br />
              <p className="fadeSixs" ref={refFourth} onAnimationEnd={() => handleScrollToFourth()}>{t('onboardingsequence:text-down3')}</p>
            </div>
          </div>
          <div className="button-landing fadeInlast">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => {
                history.goBack();
              }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              onClick={() => { redirect(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(OnboardingSequence)));
