import React from 'react'
import Modal from '../modal.component'
import './EngGameModal.style.less'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import EndGameSurvey from '../../../containers/UserSelectSection/Survey/endSurvey.component '

const EngGameSurveyModal = ({surveyCompleted,setSurveyCompleted}) => {    
    return (
        <Modal
            modalStyleTest='modal-content-survey'
            closable={false}
            visible={surveyCompleted}
        >   
            <EndGameSurvey setSurveyCompleted={setSurveyCompleted}/>
        </Modal>
    )
}

export default inject('languageStore')(observer(EngGameSurveyModal))