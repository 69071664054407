import React, { useState } from 'react'
import Modal from '../modal.component'
import { Coins } from '../../../assets/images/index';
import './modalInfoEmployee.component.style.less'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { EmployeAvatar, Vector } from '../../../assets/images/index';
import { useTranslation } from 'react-i18next'
import { Rate } from 'antd';


const ModalInfoEquipment = ({ name, education, experience, skills, competencies, languages, salary, avatarUrl, role, languageStore }) => {
    const { t } = useTranslation(["common", "employes"])
    const [visible, setVisible] = useState(false);
    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }

    const selectLanguage = (value) => {
        if (languageStore.language === 'bhs') {
            return value.bhs
        } else {
            return value.en
        }
    }

    return (
        <Modal
            modalStyleTest='modal-content-employee'
            closable={false}
            okBtnText={t('common:close-button')}
            footer={true}
            btnText={t('common:more-info')}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
        >
            <div className="content-container">
                <Vector className="back-to-back" />
                <EmployeAvatar avatarUrl={avatarUrl} />
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-name')}</div>
                <div className="dynamic">{name}</div>
            </div>
            {/* <div className="content-container">
                <div className="static">{t('employes:employes-role')}</div>
                <div className="dynamic">{role}</div>
            </div> */}
            <div className="content-container">
                <div className="static">{t('employes:employes-education')}</div>
                <div className="dynamic" dangerouslySetInnerHTML={{ __html: selectLanguage(education) }}></div>
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-experience')}</div>
                <div className="dynamic" dangerouslySetInnerHTML={{ __html: selectLanguage(experience) }}></div>
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-skills')}</div>
                <div className="dynamic" dangerouslySetInnerHTML={{ __html: selectLanguage(skills) }}></div>
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-competencies')}</div>
                <div className="dynamic" dangerouslySetInnerHTML={{ __html: selectLanguage(competencies) }}></div>
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-languages')}</div>
                <div className="dynamic" dangerouslySetInnerHTML={{ __html: selectLanguage(languages) }}></div>
            </div>
            <div className="content-container">
                <div className="static">{t('employes:employes-salary')}</div>
                <div className="dynamic coin-image">{salary}<Coins /></div>
            </div>
        </Modal>
    )
}

export default inject('languageStore')(observer(ModalInfoEquipment))