import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import EsgSequenceService from '../../../services/EsgServices/sequence.service'
import { modeGame } from '../../../helperFunctions/helperFunctions'
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, BUDGET, SEQUENCE_PROGGRES, SEQUENCE_INDEX, COMPANY_INFO, INCREMENT_CURRENT_QUESTION_INDEX, MONTHLYEXPENSES, FETCHLOADER } from '../../../localStorageMobxStore/localStorageUserProfile'
import Quiz from './Quiz/quiz.component'
import SequenceBmcComponent from './SequenceBmc/sequenceBmc.component';
import BillPayment from '../../UserSelectSection/BillsPayment/billsPayment.component';
import GameFinsh from '../../UserSelectSection/GameFinish/gameFinish.component';
import { changeBckg } from '../../../helperFunctions/helperFunctions';
import { Spin } from 'antd';

// pwa
import { openDB } from "idb";

let dbPromise;
let swRegistered = false;
/**
 * Sequnce component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , userProfile , progressBarStore)
 * @returns Loader (spiner component) and Sequnce progres component
 */
const Sequence = ({ history, userStore, sequenceStore, progressBarStore }) => {
    const [sequnceProgress, setSequnceProgres] = useState('quiz')
    const [userEsg, setUserEsg] = useState(null)
    const [budget, setBudget] = useState(null)
    const [companyInfo, setCompanyInfo] = useState(null)
    const [online, setOnline] = useState(true)
    const [sequenceIndex, setSequenceIndex] = useState(0)
    const [sequence, setSequence] = useState(null)
    const [sequences, setSequences] = useState([])
    const [monthlyExpenses, setMonthlyExpenses] = useState(null)
    const [updateProgress, setUpdateProgress] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null)
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
     * service where are esg services sequnce methods for calling api's are stored.
     */
    const esgSequenceService = new EsgSequenceService()
    const [fetchLoader, setFetchLoader] = useState(false);

    /**
     * Get Mountly Esprenses function
     * @param {Object} res State of in-game company Info
     * @param {Object} resBudget State of in-game budget
     * @returns Set local storage MONTHLYEXPENSES with monthlyExpensesRes value and 
     * set state of monthlyExpenses with monthlyExpensesRes value
     */
    const getMountlyEspenses = async (res,resBudget) => {
        const montlyData = {
            companyId: res.id,
            budgetId: resBudget.budget._id
        }

        const monthlyExpensesRes = await esgSequenceService.getMountlyExpenses(montlyData)

        // pwa localstorage monthlyExpenses
        setLocalStorage(monthlyExpensesRes.data, MONTHLYEXPENSES);
        setMonthlyExpenses(monthlyExpensesRes.data);
    }
    /**
     * Fetch Sequences is funcio where sequnces exist in player progres if not create
     * progres and fetch first two sequnces if pleyer have progres wetch two next in line 
     * sequnces.Also check state of sequnce proggres of player if progres exist and redirct him
     * to quiz , bmc or monlty event depending on hist state of progress.
     */
    const fetchSequences = async () => {
        if (navigator.onLine) {

            // If we were offline and if we have changed SEQUENCE INDEX to 1, we need to change it again to 0 after coming online
            setLocalStorage(0, SEQUENCE_INDEX);

            // try {
            const resBudget = getLocalStorage(BUDGET).BUDGET
            const res = getLocalStorage(COMPANY_INFO).COMPANY_INFO
            const gameMode = modeGame(resBudget);
            let ids;

            // Type of choosen industry
            const industryType = res.industry;
            const industry = () => {
                switch(industryType) {
                    case 'it':
                        return 'it'
                    case 'turizam':
                        return 'tourism'
                    case 'metal':
                        return 'metal'
                    default:
                        return 'custom'
                }
            }

            //Player progres body data
            const progressData = {
                companyId: res.id,
                mode: gameMode
            }
            // //Player progres return
            let playerProgress = null
            let playingSequnce = null
            try {
                playerProgress = await esgSequenceService.getProgress(progressData)
                playingSequnce = playerProgress.data[playerProgress.data.length - 1]
                //Data for fatching ids of sequnces
                const data = {
                    sequenceNrs: [playingSequnce.sequenceNr, playingSequnce.sequenceNr + 1]
                }
                //Array of two ids of sequnces
                ids = await esgSequenceService.getIdsOfSequnces(data)
                let firstSequnceId;
                let secondSequnceId;
                let firstSequence;
                let secondSequence;

                if (ids.data[1]) {
                    firstSequnceId = ids.data[0]._id
                    secondSequnceId = ids.data[1]._id
                    const firstSequencePromise = esgSequenceService.getSequnce(firstSequnceId, industry())
                    const secondSequencePromise = esgSequenceService.getSequnce(secondSequnceId, industry())
                    firstSequence = (await firstSequencePromise).data;
                    secondSequence = (await secondSequencePromise).data;
                    setSequence(firstSequence)
                    setSequences([firstSequence, secondSequence])
                    changeBckg(firstSequence.number)

                    if ('indexedDB' in window && swRegistered) {
                        // create firstSequence in indexDB
                        dbPromise
                            .then((db) => {
                                const tx = db.transaction('sequence', "readwrite");
                                const store = tx.objectStore('sequence');
                                store.put(firstSequence, 'firstSequence');
                                return tx.done;
                            });

                        // create secondSequence in indexDB
                        if (secondSequence) {
                            dbPromise
                                .then((db) => {
                                    const tx = db.transaction('sequence', "readwrite");
                                    const store = tx.objectStore('sequence');
                                    store.put(secondSequence, 'secondSequence');
                                    return tx.done;
                                })
                                .then(() => {
                                    dbPromise
                                        .then((db) => {
                                            const tx = db.transaction('progres', "readwrite");
                                            const store = tx.objectStore('progres');
                                            store.put(secondSequence, 'progres');
                                            return tx.done;
                                        })
                                })
                        }
                    };
                } else {
                    firstSequnceId = ids.data[0]._id
                    const firstSequencePromise = esgSequenceService.getSequnce(firstSequnceId, industry())
                    firstSequence = (await firstSequencePromise).data;
                    setSequence(firstSequence)
                    setSequences([firstSequence])
                    changeBckg(firstSequence.number)

                    if ('indexedDB' in window && swRegistered) {
                        // create firstSequence in indexDB
                        dbPromise
                            .then((db) => {
                                const tx = db.transaction('sequence', "readwrite");
                                const store = tx.objectStore('sequence');
                                store.put(firstSequence, 'firstSequence');
                                return tx.done;
                            });
                    };
                }
                let sequnceState = playerProgress.data[playerProgress.data.length - 1]

                const qustionLength = firstSequence.industryQuestions ? (firstSequence.quiz.questions.length + firstSequence.industryQuestions.length) : firstSequence.quiz.questions.length;
                if (sequnceState.questions.length === qustionLength && !sequnceState.sequenceCompleted) {
                    if (!sequnceState.bmcDone && firstSequence.summary) {
                        setSequnceProgres('bmc')
                    } else if (!sequnceState.monthlyEventDone) {
                        setSequnceProgres('monltyEvent')
                    }
                } else {
                    setSequnceProgres('quiz')
                }

                let progressQuestionIndex = sequnceState.questions.length;

                if (progressQuestionIndex === firstSequence.quiz.questions.length) {
                    setCurrentQuestionIndex(0)
                } else {
                    setCurrentQuestionIndex(progressQuestionIndex)
                    setLocalStorage(progressQuestionIndex, INCREMENT_CURRENT_QUESTION_INDEX)
                }
                if (firstSequence.number === 10 && sequnceState.sequenceCompleted) {
                    setSequnceProgres('gameFinish')
                }
                getMountlyEspenses(res, resBudget)
            } catch (e) {
                console.log(e, 'EROOR')
            }
            if (!playerProgress) {
                //Data for fatching ids of sequnces
                const data = {
                    sequenceNrs: [1, 2]
                }
                //Array of two ids of sequnces
                ids = await esgSequenceService.getIdsOfSequnces(data)
                //Body data for creating progress
                const createProgresData = {
                    companyId: res.id,
                    sequenceId: ids.data[0]._id,
                    gameMode
                }
                //Return progress 
                const createProgres = await esgSequenceService.playerProgresCreate(createProgresData)
                let firstSequnceId;
                let secondSequnceId;
                let firstSequence;
                let secondSequence;

                if (ids.data[1]) {
                    firstSequnceId = ids.data[0]._id
                    secondSequnceId = ids.data[1]._id
                    const firstSequencePromise = esgSequenceService.getSequnce(firstSequnceId, industry())
                    const secondSequencePromise = esgSequenceService.getSequnce(secondSequnceId, industry())
                    firstSequence = (await firstSequencePromise).data;
                    secondSequence = (await secondSequencePromise).data;
                    setSequence(firstSequence)
                    setSequences([firstSequence, secondSequence])
                    changeBckg(firstSequence.number)

                    if ('indexedDB' in window && swRegistered) {
                        // create firstSequence in indexDB
                        dbPromise
                            .then((db) => {
                                const tx = db.transaction('sequence', "readwrite");
                                const store = tx.objectStore('sequence');
                                store.put(firstSequence, 'firstSequence');
                                return tx.done;
                            });

                        // create secondSequence in indexDB
                        if (secondSequence) {
                            dbPromise
                                .then((db) => {
                                    const tx = db.transaction('sequence', "readwrite");
                                    const store = tx.objectStore('sequence');
                                    store.put(secondSequence, 'secondSequence');
                                    return tx.done;
                                })
                                .then(() => {
                                    dbPromise
                                        .then((db) => {
                                            const tx = db.transaction('progres', "readwrite");
                                            const store = tx.objectStore('progres');
                                            store.put(secondSequence, 'progres');
                                            return tx.done;
                                        })
                                })
                        }
                    };
                }
                setCurrentQuestionIndex(0)
                getMountlyEspenses(res, resBudget)
            }

            // let firstSequnceId;
            // let secondSequnceId;
            // let firstSequence;
            // let secondSequence;

            // if (ids.data[1]) {
            //     firstSequnceId = ids.data[0]._id
            //     secondSequnceId = ids.data[1]._id
            //     const firstSequencePromise = esgSequenceService.getSequnce(firstSequnceId)
            //     const secondSequencePromise = esgSequenceService.getSequnce(secondSequnceId)
            //     firstSequence = (await firstSequencePromise).data;
            //     secondSequence = (await secondSequencePromise).data;
            //     setSequence(firstSequence)
            //     setSequences([firstSequence, secondSequence])

            //     if ('indexedDB' in window && swRegistered) {
            //         // create firstSequence in indexDB
            //         dbPromise
            //             .then((db) => {
            //                 const tx = db.transaction('sequence', "readwrite");
            //                 const store = tx.objectStore('sequence');
            //                 store.put(firstSequence, 'firstSequence');
            //                 return tx.done;
            //             });

            //         // create secondSequence in indexDB
            //         if (secondSequence) {
            //             dbPromise
            //                 .then((db) => {
            //                     const tx = db.transaction('sequence', "readwrite");
            //                     const store = tx.objectStore('sequence');
            //                     store.put(secondSequence, 'secondSequence');
            //                     return tx.done;
            //                 });
            //         }
            //     };
            // } else {
            //     firstSequnceId = ids.data[0]._id
            //     const firstSequencePromise = esgSequenceService.getSequnce(firstSequnceId)
            //     firstSequence = (await firstSequencePromise).data;
            //     setSequence(firstSequence)
            //     setSequences([firstSequence])

            //     if ('indexedDB' in window && swRegistered) {
            //         // create firstSequence in indexDB
            //         dbPromise
            //             .then((db) => {
            //                 const tx = db.transaction('sequence', "readwrite");
            //                 const store = tx.objectStore('sequence');
            //                 store.put(firstSequence, 'firstSequence');
            //                 return tx.done;
            //             });
            //     };
            // };

            // const montlyData = {
            //     companyId: res.id,
            //     budgetId: resBudget.budget._id
            // }

            // const monthlyExpensesRes = await esgSequenceService.getMountlyExpenses(montlyData)

            // // pwa localstorage monthlyExpenses
            // setLocalStorage(monthlyExpensesRes.data, MONTHLYEXPENSES);
            // setMonthlyExpenses(monthlyExpensesRes.data);


            //--PWA INDEX DB U SLUCAJU DA TEODORU ZATREBA A MONTLY EXPENSES
            // if ('indexedDB' in window && swRegistered) {
            //     dbPromise
            //     .then(db => {
            //         const tx = db.transaction('monthlyExpenses', "readwrite");
            //         const store = tx.objectStore('monthlyExpenses');
            //         store.put(monthlyExpensesRes.data, 'monthlyExpenses');
            //         return tx.done;
            //     });
            // };

        } else /* if we are offline */ {
            let sequnceOne;
            let sequnceTwo;

            if ("indexedDB" in window) {
                dbPromise = openDB("esgDB");
            };
            // get data from firstSequence and set setSequence
            dbPromise.then((db) => {
                const tx = db.transaction('sequence', "readonly");
                const store = tx.objectStore('sequence');
                return store.get('firstSequence');
            })
                .then(data => {
                    sequnceOne = data;
                    setSequence(sequnceOne);
                    setSequences([sequnceOne])

                    changeBckg(sequnceOne.number);
                })

            // get data from secondSequence and set setSequences
            dbPromise.then((db) => {
                const tx = db.transaction('sequence', "readonly");
                const store = tx.objectStore('sequence');
                return store.get('secondSequence');
            })
                .then(data => {
                    if (data) {
                        sequnceTwo = data;
                        setSequences([sequnceOne, sequnceTwo]);
                        const resSeuenceIndex = getLocalStorage(SEQUENCE_INDEX).SEQUENCE_INDEX
                        if (resSeuenceIndex) {
                            setSequenceIndex(resSeuenceIndex)
                        }
                    }
                })
                .then(() => {
                    let res = getLocalStorage(INCREMENT_CURRENT_QUESTION_INDEX).INCREMENT_CURRENT_QUESTION_INDEX
                    if (res) {
                        setCurrentQuestionIndex(res)

                    } else {
                        setCurrentQuestionIndex(0)

                    }
                })

            setMonthlyExpenses(getLocalStorage(MONTHLYEXPENSES).MONTHLYEXPENSES);

        }
    };

    useEffect(() => {

        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
        setBudget(getLocalStorage(BUDGET).BUDGET)
        setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
        fetchSequences();

        navigator.serviceWorker.getRegistrations()
            .then(registrations => {
                if (registrations.length > 0) {
                    swRegistered = true;
                }
            })
            .then(() => {
                // Define indexDB
                if ("indexedDB" in window && swRegistered) {
                    dbPromise = openDB("esgDB");
                };
            });

        //Update from local storage for user game profile and budget

        //Sequnce progres update from local storage in case of refresh
        const result = getLocalStorage(SEQUENCE_PROGGRES).SEQUENCE_PROGGRES
        if (result) {
            setSequnceProgres(result)
        };

        if ('indexedDB' in window) {
            const checkFetchLoader = () => {
                // pwa | check fetchLoader value after comming online from offline
                if ('indexedDB' in window && swRegistered) {
                    dbPromise
                        .then(db => {
                            const tx = db.transaction('fetchLoader', 'readonly');
                            const store = tx.objectStore('fetchLoader');
                            return store.get('fetchLoader');
                        })
                        .then(data => {
                            if (data === true) {
                                setFetchLoader(true)
                            } else {
                                setFetchLoader(false)
                            }
                        })
                };
            };

            const fetchLoaderInterval = setInterval(checkFetchLoader, 700);

            return () => {
                clearInterval(fetchLoaderInterval)
            }
        }
    }, [])

    /*UseEffect chek if sequenceIndex is changed and 
    set new sequnce value for playing by sequnce index*/
    useEffect(() => {
        if (sequences.length > 0) {
            setSequence(sequences[sequenceIndex])
        }
    }, [sequenceIndex])

    useEffect(() => {
        if (userEsg || companyInfo || budget) {
            setUserStore(userEsg, companyInfo, budget)
        }
    }, [userEsg, companyInfo, budget])

    useEffect(() => {
        if (sequence && currentQuestionIndex !== 'null') {
            const sequenceProgressPart = (sequence.number - 1) * 10; // start point of progress depends on sequence number
            const oneQuestionPercentage = 10 / (sequence.quiz.questions.length + 2);  // value of one question in percentage
            const questionProgressPart = oneQuestionPercentage * currentQuestionIndex;
            const questionProgress = sequenceProgressPart + questionProgressPart;
            progressBarStore.setProgress(100, 100, questionProgress)
        }
    }, [sequence, currentQuestionIndex])
    /**
    * Function that three parametars userEsg , singlePlayer ,companyInfo
    * @param {Object} companyInfo propertes for in game company
    * @param {Object} userEsg properties for user ingame profile
    * @param {Object} budget budget of user depending of mode he is playing
    * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
    * budget with new value of budget ,companyInfo with new value of companyInfo.
    */
    const setUserStore = (userEsg, companyInfo, budget) => {
        userStore.updateUserInfoEsg(userEsg)
        userStore.updateCompanyInfo(companyInfo)
        userStore.updateBudget(budget)
    }
    /**
     * Sequence progres function
     * @returns Depending of sequnce progres state of player render Quiz,SequenceBmcComponent,BillPayment,GameFinsh component.
     */
    const SeqeunceProgres = () => {
        switch (sequnceProgress) {
            case 'quiz':
                return <div>
                    {sequence !== null && <Quiz setSequnceProgres={setSequnceProgres}
                        sequence={sequence}
                        setSequence={setSequence}
                        setSequenceIndex={setSequenceIndex}
                        budget={budget}
                        setBudget={setBudget}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        companyInfo={companyInfo} />}
                </div>
            case 'bmc':
                return <SequenceBmcComponent
                    setSequnceProgres={setSequnceProgres}
                    sequence={sequence}
                    setSequence={setSequence}
                    setSequenceIndex={setSequenceIndex}
                    budget={budget}
                    setBudget={setBudget}
                    companyInfo={companyInfo}
                    setOnline={setOnline}
                    currentQuestionIndex={currentQuestionIndex}
                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                    companyInfo={companyInfo} />
            case 'monltyEvent':
                return <BillPayment
                    setSequnceProgres={setSequnceProgres}
                    sequenceIndex={sequenceIndex}
                    setSequenceIndex={setSequenceIndex}
                    online={online}
                    setSequnceProgres={setSequnceProgres}
                    monthlyExpenses={monthlyExpenses}
                    sequence={sequence}
                    companyInfo={companyInfo}
                    budget={budget}
                    setBudget={setBudget}
                    setUpdateProgress={setUpdateProgress}
                    fetchSequences={fetchSequences}
                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                    setSequence={setSequence} />
            case 'gameFinish':
                return <GameFinsh
                    setSequnceProgres={setSequnceProgres}
                    companyInfo={companyInfo}
                    budget={budget} />
        }
    }
    return (
        <div className="ofSwitch">
            <div className="toSwitch">
                <div className={fetchLoader ? 'spinner-back' : 'spinner-non-back'}>
                    <Spin size="large" spinning={fetchLoader} />
                </div>
                <SeqeunceProgres />
            </div>
        </div>
    )
}

export default withRouter(inject('sequenceStore', 'userStore', 'progressBarStore')(observer(Sequence)))