import React, { useState } from 'react'
import Modal from '../modal.component'
import './modalChartPie.component.style.less'
import Button from '../../Button/button.component'
import ChartPie from '../../../containers/UserSelectSection/ChartPie/chart.component'
import Chart from 'react-google-charts';
import { useTranslation } from 'react-i18next';


const ModalChartPie = ({ visible, trigerVisible, handleOk }) => {
    const { t } = useTranslation(['common'])

    return (
        <Modal
            modalStyleTest='modal-chart-pie'
            closable={false}
            okBtnText={t("common:close-button")}
            footer={true}
            // btnText="more info"
            additionalStyle="signIn"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
        >
            <ChartPie />
        </Modal>
    )
}

export default ModalChartPie