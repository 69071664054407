


/**
 * Helper check function for redircting user for first part of game if he didnt 
 * finished selecting content about company.
 * @param {String}  company Name of company 
 * @param {String}  industry Type of industry
 * @param {Array}   team Chosen employees
 * @param {String}  building Selected office
 * @param {Array}   equipment Equipment for employees
 * @param {String}  legalAspect Type of legal aspects
 */
const userSelection = (name,industry,team,building,equipment,legalAspect,history) => {
    if(!industry){
        history.push('industrytype')
      }else if (!name){
        history.push('companyname')
      }else if (!team){
        history.push('chooseproffesions')
      }else if (team && !building){
        history.push('salarychoices')
      }else if (!building){
        history.push('officeselect')
      }else if(equipment.length === 0){
        history.push('hardware')
      }else if (!legalAspect){
        history.push('legalaspects')
      }else {
        history.push('office')
      }
}
/**
 * Check type of mode and does play play with accelerator or no.Return new value of game mode
 * @param {Object} budget Budget of company with props
 */
const modeGame = (budget) => {
  let gameMode;
    if (budget.budget.mode === 'single-player' && budget.budget.accelerator) {
      gameMode = 'single-player-accelerator'
    } else if (budget.budget.mode === 'single-player' && !budget.budget.accelerator) {
      gameMode = 'single-player'
    } else {
      gameMode = 'championship'
    }
    return gameMode
}

/**
 * Function that update remaingBudget from response value after 
 * questeion is answered or montly bills are payed
 * @param {Object} res Response from backend
 * @param {Object} budget Budget state 
 * @param {Function} setLocalStorage Function for setting up local storage
 * @param {Object} BUDGET Budget from local storage
 * @param {Store} userStore Mobix userStore
 */
const budgetUpdateHeplfunc = (res, budget, setLocalStorage, BUDGET, userStore) => {
    let budgetObj = Object.assign(budget, {})
    budgetObj.budget.remaining = res.data.remainingBudget
    setLocalStorage(budgetObj, BUDGET)
    userStore.updateBudget(budgetObj)
}

/**
 * Function that update remaingBudget from serviceWroker response value after
 * questeion is answered or montly bills are payed
 * @param {Object} data Response from backend
 * @param {Object} budget Budget state 
 * @param {Function} setLocalStorage Function for setting up local storage
 * @param {Object} BUDGET Budget from local storage
 * @param {Store} userStore Mobix userStore
 */
const budgetUpdateHeplfuncSw = (data, budget, setLocalStorage, BUDGET, userStore) => {
    let budgetObj = Object.assign(budget, {})
    budgetObj.budget.remaining = data.remainingBudget
    setLocalStorage(budgetObj, BUDGET)
    userStore.updateBudget(budgetObj)
}

/**
 * Check if index of current question is equal of quiz questions lenghth.
 * If it is redirect user to bmc if exist in sequence if on lead user to 
 * mountly event . If not increment current question index.
 * @param {Number} currentQuestionIndex Current index of quiz
 * @param {Object} quiz Current quiz that we are playing
 * @param {Function} setLocalStorage Function for storing to local storage
 * @param {String} INCREMENT_CURRENT_QUESTION_INDEX Key for localstorage of question index
 * @param {Object} sequence Sequince that user plays 
 * @param {String} setSequnceProgres String for switch case for routing component
 * @param {String} SEQUENCE_PROGGRES Key for sequnce proggres
 * @param {Number} setCurrentQuestionIndex Set for sequnce number
 */
const checkSequnceState = (currentQuestionIndex,quiz,setLocalStorage,INCREMENT_CURRENT_QUESTION_INDEX,
  sequence,setSequnceProgres,SEQUENCE_PROGGRES,setCurrentQuestionIndex) => {
  const qustionLength = sequence.industryQuestions ? (sequence.quiz.questions.length + sequence.industryQuestions.length) : sequence.quiz.questions.length;
  // console.log(currentQuestionIndex + 1, qustionLength, 'qustionLength');
  if (currentQuestionIndex + 1 === qustionLength) {
    setLocalStorage(0, INCREMENT_CURRENT_QUESTION_INDEX);
    setCurrentQuestionIndex(0);
    if (!sequence.summary) {
        setLocalStorage('monltyEvent', SEQUENCE_PROGGRES)
        setSequnceProgres('monltyEvent')
    } else {
        setLocalStorage('bmc', SEQUENCE_PROGGRES)
        setSequnceProgres('bmc')
    }
  }else{
      let incrementCurrentQuestionIndex = currentQuestionIndex + 1
      setCurrentQuestionIndex(incrementCurrentQuestionIndex)
      setLocalStorage(incrementCurrentQuestionIndex, INCREMENT_CURRENT_QUESTION_INDEX)
  }
}

const changeBckg = (number) => {
  const toSwitch = document.querySelector(".toSwitch");

  if(number === 0) {
    toSwitch.classList.add("bg1");
  } else {
    toSwitch.classList.remove("bg" + number);
    toSwitch.classList.add("bg" + (number + 1));
  }
}

//CHECKER FOR SPECIAL RANODM EVENT --TEST
const randomEventsHp = (sequence,answerValue,currentQuestionIndex,companyInfo) => {
  if (answerValue && answerValue.randomEvent.length > 0) {
      if (sequence.number === 1 && currentQuestionIndex === 0) {
          if (answerValue.randomEvent[1].flag === "buy_property_1" && ((companyInfo.building.en.name === "Property 1") && companyInfo.building.paymentType === "Buyout")) {
              return answerValue.randomEvent[1]
          } else if (answerValue.randomEvent[0].flag === "buy_property_2" && ((companyInfo.building.en.name === "Property 2") && companyInfo.building.paymentType === "Buyout")) {
              return answerValue.randomEvent[0]
          } else if (answerValue.randomEvent[2].flag === "rent_property_1" && ((companyInfo.building.en.name === "Property 1") && companyInfo.building.paymentType === "Rent")) {
              return answerValue.randomEvent[2]
          } else if (answerValue.randomEvent[3].flag === "rent_property_2" && ((companyInfo.building.en.name === "Property 2") && companyInfo.building.paymentType === "Rent")) {
              return answerValue.randomEvent[3]
          } else if (answerValue.randomEvent[4].flag === "use_coworking" && ((companyInfo.building.en.name === "Co-working space") && companyInfo.building.paymentType === "Rent")) {
              return answerValue.randomEvent[4]
          } else {
              return null
          }
      } else if (sequence.number === 2 && currentQuestionIndex === 0) {
          const consumables = companyInfo.equipment.filter((x) => x.item.en.name === "Cutlery (plastic)") === true
          const laptops = companyInfo.equipment.filter((x) => x.item.en.name === "Laptop") === true
          if (answerValue.randomEvent[1].flag === "use_laptop" && laptops) {
              return answerValue.randomEvent[1]
          } else if (answerValue.randomEvent[0].flag === "use_consumables" && consumables) {
              return answerValue.randomEvent[0]
          }else{
              return null
          }
      } else if (sequence.number === 3 && currentQuestionIndex === 0) {
          const twoStars = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.skill === 2)
          const fourStars = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.skill === 4)
          const femaleTeam = companyInfo.team.teamMembers.slice(1).filter((x) => x.employee.gender === "female")
          let membersCategory = companyInfo.team.teamMembers.slice(1).map(x=>x.role)
          const sameCategoryMembers = new Set(membersCategory).size !== membersCategory.length                

          if (answerValue.randomEvent[0].flag === "2_star_member" && twoStars.length > 1) {
              return answerValue.randomEvent[0]
          }
          else if (answerValue.randomEvent[2].flag === "4_star_member" && fourStars.length >= 3) {
              return answerValue.randomEvent[2]
          }
          else if (answerValue.randomEvent[1].flag === "female_team" && (femaleTeam.length === 2 || femaleTeam.length === 3 )) {
              return answerValue.randomEvent[1]
          } else if (answerValue.randomEvent[3].flag === "same_category_members" && sameCategoryMembers) {
              return answerValue.randomEvent[3]
          }else {
              return null
          }
      } else if (sequence.number === 4 && currentQuestionIndex === 0) {
          if (answerValue.randomEvent[0].flag === "sole_trader" && ((companyInfo.legalAspect === "SoleTrader"))) {
              return answerValue.randomEvent[0]
          } else if (answerValue.randomEvent[1].flag === "ltd" && (companyInfo.legalAspect === "LTD")) {
              return answerValue.randomEvent[1]
          } else {
              return null
          }
      }
      else {
          return answerValue.randomEvent[0]
      }
  } else {
      return null
  }

}


export { 
    userSelection,
    modeGame,
    budgetUpdateHeplfunc,
    budgetUpdateHeplfuncSw,
    checkSequnceState,
    changeBckg,
    randomEventsHp
}