import React, { useState, useEffect } from 'react'
import './singleplayerNoAcc.component.style.less'
import { Avatar, avatarArr, EmployeAvatar, HardwareEquipment, BuildingType, Coins } from '../../../../assets/images';
import { Rate } from 'antd';
import InfoModalEmployee from '../../../../components/Modal/ModalInfoEmployee/modalInfoEmployee.component'
import ModalInfoOffice from '../../../../components/Modal/ModalInfoOffice/modalInfoOffice.component'
import Download from '../../../../components/Modal/ModalViewProfile/excel'

const SingleplayerNoAcc = ({ userEsg, budgetWitoutAccelator, companyWithoutAccelator, bmcWithoutAccelerator }) => {
    const employes = companyWithoutAccelator.team.teamMembers.slice(1)
    const equipmentList = companyWithoutAccelator.equipment
    const building = companyWithoutAccelator.building
    const user = userEsg.userName
    const identitys = userEsg.identity

    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }

    const userProfileImage = utilityFilter(avatarArr, userEsg.avatarUrl)

    return (
        <div>
            <div className="general-info">
                <div className="image-row">
                    {!userEsg.avatarUrl ? null : <Avatar src={userProfileImage[0].src} />}
                    <Download user={userEsg}/>
                </div>
                <div className="startup-name">
                    <p>Name and Surname</p>
                    <span>{identitys.name} </span>
                </div>
                <div className="startup-name">
                    <p>Username</p>
                    <span>{userEsg.userName} </span>
                </div>
                <div className="startup-name">
                    <p>Email</p>
                    <span style={{ textTransform: 'lowercase' }}>{identitys.email} </span>
                </div>
                <div className="startup-name">
                    <p>City</p>
                    <span>{userEsg.city} </span>
                </div>
                <div className="startup-name">
                    <p>Year of Birth</p>
                    <span>{userEsg.yearOfBirth} </span>
                </div>
                <div className="startup-name">
                    <p>Gender</p>
                    <span>{userEsg.gender} </span>
                </div>
                <div className="startup-name">
                    <p>Startup name</p>
                    <span>{companyWithoutAccelator.name} </span>
                </div>
                <div className="startup-name">
                    <p>Industry type</p>
                    <span>{companyWithoutAccelator.industry} </span>
                </div>
                <div className="startup-name">
                    <p>Legal-administrative aspect</p>
                    <span>{companyWithoutAccelator.legalAspect} </span>
                </div>

                {/* <div className="emplyeesList">
                    <h2>Your team:</h2>
                    <div className="inline-holder">
                        {employes.employe ? employes.map((x) => {
                            return (
                                <div className="raising" key={x.employe._id}>
                                    <EmployeAvatar key={x.employe._id} avatarUrl={x.employe.avatarUrl} />
                                    <span className="money-count">
                                        <Rate disabled value={x.employe.skill} allowHalf={true} />
                                    </span>
                                    <InfoModalEmployee
                                        avatarUrl={x.employe.avatarUrl}
                                        name={x.fullName}
                                        education={x.employe.education}
                                        experience={x.employe.experience}
                                        skills={x.employe.skills}
                                        competencies={x.employe.competencies}
                                        languages={x.employe.languages}
                                        // skill={employe.skill}
                                        salary={x.employe.salary}
                                        role={x.employe.role} />
                                </div>
                            )
                        })
                            :
                            <div>
                                <h2 className="red">You haven't choose any team members</h2>
                            </div>
                        }
                    </div>

                </div> */}

                <div className="buildingList">
                    <h2>Office:</h2>
                    {building ?
                        <div className="building-holder">
                            <BuildingType imageUrl={building.imageUrl} ecoFriendly={building.ecoLevel} />
                            <div className="building-text">
                                <h2>{building.en.name}</h2>
                                <p className="green">
                                    {building.price}<Coins />
                                </p>
                            </div>
                            <div className="more-info-building">
                                <ModalInfoOffice
                                    imageUrl={building.imageUrl}
                                    contentHeader={building.en.name}
                                    contentPrice={building.price}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <h2 className="red">You haven't choose any office property</h2>
                        </div>
                    }
                </div>

                <div className="equipmentList">
                    <h2>Equipment:</h2>
                    {equipmentList?.length ? equipmentList.map((x) => {
                        return (
                            <div className="equipmentListHolder" key={x.item.en.name}>
                                {/* <HardwareEquipment key={x.item._id} imageUrl={x.item.imageUrl} /> */}
                                <p className="device-per-eqi">{x.item.en.name}</p>
                                <p className="device-per-eqi bolded">X{x.amount}</p>
                            </div>
                        )
                    })
                        :
                        <div>
                            <h2 className="red">You haven't choose any Equipment</h2>
                        </div>
                    }
                </div>
            </div >
            {bmcWithoutAccelerator ? <div className='business-model'>
                <div id="printWrapper">
                    <h2 className="title-bmc">Business Model Canvas</h2>
                    <div className="bmc">
                        <div className={!bmcWithoutAccelerator.keyPartners ? "block test-kp" : "block key-partners"} key="1">
                            <div className="click" id="keyPartners"></div>
                            <h2 className="title">Key Partners</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.keyPartners}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.keyActivities ? "block test-ka" : "block key-activities"}>
                            <div className="click" id="keyActivities" ></div>
                            <h2 className="title">Key Activities</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.keyActivities}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.keyResources ? "block test-kr" : "block key-resources"}>
                            <div className="click" id="keyResources" ></div>
                            <h2 className="title">Key Resources</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.keyResources}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.valueProposition ? "block test-vp" : "block value-propositions"}>
                            <div className="click" id="valueProposition" ></div>
                            <h2 className="title">Value Propositions</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.valueProposition}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.customerRelationships ? "block test-cr" : "block customer-relationships"}>
                            <div className="click" id="customerRelationships" ></div>
                            <h2 className="title">Customer Relationships</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.customerRelationships}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.channels ? "block test-c" : "block channels"}>
                            <div className="click" id="channels" ></div>
                            <h2 className="title">Channels</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.channels}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.customerSegment ? "block test-cseg" : "block customer-segments"}>
                            <div className="click" id="customerSegment" ></div>
                            <h2 className="title">Customer Segments</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.customerSegment}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.costStructure ? "block test-cst" : "block cost-structure"}>
                            <div className="click" id="costStructure" ></div>
                            <h2 className="title">Cost Structure</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.costStructure}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcWithoutAccelerator.revenueStreams ? "block test-rs" : "block revenue-streams"}>
                            <div className="click" id="revenueStreams" ></div>
                            <h2 className="title">Revenue Stream</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcWithoutAccelerator.revenueStreams}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

export default SingleplayerNoAcc