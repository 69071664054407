import React, { useState, useEffect } from 'react';
import './selectAccelerator.component.style.less';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, BUDGET, COMPANY_INFO } from '../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../services/EsgServices/esg.service'
import { userSelection } from '../../../helperFunctions/helperFunctions'
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'

/**
 * Select Accelerator component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.Two buttons for user to chose mode Accelerator mode and Without Accelerator mode.
 * And two buttons : Contiue button that after selected mode redirect him to award awardcoins . 
 * Back button for going back to previous page. 
 */
const SelectAccelerator = ({ history, userStore, progressBarStore }) => {
  const { t } = useTranslation(["common", "mode"])
  /** Variable type Object where we set information and store about user in game for avatars selection page.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)
  /** Variable type String mode that user selected .Default value is null*/
  const [singlePlayer, setSinglePlayer] = useState(null)
  /**Variable type String that set user select to play  accelerator mode.Default value is null*/
  const [selectAccelerator, setselectAccelerator] = useState(null);
  /**Variable type String that set user select to play without accelerator mode.Default value is null*/
  const [withoutAccelerator, setwithoutAccelerator] = useState(null);
   /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setSinglePlayer(getLocalStorage(BUDGET).BUDGET);
    progressBarStore.setProgress(95, 0, 0)
  }, [])

  useEffect(() => {
    if (userEsg || singlePlayer) {
      setUserStore(userEsg, singlePlayer)
    }
  }, [userEsg, singlePlayer])
  /**
   * Function that two parametars userEsg , singlePlayer
   * @param {Object} userEsg properties for user ingame profile
   * @param {String} singlePlayer mode that user selected
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value and 
   * userStore budget property with mode of budget singlePlayer
   */
  const setUserStore = (userEsg, singlePlayer) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateBudget(singlePlayer)
  }
  /**
   * Function that set state  withoutaccelerator to true 
   * and set state accelerator to false. And mode of user select to withoud accelator.
   */
  const setUpdateWithoutAccelerator = () => {
    setwithoutAccelerator(true);
    setselectAccelerator(false);
  };
  /**
   * Function that set state withoutaccelerator to false 
   * and set state accelerator to true.And mode of user select to accelator.
   */
  const setUpdateSelectAccelerator = () => {
    setselectAccelerator(true);
    setwithoutAccelerator(false);
  };
  /**
   * Function that called on Continue button after user have chose to play with accelerator or
   * without accelerator . EsgService is called and information about acceletor mode is save.
   * After that user is redirected to awardcoins page.
   */
  const modeSelected = () => {
    const { userId } = userStore.userInfoEsg
    const { mode } = userStore.budget.budget


    if (selectAccelerator) {
      const userAcceleratorMode = {
        userId: userId,
        mode: mode,
        accelerator: selectAccelerator
      }
      esgService.selectMode(userAcceleratorMode).then((res) => {
        if (res.data.hasOwnProperty('user')) {
          userStore.updateUserInfoEsg(res.data.user)
          setLocalStorage(res.data.user, USER_PROFILE_ESG)
        }
        const budgetObj = {
          budget: res.data.budget
        }
        userStore.updateBudget(budgetObj)
        setLocalStorage(budgetObj, BUDGET)
        const { remaining, totalAmount, company } = budgetObj.budget
        if (budgetObj.budget.hasOwnProperty('company')) {
          esgService.getCompanyInfo(company).then((res) => {
            setLocalStorage(res.data, COMPANY_INFO)
            userStore.updateCompanyInfo(res.data)
            const { building, equipment, industry, name, team, legalAspect } = res.data
            userSelection(name, industry, team, building, equipment, legalAspect, history)
          })
        } else {
          history.push('awardcoins')
        }
      })
    }

    else if (withoutAccelerator) {
      const withoutAcceleratorMode = {
        userId: userId,
        mode: mode,
        accelerator: !withoutAccelerator
      }
      esgService.selectMode(withoutAcceleratorMode).then((res) => {
        if (res.data.hasOwnProperty('user')) {
          userStore.updateUserInfoEsg(res.data.user)
          setLocalStorage(res.data.user, USER_PROFILE_ESG)
        }
        const budgetObj = {
          budget: res.data.budget
        }
        userStore.updateBudget(budgetObj)
        setLocalStorage(budgetObj, BUDGET)
        const { remaining, totalAmount, company } = budgetObj.budget
        if (budgetObj.budget.hasOwnProperty('company')) {
          esgService.getCompanyInfo(company).then((res) => {
            setLocalStorage(res.data, COMPANY_INFO)
            userStore.updateCompanyInfo(res.data)
            const { building, equipment, industry, name, team, legalAspect } = res.data
            userSelection(name, industry, team, building, equipment, legalAspect, history)
          })
        } else {
          history.push('awardcoins')
        }
      })
    }
  };
  return (
    <div className="select-accelerator app">
      <div className="select-accelerator-section">
        <div dangerouslySetInnerHTML={{ __html: t('mode:accelerator.accelerator-mode-select') }} />
        <div className="button-accelerator-holder">
          <div
            className={
              !selectAccelerator
                ? 'accelerator-text'
                : 'accelerator-text-selected'
            }
            onClick={setUpdateSelectAccelerator}
          >
            <h3>{t("mode:accelerator.accelerator-mode")}</h3>
            <p>
              {t("mode:accelerator.accelerator-mode-explanation")}
            </p>
          </div>
          <div
            className={
              !withoutAccelerator
                ? 'accelerator-text'
                : 'accelerator-text-selected'
            }
            onClick={setUpdateWithoutAccelerator}
          >
            <h3>{t("mode:accelerator.yourown-mode")}</h3>
            <p>
              {t("mode:accelerator.yourown-mode-explanation")}
            </p>
          </div>
        </div>
        <div className="button-landing">
          <Button
            additionalStyle="signIn"
            text={t("common:landing-back")}
            customBtn={true}
            onClick={() => {
              history.goBack();
            }}
          />
          <Button
            additionalStyle="buttonMakeAcc"
            text={t("common:landing-continue")}
            customBtn={true}
            disabled={selectAccelerator || withoutAccelerator ? false : true}
            onClick={modeSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore', 'progressBarStore')(observer(SelectAccelerator)));
