import React, { useState, useEffect } from 'react';
import "./accountSettings.component.style.less";
import { Form, Input, InputNumber, Select } from 'antd';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, COMPANY_NAME } from '../../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../../services/EsgServices/esg.service'
import Button from '../../../../components/Button/button.component'
import { Spin } from 'antd';
import { useTranslation } from "react-i18next"

const { Option } = Select;

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const AccountSettings = ({ userStore }) => {
    const { t } = useTranslation(["registration", "common"])
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();
    const [userLastName, setUserLastName] = useState(null)
    const [userCity, setUserCity] = useState(null)
    const [userGender, setUserGender] = useState(null)
    const esgService = new EsgService();

    useEffect(() => {
        const user = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG

        const { lastName, city, gender } = user
        form.setFieldsValue({ name: lastName, city: city, gender: gender })
    }, [])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onStartInput = (value) => {
        setUserLastName(value);
    };

    const toggleLoader = value => {
        setLoader({ loader: value })
    }

    const onFinish = () => {
        form
            .validateFields()
            .then(values => {
                setLoader(!loader)
                const value = {
                    city: values.city,
                    gender: values.gender,
                    name: `${userStore.userInfoEsg.firstName} ${values.name}`
                }

                esgService.updateUserProfile(value, userStore.userInfoEsg.userId).then(
                    (res) => {
                        setLoader(loader)
                        setLocalStorage(res.data, USER_PROFILE_ESG)
                        userStore.updateUserInfoEsg(res.data)
                        const { lastName, city, gender } = res.data
                        form.setFieldsValue({ name: lastName, city: city, gender: gender })
                    }
                )
            })
    };

    return (
        <div className="user-data">
            <div className="updateForm">
                <div className={loader ? 'spinner-back' : 'spinner-non-back'}>
                    <Spin size="large" spinning={loader} />
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Form.Item
                        label={t('registration:last-name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('registration:last-name-message'),
                            },
                        ]}
                    >
                        <Input placeholder={userLastName} onChange={(e) => onStartInput(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        label={t('registration:city')}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: t('registration:city-message'),
                            },
                        ]}
                    >
                        <Input placeholder={userCity} />
                    </Form.Item>
                    <Form.Item
                        name="gender"
                        label={t('registration:gender.gender-head')}
                        rules={[
                            {
                                required: true,
                                message: t('registration:gender.gender-message')
                            },
                        ]}
                    >
                        <Select
                            placeholder={userGender}
                            // onChange={onGenderChange}
                            allowClear
                        >
                            <Option value="female">{t('registration:gender.gender-female')}</Option>
                            <Option value="male">{t('registration:gender.gender-male')}</Option>
                            <Option value="other">{t('registration:gender.gender-other')}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            additionalStyle="signIn"
                            text={t('common:update')}
                            customBtn={true}
                            onClick={onFinish}
                            toggleLoader={toggleLoader}
                        />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default withRouter(inject("userStore")(observer(AccountSettings)));