import React, { useState } from 'react'
import Modal from '../modal.component'
import './modalOffline.component.style.less'
import { Offline } from '../../../assets/images/index'


const ModalOffline = () => {
    const [visible, setVisible] = useState(false);
    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }
    return (
        <Modal
            modalStyleTest='modal-content-offline'
            closable={false}
            footer={true}
            okBtnText='Close'
            btnText="more info"
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
        >
            <div className="contentImage-offline">
                <Offline />
            </div>
            <div className="contentHeader"><h3>Please connect to internet</h3></div>
            <div className="contentDescription">To keep playing offline, we need you to connect briefly to internet to download content.</div>
        </Modal>
    )
}

export default ModalOffline