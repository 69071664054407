import React, { useState, useEffect } from 'react';
import './gameFinish.component.style.less';
import { Chest } from '../../../assets/images/index';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Button from '../../../components/Button/button.component';
import SequnceService from '../../../services/EsgServices/sequence.service'
import EsgServices from '../../../services/EsgServices/esg.service'
import Animation10 from '../../../assets/video/animation10.mp4'
import { IlustrationFinal } from '../../../assets/images/index'
import VideoLoad from '../../../components/Video/video.component'
import EngGameSurveyModal from '../../../components/Modal/EndSurveyModal/EngGameModal'
import ShareComponent from '../../../components/Share/share.component'
import { useTranslation } from 'react-i18next';
// import awardBHS from '../../../assets/award/BizVenture_Opportunities_Award_(BHS).pdf';
// import awardEN from '../../../assets/award/BizVenture_Opportunities_Award_(ENG).pdf';
import awardBHS from '../../../assets/award/Certificate_BHS.pdf';
import awardEN from '../../../assets/award/Certificate_EN.pdf';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Bmc from '../BmcUser/bmcUser.component'

/**
 * Montly Event component
 * @class 
 * @param {Object} properties (userStore, history, companyInfo, budget, languageStore, progressBarStore)
 * @returns End game ilustration.Title , text , button for downloading document and ShareComponent.
 * Start New Game button that redirect user to endsurvey or if end surtvey is populated to selectmode.
 */
const GameFinish = ({ userStore, history, companyInfo, budget, languageStore, progressBarStore }) => {
  /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
    * service where are esg services sequnce methods for calling api's are stored.
    */
  const sequenceService = new SequnceService()
  const esgServices = new EsgServices
  const [userEsg, setUserEsg] = useState(null)
  const [videoEnd, setVideoEnd] = useState(false)
  const [surveyCompleted,setSurveyCompleted] = useState(false)
  const { t } = useTranslation(['finish'])


  useEffect(() => {
    const user = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
    setUserEsg(user)
    // esgServices.getUserSurvey(user._id).then((res) => {
    //   if (res.data.length <= 1) {
    //     setSurveyCompled(true)
    //   }
    // })

    progressBarStore.setProgress(100, 100, 100);
  }, [])
  /**
   * Game Finished function.Redirect user to endsurvey rout in case he didnt completed survey before
   * if he is calls sequenceService with method reset game and two parameters are passed companyId,
   * budgetId.Local storage is cleared. Mobix userStore companyInfo and budget are updated to null value
   * local storage USER_PROFILE_ESG is updated with userEsg value and user is redirected to selectmode route.
   */
  const gameFinished = () => {
    // if (surveyCompleted) {
    //   history.push('/endsurvey')
    // } else {
      const companyId = companyInfo.id
      const budgetId = budget.budget._id
      sequenceService.resetGame(companyId, budgetId).then((response) => {
        localStorage.clear()
        userStore.updateCompanyInfo(null)
        userStore.updateBudget(null)
        setLocalStorage(userEsg, USER_PROFILE_ESG)
        history.push('/selectmode')
      })
    // }
  }
  const printDocument = () => {
    const elementToDownload = document.getElementById('printWrapper');
    const divHeight = elementToDownload.clientHeight
    const divWidth = elementToDownload.clientWidth
    const ratio = divHeight / divWidth;

    html2canvas(elementToDownload,{scrollY: -window.scrollY}, { scale: '2' }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const orientation = divWidth >= divHeight ? 'l' : 'p'

        const pdfDOC = new jsPDF({
            orientation,
            unit: 'pt', // points, pixels won't work properly
            format: [divWidth, divHeight] // set needed dimensions for any element
        });

        const width = pdfDOC.internal.pageSize.getWidth();
        let height = pdfDOC.internal.pageSize.getHeight();
        height = ratio * width;

        pdfDOC.addImage(imgData, 'PNG', 0, 0, width, height);
        pdfDOC.save('businessModelCanvas.pdf');   //Download the rendered PDF.
    })
}
const printDocumentCertificate = () => {
    const elementToDownload = document.getElementById('certificate');
    const divHeight = elementToDownload.clientHeight

    const divWidth = elementToDownload.clientWidth
    const ratio = divHeight / divWidth;

    html2canvas(elementToDownload,{scrollY: -window.scrollY}, { scale: '2' }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const orientation = divWidth >= divHeight ? 'l' : 'p'

        const pdfDOC = new jsPDF({
            orientation,
            unit: 'pt', // points, pixels won't work properly
            format: [divWidth, divHeight] // set needed dimensions for any element
        });

        const width = pdfDOC.internal.pageSize.getWidth();
        let height = pdfDOC.internal.pageSize.getHeight();
        height = ratio * width;

        pdfDOC.addImage(imgData, 'PNG', 0, 0, width , height );
        pdfDOC.save('Certificate.pdf');   //Download the rendered PDF.
    })
}
  const handleScrollToUser = () => {
    window.scrollTo(0, 0)
  };
  return (
    <div className="game-finish app">
      { 
      !videoEnd? <VideoLoad url={Animation10} setVideoEnd={setVideoEnd} setSurveyCompleted={setSurveyCompleted} gameFinsh={true} />:
        <div>
           <EngGameSurveyModal surveyCompleted={surveyCompleted} setSurveyCompleted={setSurveyCompleted}/>
          <div className='bmc-download'>
            <Bmc/>
          </div>
          <div className="game-finish-complete">
            <IlustrationFinal />
            <h2>{t('finish:title.first')} <br /> {t('finish:title.second')}</h2>
            <p>{t('finish:content')}</p>
            <div className="game-finish-holder">
              <Chest />
            </div>
            <div >
              <div>
                  <Button
                    id='btn'
                    additionalStyle="buttonMakeAcc"
                    text={t('finish:download')}
                    customBtn={true}
                    onClick={() => { setTimeout(printDocumentCertificate, 100); }}
                  />
                  <Button
                      id='btn'
                      additionalStyle="buttonMakeAcc"
                      text={languageStore.language === 'bhs' ? 'SAČUVAJ BMC' : 'SAVE BMC'}
                      customBtn={true}
                      onClick={() => {setTimeout(printDocument, 100); }}
                  />
              </div>
              <div className="shareButtons">
                <h3>{t('finish:share')}</h3>
                <div>
                  <ShareComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('finish:start-new')}
              customBtn={true}
              onClick={gameFinished}
            />
          </div>
        </div>
      }
    </div >
  );
};

export default withRouter(inject('userStore', 'languageStore', 'progressBarStore')(observer(GameFinish)));