import React,{useState,useEffect} from 'react'
import AuthService from '../../../services/auth.service'
import IndetityService from '../../../services/identity.service';
import EsgService from '../../../services/EsgServices/esg.service'; 
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { useLocation, withRouter } from 'react-router-dom';
import { setLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import { Spin } from 'antd';
import * as token from '../../../services/core/token';

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

/**
 * SocialLogin component
 * @class
 * @param {Object} properties ( history, userStore )
 * @returns Spinner that will wait until user is logedin
 */
const SocialLogin = ({history,userStore}) => {
    const query = useQuery();
    const indetityService = new IndetityService();
    const esgService = new EsgService();
    const [verifyed, setVerifyed] = useState(false)
  
    useEffect(()=>{
      const newToken = query.get("token")
      if(newToken){
        googleVerified()
      }
    },[])
    /** Google verification process **/
    const googleVerified = () => {
      const newToken = query.get("token")
      token.set(newToken);
       indetityService.validateUserToken().then((res) => {
        if (res.status === 200) {
          const userEusiId = res.data.id
          setVerifyed(true)
          esgService.getUserProfile(userEusiId).then((userEsgResponse) => {
            setLocalStorage(userEsgResponse.data, USER_PROFILE_ESG);
            userStore.updateUserInfoEsg(userEsgResponse.data);
  
            if (!userEsgResponse.data.hasOwnProperty('avatarUrl') || userEsgResponse.data.avatarUrl === null) {
              history.replace('/avatars');
            } else if (!userEsgResponse.data.hasOwnProperty('userName')) {
              history.replace('/charctername')
            } else if (!userEsgResponse.data.hasOwnProperty('mentor')) {
              history.replace('/mentorselect')
            } else {
              history.replace('/selectmode');
            }
          })
        }
      });
    }
  
    return (
        <div>
            <div className={!verifyed ? 'spinner-back' : 'spinner-non-back'}>
                <Spin size="large" spinning={!verifyed} />
            </div>
        </div>
    )
}

export default withRouter(inject('userStore')(observer(SocialLogin)))