import React from 'react'
import { useEffect, useState} from "react";
import  './Certificate.component.style.less'
import {SectionLogoLanding, FooterLanding, avatarArr} from '../../../assets/images/index.js'
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import {getLocalStorage, USER_PROFILE_ESG} from "../../../localStorageMobxStore/localStorageUserProfile";

const Certificate = ({ userStore, languageStore }) => {
    const [userEsg, setUserEsg] = useState(null)
    useEffect(() => {
        if (userEsg) {
            setUserStore(userEsg)
        }
    }, [userEsg])
    const setUserStore = (userEsg) => {
        userStore.updateUserInfoEsg(userEsg)
    }
    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
    }, [])
    const bhsStrings = {
        for:"na ime",
        from:"za uspješno završenu BizVenture preduzetničku igru",
        certificat:"Certifikat",
        podrzanoOd:"PODRŽANO OD"
    }
    const enString = {
        for:"presented to",
        from:"for a successfully completed BizVenture entrepreneurial game",
        certificate:"Certificate",
        supportedBy:"SUPPORTED BY"
    }

    return (
        <div id='certificate' className='certificate'>
            <div className='content'>
                <div>
                    <div className='content-logo'>
                        <SectionLogoLanding/>
                    </div>
                    <div className='content-name'>
                        <p className='certificate-title'>{languageStore.language === 'bhs' ? bhsStrings.certificat : enString.certificate}</p>
                        <p className='name-title'>{languageStore.language === 'bhs' ? bhsStrings.for : enString.for}</p>
                        <p className='name'>{userEsg?.firstName} {userEsg?.lastName}</p>
                    </div>
                    <div className='content-footer'>
                        <p className='footer-text'>{languageStore.language === 'bhs' ? bhsStrings.from : enString.from}</p>
                    </div>
                </div>
                <div>
                    <div className='footer-text'>
                        <p className='footer-content'>{languageStore.language === 'bhs' ? bhsStrings.podrzanoOd : enString.supportedBy}</p>
                    </div>
                    <div className='footer-images'>
                        <FooterLanding/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  withRouter(inject('userStore', 'languageStore')(observer(Certificate)))