import React, { useState, useEffect } from 'react';
import './selectMode.component.style.less';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, BUDGET, COMPANY_INFO, setCompanyInfo } from '../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../services/EsgServices/esg.service'
import { userSelection } from '../../../helperFunctions/helperFunctions'
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'

import Animation11 from '../../../assets/video/animation11.mp4'
import VideoLoad from '../../../components/Video/video.component'

/**
 * Select mode component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.Two buttons for user to chose mode Single Player and Multiplayer.
 * And two buttons : Contiue button after user selected mode buttun is enabled to submit if he chosed single 
 * player redirct him to Selectaccelerator if he selected multi player redirect him to awardcoins if
 * no company or budget is created before.
 * Back button for going back to previous page. 
 */
const SelectMode = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["mode", "common"])
  /** Variable type Object where we set information and store about user in game for avatars selection page.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)
  /**Variable type String that set user select to play single player mode.Default value is null*/
  const [singleplayer, setSingleplayer] = useState(null);
  /**Variable type String that set user select to play multi player mode.Default value is null*/
  const [multiplayer, setMultiplayer] = useState(null);
  /**Variable type Boolean set if inital vidios is ended or not.Default value is false */
  const [videoEnd, setVideoEnd] = useState(false)
   /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
    progressBarStore.setProgress(82, 0, 0)
  }, [])

  useEffect(() => {
    if (userEsg) {
      setUserStore(userEsg)
    }
  }, [userEsg])
  /**
   * Function that one parametar userEsg 
   * @param {Object} userEsg properties for user ingame profile
   * @returns Updating userStore properties(userInfoEsg ) state with new updated value
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Function that set user select single player and set multyplayer to null.
   */
  const setUpdateSinglePlayer = () => {
    setSingleplayer('single-player');
    setMultiplayer(null);
  };
  /**
   * Function that set user select championship and set single player to null.
   */
  const setUpdateMultiPlayer = () => {
    setMultiplayer('championship');
    setSingleplayer(null);
  };
  /**
   *Function that first check if user selected single player and if he selected create new Object 
   * with property budget where mode that user selected is stored. User Store and local storage
   * budget property mode is updated with new value.And user is redicted to selectaccelerator page.
   * If user selected multi player we create new Object userMode is created with properties
   * userId , mode and accelerator.Esg Service is called with method selectMode with parameter 
   * userMode that we created.If data response have property user.User Store and Local Storage
   * is called with method updateUserInfoEsg for store and USER_PROFILE_ESG for local storage
   * and updated with res.data.user. If res.data.budget have value company.Esg service getCompanyInfo
   * is called and company parameter is passed and response is stored to local storage COMPANY_INFO
   *  and userStore updateCompanyInfo.If response data dont have company user is redirected to awardcoins.
   */
  const modeSelected = () => {
    if (singleplayer) {
      const singlePlayerMode = {
        budget: {
          mode: singleplayer
        }
      }
      userStore.updateBudget(singlePlayerMode)
      setLocalStorage(singlePlayerMode, BUDGET)
      history.push('selectaccelerator');


    } else if (multiplayer) {
      const { userId } = userStore.userInfoEsg
      const userMode = {
        userId: userId,
        mode: multiplayer,
        accelerator: false
      }
      esgService.selectMode(userMode).then((res) => {
        if (res.data.hasOwnProperty('user')) {
          userStore.updateUserInfoEsg(res.data.user)
          setLocalStorage(res.data.user, USER_PROFILE_ESG)
        }
        const budgetObj = {
          budget: res.data.budget
        }
        userStore.updateBudget(budgetObj)
        setLocalStorage(budgetObj, BUDGET)
        const { remaining, totalAmount, company } = budgetObj.budget
        if (budgetObj.budget.hasOwnProperty('company')) {
          esgService.getCompanyInfo(company).then((res) => {
            setLocalStorage(res.data, COMPANY_INFO)
            userStore.updateCompanyInfo(res.data)
            const { building, equipment, industry, name, team, legalAspect } = res.data
            userSelection(name, industry, team, building, equipment, legalAspect, history)
          })
        } else {
          history.push('awardcoins')
        }
      })
    }
  };

  return (
    <div className="select-mode">
      {!videoEnd ? <VideoLoad url={Animation11} setVideoEnd={setVideoEnd} /> :
        <div className="select-mode-section">
          <div className="button-select-holder">
            <h2>{t("mode:game-mode.game-mode-select")}</h2>
            <Button
              additionalStyle={!singleplayer ? 'signUp' : 'button-selected'}
              text={t("mode:game-mode.singleplayer")}
              customBtn={true}
              onClick={() => { setUpdateSinglePlayer(); playSound(likeAudio); }}
            />
            <Button
              additionalStyle={!multiplayer ? 'signUp' : 'button-selected'}
              text={t("mode:game-mode.championship")}
              customBtn={true}
              onClick={() => { setUpdateMultiPlayer(); playSound(likeAudio); }}
            />
          </div>
          <div className="button-landing">
            <Button additionalStyle="signIn" text={t("common:landing-back")} customBtn={true} onClick={() => {
              history.goBack();
            }} />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t("common:landing-continue")}
              customBtn={true}
              disabled={singleplayer || multiplayer ? false : true}
              onClick={() => { modeSelected(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'progressBarStore')(observer(SelectMode)));
