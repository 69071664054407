import React, { useState, useEffect } from "react";
import "./survey.component.style.less";
import Button from "../../../components/Button/button.component";
import { inject } from "mobx-react";
import { Form, Input, Radio } from "antd";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import {
  getLocalStorage,
  USER_PROFILE_ESG,
  BUDGET,
  COMPANY_INFO,
} from "../../../localStorageMobxStore/localStorageUserProfile";
import EsgService from "../../../services/EsgServices/esg.service";
import FormItem from "antd/lib/form/FormItem";
import ClickSound from "../../../assets/audio/button22.wav";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import i18n from "i18next";
import { SurveyRocket, InitP } from "../../../assets/images/index";
const { TextArea } = Input;
/**
 * Init survey component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.List of questions.For each question title of question and input for answer of question.
 * And two buttons : Contiue button after user selected avatar buttun is enabled to submit save user select of avatar and
 * redirect him to next page Meet the mentor and
 * Back button for going back to previous page.
 */
const InitSurvey = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation("common");
  /** Variable type Object where we set information and store about user in game for avatars selection page.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
   */
  const [userEsg, setUserEsg] = useState(null);
  const [budget, setBudget] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  /**
   * This is variable type of Object where we set and store information about survey that
   * is defind in base.
   * @type {Object} survey type of objects with properties _id(String) and list of questions(Array)
   * Default value is null .
   */
  const [survey, setSurvey] = useState(null);
  /**
   * Property form from Antd Form.useForm()
   */
  const [form] = Form.useForm();
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService();

  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which
   * can be either attached to a document for the user to interact with and/or listen to,
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);

  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = (audioFile) => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  };
  /**
  *Component did mount.Setting up userEsg , budget and companyInfo from localStoraga with method getLocalStorage with parmeters
   USER_PROFILE_ESG , BUDGET and COMPANY_INFO.
  *Setting up progressBarStore with parameters (34,0,0).EsgService getSurveys method is called where we 
  *fatch data from backend about surveys list and we set first survey it in survey state.
  */
  useEffect(() => {
    const userProfile = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG;
    setUserEsg(userProfile);
    setBudget(getLocalStorage(BUDGET).BUDGET);
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO);
    esgService.getSurveys().then((res) => {
      setSurvey(res.data[0]);
    });

    progressBarStore.setProgress(47, 0, 0);
  }, []);

  /**
   * Component did update.Check if userEsg or companyInfo or budget have values and if they have
   * it set that values to userStore for all three values.
   *
   */
  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget);
    }
  }, [userEsg, companyInfo, budget]);
  /**
   * Function that takes three parametars userEsg ,companyInfo , budget
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget properties for user ingame budget
   * @param {Object} companyInfo properties for ingame company that user selected
   * @returns Updating userStore properties(userInfoEsg ,companyInfo, budget) state with new updated value
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg);
    userStore.updateCompanyInfo(companyInfo);
    userStore.updateBudget(budget);
  };
  /**
   * OnFinish function is call on continue button when user enter answer for all survey questions.
   * First need to pass form validation field (that all fields(answers of questions) have input).
   * Array of answers is crated.Emty responses array is created .
   * For each answer survey questin by index of i id is used for questionId and for each answer value
   * it is added to responses array .Object data is created with three parameters userId,surveyId and
   * responses. EsgService methoh postSurvey is called and one parameter data object previously created
   * is passed. Last redirect is call and rout user to meetmentor page.
   */
  const onFinish = () => {
    form.validateFields().then((values) => {
      const arrayValues = Object.keys(values).map((x) => values[x]);
      let responses = [];
      arrayValues.forEach((x, i) => {
        responses.push({
          questionId: survey.questions[i]._id,
          answer: x,
        });
      });
      const data = {
        userId: userEsg._id,
        surveyId: survey._id,
        responses: responses,
      };
      esgService.postSurvey(data).then((res) => {
        history.push("meetmentor");
      });
    });
  };

  let langKey = i18n.language ?? "en";
  langKey = langKey === "bhs" ? "bhs" : "en";

  return (
    <div className="survey-to app">
      <div className="survey-to-complete">
        <h2>{t("common:init-survey")}</h2>
        {survey && survey.questions.length > 0 ? (
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            {survey.questions.map((x) => {
              if (x[langKey].radio) {
                if (x[langKey].radio.values) {
                  return (
                    <FormItem
                      className="survey-question"
                      key={x._id}
                      label={ReactHtmlParser(x[langKey].description)}
                      name={ReactHtmlParser(x[langKey].description)}
                      rules={[
                        {
                          required: !x[langKey].optional,
                          message: t("common:select-answer"),
                        },
                      ]}
                    >
                      <div className="radio">
                        <Radio.Group
                          style={{ justifyContent: "space-around" }}
                          className="radio-buttons"
                        >
                          {x[langKey].radio.values.map((x) => {
                            return (
                              <div className="row">
                                <Radio.Button value={x}>
                                  <SurveyRocket />
                                </Radio.Button>
                                <div className="radio-text-row start-text">
                                  {x}
                                </div>
                              </div>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </FormItem>
                  );
                }
                return (
                  <FormItem
                    className="survey-question"
                    key={x._id}
                    label={ReactHtmlParser(x[langKey].description)}
                    name={ReactHtmlParser(x[langKey].description)}
                    rules={[
                      {
                        required: !x[langKey].optional,
                        message: t("common:select-answer"),
                      },
                    ]}
                  >
                    <div className="radio">
                      <div className="radio-text start-text">
                        {x[langKey].radio.startText}
                      </div>
                      <Radio.Group className="radio-buttons">
                        <Radio.Button value="1">
                          <SurveyRocket />1
                        </Radio.Button>
                        <Radio.Button value="2">
                          <SurveyRocket />2
                        </Radio.Button>
                        <Radio.Button value="3">
                          <SurveyRocket />3
                        </Radio.Button>
                        <Radio.Button value="4">
                          <SurveyRocket />4
                        </Radio.Button>
                        <Radio.Button value="5">
                          <SurveyRocket />5
                        </Radio.Button>
                      </Radio.Group>
                      <div className="radio-text end-text">
                        {x[langKey].radio.endText}
                      </div>
                    </div>
                  </FormItem>
                );
              } else {
                return (
                  <FormItem
                    className="survey-question"
                    key={x._id}
                    label={ReactHtmlParser(x[langKey].description)}
                    name={ReactHtmlParser(x[langKey].description)}
                    rules={[
                      {
                        required: !x[langKey].optional,
                        message: t("common:select-answer2"),
                      },
                    ]}
                  >
                    <TextArea />
                  </FormItem>
                );
              }
            })}
          </Form>
        ) : null}
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="signIn"
          text={t("common:landing-back")}
          customBtn={true}
          onClick={() => {
            history.goBack();
          }}
        />
        <Button
          additionalStyle="buttonMakeAcc"
          text={t("common:landing-continue")}
          customBtn={true}
          onClick={() => {
            onFinish();
            playSound(likeAudio);
          }}
        />
      </div>
      <div className="imageOnHover">
        <div className="onHoverText">
          <span>{t("common:init-expl")}</span>
        </div>
        <div className="nd">
          <InitP />
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  inject("userStore", "soundsStore", "progressBarStore")(observer(InitSurvey))
);
