import React, { useState, useEffect } from "react";
import "./survey.component.style.less";
import Button from "../../../components/Button/button.component";
import { inject } from "mobx-react";
import { Form, Input, Radio } from "antd";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import {
  setLocalStorage,
  getLocalStorage,
  USER_PROFILE_ESG,
  BUDGET,
  COMPANY_INFO,
} from "../../../localStorageMobxStore/localStorageUserProfile";
import EsgService from "../../../services/EsgServices/esg.service";
import SequenceService from "../../../services/EsgServices/sequence.service";
import FormItem from "antd/lib/form/FormItem";
import ReactHtmlParser from "react-html-parser";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { SurveyRocket, InitP } from "../../../assets/images/index";

const { TextArea } = Input;
/**
 * End survey component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.List of questions and check boxes.For each question title of question and input for answer of question.
 * And two buttons : Contiue button after answering all question/selecting all chekcboxes that
 * redirect him to next page  select mode and
 * Back button for going back to previous page.
 */
const EndSurvey = ({ history, userStore, setSurveyCompleted }) => {
  const { t } = useTranslation("common");
  const [userEsg, setUserEsg] = useState(null);
  const [budget, setBudget] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [form] = Form.useForm();
  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService();
  /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
   * service where are esg services sequnce methods for calling api's are stored.
   */
  const sequenceService = new SequenceService();
  useEffect(() => {
    const userProfile = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG;
    setUserEsg(userProfile);
    setBudget(getLocalStorage(BUDGET).BUDGET);
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO);
    esgService.getSurveys().then((res) => {
      setSurvey(res.data[1]);
    });
  }, []);
  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget);
    }
  }, [userEsg, companyInfo, budget]);
  /**
   * Function that takes three parametars userEsg ,companyInfo , budget
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget properties for user ingame budget
   * @param {Object} companyInfo properties for ingame company that user selected
   * @returns Updating userStore properties(userInfoEsg ,companyInfo, budget) state with new updated value
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg);
    userStore.updateCompanyInfo(companyInfo);
    userStore.updateBudget(budget);
  };
  /**
   * OnFinish function is call on continue button when user enter answer for all survey questions
   * and select all checkboxex.
   * First need to pass form validation field (that all fields(answers of questions) have input).
   * After calling esgService with postSurvey method sequenceService is called with method
   * resetGame local storage is cleared mobix store budget and company info are stored to null.
   * Local storage USER_PROFILE_ESG is updated with value userEsg;
   * Last redirect is call and rout user to selectmode page.
   */
  const onFinish = () => {
    form.validateFields().then((values) => {
      const arrayValues = Object.keys(values).map((x) => values[x]);
      let responses = [];
      arrayValues.forEach((x, i) => {
        responses.push({
          questionId: survey.questions[i]._id,
          answer: x,
        });
      });
      const data = {
        userId: userEsg._id,
        surveyId: survey._id,
        responses: responses,
      };
      esgService.postSurvey(data).then((res) => {
        setSurveyCompleted(false);
        // const companyId = companyInfo.id
        // const budgetId = budget.budget._id
        // sequenceService.resetGame(companyId, budgetId).then((response) => {
        //   setSurveyCompleted(false)
        // })
      });
    });
    // history.push('meetmentor')
  };

  const langKey = i18n.language ?? "en";

  return (
    <div className="survey-to app">
      <div className="survey-to-complete">
        <h2>{t("common:end-survey")}</h2>
        {survey && survey.questions.length > 0 ? (
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            {survey.questions.map((x) => {
              if (x[langKey].radio) {
                if (x[langKey].radio.values) {
                  return (
                    <FormItem
                      className="survey-question"
                      key={x._id}
                      label={ReactHtmlParser(x[langKey].description)}
                      name={ReactHtmlParser(x[langKey].description)}
                      rules={[
                        {
                          required: true,
                          message: t("common:select-answer"),
                        },
                      ]}
                    >
                      <div className="radio">
                        <Radio.Group
                          style={{ justifyContent: "space-around" }}
                          className="radio-buttons"
                        >
                          {x[langKey].radio.values.map((x) => {
                            return (
                              <div className="row">
                                <Radio.Button value={x}>
                                  <SurveyRocket />
                                </Radio.Button>
                                <div className="radio-text-row start-text">
                                  {x}
                                </div>
                              </div>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </FormItem>
                  );
                }
                return (
                  <FormItem
                    className="survey-question"
                    key={x._id}
                    label={ReactHtmlParser(x[langKey].description)}
                    name={ReactHtmlParser(x[langKey].description)}
                    rules={[
                      {
                        required: true,
                        message: t("common:select-answer"),
                      },
                    ]}
                  >
                    <div className="radio">
                      <div className="radio-text start-text">
                        {x[langKey].radio.startText}
                      </div>
                      <Radio.Group className="radio-buttons">
                        <Radio.Button value="1">
                          <SurveyRocket />1
                        </Radio.Button>
                        <Radio.Button value="2">
                          <SurveyRocket />2
                        </Radio.Button>
                        <Radio.Button value="3">
                          <SurveyRocket />3
                        </Radio.Button>
                        <Radio.Button value="4">
                          <SurveyRocket />4
                        </Radio.Button>
                        <Radio.Button value="5">
                          <SurveyRocket />5
                        </Radio.Button>
                      </Radio.Group>
                      <div className="radio-text end-text">
                        {x[langKey].radio.endText}
                      </div>
                    </div>
                  </FormItem>
                );
              } else {
                return (
                  <FormItem
                    className="survey-question"
                    key={x._id}
                    label={ReactHtmlParser(x[langKey].description)}
                    name={ReactHtmlParser(x[langKey].description)}
                    rules={[
                      {
                        required: true,
                        message: t("common:select-answer2"),
                      },
                    ]}
                  >
                    <TextArea />
                  </FormItem>
                );
              }
            })}
          </Form>
        ) : null}
      </div>
      <div className="imageOnHover">
        <div className="onHoverText">
          <span>{t("common:init-expl")}</span>
        </div>
        <div className="nd">
          <InitP />
        </div>
      </div>
      <div className="continue-button">
        <Button
          additionalStyle="buttonMakeAcc"
          text={t("common:landing-continue")}
          customBtn={true}
          onClick={() => {
            onFinish();
          }}
        />
      </div>
    </div>
  );
};
export default withRouter(inject("userStore")(observer(EndSurvey)));
