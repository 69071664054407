import { observable, action } from "mobx";

export class UserProfile {
    @observable activeIndex = 1;

    @action setActiveIndex = (value) => {
        this.activeIndex = value
    };
}

export default new UserProfile();
