import React, { useState, useEffect } from 'react';
import "./leaderboardAdmin.component.style.less";
import EsgService from '../../../../services/EsgServices/esg.service';
import { Coins } from '../../../../assets/images/index'
import { useTranslation } from "react-i18next"

/**
 * LeaderBoard component
 * @class
 * @returns This component is used in AdminPanel component to get 
 * all players where their total point from multiplayer mode can be seen.
 */
const LeaderBoard = () => {
    const { t } = useTranslation("admin")
    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()
    const [players, setPlayers] = useState(null)

    useEffect(() => {
        esgService.getLeaders().then((res) => {
            const allPlayers = res.data
            setPlayers(allPlayers)
        })
    }, [])


    return (
        <div className="leaderboard">
            <div className="table-top-players">
                <div className="thead">
                    <p className="t-large">{t("admin:leaderboard.user")}</p>
                    <p className="t-medium">{t("admin:leaderboard.points")}</p>
                </div>

                {players && players.map((x, index) => {
                    return (
                        <div className="players-list" key={x._id}>
                            <p><span className="number-list">{index + 1}</span>{x.email}</p>
                            <p className="right-links">
                                <span>{x.points} <Coins /></span>
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default LeaderBoard;