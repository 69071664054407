import React from 'react';
import './startupFile.component.style.less';
import { LeftBubleEnd, RightBubleEnd } from '../../../assets/images/index';
import Button from '../../../components/Button/button.component';

const StartupFile = () => {

  return (
    <div className="game-finish app">
      <div>
        <LeftBubleEnd />
        <RightBubleEnd />
      </div>
      <div className="game-finish-complete">
        <h2>Download file to see how you can start your own startup</h2>
        <div className="game-finish-holder">
          <Button
            additionalStyle="buttonMakeAcc"
            text="Download file"
            customBtn={true}
          // onClick={gameFinished}
          />
        </div>
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="buttonMakeAcc"
          text="Start New Game"
          customBtn={true}
        // onClick={gameFinished}
        />
      </div>
    </div>
  );
};

export default StartupFile;
