import React, { useState } from 'react'
import Modal from '../modal.component'
import { Coins } from '../../../assets/images/index';
import './modalReset.component.style.less'
import Button from '../../Button/button.component'


const ModalReset = ({ imageUrl, visible, restartGame }) => {

    return (
        <Modal
            modalStyleTest='modal-content-loan'
            closable={false}
            footer={true}
            additionalStyle="signUp"
            visible={visible}
        >
            <div className="contentImage">
                <Coins />
            </div>
            <div className="contentHeader"><h3>Upps! You lost all the coins!</h3></div>
            {/* <div className="contentDescription">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</div> */}
            <div className="button-landing">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text="Start new game"
                    customBtn={true}
                    onClick={() => {
                        restartGame();
                    }}
                />
            </div>
        </Modal>
    )
}

export default ModalReset