import React, { useState, useEffect } from "react";
import "./siderAdmin.component.style.less";
import { Layout, Menu } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingFilled,
    VideoCameraFilled,
    CalendarFilled,
    InfoCircleFilled,
    TrophyFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Header, Sider } = Layout;

/**
 * SiderBar component
 * @class
 * @param {Object} properties (setSelectedContent)
 * @returns Menu with clickable items: Game Statistic, All Players, Top 50 Players, Business Plan, Leaderboard.
 */
const SiderBar = ({ setSelectedContent }) => {
    const { t } = useTranslation("admin");
    const [collapsed, setCollapsed] = useState(false);

    /**
     * toggle function that collapsing and opening SideBar 
     */
    const toggle = () => {
        setCollapsed(!collapsed);
    };

    /**
     * Function Click with one parametar value 
     * after a click on the menu item, it will select it and make as active
     * @param {Object} value properties thas is used in function as menu item
     */
    const click = (value) => {
        setSelectedContent(value);
    };

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo">
                    <Header
                        className="site-layout-background"
                        style={{ padding: 0 }}
                    >
                        {React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger",
                                onClick: toggle,
                            }
                        )}
                    </Header>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys="1">
                    <Menu.Item
                        key="1"
                        icon={<SettingFilled />}
                        onClick={() => click("1")}
                    >
                        {t("admin:sidebar.stats")}
                    </Menu.Item>
                    <Menu.Item
                        key="2"
                        icon={<InfoCircleFilled />}
                        onClick={() => click("2")}
                    >
                        {t("admin:sidebar.players")}
                    </Menu.Item>
                    <Menu.Item
                        key="3"
                        icon={<VideoCameraFilled />}
                        onClick={() => click("3")}
                    >
                        {t("admin:sidebar.top-players")}
                    </Menu.Item>
                    <Menu.Item
                        key="4"
                        icon={<CalendarFilled />}
                        onClick={() => click("4")}
                    >
                        {t("admin:sidebar.plan")}
                    </Menu.Item>
                    <Menu.Item
                        key="5"
                        icon={<TrophyFilled />}
                        onClick={() => click("5")}
                    >
                        {t("admin:sidebar.leaderboard")}
                    </Menu.Item>
                </Menu>
            </Sider>
        </Layout>
    );
};

export default SiderBar;
