import React, { useState, useEffect } from 'react'
import Question from './Question/question.component'
import {
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage,
    RANDOM_EVENT_VALUE,
    RANDOM_EVENT_MODAL,
    INCREMENT_CURRENT_QUESTION_INDEX,
    SELECTED,
    TIMES_UP,
    SEQUENCE_PROGGRES,
    BUDGET,
    USER_PROFILE_ESG,
    ANSWER_MODAL,
    SEQUENCE_INDEX
} from '../../../../localStorageMobxStore/localStorageUserProfile'
import SequnceService from '../../../../services/EsgServices/sequence.service'
import { modeGame } from '../../../../helperFunctions/helperFunctions'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { budgetUpdateHeplfunc, checkSequnceState,randomEventsHp } from '../../../../helperFunctions/helperFunctions'
import { Offline } from '../../../../assets/images/index.js'
import openNotification from '../../../../components/Notification/notifications.component'
import { useTranslation } from 'react-i18next'


// pwa
import { openDB } from "idb";

let dbPromise;
let swRegistered = false;

// Define indexDB
if ("indexedDB" in window) {
    dbPromise = openDB("esgDB");
};
/**
 * Sequnce component
 * @class 
 * @param {Object} properties ( history, sequence, setSequence, userStore, companyInfo, setSequnceProgres, setSequenceIndex, budget, setBudget, setCurrentQuestionIndex, currentQuestionIndex)
 * @returns Question compoent
 */
const Quiz = ({ history, sequence, setSequence, userStore, companyInfo, setSequnceProgres, setSequenceIndex, budget, setBudget, setCurrentQuestionIndex, currentQuestionIndex }) => {
    // const [timeStoped, setTimerStoped] = useState(false)
    const [randomEventModal, setRandomEventModal] = useState(false)
    const [answerValue, setAnswerValue] = useState(null)
    const [endGameModal, setEndGameModal] = useState(false)
    const [answerCorrectnessModal, setAnswerCorrectnessModal] = useState(false)
    const [mentorUsed, setMentorUsed] = useState(false)
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
     * service where are esg services sequnce methods for calling api's are stored.
     */
    const sequnceService = new SequnceService()
    const { t } = useTranslation(['offlineNotification'])

    useEffect(() => {
        //Check in case if local storage is deleted 
        if (localStorage.length === 0) {
            history.push('/selectmode')
        } else {
            //Random event modal visible
            const res = getLocalStorage(RANDOM_EVENT_MODAL).RANDOM_EVENT_MODAL
            setRandomEventModal(res)
            //Answer value from question
            const resAnswerValue = getLocalStorage(SELECTED).SELECTED
            //Check if answer have have random event
            if (resAnswerValue && resAnswerValue.hasOwnProperty('randomEvent')) {
                setAnswerValue(resAnswerValue)
            }
        };

        // pwa
        navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations.length > 0) {
                swRegistered = true;
            }
        });

        // If we are onlline and if equence_index is 1 | if we came online in second sequence
        if(navigator.onLine) {
            const sequence_index = getLocalStorage(SEQUENCE_INDEX).SEQUENCE_INDEX;
            const gameMode = modeGame(budget);

            if(sequence_index === 1) {

                const fetchNewSequence = async (sequenceNr, gameMode) => {
                    const data = {
                        sequenceNrs: [sequenceNr, sequenceNr + 1]
                    }
                
                    //Array of two ids of sequnces
                    const ids = await sequnceService.getIdsOfSequnces(data)
                                
                    const firstSequnceId = ids.data[0]._id
                
                    const firstSequencePromise = sequnceService.getSequnce(firstSequnceId)
                    const firstSequence = (await firstSequencePromise).data;
                    setSequence(firstSequence)
                    // setCurrentQuestionIndex(0)
                    // setLocalStorage('quiz', SEQUENCE_PROGGRES)
                    // setSequnceProgres('quiz')
                
                    let secondSequence;
                
                    if (ids.data[1]) {
                        const secondSequnceId = ids.data[1]._id;
                        const secondSequencePromise = sequnceService.getSequnce(secondSequnceId)
                        secondSequence = (await secondSequencePromise).data;
                    };
                
                    if ('indexedDB' in window && swRegistered) {
                        // create firstSequence in indexDB
                        dbPromise
                        .then((db) => {
                            const tx = db.transaction('sequence', "readwrite");
                            const store = tx.objectStore('sequence');
                            store.put(firstSequence, 'firstSequence');
                            return tx.done;
                        })
                        .then(() => {
                            dbPromise
                            .then((db) => {
                                const tx = db.transaction('progres', "readwrite");
                                const store = tx.objectStore('progres');
                                store.put(firstSequence, 'progres');
                                return tx.done;
                            })
                        })
                
                        // create secondSequence in indexDB
                        if (secondSequence) {
                        dbPromise
                            .then((db) => {
                            const tx = db.transaction('sequence', "readwrite");
                            const store = tx.objectStore('sequence');
                            store.put(secondSequence, 'secondSequence');
                            return tx.done;
                            });
                        }  else {
                            dbPromise
                            .then((db) => {
                              const tx = db.transaction('sequence', "readwrite");
                              const store = tx.objectStore('sequence');
                              return store.delete('secondSequence');
                            })
                        }
                    };
                };

                // Get secondSequence data from indexDB
                dbPromise
                .then(db => {
                  const tx = db.transaction('sequence', 'readonly');
                  const store = tx.objectStore('sequence');
                  return store.get('secondSequence');
                })
                .then(data => {
                    setSequenceIndex(0)
                    setLocalStorage(0, SEQUENCE_INDEX)
                    fetchNewSequence(data.number ,gameMode)
                })

            };

        }

    }, [])
    /**
     * Game Over function set state of endGameModal to true and answerCorrectnessModal to false
     */
    const gameOver = () => {
        // setTimerStoped(true)
        setEndGameModal(true)
        setAnswerCorrectnessModal(false)
    }
    const gameOverRanEv = () => {
        // setTimerStoped(true)
        setEndGameModal(true)
        setRandomEventModal(false)

    }
    /**
     * Restart game function calls sequnceService with method resetGame and pass two parameter companyId and budgetId .
     * After response from backend come. LocalStorage is cleared.Mobix userStore budget and companyinfo states are set to null.
     * LocalStorage USER_PROFILE_ESG is updated res value . User is redirected to selectmode route.
     */
    const restartGame = () => {
        const companyId = companyInfo.id
        const budgetId = budget.budget._id
        // setTimerStoped(false)
        setEndGameModal(false)
        sequnceService.resetGame(companyId, budgetId).then((response) => {
            const res = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
            localStorage.clear()
            userStore.updateCompanyInfo(null)
            userStore.updateBudget(null)
            setLocalStorage(res, USER_PROFILE_ESG)
            history.push('/selectmode')
        })
    }
    /**
     * Loan Chosen function.First two new variables are created sequnceNr and budgetId. SequnceService is called with method
     * takeLoan and two parameters are passed budgetId and sequnceNr . After response come from backend budgeObj is updated 
     * with new values.Local storage BUDGET is updated with budgetObj and mobix userStore budget is updated with budgetObj.
     * EndGameModal state is updated to false . ANSWER_MODAL is removed from local storage and answerValue state is set to false.
     */
    const loanChosen = () => {
        // let incrementCurrentQuestionIndex = currentQuestionIndex + 1
        const sequnceNr = sequence.number
        const budgetId = budget.budget._id
        sequnceService.takeLoan(budgetId, sequnceNr).then((res) => {
            let budgetObj = Object.assign(budget, {})
            budgetObj.budget.remaining = res.data.currentBudget
            budgetObj.budget.loan = res.data.loanAmount
            setLocalStorage(budgetObj, BUDGET)
            userStore.updateBudget(budgetObj)
            // setTimerStoped(true)
            setEndGameModal(false)

            removeLocalStorage(ANSWER_MODAL)
            // setRandomEventModal(false)
            setAnswerValue(false);

            // checkSequnceState(currentQuestionIndex, quiz, setLocalStorage, INCREMENT_CURRENT_QUESTION_INDEX,
            //     sequence, setSequnceProgres, SEQUENCE_PROGGRES, setCurrentQuestionIndex)
        })
    }
    /**
     * Method for handling switch to next question,also includes service for posting answer to backend
     * @param {Object} value Answer choosen value
     * @param {Boolean} mentorUsed Mentor used or not
     * @param {String} questionId Question id
     */
    const hanleNextQuestion = (value = null, mentorUsed = false, questionId) => {
        const gameMode = modeGame(budget);
        //Check if value is exist and value have random event 
        const specialRandomEvent = randomEventsHp(sequence,value,currentQuestionIndex,companyInfo)
        if (value && value.randomEvent.length > 0 && specialRandomEvent) {
            //Set random event value and random event modal visible to local storage and state
            const data = {
                budgetId: budget.budget._id,
                questionId: questionId,
                answerGiven: value._id,
                mentorUsed,
                sequenceId: sequence.id,
                gameMode
            };

            const answer = data;
            const answerPoints = value.points;

            if((budget.budget.remaining + value.points) <= 0) {
                if(navigator.onLine) {
                    // if you want to display modal after refresh, uncomment this line below
                    // setLocalStorage(true, END_GAME_MODAL)
                    setLocalStorage(true, ANSWER_MODAL)
                    setAnswerCorrectnessModal(true);
                    gameOver()
                } else {
                    setLocalStorage(true, ANSWER_MODAL)
                    setAnswerCorrectnessModal(true);
                    openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                }
            } else {
                /* Answer and random event */
                if ("serviceWorker" in navigator && "SyncManager" in window && swRegistered && !navigator.onLine) {
                    navigator.serviceWorker.ready.then((sw) => {
                        if ("sync_requests") {
                            let budgetObj = Object.assign(budget, {});
                            const remainingBudget = budgetObj.budget.remaining + answerPoints;

                            if (remainingBudget <= 0) {
                                if(navigator.onLine) {
                                    gameOver();
                                } else {
                                    openNotification(<div><Offline /></div>,<div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                                }
                            } else {
                                // pwa - add anwers in indexDB
                                dbPromise.then((db) => {
                                    const tx = db.transaction('sync_requests', "readonly");
                                    const store = tx.objectStore('sync_requests');
                                    return store.getAll();
                                })
                                .then(data => {
                                    dbPromise
                                    .then(db => {
                                        const tx = db.transaction('sync_requests', "readwrite");
                                        const store = tx.objectStore('sync_requests');
                                        store.put(answer, data.length + 1);
                                        return tx.done;
                                    })
                                    .then(() => {
                                        return sw.sync.register("sync_requests");
                                    })
                                    .then(() => {
                                        
                                    })
                                    .then(() => {
                                        // budget update 
                                        let budgetObj = Object.assign(budget, {})
                                        budgetObj.budget.remaining = (budgetObj.budget.remaining + answerPoints)
                                        // setBudget(budgetObj.budget.remaining);
                                        setLocalStorage(budgetObj, BUDGET)
                                        userStore.updateBudget(budgetObj)

                                        setLocalStorage(value.randomEvent, RANDOM_EVENT_VALUE)
                                        setLocalStorage(true, RANDOM_EVENT_MODAL)

                                        // setTimerStoped(true)
                                        setAnswerValue(value)
                                        if(budgetObj.budget.remaining <= 0) {
                                            setAnswerCorrectnessModal(true)
                                        } else {
                                            setLocalStorage(false, ANSWER_MODAL)
                                            setAnswerCorrectnessModal(false)
                                        };
                                        // setRandomEventModal(true)
                                    })
                                    .catch(err => console.log('sync err ==>', err))
                                })
                            }
                        }
                    })
                } else {
                    // if((budget.budget.remaining + value.points) <= 0){
                    //     gameOver()
                    // }else {
                    sequnceService.answerQuestion(data).then((res) => {
                        budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
                        // setLocalStorage(value.randomEvent, RANDOM_EVENT_VALUE)
                        // setLocalStorage(true, RANDOM_EVENT_MODAL)
                        // // setTimerStoped(true)
                        // setAnswerValue(value)
                        // setAnswerCorrectnessModal(false)
                        // setRandomEventModal(true)
                    })
                    // }
                }
            }
        } else {
            const gameMode = modeGame(budget);
            //Check if user answered on question or not
            if (value) {
                const data = {
                    budgetId: budget.budget._id,
                    questionId: questionId,
                    answerGiven: value._id,
                    mentorUsed,
                    sequenceId: sequence.id,
                    gameMode
                };

                const answer = data;
                const answerPoints = value.points;

                if((budget.budget.remaining + value.points) <= 0){
                    if(navigator.onLine) {
                    // if you want to display modal after refresh, uncomment this line below
                    // setLocalStorage(true, END_GAME_MODAL)
                    gameOver()
                    } else {
                        openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                    }
                } else {
                    // pwa
                    if ("serviceWorker" in navigator && "SyncManager" in window && swRegistered && !navigator.onLine) {
                        navigator.serviceWorker.ready.then((sw) => {
                            if ('sync_requests') {
                                let budgetObj = Object.assign(budget, {});
                                const remainingBudget = budgetObj.budget.remaining;

                                if (remainingBudget <= 0) {
                                    if(navigator.onLine) {
                                        gameOver();
                                    } else {
                                        openNotification(<div><Offline /></div>,<div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                                    }
                                } else {
                                    // pwa - add anwers in indexDB
                                    dbPromise.then((db) => {
                                        const tx = db.transaction('sync_requests', "readonly");
                                        const store = tx.objectStore('sync_requests');
                                        return store.getAll();
                                    })
                                    .then(data => {
                                        dbPromise
                                            .then(db => {
                                                const tx = db.transaction('sync_requests', "readwrite");
                                                const store = tx.objectStore('sync_requests');
                                                store.put(answer, data.length + 1);
                                                return tx.done;
                                            })
                                            .then(() => {
                                                return sw.sync.register("sync_requests");
                                            })
                                            .then(() => {
                                                
                                            })
                                            .then(() => {
                                                // budget update
                                                let budgetObj = Object.assign(budget, {})
                                                budgetObj.budget.remaining = (budgetObj.budget.remaining + answerPoints)
                                                setLocalStorage(budgetObj, BUDGET)
                                                userStore.updateBudget(budgetObj)
                                                //Set current question index 
                                                // setCurrentQuestionIndex(incrementCurrentQuestionIndex)
                                                // setLocalStorage(incrementCurrentQuestionIndex, INCREMENT_CURRENT_QUESTION_INDEX)
                                                checkSequnceState(currentQuestionIndex, quiz, setLocalStorage, INCREMENT_CURRENT_QUESTION_INDEX,
                                                    sequence, setSequnceProgres, SEQUENCE_PROGGRES, setCurrentQuestionIndex)
                                            })
                                            .catch(err => console.log('sync err ==>', err))
                                    })
                                }
                            }
                        })
                    } else /* if browser doesn't support SyncManager and ServiceWorker*/ {
                        // if((budget.budget.remaining + value.points) <= 0){
                        //     gameOver()
                        // }else {
                            sequnceService.answerQuestion(data).then((res) => {
                                budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
                                checkSequnceState(currentQuestionIndex, quiz, setLocalStorage, INCREMENT_CURRENT_QUESTION_INDEX,
                                    sequence, setSequnceProgres, SEQUENCE_PROGGRES, setCurrentQuestionIndex)
                            })
                        // }
                    }
                }
            }
        }
        //Setting mode in what user play for backend 
    }
    /**
     * Submit random event value to bakcend alse we remove random event modal visible,
     * randome event value from local storage and local state and we set new
     * value of increment current question index + 1
     * @param {Object} value Random event value 
     */
    const submitRandomEvent = (value) => {
        const data = {
            budgetId: budget.budget._id,
            randomEventId: value._id,
        };

        // pwa
        const randomEventData = data;
        const randomEventPoints = value.points;

        // hanleNextQuestion(answerValue, mentorUsed, question._id);


        // Answer data start --->
        const gameMode = modeGame(budget);
        const questionId = question._id;

        const answerData = {
            budgetId: budget.budget._id,
            questionId: questionId,
            answerGiven: answerValue._id,
            mentorUsed,
            sequenceId: sequence.id,
            gameMode
        };

        const answerPoints = answerValue.points;
        // Answer data end <----

        if((budget.budget.remaining + answerValue.points) <= 0){
            if(navigator.onLine) {
                // if you want to display modal after refresh, uncomment this line below
                // setLocalStorage(true, END_GAME_MODAL)
                gameOver();
                setRandomEventModal(false);
                setLocalStorage(false, RANDOM_EVENT_MODAL);

            } else {
                openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
            }
        } else if((budget.budget.remaining + value.points) <=0){
            if(navigator.onLine){
                openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                setRandomEventModal(false);
                setLocalStorage(false, RANDOM_EVENT_MODAL);
            } else {
                openNotification(<div><Offline /></div>, <div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
            }
        } else {            
            if ("serviceWorker" in navigator && "SyncManager" in window && swRegistered && !navigator.onLine) {
                navigator.serviceWorker.ready.then((sw) => {
                    if ('sync_requests') {
                        if ('indexedDB' in window) {
                            // Answer part start --->
                            dbPromise.then((db) => {
                                const tx = db.transaction('sync_requests', "readonly");
                                const store = tx.objectStore('sync_requests');
                                return store.getAll();
                            })
                            .then(data => {
                                dbPromise
                                .then(db => {
                                    const tx = db.transaction('sync_requests', "readwrite");
                                    const store = tx.objectStore('sync_requests');
                                    store.put(answerData, data.length + 1);
                                    return tx.done;
                                })
                                .then(() => {
                                    return sw.sync.register("sync_requests");
                                })
                                .then(() => {
                                    
                                })
                                .then(() => {
                                    // budget update 
                                    let budgetObj = Object.assign(budget, {})
                                    budgetObj.budget.remaining = (budgetObj.budget.remaining + answerPoints)
                                    // setBudget(budgetObj.budget.remaining);
                                    setLocalStorage(budgetObj, BUDGET)
                                    userStore.updateBudget(budgetObj)
            
                                    setLocalStorage(answerValue.randomEvent, RANDOM_EVENT_VALUE)
                                    setLocalStorage(true, RANDOM_EVENT_MODAL)
            
                                    // setTimerStoped(true)
                                    setAnswerValue(answerValue)
                                    if(budgetObj.budget.remaining <= 0) {
                                        setAnswerCorrectnessModal(true)
                                    } else {
                                        setLocalStorage(false, ANSWER_MODAL)
                                        setAnswerCorrectnessModal(false)
                                    };
                                    // setRandomEventModal(true)
                                })
                                .catch(err => console.log('sync err ==>', err))
                            })
                            // Answer part end <---

                            // pwa add radnom events
                            .then(() => {
                                dbPromise
                                .then((db) => {
                                    const tx = db.transaction('sync_requests', "readonly");
                                    const store = tx.objectStore('sync_requests');
                                    return store.getAll();
                                })
                                .then(data => {
                                    dbPromise
                                        .then(db => {
                                            const tx = db.transaction('sync_requests', "readwrite");
                                            const store = tx.objectStore('sync_requests');
                                            store.put(randomEventData, data.length + 1);
                                            return tx.done;
                                        });
                                })
                                .then(() => {
                                    return sw.sync.register("sync_requests");
                                })
                                .then(() => {
                                    
                                })
                                .then(() => {
                                    let budgetObj = Object.assign(budget, {});
                                    const remainingBudget = budgetObj.budget.remaining + randomEventPoints;
    
                                    if (remainingBudget < 0) {
                                        if(navigator.onLine) {
                                            gameOver();
                                        } else {
                                            openNotification(<div><Offline /></div>,<div><p><b>{t("offlineNotification:lowBudget.title")}</b></p></div>, <div><p>{t("offlineNotification:lowBudget.content")}</p></div>);
                                        }
                                    } else {
                                        budgetObj.budget.remaining = remainingBudget;
                                        setLocalStorage(budgetObj, BUDGET);
                                        userStore.updateBudget(budgetObj);
                                        removeLocalStorage(RANDOM_EVENT_MODAL, RANDOM_EVENT_VALUE, TIMES_UP, SELECTED);
                                        setRandomEventModal(false);
                                        // setTimerStoped(false);
                                        setAnswerValue(null);
                                        checkSequnceState(currentQuestionIndex, quiz, setLocalStorage, INCREMENT_CURRENT_QUESTION_INDEX,
                                            sequence, setSequnceProgres, SEQUENCE_PROGGRES, setCurrentQuestionIndex)
                                        /**
                                         * Check if index of current question is equal of quiz questions lenghth.
                                         * If it is redirect user to bmc if exist in sequence if on lead user to 
                                         * mountly event . If not increment current question index.
                                         */
                                    }
                                })
                            })
                            
                            
                        }
                    }
                })
            } else {
                //--Here we need to create service for posting random event to backend
                // if((budget.budget.remaining + value.points)<=0){
                //     gameOverRanEv()
                // }else{
                    hanleNextQuestion(answerValue, mentorUsed, question._id);
                    sequnceService.postRandomEvent(data).then((res) => {
                        budgetUpdateHeplfunc(res, budget, setLocalStorage, BUDGET, userStore)
                        removeLocalStorage(RANDOM_EVENT_MODAL, RANDOM_EVENT_VALUE, TIMES_UP, SELECTED)
                        setRandomEventModal(false)
                        // setTimerStoped(false)
                        setAnswerValue(null)
    
                        checkSequnceState(currentQuestionIndex, quiz, setLocalStorage, INCREMENT_CURRENT_QUESTION_INDEX,
                            sequence, setSequnceProgres, SEQUENCE_PROGGRES, setCurrentQuestionIndex)
                    })
                // }
            };
        }
    }
    const { quiz, industryQuestions } = sequence
    //Current question find by current question index
    /**
     * Current Question function. Check current index of question from questions array
     * @returns regular question or industry question
     */
    const currentQuestion = () => {
        if(quiz.questions[currentQuestionIndex]) {
            return quiz.questions[currentQuestionIndex]?.question
        } else if(industryQuestions) {
            return industryQuestions[0]
        }
    }

    const question = currentQuestion();

    return (
        <div>
            {question ?
                <Question
                    sequence={sequence}
                    quiz={quiz}
                    question={question}
                    currentQuestionIndex={currentQuestionIndex}
                    hanleNextQuestion={hanleNextQuestion}
                    randomEventModal={randomEventModal}
                    submitRandomEvent={submitRandomEvent}
                    setSequnceProgres={setSequnceProgres}
                    setAnswerValue={setAnswerValue}
                    // timeStoped={timeStoped}
                    // setTimerStoped={setTimerStoped}
                    setMentorUsed={setMentorUsed}
                    mentorUsed={mentorUsed}
                    answerValue={answerValue}
                    endGameModal={endGameModal}
                    restartGame={restartGame}
                    loanChosen={loanChosen}
                    budget={budget}
                    setAnswerCorrectnessModal={setAnswerCorrectnessModal}
                    answerCorrectnessModal={answerCorrectnessModal}
                    companyInfo={companyInfo}
                    setRandomEventModal={setRandomEventModal}
                    answerValue={answerValue}
                /> : null}
        </div>
    )
}

export default withRouter(inject('userStore')(observer(Quiz)))
