export function register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('service-worker.js')
        .then((registration) => {
          
        })
        .catch((err) => {
          console.log('Failure: ', err)
        });
      })
    };
  }