import React, { useState, useEffect } from 'react';
import "./bussinesAdmin.component.style.less";
import EsgService from '../../../../services/EsgServices/esg.service';
import { useTranslation } from "react-i18next"

/**
 * BussinesAdmin component
 * @class
 * @returns This component is used in AdminPanel component to get 
 * all player's business plans of the players that are uploaded and display them.
 */
const BussinesAdmin = () => {
    const { t } = useTranslation("admin")

    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()


    /** useRef can be used keep track of variables without causing re-render of the component. 
    * And give a solution on how to enforce re-render without affecting the performance of a component.
    */
    const isReady = React.useRef(false);
    const [datas, setDatas] = useState(null)

    useEffect(() => {
        fetch();
        return () => {
            isReady.current = false;
        };
    }, [])

    /**
     *Function that is used in component did mount to get all necessary data that we need to display
     - all players with their bussines models
    */
    const fetch = () => {
        esgService.getBussinesModels().then((res) => {
            if (!isReady.current) {
                setDatas(res.data)
            }
        });
    }

    return (
        <div className='bussines-plan' >
            <h2>{t("admin:bussines-plan.name")}</h2>
            <div>
                {datas ? datas.map((x) => {
                    return (
                        <div className="bpHolder">
                            <div className="userName">
                                <p> {x.firstName}</p>
                            </div>
                            <div className="userName">
                                <a target="_blank" href={x.businessPlanUrl}> {t("admin:bussines-plan.link")} </a>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </div>
    );
}

export default BussinesAdmin;