import React, { useState } from 'react'
import Modal from '../modal.component'
import { Loan } from '../../../assets/images/index';
import './modalLoan.component.style.less'
import Button from '../../Button/button.component'
import Animation9 from '../../../assets/video/animation9.mp4'
import Animation9BHS from '../../../assets/video/animation9BHS.mp4'
import VideoLoad from '../../../components/Video/video.component'
import { useTranslation } from 'react-i18next'

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';


const ModalLoan = ({ imageUrl, visible, restartGame, loanChosen, languageStore }) => {
    const { t } = useTranslation(['common', 'loan'])
    const [videoEnd, setVideoEnd] = useState(false)

    return (
        <div>
            {visible ?
                !videoEnd ? <VideoLoad url={languageStore.language === 'bhs' ? Animation9BHS : Animation9} setVideoEnd={setVideoEnd} /> :
                    <Modal
                        modalStyleTest='modal-content-loan'
                        closable={false}
                        footer={true}
                        additionalStyle="signUp"
                        visible={visible}
                    >
                        <div className="contentImage">
                            <Loan imageUrl={imageUrl} />
                        </div>
                        <div className="contentHeader"><h3>{t('loan:lost')}</h3></div>
                        {/* <div className="contentDescription">{t('loan:desc')}</div> */}
                        <div className="button-landing">
                            <Button
                                additionalStyle="signIn"
                                text={t('loan:start-new')}
                                customBtn={true}
                                onClick={() => {
                                    restartGame();
                                }}
                            />
                            <Button
                                additionalStyle="buttonMakeAcc"
                                text={t('loan:take-loan')}
                                customBtn={true}
                                onClick={() => {
                                    loanChosen()
                                }}
                            />
                        </div>
                    </Modal>
                : null}
        </div>
    )
}

export default (inject('languageStore')(observer(ModalLoan)));
