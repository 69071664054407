import { observable, action } from "mobx";
import i18n from 'i18next';

export class LanguageStore {

    @observable language = i18n.language ?? 'bhs';

    @action setEnglishLanguage = () => {
        this.language = 'en'
    }
    
    @action setBhsLanguage = () => {
        this.language = 'bhs'
    }
}

export default new LanguageStore()