import React, { useEffect, useState } from 'react';
import './bmcUser.component.style.less';
import { Pen } from '../../../assets/images/index'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import SequnceService from '../../../services/EsgServices/sequence.service';
import { getLocalStorage, USER_PROFILE_ESG, BUDGET, COMPANY_INFO } from '../../../localStorageMobxStore/localStorageUserProfile'
import { modeGame } from '../../../helperFunctions/helperFunctions'
import Button from '../../../components/Button/button.component'
import ModalBmc from '../../../components/Modal/ModalBMC/modalBmc.component'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import Certificate from "../Certificate/Certificate.component";

/**
 * Business Model Canvas component
 * @class
 * @param {Object} properties (history, userStore)
 * @returns Title, list of all bmc modules.
 * Three buttons Back to office , Back to game and save Bmc.
 */
const BusinessModelCanvas = ({ history, userStore }) => {
    const [userEsg, setUserEsg] = useState(null)
    const [budget, setBudget] = useState(null)
    const [companyInfo, setCompanyInfo] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bmcs, setBmcs] = useState('')
    const [keyPartners, setKeyPartners] = useState(false)
    const [keyActivities, setKeyActivities] = useState(false)
    const [keyResources, setKeyResources] = useState(false)
    const [valueProposition, setValueProposition] = useState(false)
    const [customerRelationships, setCustomerRelationships] = useState(false)
    const [channels, setChannels] = useState(false)
    const [customerSegment, setCustomerSegment] = useState(false)
    const [costStructure, setCostStructure] = useState(false)
    const [revenueStreams, setRevanueStream] = useState(false)
    const { t } = useTranslation(['sequences', 'common'])


    const handleScrollToUser = () => {
        window.scrollTo(0, 0)
    };
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
    * service where are esg services sequnce methods for calling api's are stored.
    */
    const sequnceService = new SequnceService

    useEffect(() => {
        const budget = getLocalStorage(BUDGET).BUDGET
        const companyInfo = getLocalStorage(COMPANY_INFO).COMPANY_INFO
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
        setCompanyInfo(companyInfo)
        setBudget(budget)
        const gameMode = modeGame(budget)
        const companyId = companyInfo.id
        sequnceService.getBmcs(companyId, gameMode).then((res) => {
            setBmcs(res.data)
        })
    }, [])

    useEffect(() => {
        if (userEsg || companyInfo || budget) {
            setUserStore(userEsg, companyInfo, budget)
        }
    }, [userEsg, companyInfo, budget])
    /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
    const setUserStore = (userEsg, companyInfo, budget) => {
        userStore.updateUserInfoEsg(userEsg)
        userStore.updateCompanyInfo(companyInfo)
        userStore.updateBudget(budget)
    }

    /**
     * Show modal function
     * @param {String} value Type of BMC
     * @returns updeted local state depending of bmc type and state of isModalVisible to true
     */
    const showModal = (value) => {
        switch (value) {
            case 'keyPartners':
                setKeyPartners(true)
                setIsModalVisible(true);
                break;
            case 'keyActivities':
                setKeyActivities(true)
                setIsModalVisible(true);
                break;
            case 'keyResources':
                setKeyResources(true)
                setIsModalVisible(true);
                break;
            case 'valueProposition':
                setValueProposition(true)
                setIsModalVisible(true);
                break;
            case 'customerRelationships':
                setCustomerRelationships(true)
                setIsModalVisible(true);
                break;
            case 'channels':
                setChannels(true)
                setIsModalVisible(true);
                break;
            case 'customerSegment':
                setCustomerSegment(true)
                setIsModalVisible(true);
                break;
            case 'costStructure':
                setCostStructure(true)
                setIsModalVisible(true);
                break;
            case 'revenueStreams':
                setRevanueStream(true)
                setIsModalVisible(true);
                break;
        }

    };
    /**
     * Update Bmc function
     * @param {String} value
     * @param {String} key
     * @param {Function} setStateModal
     * @param {Function} setStateBmc
     * @returns New variable data is created with properties companyId,gameMode,key,value,
     * SequnceService is called with method updateBmc data variable is passed as parameter
     * and after response come from server side state of stateBmc is set to false as stateModal
     * state is set to false
     */
    const updateBmc = (value, key, setStateModal, setStateBmc) => {
        const gameMode = modeGame(budget)
        const data = {
            companyId: companyInfo.id,
            gameMode,
            key: key,
            value: value
        }
        sequnceService.updateBmc(data).then((res) => {
            setStateBmc(false)
            setStateModal(false)
        })
    }
    /**
     * Handle Ok function
     * @param {String} value type of bmc
     * @returns updtaed Bmc state with params bmcs.keyPartners, value, setIsModalVisible,
     * setKeyPartners depending what type of bmc needs to be updated
     */
    const handleOk = (value) => {
        switch (value) {
            case 'keyPartners':
                updateBmc(bmcs.keyPartners, value, setIsModalVisible, setKeyPartners)
                break;
            case 'keyActivities':
                updateBmc(bmcs.keyActivities, value, setIsModalVisible, setKeyActivities)
                break;
            case 'keyResources':
                updateBmc(bmcs.keyResources, value, setIsModalVisible, setKeyResources)
                break;
            case 'valueProposition':
                updateBmc(bmcs.valueProposition, value, setIsModalVisible, setValueProposition)
                break;
            case 'customerRelationships':
                updateBmc(bmcs.customerRelationships, value, setIsModalVisible, setCustomerRelationships)
                break;
            case 'channels':
                updateBmc(bmcs.channels, value, setIsModalVisible, setChannels)
                break;
            case 'customerSegment':
                updateBmc(bmcs.customerSegment, value, setIsModalVisible, setCustomerSegment)
                break;
            case 'costStructure':
                updateBmc(bmcs.costStructure, value, setIsModalVisible, setCostStructure)
                break;
            case 'revenueStreams':
                updateBmc(bmcs.revenueStreams, value, setIsModalVisible, setRevanueStream)
                break;
        }
    };
    /**
     * Handle Cancel function
     * @param {String} value Type of Bmc
     * @returns updated state depending of type of bmc and state of isModalVisible to false
     */
    const handleCancel = (value) => {
        switch (value) {
            case 'keyPartners':
                setKeyPartners(false)
                setIsModalVisible(false);
                break;
            case 'keyActivities':
                setKeyActivities(false)
                setIsModalVisible(false);
                break;
            case 'keyResources':
                setKeyResources(false)
                setIsModalVisible(false);
                break;
            case 'valueProposition':
                setValueProposition(false)
                setIsModalVisible(false);
                break;
            case 'customerRelationships':
                setCustomerRelationships(false)
                setIsModalVisible(false);
                break;
            case 'channels':
                setChannels(false)
                setIsModalVisible(false);
                break;
            case 'customerSegment':
                setCustomerSegment(false)
                setIsModalVisible(false);
                break;
            case 'costStructure':
                setCostStructure(false)
                setIsModalVisible(false);
                break;
            case 'revenueStreams':
                setRevanueStream(false)
                setIsModalVisible(false);
                break;
        }
    };

    /**
     * On input change function
     * @param {Object} e event parametar
     * @returns set new value of bmcs with value of variable newValue
     */
    const onInputChange = (e) => {
        const { value } = e.target;
        const newValue = {
            ...bmcs,
            [e.target.id]: value
        }
        setBmcs(newValue)
    }
    /**
     * Function Back To Game redirect user to game rout.
     */
    const backToGame = () => {
        history.push('game')
    }

    /**
     * Function that is called on Save Bmc that ganerate BMC as pdf file
     */
    const printDocument = () => {
        const elementToDownload = document.getElementById('printWrapper');
        const divHeight = elementToDownload.clientHeight
        const divWidth = elementToDownload.clientWidth
        const ratio = divHeight / divWidth;

        html2canvas(elementToDownload, { scale: '2' }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const orientation = divWidth >= divHeight ? 'l' : 'p'

            const pdfDOC = new jsPDF({
                orientation,
                unit: 'pt', // points, pixels won't work properly
                format: [divWidth, divHeight] // set needed dimensions for any element
            });

            const width = pdfDOC.internal.pageSize.getWidth();
            let height = pdfDOC.internal.pageSize.getHeight();
            height = ratio * width;

            pdfDOC.addImage(imgData, 'PNG', 0, 0, width, height);
            pdfDOC.save('businessModelCanvas.pdf');   //Download the rendered PDF.
        })
    }



    return (
        <div className='business-model'>
            <div className="certificateHide">
                <Certificate/>
            </div>
            <div id="printWrapper">
                <h2 className="title-bmc">Business Model Canvas</h2>
                <div className="bmc">
                    <div className={!bmcs.keyPartners ? "block test-kp" : "block key-partners"} key="1">
                        <div className="click" id="keyPartners" onClick={() => showModal('keyPartners')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && keyPartners}
                            onClick={() => handleOk('keyPartners')}
                            onCancel={() => handleCancel('keyPartners')}
                            bmcValue={t('sequences:key-partners')}
                            id="keyPartners"
                            onChange={onInputChange}
                            value={bmcs.keyPartners}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:key-partners')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.keyPartners}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.keyActivities ? "block test-ka" : "block key-activities"}>
                        <div className="click" id="keyActivities" onClick={() => showModal('keyActivities')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && keyActivities}
                            onClick={() => handleOk('keyActivities')}
                            onCancel={() => handleCancel('keyActivities')}
                            bmcValue={t('sequences:activities')}
                            id="keyActivities"
                            onChange={onInputChange}
                            value={bmcs.keyActivities}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:activities')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.keyActivities}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.keyResources ? "block test-kr" : "block key-resources"}>
                        <div className="click" id="keyResources" onClick={() => showModal('keyResources')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && keyResources}
                            onClick={() => handleOk('keyResources')}
                            onCancel={() => handleCancel('keyResources')}
                            bmcValue={t('sequences:resurces')}
                            id="keyResources"
                            onChange={onInputChange}
                            value={bmcs.keyResources}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:resurces')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.keyResources}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.valueProposition ? "block test-vp" : "block value-propositions"}>
                        <div className="click" id="valueProposition" onClick={() => showModal('valueProposition')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && valueProposition}
                            onClick={() => handleOk('valueProposition')}
                            onCancel={() => handleCancel('valueProposition')}
                            bmcValue={t('sequences:value')}
                            id="valueProposition"
                            onChange={onInputChange}
                            value={bmcs.valueProposition}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:value')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.valueProposition}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.customerRelationships ? "block test-cr" : "block customer-relationships"}>
                        <div className="click" id="customerRelationships" onClick={() => showModal('customerRelationships')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && customerRelationships}
                            onClick={() => handleOk('customerRelationships')}
                            onCancel={() => handleCancel('customerRelationships')}
                            bmcValue={t('sequences:customer')}
                            id="customerRelationships"
                            onChange={onInputChange}
                            value={bmcs.customerRelationships}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:customer')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.customerRelationships}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.channels ? "block test-c" : "block channels"}>
                        <div className="click" id="channels" onClick={() => showModal('channels')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && channels}
                            onClick={() => handleOk('channels')}
                            onCancel={() => handleCancel('channels')}
                            bmcValue={t('sequences:channel')}
                            id="channels"
                            onChange={onInputChange}
                            value={bmcs.channels}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:channel')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.channels}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.customerSegment ? "block test-cseg" : "block customer-segments"}>
                        <div className="click" id="customerSegment" onClick={() => showModal('customerSegment')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && customerSegment}
                            onClick={() => handleOk('customerSegment')}
                            onCancel={() => handleCancel('customerSegment')}
                            bmcValue={t('sequences:segments')}
                            id="customerSegment"
                            onChange={onInputChange}
                            value={bmcs.customerSegment}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:segments')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.customerSegment}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.costStructure ? "block test-cst" : "block cost-structure"}>
                        <div className="click" id="costStructure" onClick={() => showModal('costStructure')}><Pen /></div>
                        <ModalBmc
                            visible={isModalVisible && costStructure}
                            onClick={() => handleOk('costStructure')}
                            onCancel={() => handleCancel('costStructure')}
                            bmcValue={t('sequences:cost')}
                            id="costStructure"
                            onChange={onInputChange}
                            value={bmcs.costStructure}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:cost')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.costStructure}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={!bmcs.revenueStreams ? "block test-rs" : "block revenue-streams"}>
                        <div className="click" id="revenueStreams" onClick={() => showModal('revenueStreams')}><Pen /></div>
                        <ModalBmc
                            title="Basic Modal"
                            visible={isModalVisible && revenueStreams}
                            onClick={() => handleOk('revenueStreams')}
                            onCancel={() => handleCancel('revenueStreams')}
                            bmcValue={t('sequences:revenue')}
                            id="revenueStreams"
                            onChange={onInputChange}
                            value={bmcs.revenueStreams}
                            disableEnforceFocus={true}
                        />
                        <h2 className="title">{t('sequences:revenue')}</h2>
                        <ul className="ingress">
                            <li>
                                <p>{bmcs.revenueStreams}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Descriptionm */}

            </div>
            <div className="bmc-description">
                <p className="hi-msg">{t('sequences:sequence-bmc')}</p>
            </div>
            <div className="button-landing">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('common:back-to-office')}
                    customBtn={true}
                    onClick={() => history.push('office')}
                />
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('common:back-game')}
                    customBtn={true}
                    onClick={backToGame}
                />
                <Button
                    id='btn'
                    additionalStyle="buttonMakeAcc"
                    text={t('common:save-bmc')}
                    customBtn={true}
                    onClick={() => { handleScrollToUser(); setTimeout(printDocument, 100); }}
                />
            </div>
        </div>
    );
};

export default withRouter(inject('userStore')(observer(BusinessModelCanvas)));
