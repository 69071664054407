import React, { useState } from 'react';
import './tableAdmin.component.style.less';
import { Layout } from 'antd';
import AllPlayers from '../AdminTableComponent/AllPlayers/allPlayers.component'
import BussinesAdmin from '../AdminTableComponent/BusinessAdmin/bussinesAdmin.component'
import GameStats from '../AdminTableComponent/GameStats/gameStats.component'
import LeaderBoard from '../AdminTableComponent/LeaderBoard/leaderboardAdmin.component'
import TopPlayers from '../AdminTableComponent/TopPlayers/topPlayers.component'


const { Content } = Layout;

/**
 * TableAdmin component
 * @class
 * @param {Object} properties (selectedContent)
 * @returns This component is used in AdminPanel component to display 
 * all players data that is passed in props selectedContent
 */
const TableAdmin = ({ selectedContent }) => {

    const MenuSwitch = () => {
        switch (selectedContent) {
            case '1':
                return (
                    <Layout className="site-layout">
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <GameStats />
                        </Content>
                    </Layout>
                );
            case '2':
                return (
                    <Layout className="site-layout">
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <AllPlayers />
                        </Content>
                    </Layout>
                );
            case '3':
                return (
                    <Layout className="site-layout">
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <TopPlayers />
                        </Content>
                    </Layout>
                );
            case '4':
                return (
                    <Layout className="site-layout">
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <BussinesAdmin />
                        </Content>
                    </Layout>
                );
            case '5':
                return (
                    <Layout className="site-layout">
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >
                            <LeaderBoard />
                        </Content>
                    </Layout>
                );
            default:
                break;
        }
    };

    return (
        <div className="content-switch">
            <MenuSwitch />
        </div>
    );
}


export default TableAdmin;