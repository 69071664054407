import React, { useEffect } from 'react';
import './video.component.style.less';
import Button from '../Button/button.component'

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next'

const VideoLoad = ({ videoEnd, setVideoEnd, url, soundsStore,setSurveyCompleted ,gameFinsh ,
    hireTeam , teamSelect}) => {
    const { t } = useTranslation("common");
    const stopButton = document.querySelector('button.offmusic');
    const playButton = document.querySelector('button.onmusic');
    const soundPlaying = soundsStore.sounds; // boolean

    useEffect(() => {
        if (stopButton && soundPlaying) {
            stopButton.click()
        }
        const videoContainer = document.querySelector('.videoContainer');
        const video = document.querySelector('video.video');
        video.addEventListener('ended', () => {
            videoContainer.classList.add('d-none')
            if(gameFinsh){
                setVideoEnd(true);
                setSurveyCompleted(true)
            }else if(teamSelect){
                hireTeam()
            }else {
                setVideoEnd(true);
            }
            if (stopButton && soundPlaying) {
                playButton.click()
            }
        })
    }, [])

    const skipVideo = () => {
        if(teamSelect){
            hireTeam()
        }else {
            document.querySelector('video').currentTime = document.querySelector('video').duration
        }
    }

    return (
        <div className="videoHolderParent">
            <div className="videoContainer">
                <video controls={false} autoPlay className="video">
                    <source src={url} type="video/mp4" />
                </video>
            </div>
            <div className="button-landing">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('common:skip-video')}
                    customBtn={true}
                    onClick={skipVideo}
                />
            </div>
        </div>
    )
}

export default (inject('userStore', 'soundsStore')(observer(VideoLoad)));