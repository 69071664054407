import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (withAccelerator, withoutAccelerators)
 * @returns This component is used in chartAccelerator component
 * to download Excel file based on the data form props
 */
const Download = ({ user }) => {
    const { t } = useTranslation("admin")


    user.email = user.identity.email;
    const dataSet = [user];
    return (
        <ExcelFile filename={`${user.firstName}_${user.lastName}`} element={<button className="buttonData">{t("admin:excels-profile.download")}</button>}>
            <ExcelSheet data={dataSet} name={t("admin:excels-profile.nameA")}>
                <ExcelColumn label={t("admin:excels-profile.first-name")} value="firstName" />
                <ExcelColumn label={t("admin:excels-profile.last-name")}value="lastName" />
                <ExcelColumn label={t("admin:excels-profile.email")} value="email" />
                <ExcelColumn label={t("admin:excels-profile.city")} value="city" />
                <ExcelColumn label={t("admin:excels-profile.year-of-birth")} value="yearOfBirth" />
                <ExcelColumn label={t("admin:excels-profile.gender")} value="gender" />

            </ExcelSheet>
        </ExcelFile>
    );
};

export default Download;