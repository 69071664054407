import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (usersData, surveyQuestions)
 * @returns This component is used in userInfo
 * to download Excel file based on the data form props
 */
const Download = ({ usersData, surveyQuestions }) => {
  const { t } = useTranslation("admin");
  const dataSet = [];
  usersData?.forEach((user) => {
    user.genderTranslate = t(`admin:excels-users.${user.gender}`);
    user.terms = t("admin:excels-users.accepted-terms");
    user.completed =
      user.sequenceNr == 10 && user.sequenceCompleted
        ? t("admin:excels-users.yes")
        : t("admin:excels-users.no");
    user.pre_survey =
      user.survey.length != 0
        ? t("admin:excels-users.yes")
        : t("admin:excels-users.no");
    user.post_survey =
      user.survey.length > 1
        ? t("admin:excels-users.yes")
        : t("admin:excels-users.no");
    user.survey.forEach((survey) => {
      survey.responses.forEach((response) => {
        user[`answer_${response.questionId}`] = response.answer;
      });
    });
    dataSet.push(user);
  });

  return (
    <ExcelFile
      filename="users_info"
      element={
        <button className="buttonData">
          {t("admin:excels-users.download")}
        </button>
      }
    >
      <ExcelSheet data={dataSet} name={t("admin:excels-users.name")}>
        <ExcelColumn
          label={t("admin:excels-users.first-name")}
          value="first_name"
        />
        <ExcelColumn
          label={t("admin:excels-users.last-name")}
          value="last_name"
        />
        <ExcelColumn label={t("admin:excels-users.email")} value="email" />
        <ExcelColumn label={t("admin:excels-users.city")} value="city" />
        <ExcelColumn
          label={t("admin:excels-users.year-of-birth")}
          value="year_of_birth"
        />
        <ExcelColumn
          label={t("admin:excels-users.gender")}
          value="genderTranslate"
        />
        <ExcelColumn label={t("admin:excels-users.terms")} value="terms" />
        <ExcelColumn
          label={t("admin:excels-users.sequence")}
          value="sequenceNr"
        />
        <ExcelColumn
          label={t("admin:excels-users.coins")}
          value="remaining_coins"
        />
        <ExcelColumn
          label={t("admin:excels-users.certificate")}
          value="completed"
        />

        {surveyQuestions &&
          surveyQuestions.reduce((current, surveyQuestion, index) => {
            current.push(
              <ExcelColumn
                label={
                  index == 0
                    ? t("admin:excels-users.survey-before")
                    : t("admin:excels-users.survey-after")
                }
                value={index == 0 ? "pre_survey" : "post_survey"}
              />
            );
            current.push(
              surveyQuestion.questions.map((question, i) => (
                <ExcelColumn
                  label={
                    i18n.language == "bhs"
                      ? question.bhs.description
                      : question.en.description
                  }
                  value={`answer_${question._id}`}
                />
              ))
            );

            return current;
          }, [])}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Download;
