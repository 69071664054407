import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import EsgService from '../../../services/EsgServices/esg.service';
import Button from '../../../components/Button/button.component';
import { Avatar, avatarArr } from '../../../assets/images/index.js';
import './avatars.style.less';
import { useTranslation } from "react-i18next"
import ClickSound from '../../../assets/audio/button22.wav'

/**
 * In game avatar select component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title. List of all avatars that user can choose to select.
 * And two buttons : Contiue button after user selected avatar buttun is enabled to submit save user select of avatar and 
 * redirct him to next page Charcter name and
 * Back button for going back to previous page. 
 */
const Avatars = ({ history, userStore, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["avatars", "common"])

  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are services methods for calling api's stored.
   */
  const esgService = new EsgService()

  /** Variable type Object where we set information and store about user in game.
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)

  /**
   * This is variable type of String where we set and store information about what avatar user selected.
   * @type {Object} selectedAvatar String with name of avatar
   * Default value is null .
   */
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);

    /**
   *Component did mount.Setting up userEsg from localStoraga with method getLocalStorage with parmeter USER_PROFILE_ESG.
    Setting up progressBarStore with parameters (11,0,0).
   */
    useEffect(() => {
      setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
      progressBarStore.setProgress(11, 0, 0);
    }, [])
    /**
     * Component did update.First check if userEsg have value and if it have set that value to userStore.
     * After that check if userStore.userInfoEsg have value and if it have set selectedAvatar value.
     */
    useEffect(() => {
      if (userEsg) {
        setUserStore(userEsg)
      }
      if (userStore.userInfoEsg) {
        setSelectedAvatar(userStore.userInfoEsg.avatarUrl)
      }
    }, [userEsg])

    /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
    const playSound = audioFile => {
      if (soundsStore.sounds) {
        audioFile.play();
      }
    }

  
  /**
   * Function that take one parameter avatar as argument and set's it for selected avatar
   * @param {String} avatar Selected Avatar
   * @returns Set's selected avatar .
   */
  const onChangeAvatar = (avatar) => {
    setSelectedAvatar(avatar)
  }
  /**
   * Function where we call updateUserInfoEsg from userStore mobix user store and update there update 
   * userInfoEsg.
   * @param {Object} userEsg  
   * @returns userEsg
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }
  /**
   * Sumbit Avatar function call esgService with method addAvatar.Two argument are passed selectedAvatar and 
   * userStore.userInfoEsg.userId (id of user) . After response from backend first local storage USER_PROFILE_ESG is set with response data from backend.
   * After that userStore updateUserInfoEsg methoth is called and response is passed to it where userInfoEsg is updated.
   * At the end hisoty.push is called and user is redirected to charctername rout.
   */
  const submitAvatar = () => {
    esgService.addAvatar(selectedAvatar, userStore.userInfoEsg.userId).then((res) => {
      setLocalStorage(res.data, USER_PROFILE_ESG)
      userStore.updateUserInfoEsg(res.data)
      history.push('charctername')
    })
  }
  
  return (
    <div className="section-avatars app">
      <div className="section-one-avatars">
        <div dangerouslySetInnerHTML={{ __html: t('avatars:avatar-select') }} />

        <div className="avatars-holder">
          {avatarArr.map((avatar, index) => (

            <Avatar
              key={avatar.key}
              selected={avatar.key === selectedAvatar}
              avatarIndex={avatar.key}
              src={avatar.src}
              handleclick={() => { onChangeAvatar(avatar.key); playSound(likeAudio) }}
            />
          ))}
        </div>
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="signIn"
          text={t("common:landing-back")}
          customBtn={true}
          onClick={() => {
            history.goBack();
          }}
        />
        <Button
          additionalStyle="buttonMakeAcc"
          text={t("common:landing-continue")}
          customBtn={true}
          disabled={selectedAvatar ? false : true}
          onClick={submitAvatar}
        />
      </div>
    </div >
  );
};


export default withRouter(inject("userStore", 'soundsStore', 'progressBarStore')(observer(Avatars)));
