import "./privacy-policy.less";
import React from "react";
import Button from "../../components/Button/button.component";

import { useTranslation } from "react-i18next";

/**
 * PrivacyPolicy component
 * @class
 * @param {Object} properties (history)
 * @returns This component will display content for privacy policy and button to go back
 */
const PrivacyPolicy = ({ history }) => {
    const { t } = useTranslation(["privacy-policy", "common"]);

    return (
        <div className="privacy-policy">
            <h3>{t("privacy-policy:title")}</h3>

            <div
                className="content"
                dangerouslySetInnerHTML={{
                    __html: t("privacy-policy:content"),
                }}
            ></div>

            <div className="buttons">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t("common:landing-back")}
                    customBtn={true}
                    onClick={() => {
                        history.goBack();
                    }}
                />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
