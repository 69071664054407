import React from 'react';
import { Button as AntButton } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './button.style.less';

const Button = ({
  text, // button text value
  shape, // can be set to circle, round or omitted
  type, // can be set to primary ghost dashed link or omitted (meaning default)
  onClick, // set the handler to handle click event
  disabled, // disabled state of button
  size, // set the size of button
  block, // option to fit button width to its parent width
  loading, // set the loading status of button
  icon, // set the icon component of button
  customBtn, // if custom eusi button is needed, set this property to true (it's false by default)
  ghost, // make background transparent and invert text and border colors. False by default.
  additionalStyle, // user can additionally style button if needed
}) => (
  <AntButton
    className={classnames({ '': customBtn }, additionalStyle)}
    type={type}
    shape={shape}
    disabled={disabled}
    size={size}
    block={block}
    loading={loading}
    icon={icon}
    ghost={ghost}
    onClick={onClick}
  >
    {text}
  </AntButton>
);
Button.defaultProps = {
  text: '',
  shape: '',
  type: '',
  onClick: () => {},
  disabled: false,
  size: '',
  block: false,
  loading: false,
  icon: null,
  customBtn: false,
  ghost: false,
};
Button.propTypes = {
  text: PropTypes.string.isRequired,
  shape: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string.isRequired,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  customBtn: PropTypes.bool,
  ghost: PropTypes.bool,
};
export default Button;
