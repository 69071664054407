import React from 'react';
import './index.style.less';

/******* Images *******/
import avatar4 from './avatar4.png';
import avatar2 from './avatar2.png';
import avatar3 from './avatar3.png';
import avatar1 from './avatar1.png';
import avatar5 from './avatar5.png';
import avatar6 from './avatar6.png';
import avatar7 from './avatar7.png';
import avatar8 from './avatar8.png';
import avatar9 from './avatar9.png';
import avatar10 from './avatar10.png';
import avatar11 from './avatar11.png';
import avatar12 from './avatar12.png';
import avatar13 from './avatar13.png';
import avatar14 from './avatar14.png';
import avatar15 from './avatar15.png';
import avatar16 from './avatar16.png';
import avatar17 from './avatar17.png';
import avatar18 from './avatar18.png';
import avatar19 from './avatar19.png';
import avatar20 from './avatar20.png';
import avatar21 from './avatar21.png';
import avatar22 from './avatar22.png';
import avatar23 from './avatar23.png';
import avatar24 from './avatar24.png';
import avatar25 from './avatar25.png';
import avatar26 from './avatar26.png';
import avatar27 from './avatar27.png';
import avatar28 from './avatar28.png';
import avatar29 from './avatar29.png';
import avatar30 from './avatar30.png';
import avatar31 from './avatar31.png';
import avatar32 from './avatar32.png';
import avatar33 from './avatar33.png';
import avatar34 from './avatar34.png';
import avatar35 from './avatar35.png';
import avatar36 from './avatar36.png';

import buyNew from './buyNew.png';
import buyOld from './buyOld.png';
import rentNew from './rentNew.png';
import rentOld from './guesthouse.png';
import coworking from './laboratorybuilding.png';

import industry from './industry.png';
import itindustry from './itindustry.png';
import metalindustry from './metalindustry.png';
import tourismindustry from './tourismindustry.png';
import ownbussines from './ownbussines.png';

import ecofriendly from './ecofriendly.png';

import elevator from './elevator.png';
import init from './init.png';
import coins from './coins.png';
import loggedAvatar from './logged-avatar.png';
import online from './online.png';
import backgroundMain from './backgroundMain.png';
import aboutGame from './about-game.png';
import backgroundFeatures from './background-features.png';
import footerLanding from './unlogo.png';
import swedenLogo from './sweden-sverige-logo-vector.png';
import group from './group.png';
import union from './union.png';
import enflag from './enflag.svg';
import bhsflag from './bhsflag.svg';
import timer from'./timer.png';
import hint from './hint.png';
import hintDisabled from './hintDisabled.png'
import help from './help.png';
import vector from './vector.png';
import leftBubble from './left-bubble.png';
import rightBubble from './right-bubble.png';
import smiley from './smiley.png'
import tugy from './tugy.png'
import certificat from './certificate.jpg'

import desktop from './desktop.png';
import laptop from './laptop.png';
import tablet from './tablet.png';
import deskOffice from './desk-office.png';
import deskEcofriendly from './desk-ecofriendly.png';
import deskAluminium from './desk-aluminium.png';
import chairOffice from './chair-office.png';
import chairEcofriendly from './chair-ecofriendly.png';
import chairLuxury from './chair-luxury.png';
import ionizer from './ionizer.png';
import headphones from './headphones.png';
import mouseKeyboard from './mouse-keyboard.png';
import cutlery from './cutlery.png';
import plasticCutlery from './plastic-cutlery.png';
import plant from './plant.png';

import offlineImage from './offline.png'
import loan from './loan.png'
import chest from './chest.png'
import pen from './pen.png'
import leftBgEnd from './left-bg-end.png'
import right from './right-bg-end.png'
import soundon from './soundon.svg'
import soundoff from './soundoff.svg'

import randEventPositive from './specialdefaultpositive.svg'
import randEventNegative from './specialdefaultnegative.svg'

import ilustration1 from './specialilustration1.svg'
import ilustration2 from './specialilustration2.svg'
import ilustration3 from './specialilustration3.svg'
import ilustration4 from './specialilustration4.svg'
import ilustration5 from './specialilustration5.svg'
import ilustration6 from './specialilustration6.svg'
import ilustration7 from './specialilustration7.svg'
import ilustration8 from './specialilustration8.svg'
import ilustration9 from './specialilustration9.svg'
import ilustrationFinal from './specialilustrationfinal.svg'
import sectionLogo from './footerlandingnew.png'

import calendar from './calendar.svg'
import cookies from './cookies.png'


export const mentorArr = [
  { key: 'mentor4', src: avatar1, name:"Klara", descEn: "Klara has over 15 years of experience in sales, business development and management. She was previously focused on the banking sector, but she has been primarily active in the IT sector for the last 7 years. Through her work in ICT corporations, she worked with startups for many years and led corporate innovation processes. After graduating from the Faculty of Business Economics, she dedicated herself to practical work and skills development. After a while, she dedicated herself to the MBA study, which she successfully finalized with a focus on topics of innovation in the IT sector. Klara has so far mentored and coached more than 30 startups around the world. ", descBhs: "Klara ima preko 15 godina iskustva u prodaji, poslovnom razvoju i menadžmentu. Ranije je bila usmjerena na bankarski sektor, ali u proteklih 7 godina je najviše aktivna u IT sektoru. Kroz svoj rad u IKT korporacijama dugi niz godina je sarađivala sa startupovima i vodila korporativne inovacione procese. Nakon završetka Fakulteta za poslovnu ekonomiju, posvetila se praktičnom radu i nadogradnji vještina. Nakon nekog vremena posvetila se MBA studiju koji je uspješno završila sa fokusom na teme inovacija u IT sektoru. Klara je do sada bila mentorica i trenerica u preko 30 startupa širom svijeta."},
  { key: 'mentor2', src: avatar4, name:"Ana", descEn: "Ana currently works as a startup program director at a local accelerator, where she has worked with more than 45 startups so far. She has over 10 years of experience in multiple innovation programs. Prior to focusing on the startup ecosystem, Ana built her career path in the metallurgical industry, with a focus on robotics and automation, where she gained valuable experience in team management and project management as well. After the Faculty of Electrical Engineering, she dedicated herself to practical work and her knowledge was certified by several established local and foreign educational institutions. Ana has been a mentor and coach to more than 25 startups so far.", descBhs: "Ana trenutno radi kao direktorica startup programa u lokalnom akceleratoru, gdje je do sada radila sa preko 45 startupa. Ima preko 10 godina iskustva u nekoliko inovativnih programa. Prije nego što se usmjerila na startup iz oblasti ekosistema, Ana je svoju karijeru gradila u metalurškoj industriji, s naglaskom na robotiku i automatizaciju, gdje je stekla dragocjeno iskustvo u upravljanju timovima i projektnom menadžmentu. Nakon Elektrotehničkog fakulteta posvetila se praktičnom radu, a njeno znanje potvrdilo je nekoliko etabliranih domaćih i stranih obrazovnih institucija. Ana je do sada bila mentorica i trenerica u preko 25 startupa." },
  { key: 'mentor3', src: avatar6, name:"Ena", descEn: "Ena is the founder of a startup that has successfully scaled to the European and the US market, and won numerous awards at international competitions. Also, through developing the startup, she got acquainted with the processes of finding adequate investment funds, negotiating and making investments happen. She actively shares her experience with other startups across the region, as a speaker at conferences, panel discussions and through training programs for startups and accelerators. So far, she has mentored more than 12 startups and is the author of more than 20 workshops for startups on the topic of acquiring the first investment. In addition to this startup, which has already achieved significant success in the local and international market, Ena is the co-founder of a new startup focused on the tourism industry.", descBhs: "Ena je osnivačica startupa koji se uspješno plasirao na evropsko i američko tržište i osvojio brojne nagrade na međunarodnim takmičenjima. Također, kroz razvoj startupa upoznala se s procesima pronalaska adekvatnih investicionih fondova, pregovaranja i ostvarivanja investicija. Aktivno dijeli svoje iskustvo s drugim startup kompanijama u regiji, kao govornica na konferencijama, panel diskusijama i kroz programe obuke za startupe i akceleratore. Do sada je bila mentorica u preko 12 startupa i autorica je preko 20 radionica za startupe na temu sticanja prve investicije. Uz ovaj startup, koji je već postigao značajan uspjeh na lokalnom i međunarodnom tržištu, Ena je suosnivačica novog startupa usmjerenog na turističku industriju." },
  { key: 'mentor1', src: avatar7, name:"Asja", descEn: "Asja is the Vice-Rector for Innovation, Technology Transfer and Communication, and a professor at the Faculty of Information Technology. In addition to her long academic career, she has achieved significant success in her own innovative ventures - startups whose products have reached international placement. Through mentoring more than 500 students interested in entrepreneurship over the years she has supported the formation of more than 20 startups by students. As a regular member of the jury at hackathons, ideathones and startup competitions, she contributes to the development of the local and regional startup community. She is currently an active mentor to 2 startups and a consultant to the startup program of a number of accelerators and incubators around the world.", descBhs: "Asja je prorektorica za inovacije, transfer tehnologije i komunikacije i profesorica na Fakultetu informacionih tehnologija. Uz svoju dugu akademsku karijeru, postigla je značajan uspjeh u vlastitim inovativnim poduhvatima - startupovima čiji su proizvodi dosegli međunarodni plasman. Kroz mentorstvo preko 500 učenika zainteresovanih za preduzetništvo tokom godina je podržala studente u osnivanju preko 20 startupa. Kao redovan član žirija na hakatonima, ideathonima i startup takmičenjima, doprinosi razvoju lokalne i regionalne startup zajednice. Trenutno je aktivna mentorica za 2 startupa i savjetnica za startup program brojnih akceleratora i inkubatora širom svijeta." },
];

export const avatarArr = [
  { key: 'avatar1', src: avatar1 },
  { key: 'avatar2', src: avatar2 },
  { key: 'avatar3', src: avatar3 },
  { key: 'avatar7', src: avatar7 },
  { key: 'avatar5', src: avatar5 },
  { key: 'avatar6', src: avatar6 },
  { key: 'avatar4', src: avatar4 },
  { key: 'avatar8', src: avatar8 },
  { key: 'avatar9', src: avatar9 },
  { key: 'avatar10', src: avatar10 },
  { key: 'avatar11', src: avatar11 },
  { key: 'avatar12', src: avatar12 },
  { key: 'avatar13', src: avatar13 },
  { key: 'avatar14', src: avatar14 },
  { key: 'avatar15', src: avatar15 },
  { key: 'avatar16', src: avatar16 },
  { key: 'avatar17', src: avatar17 },
  { key: 'avatar18', src: avatar18 },
  { key: 'avatar19', src: avatar19 },
  { key: 'avatar20', src: avatar20 },
  { key: 'avatar21', src: avatar21 },
  { key: 'avatar22', src: avatar22 },
  { key: 'avatar23', src: avatar23 },
  { key: 'avatar24', src: avatar24 },
  { key: 'avatar25', src: avatar25 },
  { key: 'avatar26', src: avatar26 },
  { key: 'avatar27', src: avatar27 },
  { key: 'avatar28', src: avatar28 },
  { key: 'avatar29', src: avatar29 },
  { key: 'avatar30', src: avatar30 },
  { key: 'avatar31', src: avatar31 },
  { key: 'avatar32', src: avatar32 },
  { key: 'avatar33', src: avatar33 },
  { key: 'avatar34', src: avatar34 },
  { key: 'avatar35', src: avatar35 },
  { key: 'avatar36', src: avatar36 },
];
export const buildingtypeArr = [
  { key: "buyNew", src: buyNew , name:"New Building"},
  { key: "buyOld", src: buyOld, name:"Old Building"},
  { key: "rentNew", src: rentNew, name:"New Building"},
  { key: "rentOld", src: rentOld, name:"Old Building"},
  { key: "coworking", src: coworking, name:"Co-working"},
];

export const hardwarEquipmentArr = [
  //--//Ovde treba promjenit sta se stavlja u bazu imageURL ISPRAVITI DA BUDE NORMALNP//--//
  // { key: "avatar1", src: industry1 , name:"Laptop"},
  // { key: "avatar2", src: industry2, name:"Desktop Setup"},
  // { key: "avatar3", src: industry3, name:"Tablet"},
  // { key: "avatar4", src: industry3, name:"Tablet"},
  // { key: "avatar5", src: industry3, name:"Tablet"},
  // { key: "avatar9", src: industry3, name:"Tablet"},

  { key: "desktop", src: desktop, name:"Desktop Setup"},
  { key: "laptop", src: laptop, name:"Laptop"},
  { key: "tablet", src: tablet, name:"Tablet"},
  { key: "desk-office", src: deskOffice, name:"Table no. 1",ecoFriendly:5},
  { key: "desk-ecofriendly", src: deskEcofriendly, name:"Desk Ecofriendly"},
  { key: "desk-aluminium", src: deskAluminium, name:"Desk Eco"},
  { key: "chair-office", src: chairOffice, name:"Chair no. 1",ecoFriendly:5},
  { key: "chair-ecofriendly", src: chairEcofriendly, name:"Chair Ecofriendly"},
  { key: "chair-luxury", src: chairLuxury, name:"Chair Luxury"},
  { key: "ionizer", src: ionizer, name:"Ionizer",ecoFriendly:5},
  { key: "headphones", src: headphones, name:"Headphones"},
  { key: "mouse-keyboard", src: mouseKeyboard, name:"Mouse-Keyboard"},
  { key: "cutlery", src: cutlery, name:"Cutlery",ecoFriendly:5},
  { key: "plastic-cutlery", src: plasticCutlery, name:"Plastic-Cutlery"},
  { key: "plant", src: plant, name:"Plant",ecoFriendly:5},
];

const HardwareEquipment = ({ src, selected, handleclick,imageUrl,ecoFriendly }) => {
  let ecofriendlyItems = ['Property 1', 'Co-working space', 'Chair no. 1', 'Table no. 1', 'Ionizer', 'Plant', 'Cutlery']
  const equipmentSelect =  hardwarEquipmentArr.filter(office => office.key === imageUrl)
  return (
    <div
      className={!selected ? 'industry-backg-holder' : 'selected'}
      onClick={handleclick}
    > 
      {ecoFriendly >= 5 ? <img className="ecofriend" src={ecofriendly} alt="eco" /> : null}
      <img src={equipmentSelect[0].src} alt="equipment" />
    </div>
  );
};

const Avatar = ({ avatarName, src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={src} alt="avatar" />
    </div>
  );
};
const EmployeAvatar = ({handleclick,src,selected,avatarUrl}) => {
  //Create array of pictures for eployees
 const avatar =  avatarArr.filter(avatar => avatar.key === avatarUrl)
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={avatar[0].src} alt="avatar" />
    </div>
  )
}

const BuildingType = ({ src, selected, handleclick,imageUrl, ecoFriendly}) => {
  const building =  buildingtypeArr.filter(building => building.key === imageUrl)
  return (
    <div
      className={!selected ? 'industry-backg-holder' : 'selected-building'}
      onClick={handleclick}
    >
     {ecoFriendly >= 5 ? <img className="ecofriend" src={ecofriendly} alt="eco" /> : null}
           <img src={building[0].src} alt="building" />
    </div>
  );
};

const Mentor = ({ src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={src} alt="mentor" />
    </div>
  );
};

const Avatar1 = ({ avatarName, src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={avatar1} alt="avatar1" />
    </div>
  );
};

const Avatar2 = ({ avatarName, src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={avatar2} alt="avatar2" />
    </div>
  );
};

const Avatar3 = ({ avatarName, src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={avatar3} alt="avatar3" />
    </div>
  );
};

const Avatar4 = ({ avatarName, src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'avatars-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={avatar4} alt="avatar4" />
    </div>
  );
};

const Avatar5 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar5} alt="avatar5" />
    </div>
  );
};

const Avatar6 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar6} alt="avatar6" />
    </div>
  );
};

const Avatar7 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar7} alt="avatar7" />
    </div>
  );
};

const Avatar8 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar8} alt="avatar8" />
    </div>
  );
};

const Avatar9 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar9} alt="avatar9" />
    </div>
  );
};

const Avatar10 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar10} alt="avatar10" />
    </div>
  );
};

const Avatar11 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar11} alt="avatar11" />
    </div>
  );
};

const Avatar12 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar12} alt="avatar12" />
    </div>
  );
};

const Avatar13 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar13} alt="avatar13" />
    </div>
  );
};

const Avatar14 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar14} alt="avatar14" />
    </div>
  );
};

const Avatar15 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar15} alt="avatar15" />
    </div>
  );
};

const Avatar16 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar16} alt="avatar16" />
    </div>
  );
};

const Avatar17 = (src) => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar17} alt="avatar17" />
    </div>
  );
};

const Avatar18 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar18} alt="avatar18" />
    </div>
  );
};

const Avatar19 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar19} alt="avatar19" />
    </div>
  );
};

const Avatar20 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar20} alt="avatar20" />
    </div>
  );
};
const Avatar21 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar21} alt="avatar21" />
    </div>
  );
}
const Avatar22 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar22} alt="avatar22" />
    </div>
  );
}
const Avatar23 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar23} alt="avatar23" />
    </div>
  );
}
const Avatar24 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar24} alt="avatar24" />
    </div>
  );
}
const Avatar25 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar25} alt="avatar25" />
    </div>
  );
}
const Avatar26 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar26} alt="avatar26" />
    </div>
  );
}
const Avatar27 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar27} alt="avatar27" />
    </div>
  );
}
const Avatar28 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar28} alt="avatar28" />
    </div>
  );
}
const Avatar29 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar28} alt="avatar28" />
    </div>
  );
}
const Avatar30 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar30} alt="avatar30" />
    </div>
  );
}
const Avatar31 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar31} alt="avatar31" />
    </div>
  );
}
const Avatar32 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar32} alt="avatar32" />
    </div>
  );
}
const Avatar33 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar33} alt="avatar33" />
    </div>
  );
}
const Avatar34 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar34} alt="avatar34" />
    </div>
  );
}
const Avatar35 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar35} alt="avatar35" />
    </div>
  );
}
const Avatar36 = () => {
  return (
    <div className="avatars-backg-holder">
      <img src={avatar36} alt="avatar36" />
    </div>
  );
}
const Pen = () => {
  return <img className="pen-image" src={pen} alt="pen" />;
};

const Coins = () => {
  return <img src={coins} alt="coins" />;
};

const LoggedAvatar = () => {
  return <img src={loggedAvatar} alt="logged" />;
};

const Online = () => {
  return <img src={online} alt="online" />;
};

const BackgroundMain = () => {
  return <img src={backgroundMain} alt="background" />;
};

const LandingAbout = () => {
  return <img src={aboutGame} alt="background" />;
};

const FeaturesB = () => {
  return <img src={backgroundFeatures} alt="background" />;
};

const FooterLanding = () => {
  return <div>
  <img className="image-main" src={footerLanding} alt="background" />
  <img className="image-main" src={swedenLogo} alt="background" />
  </div>
  ;
};

const CertifacePicture = () => {
  return <div>
      <img className="image-main" src={certificat} alt='background'/>
  </div>
}

const Group = () => {
  return <img className="image-first" src={group} alt="background" />;
};

const Union = () => {
  return <img className="image-second" src={union} alt="background" />;
};

const EnLang = ({selectEnglish}) => {
  return <img className="image-second english" src={enflag} alt="en" onClick={selectEnglish}/>;
};

const BhsLang = ({selectBhs}) => {
  return <img className="image-second bosnian" src={bhsflag} alt="bhs" onClick={selectBhs}/>;
};

const TimerPrev = () => {
  return <img className="image-second" src={timer} alt="bhs" />;
};

const HintPrev = () => {
  return <img className="image-second" src={hint} alt="bhs" />;
};

const HintDisabledPrev = ()=>{
  return <img className="image-second" src={hintDisabled} alt="bhs" />;
}

const HelpPrev = () => {
  return <img className="image-second" src={help} alt="bhs" />;
};

const Vector = () => {
  return <img className="back-to-back" src={vector} alt="bhs" />;
};

const LeftBubble = () => {
  return <img className="absoulute-back-left" src={leftBubble} alt="bhs" />;
};

const RightBubble = () => {
  return <img className="absoulute-back-right" src={rightBubble} alt="bhs" />;
};

const LeftBubleEnd = () => {
  return <img className="absoulute-back-left" src={leftBgEnd} alt="bhs" />;
};

const RightBubleEnd = () => {
  return <img className="absoulute-back-right" src={right} alt="bhs" />;
};

const Chest = () => {
  return <img className="chest-end" src={chest} alt="bhs" />;
};

const Loan = () => {
  return  ( 
    <img src={loan} alt="avatar20" />)
};

const RandEventPositive = () => {
  return (
    <img src={randEventPositive} alt="Random Event negative" style={{width:"100%"}}/>
  )
}

const RandEventNegative = () => {
  return (
    <img src={randEventNegative} alt="Random Event positive" style={{width:"100%"}}/>
  )
}

const Ilustration1 = () => {
  return (
    <img src={ilustration1} alt="ilustration1" style={{width:"100%"}}/>
  )
}

const Ilustration2 = () => {
  return (
    <img src={ilustration2} alt="ilustration2" style={{width:"100%"}}/>
  )
}

const Ilustration3 = () => {
  return (
    <img src={ilustration3} alt="ilustration3" style={{width:"100%"}}/>
  )
}

const Ilustration4 = () => {
  return (
    <img src={ilustration4} alt="ilustration4" style={{width:"100%"}}/>
  )
}

const Ilustration5 = () => {
  return (
    <img src={ilustration5} alt="ilustration5" style={{width:"100%"}}/>
  )
}

const Ilustration6 = () => {
  return (
    <img src={ilustration6} alt="ilustration6" style={{width:"100%"}}/>
  )
}

const Ilustration7 = () => {
  return (
    <img src={ilustration7} alt="ilustration7" style={{width:"100%"}}/>
  )
}

const Ilustration8 = () => {
  return (
    <img src={ilustration8} alt="ilustration8" style={{width:"100%"}}/>
  )
}

const Ilustration9 = () => {
  return (
    <img src={ilustration9} alt="ilustration9" style={{width:"100%"}}/>
  )
}

const IlustrationFinal = () => {
  return (
    <img className="final-ilu" src={ilustrationFinal} alt="ilustration9" style={{width:"100%"}}/>
  )
}

const Offline = () => {
  return  ( 
    <img className="offline" src={offlineImage} alt="offline" />)
};
const SoundOn = () => {
  return  ( 
    <img className="soundOn" src={soundon} alt="offline" />)
};

const SoundOff = () => {
  return  ( 
    <img className="soundoff" src={soundoff} alt="offline" />)
};

export const companytypeArr = [
  { key: 'it', src: itindustry ,nameBhs:"IT industrija",nameEn:"IT industry", descriptionEn: '“IT industry” stands for the information technology industry, which is a business sector that works with computing and digital media. IT provides hardware, software, and internet services worldwide. IT products, hardware, software, applications and websites, maintenance and support for hardware devices, applications or websites and consulting are crucial segments of the IT industry.',descriptionBhs:"IT industrija označava industriju informacione tehnologije, koja predstavlja poslovni sektor iz oblasti kompjuterizacije i digitalnih medija. U sklopu ove industrije pružaju se usluge hardvera, softvera i interneta širom svijeta. IT proizvodi, hardver, softver, aplikacije i web stranice, održavanje i podrška za hardverske uređaje, aplikacije ili web stranice i savjetovanje su ključni segmenti IT industrije."},
  { key: 'turizam', src: tourismindustry,nameBhs:"Turistička industrija",nameEn:"Tourism industry", descriptionEn: '“Tourism industry” is one of the world’s largest industries and the economies of many nations are driven, to a large extent, by their tourist trade. It is also a wide-ranging industry, which includes the hotel industry, the transport industry and a number of additional industries or sectors. It is vital to understand that the tourism industry is linked to movement to different locations, based not only on leisure, but also business and some additional travel motivators, all while the travels last for a period of no longer than one year.',descriptionBhs:"Turistička industrija predstavlja jednu od najvećih svjetskih industrija, a privrede mnogih zemalja u velikoj mjeri pokreće njihova turistička ponuda. To je također industrija širokog spektra, koja uključuje hotelijerstvo, saobraćajni sektor i ostale mnogobrojne sektore. Od ključne je važnosti shvatiti da je turistička industrija povezana s putovanjima na različita mjesta, ne samo u cilju razonode, nego i poslovanja, kao i ostalih dodatnih razloga za putovanja, dokle god ta putovanja ne traju duže od godinu dana."},
  { key: 'metal', src: metalindustry , nameBhs:"Metalurška industrija", nameEn:"Metallurgical industry",descriptionEn: 'Within the metallurgical industry there is an increasing demand for specific products. Different technological developments such as 3D printing, robotics and automation make it possible to efficiently deliver answers to these specific requirements.',descriptionBhs:"Unutar metalurške industrije sve je veća potražnja za određenim proizvodima. Različita tehnološka dostignuća poput 3D štampe, robotike i automatizacije omogućuju efikasan odgovor na ove specifične zahtjeve."},
  { key: 'ostalo', src: ownbussines, nameBhs:"Industrija po tvom izboru", nameEn:"Other industry",descriptionEn: 'In case you select this option, you will not receive industry specific questions to answer and situations to solve. Your journey will enable you to grow your startup regardless of the industry it is focused on.',descriptionBhs:"U slučaju da odabereš ovu opciju, nećeš dobiti konkretna pitanja za industriju i situacije koje trebaš rješavati. Tvoje putovanje će ti omogućiti rast tvog startupa bez obzira na industriju na koju se odnosi." },
];

const Itindustry = ({ src, selected, handleclick }) => {
  return (
    <div
      className={!selected ? 'industry-backg-holder' : 'selected'}
      onClick={handleclick}
    >
      <img src={src} alt="industry" />
    </div>
  );
};

const ItindustryPrev = () => {
  return (
    <div className="industry-backg-holder">
      <img src={industry} alt="avatar1" />
    </div>
  );
};

const SectionLogoLanding = () => {
  return (
    <div className="start-logo">
      <img src={sectionLogo} alt="avatar1" />
    </div>
  );
};

const PauseButton = () => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32.5" cy="32.5" r="32.5" fill="#7B35DF"/>
      <rect x="22" y="21" width="6" height="23" rx="3" fill="#CEACFF"/>
      <rect x="36" y="21" width="6" height="23" rx="3" fill="#CEACFF"/>
    </svg>
  );
};

const Smiley = () => {
  return (
      <img src={smiley} alt="avatar1" />
  );
};

const Tugy = () => {
  return (
      <img src={tugy} alt="avatar1" />
  );
};

const Calendar = () => {
  return (
    <img src={calendar} alt="calendar" />
  )
}

const SurveyRocket = () => {
  return (
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.22006 37.5893L9.65413 38.8727H28.0926L28.5267 37.5893C28.8072 36.7714 29.0465 35.94 29.2438 35.0981H37.7554V28.4173L30.2063 23.3405C30.0391 19.2164 29.0557 15.1663 27.3128 11.4247C25.57 7.68313 23.1024 4.32435 20.0529 1.54275L18.8828 0.599121L17.7127 1.54275C14.6632 4.32435 12.1956 7.68313 10.4528 11.4247C8.70995 15.1663 7.72648 19.2164 7.55927 23.3405L0.0102539 28.4173V35.0981H8.52177C8.71288 35.9397 8.94593 36.7711 9.22006 37.5893ZM33.9808 31.3236H29.8666C29.9987 30.229 30.0742 29.0778 30.1308 27.8888L33.9054 30.4555L33.9808 31.3236ZM18.8828 5.61922C23.872 11.0874 26.5739 18.261 26.4318 25.6619C26.4873 28.8408 26.1256 32.0133 25.3561 35.0981H12.4095C11.64 32.0133 11.2783 28.8408 11.3338 25.6619C11.1663 18.2566 13.8716 11.0739 18.8828 5.61922ZM3.78476 31.3236V30.4555L7.55927 27.8888C7.55927 29.0778 7.69138 30.229 7.82349 31.3236H3.78476Z" fill="#7B35DF"/>
    </svg>
  )
}

const ElevatorP = () => {
  return <img className="slik" src={elevator} alt="online" />;
};

const InitP = () => {
  return <img className="slik" src={init} alt="online" />;
};

const Cookies = () => {
  return <img className="slik" src={cookies} alt="online" />;
};

export const avatarTypeCOunt = avatarArr.length;
export const companyTypeCOunt = companytypeArr.length;
export const mentorTypeCount = mentorArr.length;
export const buildingTypeCount = buildingtypeArr.length;
export const equipmentTypeCount = hardwarEquipmentArr.length;

export {
  InitP,
  ElevatorP,
  Smiley,
  Tugy,
  PauseButton,
  Avatar,
  Mentor,
  EmployeAvatar,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
  LoggedAvatar,
  Online,
  BackgroundMain,
  Itindustry,
  ItindustryPrev,
  Coins,
  LandingAbout,
  BuildingType,
  FeaturesB,
  FooterLanding,
  Group,
  Union,
  HardwareEquipment,
  EnLang,
  BhsLang,
  TimerPrev,
  HintPrev,
  HintDisabledPrev,
  HelpPrev,
  Vector,
  LeftBubble,
  RightBubble,
  Pen,
  Loan,
  LeftBubleEnd,
  RightBubleEnd,
  Chest,
  Offline,
  SoundOn,
  SoundOff,
  RandEventNegative,
  RandEventPositive,
  Ilustration9,
  Ilustration8,
  Ilustration7,
  Ilustration6,
  Ilustration5,
  Ilustration4,
  Ilustration3,
  Ilustration2,
  Ilustration1,
  IlustrationFinal,
  SectionLogoLanding,
  Calendar,
  SurveyRocket,
  Cookies,
  CertifacePicture
};
