import React, { useState, useEffect } from "react";
import "./admin.component.style.less";
import SiderBar from "../AdminPanel/AdminSiderComponent/siderAdmin.component";
import TableAdmin from "../AdminPanel/AdminTableComponent/tableAdmin.component";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import {
    getLocalStorage,
    USER_PROFILE_ESG,
} from "../../localStorageMobxStore/localStorageUserProfile";

/**
 * AdminPanel component
 * @class
 * @param {Object} properties (userStore)
 * @returns SideBar menu with clickable items: Game Statistic, All Players, Top 50 Players, Business Plan, Leaderboard.
 * TableAdmin which display proper the content after selection item from SideBar
 */
const AdminPanel = ({ userStore }) => {
    const [selectedContent, setSelectedContent] = useState("1");
    const [userEsg, setUserEsg] = useState(null);

    useEffect(() => {
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
        const a = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG;
    }, []);

    useEffect(() => {
        if (userEsg) {
            setUserStore(userEsg);
        }
    }, [userEsg]);

    /**
     * Function setUserStore with one parametar userEsg
     * @param {Object} userEsg properties for user ingame profile
     * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
     * budget with new value of budget ,companyInfo with new value of companyInfo.
     */
    const setUserStore = (userEsg) => {
        userStore.updateUserInfoEsg(userEsg);
    };

    return (
        <div className="test">
            <SiderBar
                setSelectedContent={setSelectedContent}
                selectedContent={selectedContent}
            />
            <TableAdmin selectedContent={selectedContent} />
        </div>
    );
};

export default withRouter(inject("userStore")(observer(AdminPanel)));
