import Cookies from 'js-cookie';
const KEY = 'authorization';

const get = () => {
    return Cookies.get(KEY);
};

const set = (value) => {
    return Cookies.set(KEY, value);
};

const remove = (value) => {
    return Cookies.remove(KEY,value)
};

export {
    get,
    set,
    remove
}
 
