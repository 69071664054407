import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import EsgService from '../../../../../services/EsgServices/esg.service';
import './chartEco.component.style.less'
import { useTranslation } from "react-i18next"
import Download from './excel'

/**
 * ChartAccelerator component
 * @class
 * @returns This component is used in gameStats component to display 
 * all players data represeted in charts
 */
const ChartAccelerator = () => {
    const { t } = useTranslation("admin")

    /** useRef can be used keep track of variables without causing re-render of the component. 
    * And give a solution on how to enforce re-render without affecting the performance of a component.
    */
    const isReady = React.useRef(false);

    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()
    const [ecoFriendly, setEcoFriendly] = useState(10)
    const [otherNoEco, setOtherNoEco] = useState(12)

    useEffect(() => {
        fetch();
        return () => {
            isReady.current = true;
        };
    }, [])

    /**
     *Function that is used in component did mount to get all necessary data that we need to display
    */
    function fetch() {
        esgService.getGeneralStats().then((res) => {
            if (!isReady.current) {
                const allStatsEco = res.data.ecofriendly
                setEcoFriendly(allStatsEco.yes)
                setOtherNoEco(allStatsEco.no)
            }
        });
    }



    return (
        <div className='chart-comp-acc'>
            <h2>{t("admin:charts.eco")}</h2>
            <div style={{ display: 'flex' }}>
                <Chart
                    width={'100%'}
                    height={300}
                    className="accelerator-holder"
                    chartType="PieChart"
                    loader={<div>{t("admin:charts.loading")}</div>}
                    data={[
                        [t("admin:charts.ecoFriendly"), t("admin:charts.ecoFriendly")],
                        [t("admin:charts.ecoFriendly"), ecoFriendly],
                        [t("admin:charts.other"), otherNoEco],
                    ]}
                    options={{
                        // title: 'My Daily Activities',
                        is3D: true,
                        colors: ['#2ecc71', '#95a5a6']
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
            <div>
                <Download
                    ecoFriendly={ecoFriendly}
                    otherNoEco={otherNoEco}
                />
            </div>
        </div>
    );
};

export default ChartAccelerator;
