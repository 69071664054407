import React, { useState } from 'react'
import Modal from '../modal.component'
import { Coins } from '../../../assets/images/index';
import './modalInfoMentor.component.style.less'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { EmployeAvatar, Vector } from '../../../assets/images/index';
import { useTranslation } from 'react-i18next'
import { Rate } from 'antd';
import { Mentor } from '../../../assets/images/index.js';



const ModalInfoMentor = ({ mentorDescription, name, skill, salary, avatarUrl, role, languageStore }) => {
    const { t } = useTranslation(["common", "employes"])
    const [visible, setVisible] = useState(false);
    const trigerVisible = () => {
        setVisible(!visible)
    }
    const handleOk = () => {
        trigerVisible()
    }

    // const selectLanguage = (value) => {
    //     if (languageStore.language === 'bhs') {
    //         return value.bhs
    //     } else if (languageStore.language === 'en') {
    //         return value.en
    //     } else {
    //         return value.en
    //     }
    // }

    return (
        <Modal
            modalStyleTest='modal-content-employee'
            closable={false}
            okBtnText={t('common:close-button')}
            footer={true}
            btnText={t('common:more-info')}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
        >
            <div className="content-container mentor-select">
                <div className="header">
                    <Mentor 
                        src={avatarUrl}
                    />
                    <h3 className="name">{name}</h3>
                </div>
                <div className="mentorDescription">
                    <p>{mentorDescription}</p>
                </div>
            </div>
        </Modal>
    )
}

export default inject('languageStore')(observer(ModalInfoMentor))