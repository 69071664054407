import React, { useState, useEffect, useRef } from 'react';
import './bonusChest.component.style.less';
import Button from '../../../components/Button/button.component';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Coins } from '../../../assets/images/index.js';
import { getLocalStorage, USER_PROFILE_ESG, BUDGET, COMPANY_INFO } from '../../../localStorageMobxStore/localStorageUserProfile'
import { Mentor, mentorArr } from '../../../assets/images/index';
import { modeGame } from '../../../helperFunctions/helperFunctions'
import SequnceService from '../../../services/EsgServices/sequence.service';
import Modal from '../../../components/Modal/modal.component'
import Chest from './Chest/chest.component'
import { useTranslation } from 'react-i18next'

/**
 * Bonus Chest component
 * @class 
 * @param {Object} properties (history, userStore)
 * @returns Title, list of bonus chests components and button that redirect user to game.
 */
const BonusChest = ({ history, userStore }) => {
  const { t } = useTranslation(['common', 'sidechest'])
  const [userEsg, setUserEsg] = useState(null)
  const [mentorImage, setMentorImage] = useState(null)
  const [budget, setBudget] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [bonusChests, setBonusChests] = useState(null)
  /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
  * service where are esg services sequnce methods for calling api's are stored.
  */
  const sequnceSrevices = new SequnceService()
  /**
   * Player Side Quest Function
   * @param {String} gameMode Mode of game 
   * @param {String} companyId Id of company
   * @returns Sequence service is called with method playerProgres gameMode and companyId are passed
   * as params and we store that result in to result variable. SequnceService is called again with method
   * getSideQuests and result is stored in sideQuest variable.State of bonusChest is updated newChests.
   */
  const playerSideQuests = async (gameMode, companyId) => {
    const result = await sequnceSrevices.playerProgres(gameMode, companyId)
    const sideQuests = await sequnceSrevices.getSideQuests()
    let newChests = []
    result.data.map((x, i) => {
      sideQuests.data.map((y, z) => {
        if (i === z && x.sequenceCompleted) {
          let bmcsObje;
          bmcsObje = Object.assign(x, {})
          bmcsObje.sideQuestInfo = y
          return newChests.push(bmcsObje)
        }
      })
    })
    setBonusChests(newChests)
  }

  useEffect(() => {
    const userEsg = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
    const budget = getLocalStorage(BUDGET).BUDGET
    const companyInfo = getLocalStorage(COMPANY_INFO).COMPANY_INFO
    setUserEsg(userEsg)
    setBudget(budget)
    setCompanyInfo(companyInfo)
    const gameMode = modeGame(budget)
    const companyId = companyInfo.id
    playerSideQuests(gameMode, companyId)
  }, [])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget])

   /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }

  useEffect(() => {

    if (userEsg) {
      setMentorImage(userEsg.mentor.avatarUrl)
    }
  }, [userEsg])
  /**
   * Redirect To Game function redirect user to game routed
   */
  const redirctToGame = () => {
    history.push('game')
  }
  /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }

  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)

  return (
    <div className="bonus-chest app">
      <div className="bonus-chest-section">
        <h2>{t('sidechest:side-head')}</h2>
        <div className="sequence-board-info-talk-mentor">
          {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
          <p className="hi-msg">{t('sidechest:side-note')}</p>
        </div>
        <div className="bonus-chest-holder fadeInBottom-bonus">
          {bonusChests ? bonusChests.map((x, index) => {
            return <Chest chest={x}
              index={index}
              key={x.sideQuestInfo._id}
              budget={budget}
              companyInfo={companyInfo}
              setBudget={setBudget}
              userStore={userStore}
              bonusChests={bonusChests}
              setBonusChests={setBonusChests} />
          }) : null}
        </div>
        <div className="button-landing">
          <Button
            additionalStyle="buttonMakeAcc"
            text={t('common:back-game')}
            customBtn={true}
            disabled={false}
            onClick={redirctToGame}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(inject('userStore')(observer(BonusChest)));
