import React, { useState, useEffect } from 'react';
import './nameCharacter.style.less';
import { withRouter } from 'react-router-dom';
import { inject } from "mobx-react";
import { observer } from 'mobx-react-lite'
import EsgService from '../../../services/EsgServices/esg.service'
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile';
import Button from '../../../components/Button/button.component';
import { Form, Input } from 'antd';
import { Avatar, avatarArr } from '../../../assets/images/index.js';
import { useTranslation } from 'react-i18next'
import ClickSound from '../../../assets/audio/button22.wav'

/**
 * Charcter name component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Title.Picture of choosen avatar.Input field where user can type in name of in-game character.
 * And two buttons : Contiue button after user selected avatar buttun is enabled to submit save user select of avatar and 
 * redirect him to next page MentorSelect name and
 * Back button for going back to previous page. 
 */
const AvatarName = ({ userStore, history, soundsStore, progressBarStore }) => {
  const { t } = useTranslation(["avatars", "common"]);

  /**
   * Property form from Antd Form.useForm()
   */
  const [form] = Form.useForm();
  /** Variable type Object where we set information and store about user in game .
   * Property we have in this object are:
   * avatarUrl(String),budgets(Object),city(String),firstName(String),gender(String),lastName(String),mentor(Object),
   * role(Object),userId(String),userName(String),yearOfBirth(Number) and id(String).
   * Default value is null .
  */
  const [userEsg, setUserEsg] = useState(null)
   /**
   * This is variable type of String where we set and store information about what user in-game name user typed in.
   * @type {String} name String with user in-game name
   * Default value is emty string.
   */
  const [nameInput, setNameInput] = useState('');
  /**
   * This is variable type of String where we set and store information about what avatar user selected.
   * @type {Object} avatar String with name of avatar
   * Default value is null .
   */
  const [avatar, setAvatar] = useState('')

  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);

   /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }
  /**
  *Component did mount.Setting up userEsg from localStoraga with method getLocalStorage with parmeter USER_PROFILE_ESG.
  Setting up progressBarStore with parameters (22,0,0).
  */
  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
    progressBarStore.setProgress(22, 0, 0)
  }, [])
  /**
  * Component did update.First check if userEsg have value and if it have set that value to userStore.
  * AvatarUrl is desturctured from userStore.userInfoEsg. Method find is used for finding chosen Avatar from
  * array of avatars by key and result is saved with useState in avatar state.
  * After that check if userStore.userInfoEsg have value and if it have set input value.
  * Also form input fild is set with value from userStore userInfoEsg userName
  */
  useEffect(() => {
    if (userEsg) {
      setUserStore(userEsg)
      const { avatarUrl } = userStore.userInfoEsg
      const chosenAvatar = avatarArr.find(avatar => avatar.key === avatarUrl)
      setAvatar(chosenAvatar)
    }
    if (userStore.userInfoEsg) {
      setNameInput(userStore.userInfoEsg.userName)
      form.setFieldsValue({ name: userStore.userInfoEsg.userName })
    }
  }, [userEsg, avatar])

  /**
   * Function where we call updateUserInfoEsg from userStore mobix user store and update there update 
   * userInfoEsg.
   * @param {Object} userEsg  
   * @returns updating userStore property userEsg
   */
  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }

  /**
   * Sumbit name function call esgService with method addCharName.Two argument are passed to addCharName function values and 
   * userStore.userInfoEsg.userId (id of user) . After response from backend first local storage USER_PROFILE_ESG is set with response data from backend.
   * After that userStore updateUserInfoEsg methoth is called and response is passed to it where userInfoEsg is updated.
   * At the end hisoty.push is called and user is redirected to mentorselect rout also form field is reseted.
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        esgService.addCharName(values, userStore.userInfoEsg.userId).then((res) => {
          setLocalStorage(res.data, USER_PROFILE_ESG)
          userStore.updateUserInfoEsg(res.data)
          history.push('/mentorselect')
          form.resetFields()
        })

      })
  };
  /**
   * Function for checking in case submiting for have error
   * @param {Object} errorInfo 
   * @returns Error object in case form is invalid
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  /**
   * Function that take one parametar value from input field.
   * @param {String} value Value of input user that typed
   * @returns Setting value of nameInput
   */
  const onStartInput = (value) => {
    setNameInput(value);
  };

  return (
    <div className="name-avatars app">
      <div className="name-section-avatars">
        <h2>{t('avatars:avatar-name')}</h2>
        <div className="name-avatars-holder">
          <Avatar src={avatar.src} />
        </div>
        <div className="name-input-holder">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t('avatars:name-message'),
                },
              ]}
            >
              <Input
                value={nameInput}
                onChange={(e) => onStartInput(e.target.value)}
                placeholder={t('avatars:name-message')}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="button-landing">
          <Button additionalStyle="signIn" text={t('common:landing-back')} customBtn={true} onClick={() => { history.goBack() }} />
          <Button
            additionalStyle="buttonMakeAcc"
            text={t('common:landing-continue')}
            customBtn={true}
            disabled={nameInput === ''}
            onClick={() => { onFinish(); playSound(likeAudio); }}
          />
        </div>
      </div>
    </div >
  );
};

export default withRouter(inject("userStore", 'soundsStore', 'progressBarStore')(observer(AvatarName)));
