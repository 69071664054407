import React from 'react';
import './verifyEmail.component.style.less';
import { withRouter } from 'react-router-dom';
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'

const verifyEmail = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation("common");
  return (
    <div className="verify-email app">
      <div className="verify-email-section">
        <div className="verify-holder fadeInBottom-verify">
          <h2>{t('common:verify-email')}</h2>
        </div>
      </div>
    </div>
  );
};

export default withRouter(verifyEmail);
