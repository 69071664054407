import React from 'react'
import { withRouter } from 'react-router-dom';
import './home.container.style.less'

const Home = ({history}) => {
    return (
        <div>
            <button onClick={()=>{history.push('/avatars')}}>JUST PUSH FOR AVATARS</button>
        </div>
    )
}

export default withRouter(Home)