import React, { useState } from 'react'
import Modal from '../modal.component'
import Button from '../../Button/button.component'
import './modalBmc.component.style.less'
import { useTranslation } from 'react-i18next'


const ModalBmc = ({ title, visible, onCancel, disableEnforceFocus, bmcValue, id, onChange, value, onClick }) => {
    const { t } = useTranslation('user-profile')
    return (
        <Modal
            modalStyleTest='modal-content-bmc'
            closable={false}
            visible={visible}
            title={title}
            onChange={onChange}
            disableEnforceFocus={disableEnforceFocus}
            bmcValue={bmcValue}
        >
            <div className="contentHeader"><h3 dangerouslySetInnerHTML={{ __html: bmcValue }}></h3></div>
            <textarea id={id} type="text" onChange={onChange} value={value} />

            <div className="button-landing">
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('user-profile:cancels')}
                    customBtn={true}
                    onClick={onCancel}
                    disabled={false}
                />
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t('user-profile:save')}
                    customBtn={true}
                    onClick={onClick}
                    disabled={value ? false : true}
                />
            </div>
        </Modal>
    )
}

export default ModalBmc