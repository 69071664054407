import React from 'react';
import './survey.component.style.less';
import Button from '../../../components/Button/button.component';

const Survey = () => {

  return (
    <div className="how-to app">
      <div className="how-to-complete">
        <h2>1) Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint?</h2>
        <textarea className="textarea"></textarea>
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="signIn"
          text="Back"
          customBtn={true}
        />
        <Button
          additionalStyle="buttonMakeAcc"
          text="Continue"
          customBtn={true}
        />
      </div>
    </div>
  );
};

export default Survey;
