import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { setLocalStorage, getLocalStorage, COMPANY_INFO } from '../../../localStorageMobxStore/localStorageUserProfile';
import './chart.component.style.less';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';


/**
 * @class
 * @returns Char componet with parameters width(String) ,height(String),chartType(String),
 * loader(JSX.element),data(Object),options(Object),rootProps(any)
 */
const ChartPie = () => {
  const [companyInfo, setCompanyInfo] = useState(null)
  const [rent, setRent] = useState(null)
  const [salaries, setSalaries] = useState(null)
  const [tax, setTax] = useState(null)
  const [equipment, setEquipment] = useState(null)

  const { t } = useTranslation(['billpayment'])


  useEffect(() => {
    const getAllData = getLocalStorage(COMPANY_INFO).COMPANY_INFO
    if (getAllData) {
      setCompanyInfo(getAllData)
      setRent(getAllData.monthlyExpense.rent)
      setSalaries(getAllData.monthlyExpense.salaries)
      setTax(getAllData.monthlyExpense.tax)
      setEquipment(getAllData.monthlyExpense.equipment)
    } else {
      setCompanyInfo(0)
      setRent(0)
      setSalaries(0)
      setTax(0)
      setEquipment(0)
    }
  }, [])

  return (
    <div className='chart-comp' style={{ display: 'flex' }}>
      <Chart
        width={'90%'}
        height={'200px'}
        chartType="PieChart"
        loader={<div>{t("billpayment:chart.loading")}</div>}
        data={[
          ['Utrošak', 'novca'],
          [t("billpayment:rent"), rent],
          [t("billpayment:salaries"), salaries],
          [t("billpayment:tax"), tax],
          [t("billpayment:equipment"), equipment],
        ]}
        options={{
          title: `${t("billpayment:chart.title")}`,
          is3D: true,
          titleTextStyle: {
            fontSize: 22,
          },
          colors: ['#CF3DFF', '#FF00A1', '#FF5F4D', '#FFA600']
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default inject('languageStore')(observer(ChartPie))

