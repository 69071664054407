import React from 'react'
import Modal from '../modal.component'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import './hint.modal.bmc.component.style.less'


const HintModal = ({languageStore,btnText,okBtnText,closable,visible,trigerVisible,footer,handleOk,maskClosable,sequence}) => {
    const selectLanguage = () => {
        const { summary } = sequence
        if(languageStore.language === 'bhs'){
            return summary.bhs.hint
        }else if (languageStore.language === 'en'){
            return summary.en.hint
        }else {
            return summary.en.hint
        }
    }
    return (
        <Modal
            modalStyleTest='modal-content-equipment'
            closable={closable}
            okBtnText={okBtnText}
            footer={footer}
            btnText={btnText}
            additionalStyle="signUp"
            visible={visible}
            trigerVisible={trigerVisible}
            handleOk={handleOk}
            maskClosable={maskClosable}
        >
            <p className="hint-help" dangerouslySetInnerHTML={{ __html:selectLanguage()}}></p>
        </Modal>
    )
}

export default inject('languageStore')(observer(HintModal))