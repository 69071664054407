import React from "react";
import './counter.component.style.less';
import { Input } from "antd";

const Counter = ({ value, handleChange, MAX_VALUE = 8, MIN_VALUE = 0 }) => {

  //#region behavior methods
  const handleFinalChange = (value) => {
    if (!value || typeof value === 'string') {
      handleChange(MIN_VALUE)
    } else if (value < MIN_VALUE) {
      handleChange(MIN_VALUE)
    } else if (value > MAX_VALUE) {
      handleChange(MAX_VALUE)
    } else {
      handleChange(value)
    }
  }

  const handleAdd = () => {
    handleFinalChange(value + 1);
  }

  const handleSubtract = () => {
    handleFinalChange(value - 1);
  }

  const handleLocalChange = (e) => {
    const { value } = e.target
    handleFinalChange(value);
  }
  //#endregion

  return (
    <div className="counter-holder">
      <div
        onClick={handleSubtract}
        className="minus-holder"
      >
        {/* <Minus /> */}
        <span> - </span>
      </div>
      <Input
        type="number"
        min={0}
        value={value}
        min={MIN_VALUE}
        max={MAX_VALUE}
        onChange={handleLocalChange}
        readOnly={true}
        size="small"
      />
      <div
        onClick={handleAdd}
        className="plus-holder"
      >
        {/* <Add /> */}
        <span> + </span>
      </div>
    </div>
  );

}

export default Counter;