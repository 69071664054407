import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './footer.container.style.less';
import { useTranslation } from 'react-i18next'
/**
 * Foter component
 * @class
 * @param {Object} properties (history)
 * @returns Paragraf with text content and two link terms-and-conditions that 
 * redirect user to terms-and-conditions page and privacy-policy link that redirect user
 * privacy-policy route
 */
const FooterNavigation = ({ history }) => {
  const { t } = useTranslation("common")
  const [updateFooter, setupdateFooter] = useState({
    appClass: 'footer-main',
  });

  useEffect(() => {
    let currentUrl = window.location.href;
    if (currentUrl === 'http://localhost:4200/#/') {
      setupdateFooter({ appClass: 'footer-landing' });
    }
  }, [])

  return (
    <div className={updateFooter.appClass}>
      <div className="footer-holder">
        <div className="left-corner-footer">
          <p>{t("common:all-rights")}</p>
        </div>
        <div className="right-corner-footer">
          <a onClick={() => history.push('/terms-and-conditions')}>{t("common:terms")}</a>
          <a onClick={() => history.push('/privacy-policy')}>{t("common:privacy")}</a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FooterNavigation);
