import React, { useState, useEffect, useRef } from 'react'
import AnswerModal from '../../../../components/Modal/ModalBMC/modalBmc.component'
import { Coins } from '../../../../assets/images/index.js';
import SequnceService from '../../../../services/EsgServices/sequence.service';
import { modeGame } from '../../../../helperFunctions/helperFunctions'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import './chest.style.less'
import { BUDGET, setLocalStorage } from '../../../../localStorageMobxStore/localStorageUserProfile';

/**
 * Chest component
 * @class Picture and on top of picture number of chest is shown.On click modal is open 
 * with title , input fiedl and two buttons save and cancel
 * @param {Object} properties (history, userStore)
 * @returns Title, list of bonus chests components and button that redirect user to game.
 */
const Chest = ({ chest, index, budget, companyInfo, userStore, bonusChests, setBonusChests, languageStore }) => {
    const [visible, setVisible] = useState(false)
    const [inputAsnwer, setInputAnswer] = useState('')
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
     * service where are esg services sequnce methods for calling api's are stored.
     */
    const sequnceService = new SequnceService()

    /**
     * Handle Ok function.Calls sequnce service with method answerSideQuest passing data object
     * when response come from server side bonusChest state is updated local storage BUDGET is 
     * updated with newBudge,mobix userStore budget alse is updated with newBudgde visble state is set to false.
     */
    const handleOk = () => {
        const gameMode = modeGame(budget)
        const companyId = companyInfo.id
        const data = {
            gameMode,
            companyId,
            answer: inputAsnwer,
            sidequestId: chest.sideQuestInfo._id,
            budgetId: budget.budget._id
        }
        sequnceService.answerSideQuest(data).then((res) => {
            let updateProsgres = Object.assign(chest, {})
            updateProsgres.sideQuests = res.data.progress.sideQuests
            let updateChests = [...bonusChests]
            updateChests[updateChests.findIndex(x => x._id === updateProsgres._id)] = updateProsgres
            setBonusChests(updateChests)
            let newBudget;
            newBudget = Object.assign(budget, {})
            newBudget.budget.remaining = res.data.remainingBudget
            setLocalStorage(newBudget, BUDGET)
            userStore.updateBudget(newBudget)
            setVisible(false)
        })
    }
    /**
     * Function close Modal that set state of visilbe to true
     */
    const openChestModal = () => {
        setVisible(true)
    }
    /**
     * Function close Modal that set state of visilbe to false
     */
    const closeModal = () => {
        setVisible(false)
    }
    /**
     * Type answer function
     * @param {Object} e event
     * @returns set state of inputAnser to valu that user typed in to input
     */
    const typeAnswer = (e) => {
        setInputAnswer(e.target.value)
    }
    /**
   * Select Language function that takes one parametar value check what lagngue we are 
   * currently using from mobx languageStore.language
   * @param {Object} building 
   * @returns Description in BHS language or EN language for paragraph
   */
    const selectLanguage = (value) => {
        if (languageStore.language === 'bhs') {
            return value.bhs.description
        } else if (languageStore.language === 'en') {
            return value.en.description
        } else {
            return value.en.description
        }
    }
    return (
        <div className={chest.sideQuests.length > 0 ? 'disable-edit' : 'enable-edit'}>
            <div onClick={!chest.sideQuests.length ? openChestModal : null} className="content-wrapper">
                <p>{index + 1}</p>
                <Coins />
            </div>
            {visible ?
                <AnswerModal
                    visible={visible}
                    className='roki'
                    cancelBtnText="Cancel"
                    okBtnText="Answer Bmc Question"
                    trigerVisible={closeModal}
                    onClick={handleOk}
                    onCancel={closeModal}
                    footer={true}
                    bmcValue={selectLanguage(chest.sideQuestInfo)}
                    onChange={(e) => typeAnswer(e)}
                    value={inputAsnwer} />
                : null}
        </div>
    )
}

export default inject('userStore', 'languageStore')(observer(Chest))