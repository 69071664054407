import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Download component
 * @class
 * @param {Object} properties (withAccelerator, withoutAccelerators)
 * @returns This component is used in chartAccelerator component
 * to download Excel file based on the data form props
 */
const Download = ({ withAccelerator, withoutAccelerator }) => {
    const { t } = useTranslation("admin")
    const dataSet1 = [
        {
            name: t("admin:excels-acc.accW"),
            amount: withAccelerator ? withAccelerator : 0
        },
        {
            name: t("admin:excels-acc.accN"),
            amount: withoutAccelerator ? withoutAccelerator : 0
        },
    ];
    return (
        <ExcelFile filename='accelerators' element={<button className="buttonData">{t("admin:excels-acc.download")}</button>}>
            <ExcelSheet data={dataSet1} name={t("admin:excels-acc.nameA")}>
                <ExcelColumn label={t("admin:excels-acc.lblN")} value="name" />
                <ExcelColumn label={t("admin:excels-acc.lblU")} value="amount" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default Download;