import React, { useState } from 'react'
import './singleplayerAcc.component.style.less'
import { Avatar, avatarArr, EmployeAvatar, HardwareEquipment, BuildingType, Coins } from '../../../../assets/images';
import { Rate } from 'antd';
import InfoModalEmployee from '../../../../components/Modal/ModalInfoEmployee/modalInfoEmployee.component'
import ModalInfoOffice from '../../../../components/Modal/ModalInfoOffice/modalInfoOffice.component'
import Download from '../../../../components/Modal/ModalViewProfile/excel'

const SingleplayerAcc = ({ userEsg, budgetAccelerator, companyAccelerator, bmcAccelator }) => {
    const employes = companyAccelerator.team.teamMembers.slice(1)
    const equipmentList = companyAccelerator.equipment
    const building = companyAccelerator
    const identitys = userEsg.identity

    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }

    const userProfileImage = utilityFilter(avatarArr, userEsg.avatarUrl)

    return (
        <div>
            <div className="general-info">
                <div className="image-row">
                    {!userEsg.avatarUrl ? null : <Avatar src={userProfileImage[0].src} />}
                    <Download user={userEsg}/>
                </div>
                <div className="startup-name">
                    <p>Name and Surname</p>
                    <span>{identitys.name} </span>
                </div>
                <div className="startup-name">
                    <p>Username</p>
                    <span>{userEsg.userName} </span>
                </div>
                <div className="startup-name">
                    <p>Email</p>
                    <span style={{ textTransform: 'lowercase' }}>{identitys.email} </span>
                </div>
                <div className="startup-name">
                    <p>City</p>
                    <span>{userEsg.city} </span>
                </div>
                <div className="startup-name">
                    <p>Year of Birth</p>
                    <span>{userEsg.yearOfBirth} </span>
                </div>
                <div className="startup-name">
                    <p>Gender</p>
                    <span>{userEsg.gender} </span>
                </div>
                <div className="startup-name">
                    <p>Startup name</p>
                    <span>{companyAccelerator.name} </span>
                </div>
                <div className="startup-name">
                    <p>Industry type</p>
                    <span>{companyAccelerator.industry} </span>
                </div>
                <div className="startup-name">
                    <p>Legal-administrative aspect</p>
                    <span>{companyAccelerator.legalAspect} </span>
                </div>

                {/* <div className="emplyeesList">
                    <h2>Your team:</h2>
                    <div className="inline-holder">
                        {employes[0].employee != null ? employes.map((x) => {
                            return (
                                <div className="raising" key={x.employee._id}>
                                    <EmployeAvatar key={x.employee._id} avatarUrl={x.employee.avatarUrl} />
                                    <span className="money-count">
                                        <Rate disabled value={x.employee.skill} allowHalf={true} />
                                    </span>
                                    <InfoModalEmployee
                                        avatarUrl={x.employee.avatarUrl}
                                        details={x.employee.details}
                                        name={x.employee.firstName}
                                        skill={x.employee.skill}
                                        salary={x.employee.salary}
                                        role={x.role} />
                                </div>
                            )
                        })
                            :
                            <div>
                                <h2 className="red">You haven't choose any team members</h2>
                            </div>
                        }
                    </div>

                </div> */}

                <div className="buildingList">
                    <h2>Office:</h2>
                    {building ?
                        <div className="building-holder">
                            <BuildingType imageUrl={building.building.imageUrl} ecoFriendly={building.building.ecoLevel} />
                            <div className="building-text">
                                <h2>{building.building.en.name}</h2>
                                <p className="green">
                                    {building.building.price}<Coins />
                                </p>
                            </div>
                            <div className="more-info-building">
                                <ModalInfoOffice
                                    imageUrl={building.building.imageUrl}
                                    contentHeader={building.building.en.name}
                                    contentPrice={building.building.price}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <h2 className="red">You haven't choose any office property</h2>
                        </div>
                    }
                </div>

                <div className="equipmentList">
                    <h2>Equipment:</h2>
                    {equipmentList?.length ? equipmentList.map((x) => {
                        return (
                            <div className="equipmentListHolder" key={x.item.en.name}>
                                {/* <HardwareEquipment key={x.item._id} imageUrl={x.item.imageUrl} /> */}
                                <p className="device-per-eqi">{x.item.en.name}</p>
                                <p className="device-per-eqi bolded">X{x.amount}</p>
                            </div>
                        )
                    })
                        :
                        <div>
                            <h2 className="red">You haven't choose any Equipment</h2>
                        </div>
                    }
                </div>
            </div >
            {bmcAccelator ? <div className='business-model'>
                <div id="printWrapper">
                    <h2 className="title-bmc">Business Model Canvas</h2>
                    <div className="bmc">
                        <div className={!bmcAccelator.keyPartners ? "block test-kp" : "block key-partners"} key="1">
                            <div className="click" id="keyPartners"></div>
                            <h2 className="title">Key Partners</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.keyPartners}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.keyActivities ? "block test-ka" : "block key-activities"}>
                            <div className="click" id="keyActivities" ></div>
                            <h2 className="title">Key Activities</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.keyActivities}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.keyResources ? "block test-kr" : "block key-resources"}>
                            <div className="click" id="keyResources" ></div>
                            <h2 className="title">Key Resources</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.keyResources}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.valueProposition ? "block test-vp" : "block value-propositions"}>
                            <div className="click" id="valueProposition" ></div>
                            <h2 className="title">Value Propositions</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.valueProposition}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.customerRelationships ? "block test-cr" : "block customer-relationships"}>
                            <div className="click" id="customerRelationships" ></div>
                            <h2 className="title">Customer Relationships</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.customerRelationships}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.channels ? "block test-c" : "block channels"}>
                            <div className="click" id="channels" ></div>
                            <h2 className="title">Channels</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.channels}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.customerSegment ? "block test-cseg" : "block customer-segments"}>
                            <div className="click" id="customerSegment" ></div>
                            <h2 className="title">Customer Segments</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.customerSegment}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.costStructure ? "block test-cst" : "block cost-structure"}>
                            <div className="click" id="costStructure" ></div>
                            <h2 className="title">Cost Structure</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.costStructure}</p>
                                </li>
                            </ul>
                        </div>

                        <div className={!bmcAccelator.revenueStreams ? "block test-rs" : "block revenue-streams"}>
                            <div className="click" id="revenueStreams" ></div>
                            <h2 className="title">Revenue Stream</h2>
                            <ul className="ingress">
                                <li>
                                    <p>{bmcAccelator.revenueStreams}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

export default SingleplayerAcc