import { observable, action } from "mobx";


export class SoundsStore {

    @observable sounds = false

    @action enableSounds = () => {
        this.sounds = true
    }
    
    @action disableSounds = () => {
        this.sounds = false
    }
}

export default new SoundsStore()