import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { setLocalStorage, getLocalStorage, USER_PROFILE_ESG, COMPANY_INFO, BUDGET } from '../../../localStorageMobxStore/localStorageUserProfile';
import './selectLegalAspects.component.style.less';
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'
import EsgService from '../../../services/EsgServices/esg.service'
import IntroOfficeModal from '../../../components/Modal/IntroductionOfficeModal/introOfficeModal.component'
import ClickSound from '../../../assets/audio/button22.wav'

import Animation7 from '../../../assets/video/animation7.mp4'
import Animation7BHS from '../../../assets/video/animation7BHS.mp4'
import AnimtionLeaglAspectsBHS from '../../../assets/video/AnimacijaConversation_about_founding_a_company_BHS.mp4'
import AnimtionLeaglAspectsEN from '../../../assets/video/AnimacijaConversation_about_fou_ding_a_company_EN.mp4'
import VideoLoad from '../../../components/Video/video.component'

/**
 * Equipment Payment component
 * @class 
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns First modal is opened with video and Skip button covering component. After modal is closed.
 * Title and two buttons are displayed every button for each type of legal-administrative aspects.
 * Continue button that redirect user to onboardingsequence component.Back button for going back to previous page. 
 */
const LegalAspects = ({ userStore, history, soundsStore, languageStore, progressBarStore }) => {
  const { t } = useTranslation('legal', "common")
  const [userEsg, setUserEsg] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [budget, setBudget] = useState(null)
  const [selectSoloTrader, setselectSoloTrader] = useState(null);
  const [selectLtd, setselectLtd] = useState(null);
  const [testModal, setTestModal] = useState(true)
  const [visible, setVisible] = useState(true)

  const [viedo1,setVideo1] = useState(false)
  const [video2,setVideo2] = useState(false)

  /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
  const esgService = new EsgService()
  /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
  const likeAudio = new Audio(ClickSound);
  /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
  const playSound = audioFile => {
    if (soundsStore.sounds) {
      audioFile.play();
    }
  }

  const toggleContent = () => {
    setTestModal(false)
    setVisible(false)
  }

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
    const resBudgetLS = getLocalStorage(BUDGET).BUDGET
    esgService.getBudget(resBudgetLS.budget._id).then((res) => {
      const budget = {
        budget: res.data
      }
      setBudget(budget)
      setLocalStorage(budget, BUDGET)
    })
    progressBarStore.setProgress(100, 96, 0);
  }, [])

  useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        history.replace('officeselect');
      }
    };
  }, [history])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg, companyInfo, budget,viedo1])
  /**
   * Function that three parametars userEsg , singlePlayer ,companyInfo
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo.
   */
  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }
  /**
   * Function that set  state of selectLtd to true and state of 
   * selectSoloTrader to false
   */
  const setUpdateSelectLtd = () => {
    setselectLtd(true);
    setselectSoloTrader(false);
  };
  /**
   * Function that set  state of selectSoloTrader to true and state of 
   * selectLtd to false
   */
  const setUpdateSelectSoloTrader = () => {
    setselectSoloTrader(true);
    setselectLtd(false);
  };

  /**Submit payment function that take no parameters.First we create variable companyId and legal.
   * After that we chack if selectSoloTrader SoleTrader or LTD and set value to legal.EsgService is called 
   * with function legalAspects parameters company id and legal are passed.Object obj is created and 
   * res.data.legalAspect is passed to obj.legalAspect property. LocalStorage COMPANY_INFO is updated with
   * obj and mobix userStore companyInfo is update with obj. At the end user is redirected to onboardingsequence rounte.
   *
   */
  const submitAspect = () => {
    const companyId = companyInfo.id
    let legal;
    if (selectSoloTrader) {
      legal = 'SoleTrader'
    } else {
      legal = 'LTD'
    }
    esgService.legalAspects(companyId, legal).then((res) => {
      let obj = Object.assign(companyInfo, {})
      obj.legalAspect = res.data.legalAspect
      setLocalStorage(obj, COMPANY_INFO)
      userStore.updateCompanyInfo(obj)
      history.push('/onboardingsequence')
    })
  }

  const VideosComponent = () => {
    if(!viedo1){
      return (
        <VideoLoad url={languageStore.language === 'bhs' ? Animation7BHS : Animation7} setVideoEnd={setVideo1} />
      )
    }else {
      return (
        <div>
          <VideoLoad url={languageStore.language === 'bhs' ? AnimtionLeaglAspectsBHS : AnimtionLeaglAspectsEN} setVideoEnd={setVideo2} />
        </div>
      )
    }
  }

  return (
    <div className="legal-aspects app">
        {!video2 ? <VideosComponent/> :
        <div className="legal-aspects-section">
          <h2>{t('legal:legal-aspects')}</h2>
          <div className="button-legal-holder">
            <div
              className={
                !selectSoloTrader
                  ? 'legal-text fadeLeft'
                  : 'legal-text-selected'
              }
              onClick={() => { setUpdateSelectSoloTrader(); playSound(likeAudio); }}
            >
              <h3>{t('legal:sole.text')}</h3>
              <p>
                {t('legal:sole.definition')}
              </p>
            </div>
            <div
              className={
                !selectLtd
                  ? 'legal-text fadeRight'
                  : 'legal-text-selected'
              }
              onClick={() => { setUpdateSelectLtd(); playSound(likeAudio); }}
            >
              <h3>{t('legal:ltd.text')}</h3>
              <p>
                {t('legal:ltd.definition')}
              </p>
            </div>
          </div>
          <div className="button-landing">
            <Button
              additionalStyle="signIn"
              text={t('common:landing-back')}
              customBtn={true}
              onClick={() => {
                history.replace('officeselect');
              }}
            />
            <Button
              additionalStyle="buttonMakeAcc"
              text={t('common:landing-continue')}
              customBtn={true}
              disabled={selectSoloTrader || selectLtd ? false : true}
              onClick={() => { submitAspect(); playSound(likeAudio); }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('userStore', 'soundsStore', 'languageStore', 'progressBarStore')(observer(LegalAspects)));
