import React from 'react';
import {Checkbox as AntCheckbox} from 'antd';
import PropTypes from 'prop-types';
import './checkbox.style.less';

const Checkbox = ({
                      children, // Children (props.children)
                      checked, // Specifies whether the checkbox is selected.
                      defaultChecked, // Specifies the initial state: whether or not the checkbox is selected.
                      disabled, // Disable checkbox
                      onChange, // The callback function that is triggered when the state changes.
                      text // checkbox label
                  }) => (
    <AntCheckbox
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}>{text}{children}</AntCheckbox>
);

Checkbox.defaultProps = {
    checked: false,
    defaultChecked: false,
    disabled: false,
    onChange: () => {
    },
    text: ''
};

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string
};

export default Checkbox;
