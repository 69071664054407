import React, { useState, useEffect } from 'react'
import Modal from '../modal.component'
import './introOfficeModal.component.style.less'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { getLocalStorage, USER_PROFILE_ESG } from '../../../localStorageMobxStore/localStorageUserProfile'
import { Avatar, avatarArr, Mentor, mentorArr } from '../../../assets/images'
import { useTranslation } from 'react-i18next'

const IntroOfficeModal = ({ userStore, contentMsgOne, contentMsgTwo, contentMsgThree, contentMsgFour, contentMsgFive, onOk, userTalk, mentorMore, contentMsgMoreOne, contentMsgMoreTwo, userTalk2, contentUserOne, contentUserTwo, mentorMore2, contentMsgMore2One, contentMsgMore2Two }) => {

  const { t } = useTranslation(["common"])

  const [userEsg, setUserEsg] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userImage, setUserImage] = useState(null)
  const [mentorImage, setMentorImage] = useState(null)
  const [mentorName, setMentorName] = useState(null)
  const [visible, setVisible] = useState(true);
  const ref = React.createRef();
  const refMentorSecond = React.createRef();
  const refUserSecond = React.createRef();
  const refMentorThirdLast = React.createRef();
  const refMentorSecondNd = React.createRef();
  const refMentorThirdFirst = React.createRef();
  const refThree = React.createRef();
  const refFourth = React.createRef();
  const refFifth = React.createRef();

  const handleScrollToUser = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'center'
    });

  const handleScrollToMentorSecond = () =>
    refMentorSecond.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'center'
    });

  const handleScrollToMentorSecondNd = () =>
    refMentorSecondNd.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'center'
    });


  const handleScrollToUserSecond = () =>
    refUserSecond.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToMentorThirdLast = () =>
    refMentorThirdLast.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });

  const handleScrollToMentorThirdFirst = () =>
    refMentorThirdFirst.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToThree = () =>
    refThree.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToFourth = () =>
    refFourth.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  const handleScrollToFifth = () =>
    refFifth.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });

  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
  }, []);

  useEffect(() => {

    if (userEsg) {
      setUserStore(userEsg)
      setUserName(userEsg.userName)
      setUserImage(userEsg.avatarUrl)
      setMentorImage(userEsg.mentor.avatarUrl)
      setMentorName(userEsg.mentor.name)
    }
  }, [userEsg])


  useEffect(() => {
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
  }, []);

  const setUserStore = (userEsg) => {
    userStore.updateUserInfoEsg(userEsg)
  }

  const utilityFilter = (arr, image) => {
    return arr.filter((x) => {
      return x.key === image
    })
  }

  const mentorProfileImage = utilityFilter(mentorArr, mentorImage)
  const userProfileImage = utilityFilter(avatarArr, userImage)

  return (
    <Modal
      modalStyleTest='modal-content-mentor-intro'
      closable={false}
      okBtnText={t('common:landing-continue')}
      additionalStyle="signUp"
      footer={true}
      additionalStyle="signUp"
      visible={visible}
      handleOk={onOk}
    >
      <div className="meet-mentor-section">
        <div className="button-mentor-holder">
          <div className='mentor-text'>
            {/* <h3 className="contentHeader">{mentorName}</h3> */}
            <div className="text-speach">
              <div className="mentor-talk">
                {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                {contentMsgOne ? <p className='expl-msg fadeFirst' style={{ marginTop: "0px" }}>{contentMsgOne}</p> : null}
                {contentMsgTwo ? <p className='expl-msg margin fadeSecond'>{contentMsgTwo}</p> : null}
                {contentMsgThree ? <p className='expl-msg fadeThird' ref={refThree} onAnimationEnd={() => handleScrollToThree()}>{contentMsgThree}</p> : null}
                {contentMsgFour ? <p className='expl-msg fadeFourth' ref={refFourth} onAnimationEnd={() => handleScrollToFourth()}>{contentMsgFour}</p> : null}
                {contentMsgFive ? <p className='expl-msg fadeFifth' ref={refFifth} onAnimationEnd={() => handleScrollToFifth()}>{contentMsgFive}</p> : null}
              </div>
              {userTalk ?
                <div className="user-talk" ref={ref} onAnimationEnd={() => handleScrollToUser()} style={{ marginBottom: "10px" }}>
                  {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
                  <p className="hi-msg-user fadeSecond">{contentUserOne}</p>
                </div> : null}
              {mentorMore ?
                <div className="mentor-talk mentorMore">
                  {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                  {contentMsgMoreOne ? <p ref={refMentorSecond} onAnimationStart={() => handleScrollToMentorSecond()} className='hi-msg fadeSecond'>{contentMsgMoreOne}</p> : null}
                  {contentMsgMoreTwo ? <p ref={refMentorSecondNd} onAnimationStart={() => handleScrollToMentorSecondNd()} className='expl-msg fadeThird'>{contentMsgMoreTwo}</p> : null}
                </div> : null
              }
              {userTalk2 ?
                <div className="user-talk userMore" ref={refUserSecond} onAnimationStart={() => handleScrollToUserSecond()}>
                  {!userImage ? null : <Avatar src={userProfileImage[0].src} />}
                  <p className="hi-msg-user fadeSecond">{contentUserTwo}</p>
                </div> : null}
              {mentorMore2 ?
                <div className="mentor-talk mentorMore2" >
                  {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                  {contentMsgMoreOne ? <p ref={refMentorThirdFirst} onAnimationStart={() => handleScrollToMentorThirdFirst()} className='hi-msg fadeSecond'>{contentMsgMore2One}</p> : null}
                  {contentMsgMoreTwo ? <p ref={refMentorThirdLast} onAnimationStart={() => handleScrollToMentorThirdLast()} className='expl-msg fadeThird'>{contentMsgMore2Two}</p> : null}
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(inject('userStore')(observer(IntroOfficeModal)));