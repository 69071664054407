import HttpClient from '../core/http';
import confing from '../../config/config.local';

class SequnceService {

    constructor() {
        this.http = new HttpClient(confing.API_BASE_URL_ESG);
    }

    async answerQuestion(data, query = {}) {
        const { http } = this;
        const { budgetId,questionId,answerGiven,mentorUsed,sequenceId,gameMode,progressId } = data
        const body = {
            budgetId,
            questionId,
            answerGiven,
            mentorUsed,
            sequenceId,
            gameMode,
            progressId
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/proccess/answer-question`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }

    async getIdsOfSequnces(data, query = {}) {
        const { http } = this;
        const { sequenceNrs , industry } = data
        const body = {
            sequenceNrs,
            industry
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/sequences/multiple`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }
    async getSequnce(sequenceId, industry, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/sequences/${sequenceId}/${industry}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }

    async playerProgresCreate(data, query = {}) {
        const { http } = this;
        const { companyId , sequenceId, gameMode} = data
        const body = {
            companyId,
            sequenceId,
            gameMode
        }
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/player-progress/create`);
        const url = http.combine(path, queryString);
        return this.http.post(url, body)
    }

    async getProgress(data, query = {}) {
        const { companyId,mode } = data
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/player-progress/player/${mode}/${companyId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }

    async postRandomEvent(data, query = {}) {
        const { budgetId,randomEventId } = data
        const body = {
            budgetId,
            randomEventId
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/proccess/complete-event`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }

    async postBmc(data, query = {}) {
        const { budgetId,companyId,key,value,gameMode,sequenceId } = data
        const body = {
            budgetId,
            companyId,
            key,
            value,
            gameMode,
            sequenceId
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/proccess/update-bmc`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }
    async getMountlyExpenses(data, query = {}) {
        const { companyId,budgetId } = data
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/companies/monthly-expenses/${companyId}/${budgetId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async postMontlyEvent(data, query = {}) {
        const { sequenceId,companyId,budgetId,gameMode} = data
        const body = {
            sequenceId,
            companyId,
            budgetId,
            gameMode
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/proccess/monthly-event`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }
    async takeLoan(budgetId,sequenceNr, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`companies/loan/${budgetId}/${sequenceNr}`);
        const url = http.combine(path, queryString);
        return this.http.post(url)
    }
    async getBmcs(companyId,gameMode, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/bmcs/${companyId}/${gameMode}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async getSideQuests(query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/sidequest/all`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    }
    async answerSideQuest(data, query = {}) {
        const { companyId,sidequestId,budgetId,answer,gameMode } = data
        const body = {
            companyId,
            sidequestId,
            budgetId,
            answer,
            gameMode
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`proccess/set-sidequest`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }
    async playerProgres(mode,companyId,query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`/player-progress/player/${mode}/${companyId}`);
        const url = http.combine(path, queryString);
        return this.http.get(url)
    } 
    async resetGame(companyId,budgetId, query = {}) {
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`proccess/reset/${companyId}/${budgetId}`);
        const url = http.combine(path, queryString);
        return this.http.post(url)
    }
    async updateBmc(data, query = {}) {
        const { companyId , gameMode, key,value } = data
        const body = {
            gameMode,
            key,
            value
        }
        const { http } = this;
        const queryString = http.qs.stringify(query);
        const path = http.url.build(`bmcs/${companyId}`);
        const url = http.combine(path, queryString);
        return this.http.post(url,body)
    }


}

export default SequnceService;