import React, { useState } from 'react';
import './successVerify.component.style.less';
import { useLocation, withRouter } from 'react-router-dom';
import Button from '../../../components/Button/button.component';
import { useTranslation } from 'react-i18next'
import AuthService from '../../../services/auth.service'
import { useEffect } from 'react';
import { Spin } from 'antd';

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}


/**
 * SuccessVerify component
 * @class
 * @param {Object} properties ( history )
 * @returns It will show spinner until user is logedin and then display text and button to open login page
 */
const SuccessVerify = ({ history }) => {
  const { t } = useTranslation(['common', 'registration']);
  const query = useQuery();
  const [verifyed, setVerifyed] = useState(false)
  const authService = new AuthService()

  useEffect(() => {
    verifyEmail()
  }, [])

    /**
     *Function that is used in component did mount to collect token and email and then try to do verification
    */
  const verifyEmail = () => {
    const token = query.get("token")
    const email = query.get("email")
    const data = {
      email,
      token
    }
    authService.mailVerification(data).then((res) => {
      if (res.data.status === 'ACTIVE') {
        setVerifyed(true)
      }
    })
  }

  /**
   *Function that leads to login page
  */
  const signIn = () => {
    history.push('login')
  }

  return (
    <div className="verify-awards app">
      {verifyed ?
        <div className="verify-section-awards">
          <div className="text-holder fadeInBottom-c">
            <h2>{t('common:success-verify')}</h2>
            <Button additionalStyle="signUp" text={t('registration:sign-in')} customBtn={true} onClick={signIn} />
          </div>
        </div>
        :
        <div className={!verifyed ? 'spinner-back' : 'spinner-non-back'}>
          <Spin size="large" spinning={!verifyed} />
        </div>}
    </div>
  );
};

export default withRouter(SuccessVerify);
