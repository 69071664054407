import React,{useState,useEffect} from 'react';
import './howToStart.component.style.less';
import { LeftBubleEnd, RightBubleEnd } from '../../../assets/images/index';
import Button from '../../../components/Button/button.component';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { getLocalStorage, USER_PROFILE_ESG, BUDGET, COMPANY_INFO } from '../../../localStorageMobxStore/localStorageUserProfile'

const HowToStart = ({history,userStore}) => {
  const [userEsg, setUserEsg] = useState(null)
  const [budget, setBudget] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)

  useEffect(()=>{
    setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG)
    setBudget(getLocalStorage(BUDGET).BUDGET)
    setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO)
  },[])

  useEffect(() => {
    if (userEsg || companyInfo || budget) {
      setUserStore(userEsg, companyInfo, budget)
    }
  }, [userEsg,companyInfo,budget])

  const setUserStore = (userEsg, companyInfo, budget) => {
    userStore.updateUserInfoEsg(userEsg)
    userStore.updateCompanyInfo(companyInfo)
    userStore.updateBudget(budget)
  }

  const redirect = () => {
    history.push('endsurvey')
  }
  return (
    <div className="how-to app">
      <div>
        <LeftBubleEnd />
        <RightBubleEnd />
      </div>
      <div className="how-to-complete">
        <h2>Download file to see how you can start your own startup</h2>
        <Button
          additionalStyle="buttonMakeAcc"
          text="Download file"
          customBtn={true}
        />
      </div>
      <div className="button-landing">
        <Button
          additionalStyle="buttonMakeAcc"
          text="Continue"
          customBtn={true}
          onClick={redirect}
        />
      </div>
    </div>
  );
};

export default withRouter(inject('userStore')(observer(HowToStart)));
