import { observable, action } from "mobx";


export class SequenceStore {

    @observable sequences = []
    @observable sequenceIndex = 0

    @action updatedSequences = (value) => {
        this.sequences = value
    }
    
    @action updateSequenceIndex = (sequenceIndex) => {
        this.sequenceIndex = sequenceIndex
    }
}

export default new SequenceStore()