import React, { useState, useEffect } from "react";
import "./employeeSelect.component.style.less";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import EsgService from "../../../services/EsgServices/esg.service";
import {
    getLocalStorage,
    USER_PROFILE_ESG,
    COMPANY_INFO,
    BUDGET,
    EMPLOYEES_PROFESSION,
    EMPLOYEES,
    setLocalStorage,
} from "../../../localStorageMobxStore/localStorageUserProfile";
import { Tabs } from "antd";
import { EmployeAvatar } from "../../../assets/images/index";
import Button from "../../../components/Button/button.component";
import InfoModalEmployee from "../../../components/Modal/ModalInfoEmployee/modalInfoEmployee.component";
import { Rate } from "antd";
import ClickSound from "../../../assets/audio/button22.wav";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import VideoLoad from '../../../components/Video/video.component'
import Team from '../../../assets/video/Team.mp4'
import TeamBHS from '../../../assets/video/TeamBHS.mp4'


const { TabPane } = Tabs;
/**
 * Choose Proffesion component
 * @class
 * @param {Object} properties (desturctured properties history , userStore  , soundStore , progressBarStore)
 * @returns Component with title and list of four proffesions for picked industry . Every proffesion
 * have list of four different employes.Every employi have picture , star rate and more info button. Clicking
 * on more info button open modal with list of inforations about employe and close button for closing modal.
 * And two buttons : Contiue button that redirect user to salarychoices .
 * Back button for going back to previous page. 
 */
const ChooseEmployees = ({
    history,
    userStore,
    soundsStore,
    languageStore,
    progressBarStore,
}) => {
    const { t } = useTranslation(["common", "employes"]);
    const [userEsg, setUserEsg] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [budget, setBudget] = useState(null);
    const [employeProffesion, setEmployeProffesion] = useState(null);
    const [allEmployes, setAllEmployes] = useState(null);
    const [selectedEmployes, setSelectedEployes] = useState([]);
    const [orderedEmploye, setOrderedEmploye] = useState([]);
    const [hiredEmployes, setHiredEmployes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [videoPlaying,setVideoPlaying] = useState(false)
    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
   * service where are esg services methods for calling api's stored.
   */
    const esgService = new EsgService();
    const [testModal, setTestModal] = useState(true);
    const [visible, setVisible] = useState(true);
    /**New variable "LikeAudio" new instacnce created from The Audio() constructor creates and returns a new HTMLAudioElement which 
   * can be either attached to a document for the user to interact with and/or listen to, 
   * or can be used offscreen to manage and play audio. */
    const likeAudio = new Audio(ClickSound);
    /**
   * Function playSound .Check if sound is actvice from soundStore.sounds.
   * If it is active clicking on one of the avatar from avatar list produce sound.
   * @type {HTML} selected HTML - selected Avatar
   * @returns audioFile play method active
   */
    const playSound = (audioFile) => {
        if (soundsStore.sounds) {
            audioFile.play();
        }
    };
    /**Function that close initial modal that is shown on top of page.
    */
    const toggleContent = () => {
        setTestModal(false);
        setVisible(false);
    };

    useEffect(() => {
        setLoader(true);
        setUserEsg(getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG);
        setCompanyInfo(getLocalStorage(COMPANY_INFO).COMPANY_INFO);
        setBudget(getLocalStorage(BUDGET).BUDGET);
        setEmployeProffesion(
            getLocalStorage(EMPLOYEES_PROFESSION).EMPLOYEES_PROFESSION
        );
        setAllEmployes(getLocalStorage(EMPLOYEES).EMPLOYEES);

        esgService.getEmployees().then((res) => {
            const allEployed = res;

            let employes = [];

            // Shuffle arrays
            const softwareShuffled = allEployed.software.sort(
                () => 0.5 - Math.random()
            );
            const bizdevShuffled = allEployed.bizdev.sort(
                () => 0.5 - Math.random()
            );
            const marketingShuffled = allEployed.marketing.sort(
                () => 0.5 - Math.random()
            );
            const customShuffled = allEployed.custom.sort(
                () => 0.5 - Math.random()
            );

            employes.push(
                softwareShuffled.slice(0, 4),
                bizdevShuffled.slice(0, 4),
                marketingShuffled.slice(0, 4),
                customShuffled.slice(0, 4)
            );

            const employeProff = Object.values(
                getLocalStorage(EMPLOYEES_PROFESSION).EMPLOYEES_PROFESSION
            );

            let newEmpArray = [];

            employeProff.forEach((proffesion, index) => {
                newEmpArray.push({
                    empArr: employes[index],
                    key: proffesion,
                });
            });
            setOrderedEmploye(newEmpArray);
            setLoader(false);
        });

        setSelectedEployes(
            selectedEmployes.concat(
                getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG.avatarUrl
            )
        );
        progressBarStore.setProgress(100, 28, 0);
    }, []);
    useEffect(() => {
        if (userEsg || companyInfo || budget || allEmployes) {
            setUserStore(userEsg, companyInfo, budget, allEmployes);
            if (!allEmployes) {
                setHiredEmployes((hiredEmployes) => [
                    ...hiredEmployes,
                    {
                        selected: true,
                        worker: userEsg,
                    },
                ]);
            } else {
                allEmployes.splice(0, 1);
                const arrayWithUser = [
                    {
                        selected: true,
                        worker: userEsg,
                    },
                    ...allEmployes,
                ];
                setHiredEmployes(arrayWithUser);
            }
        }
    }, [userEsg, companyInfo, budget, allEmployes]);
    /**
   * Function that four parametars userEsg , singlePlayer ,companyInfo , industry
   * @param {Object} companyInfo propertes for in game company
   * @param {Object} userEsg properties for user ingame profile
   * @param {Object} budget budget of user depending of mode he is playing
   * @param {Array} allEmployes array of employes selected 
   * @returns Updating mobix userStore properties userInfoEsg with new value userInfoEsg ,
   * budget with new value of budget ,companyInfo with new value of companyInfo and 
   * employees with new value of allEmployes
   */
    const setUserStore = (userEsg, companyInfo, budget, allEmployes) => {
        userStore.updateUserInfoEsg(userEsg);
        userStore.updateCompanyInfo(companyInfo);
        userStore.updateBudget(budget);
        userStore.updateHiredEmployees(allEmployes);
    };
    /**
     * Function Select Employe
     * @param {Object} employe 
     * @returns set and store employe to array state of hiredEmployes
     */
    const selectEmploye = (employe) => {
        if (hiredEmployes.length <= 4)
            setHiredEmployes((hiredEmployes) => [
                ...hiredEmployes,
                {
                    selected: true,
                    worker: employe,
                },
            ]);

        if (hiredEmployes.map((x) => x.worker._id).includes(employe._id)) {
            setHiredEmployes(
                hiredEmployes.filter(
                    (employed) => employed.worker._id !== employe._id
                )
            );
        }
    };
    /**
     * Deselect employe
     * @param {Object} employe 
     * @returns remove eploye from array state of hiredEmployes
     */
    const deselectEmploye = (employe) => {
        setHiredEmployes(
            hiredEmployes.filter((employed) => {
                if (employed.worker.role === "Player") return true;
                return employed.worker._id !== employe.worker._id;
            })
        );
    };
    /**
     * Avatar Functional Component
     * @param {Object} employe destructured properti 
     * @returns EmployeAvatar picture , Rate of employe amd Info Modal about employe
     */
    const AvatarComponent = ({ employe }) => {
        let selectedEmpoye = "";
        hiredEmployes.map((emp) => {
            if (emp.worker._id === employe._id) {
                selectedEmpoye = emp.selected;
            }
            return selectedEmpoye;
        });
        const fullName = `${employe.firstName}`;
        return (
            <div className="worker-holder">
                <EmployeAvatar
                    handleclick={() => {
                        selectEmploye(employe);
                        playSound(likeAudio);
                    }}
                    avatarUrl={employe.avatarUrl}
                    selected={selectedEmpoye}
                />
                <p>{fullName}</p>
                <span className="money-count">
                    <Rate disabled value={employe.skill} allowHalf={true} />
                </span>
                <InfoModalEmployee
                    avatarUrl={employe.avatarUrl}
                    name={fullName}
                    education={employe.education}
                    experience={employe.experience}
                    skills={employe.skills}
                    competencies={employe.competencies}
                    languages={employe.languages}
                    // skill={employe.skill}
                    salary={employe.salary}
                    role={employe.role}
                />
            </div>
        );
    };
    /**
     * Function Hire Team is fucntion that is called on Continue(submit) button.
     * To button to be enabled we need to hire 4 employes after that
     * we create object hiredEmployesId and objTeam . Than we call esgService and call 
     * method hireCustomTeam with parameter objTeam .When response come from backend ,first we
     * set localStorage EMPLOYEES with hiredEmployes after that we call esgService with method
     * getCompanyInfo with paramenter companyInfo.id and response we save to local storage
     * COMPANY_INFO and update mobix userStore company info with that response. At the end 
     * we redirect user to salarychoices route.
     */
    const hireTeam = () => {
        let hiredEmployesId = hiredEmployes.map((employe) => {
            return {
                role: employe.worker.role,
                employeeId: employe.worker._id,
            };
        });
        const objTeam = {
            companyId: companyInfo.id,
            employees: hiredEmployesId,
        };
        esgService
            .hireCustomTeam(objTeam)
            .then((res) => {
                setLocalStorage(hiredEmployes, EMPLOYEES);
            })
            .then(() => {
                esgService.getCompanyInfo(companyInfo.id).then((res) => {
                    setLocalStorage(res.data, COMPANY_INFO);
                    userStore.updateCompanyInfo(res.data);
                    history.push("/salarychoices");
                });
            });
    };
    const endHireTeamVideo = () => {
        setVideoPlaying(true)
    }

    return (
        <div>
            {videoPlaying ? 
            <VideoLoad url={languageStore.language === 'bhs' ? TeamBHS : Team} setVideoEnd={setVideoPlaying} hireTeam={hireTeam} teamSelect={true}/>:
            <div className="employee-selection app">
                <div className="employee-selection-section">
                
                    <div className="employee-holder fadeInBottom">
                        <h2>{t("employes:employes-header")}</h2>
                        {/* <Button additionalStyle="signUp" text="Sign In" customBtn={true} /> */}
                        <div className="tabs-holder">
                            <Tabs>
                                {orderedEmploye.length > 0 &&
                                    orderedEmploye.map((val) => {
                                        return (
                                            <TabPane key={val.key} tab={val.key}>
                                                <div className="all-workers-holder fadeInBottom">
                                                    {val.empArr.map((employe) => {
                                                        return (
                                                            <AvatarComponent
                                                                employe={employe}
                                                                key={employe._id}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </TabPane>
                                        );
                                    })}
                            </Tabs>
                        </div>
                        <div className="selected-employees">
                            <p>{t("employes:employes-current")}</p>
                            <div className="selected-team">
                                {hiredEmployes.map((employe) => {
                                    return (
                                        <div
                                            className="selected-team-holder"
                                            key={employe.worker._id}
                                        >
                                            {" "}
                                            <EmployeAvatar
                                                handleclick={() => {
                                                    deselectEmploye(employe);
                                                    playSound(likeAudio);
                                                }}
                                                avatarUrl={employe.worker.avatarUrl}
                                            />{" "}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="button-landing">
                            <Button
                                additionalStyle="signIn"
                                text={t("common:landing-back")}
                                customBtn={true}
                                onClick={() => {
                                    history.goBack();
                                }}
                            />
                            <Button
                                additionalStyle="buttonMakeAcc"
                                text={t("common:landing-continue")}
                                customBtn={true}
                                disabled={hiredEmployes.length <= 4 ? true : false}
                                onClick={() => {
                                    endHireTeamVideo();
                                    playSound(likeAudio);
                                }}
                            />
                        </div>
                    </div>
                
                </div>
                <div className={loader ? "spinner-back" : "spinner-non-back"}>
                    <Spin size="large" spinning={loader} />
                </div>
            </div>
            }
        </div>
    );
};

export default withRouter(
    inject(
        "userStore",
        "soundsStore",
        "languageStore",
        "progressBarStore"
    )(observer(ChooseEmployees))
);
