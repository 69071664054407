import React, { useState, useEffect } from "react";
import "./allPlayers.component.style.less";
import EsgService from "../../../../services/EsgServices/esg.service";
import ModalViewProfile from "../../../../components/Modal/ModalViewProfile/modalViewProfile.component";

/**
 * AllPlayers component
 * @class
 * @returns This component is used in AdminPanel component to get 
 * All players where their statistics can be seen regarding all modes in the game display them.
 */
const AllPlayers = () => {
    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService();

    const [players, setPlayers] = useState(null);

    useEffect(() => {
        esgService.getAllPlayers().then((res) => {
            const allPlayers = res.data;
            setPlayers(allPlayers);
        });
    }, []);

    return (
        <div className="list-all-players">
            <div className="players">
                {players &&
                    players.map((x, index) => {
                        return (
                            <div className="players-list" key={x._id}>
                                <p>
                                    <span className="number-list">
                                        {index + 1}
                                    </span>
                                    {x.email}
                                </p>
                                <p className="right-links">
                                    <span>
                                        <ModalViewProfile player={x} />
                                    </span>
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default AllPlayers;
