import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Input } from 'antd';
import {
    setLocalStorage,
    SEQUENCE_PROGGRES,
    getLocalStorage, BUDGET, USER_PROFILE_ESG, SEQUENCE_INDEX
} from '../../../../localStorageMobxStore/localStorageUserProfile'
import { modeGame } from '../../../../helperFunctions/helperFunctions'
import SequenceService from '../../../../services/EsgServices/sequence.service'
import HintModalBmc from '../../../../components/Modal/HintModalBmc/hint.modal.bmc.component'
import background from '../../../../assets/images/hint.png';
import { Mentor, mentorArr } from '../../../../assets/images/index';
import './sequenceBmc.style.less'
import { openDB } from "idb";
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/button.component'


const { TextArea } = Input;

// pwa
let dbPromise;
let swRegistered = false;
/**
 * Sequence Bmc component
 * @class 
 * @param {Object} properties (history, setSequnceProgres, setOnline, budget, setBudget, sequence, setSequence, setSequenceIndex, companyInfo, languageStore, progressBarStore )
 * @returns Title(question of bmc)and input field where user can type answer on bmc question.Continue button 
 * that on submit redirect user mounty expenses page.And Back to office button that redirect user to office
 */
const SequenceBmc = ({ history, setSequnceProgres, setOnline, budget, setBudget, sequence, setSequence, setSequenceIndex, companyInfo, languageStore, progressBarStore }) => {
    const { t } = useTranslation(['common', 'sequences'])
    /**New variable esgSequenceService it is a new instacnce created from The EsgSequenceService() constructor for creating new
     * service where are esg services sequnce methods for calling api's are stored.
     */
    const sequenceService = new SequenceService()
    const [inputValue, setInputValue] = useState('')
    const [visible, setVisible] = useState(false)
    const [userEsg, setUserEsg] = useState(null)
    const [mentorImage, setMentorImage] = useState(null)
    /**
     * Select Languege function
     * @returns Returns bhs/en answer depending on current language
     */
    const selectLanguage = () => {
        const textBhs = sequence.summary.bhs.question;
        const textEn = sequence.summary.en.question;
        if (languageStore.language === 'bhs') {
            return textBhs
        } else if (languageStore.language === 'en') {
            return textEn
        } else {
            return textEn
        }
    }

    useEffect(() => {
        navigator.serviceWorker.getRegistrations()
            .then(registrations => {
                if (registrations.length > 0) {
                    swRegistered = true;
                }
            })
            .then(() => {
                // Define indexDB
                if ("indexedDB" in window) {
                    dbPromise = openDB("esgDB");
                };
            })

        //  set progressBar
        if (sequence) {
            const oneQuestionPercentage = 10 / (sequence.quiz.questions.length + 2);  // value of one question in percentage
            const questionProgress = (sequence.number * 10) - (oneQuestionPercentage * 2);
            progressBarStore.setProgress(100, 100, questionProgress)
        }

    }, [sequence])

    /**
     * Set value of input field
     * @param {String} e Value of input filed 
     * @returns updating state of inputValue with e.target.value
     */
    const onChange = (e) => {
        setInputValue(e.target.value)
    }
    /**
     * Functions that set state of viseble to oposite of what is set in that moment
     */
    const handleVisible = () => {
        setVisible(!visible)
    }
    /**
     * Function that set state of visible to false
     */
    const handleOk = () => {
        setVisible(false)
    }
    /**
     * Function that retuns backround picture image
     */
    const HintImage = () => {
        return <img src={background} alt="background" />;
    }

    /**
     * Redirect function.Object data is created with properties budgetId,companyId,key,value,
     * gameMode,sequenceId.SequenceService is call with method postBmc new created object data is 
     * passed as only parameter after response come from backend . Localal storage SEQUENCE_PROGGRES 
     * is updated with value 'monltyEvent' and sequnceProgres state is updated with valau 'monltyEvent'.
    */
    const redirect = () => {
        const gameMode = modeGame(budget);
        const data = {
            budgetId: budget.budget._id,
            companyId: companyInfo.id,
            key: sequence.summary.partOfBmc,
            value: inputValue,
            gameMode: gameMode,
            sequenceId: sequence.id
        };

        const bmcData = data;


        /* pwa bmc */
        if ("serviceWorker" in navigator && "SyncManager" in window && swRegistered && !navigator.onLine) {
            navigator.serviceWorker.ready.then((sw) => {
                if ("bmc") {
                    if ('indexedDB' in window) {
                        dbPromise.then((db) => {
                            const tx = db.transaction('sync_requests', "readonly");
                            const store = tx.objectStore('sync_requests');
                            return store.getAll();
                        })
                            .then(data => {
                                dbPromise
                                    .then(db => {
                                        const tx = db.transaction('sync_requests', "readwrite");
                                        const store = tx.objectStore('sync_requests');
                                        store.put(bmcData, data.length + 1);
                                        return tx.done;
                                    })
                                    .then(() => {
                                        return sw.sync.register("sync_requests");
                                    })
                                    .then(() => {

                                    })
                                    .then(() => {
                                        setLocalStorage('monltyEvent', SEQUENCE_PROGGRES)
                                        setSequnceProgres('monltyEvent')
                                        if (!navigator.onLine) {
                                            let budget = getLocalStorage(BUDGET).BUDGET;
                                            budget.budget.remaining = budget.budget.remaining + 50;
                                            setLocalStorage(budget, BUDGET);
                                            setBudget(budget);
                                        };

                                    })
                                    .catch(err => console.log('sync bmc err ==>', err))
                            })
                    }
                }
            })
        } else {
            sequenceService.postBmc(data).then((res) => {
                setLocalStorage('monltyEvent', SEQUENCE_PROGGRES)
                setSequnceProgres('monltyEvent')
            })
        };


    }

    useEffect(() => {
        const userEsg = getLocalStorage(USER_PROFILE_ESG).USER_PROFILE_ESG
        setUserEsg(userEsg);

        setLocalStorage('bmc', SEQUENCE_PROGGRES);


        // If we are onlline and if equence_index is 1 | if we came online in second sequence
        if (navigator.onLine) {
            const sequence_index = getLocalStorage(SEQUENCE_INDEX).SEQUENCE_INDEX;
            const gameMode = modeGame(budget);

            if (sequence_index === 1) {
                const fetchNewSequence = async (sequenceNr, gameMode) => {
                    const data = {
                        sequenceNrs: [sequenceNr, sequenceNr + 1]
                    }

                    //Array of two ids of sequnces
                    const ids = await sequenceService.getIdsOfSequnces(data)

                    const firstSequnceId = ids.data[0]._id

                    const firstSequencePromise = sequenceService.getSequnce(firstSequnceId)
                    const firstSequence = (await firstSequencePromise).data;
                    setSequence(firstSequence);

                    let secondSequence;

                    if (ids.data[1]) {
                        const secondSequnceId = ids.data[1]._id;
                        const secondSequencePromise = sequenceService.getSequnce(secondSequnceId)
                        secondSequence = (await secondSequencePromise).data;
                    };

                    if ('indexedDB' in window && swRegistered) {
                        // create firstSequence in indexDB
                        dbPromise
                            .then((db) => {
                                const tx = db.transaction('sequence', "readwrite");
                                const store = tx.objectStore('sequence');
                                store.put(firstSequence, 'firstSequence');
                                return tx.done;
                            })
                            .then(() => {
                                dbPromise
                                    .then((db) => {
                                        const tx = db.transaction('progres', "readwrite");
                                        const store = tx.objectStore('progres');
                                        store.put(firstSequence, 'progres');
                                        return tx.done;
                                    })
                            })

                        // create secondSequence in indexDB
                        if (secondSequence) {
                            dbPromise
                                .then((db) => {
                                    const tx = db.transaction('sequence', "readwrite");
                                    const store = tx.objectStore('sequence');
                                    store.put(secondSequence, 'secondSequence');
                                    return tx.done;
                                });
                        } else {
                            dbPromise
                                .then((db) => {
                                    const tx = db.transaction('sequence', "readwrite");
                                    const store = tx.objectStore('sequence');
                                    return store.delete('secondSequence');
                                })
                        }
                    };
                };

                // Get secondSequence data from indexDB
                dbPromise
                    .then(db => {
                        const tx = db.transaction('sequence', 'readonly');
                        const store = tx.objectStore('sequence');
                        return store.get('secondSequence');
                    })
                    .then(data => {
                        fetchNewSequence(data.number, gameMode)
                        setLocalStorage(0, SEQUENCE_INDEX)
                        setSequenceIndex(0)

                    })

            };

        }
    }, [])

    useEffect(() => {

        if (userEsg) {
            setMentorImage(userEsg.mentor.avatarUrl)
        }
    }, [userEsg])

   /**
   * Utility function that take two parameter
   * @param {Array} arr 
   * @param {String} image 
   * @returns Returns new array with one Object type element 
   */
    const utilityFilter = (arr, image) => {
        return arr.filter((x) => {
            return x.key === image
        })
    }

    const mentorProfileImage = utilityFilter(mentorArr, mentorImage)

    return (
        <div className="section-bmc">
            {sequence ?
                <div className="section-bmc-holder">
                    <div className='hint-modal-holder-bmc'>
                        <HintModalBmc
                            btnText={<HintImage />}
                            okBtnText={t('sequences:sequence-bmc-continue')}
                            closable={false}
                            visible={visible}
                            trigerVisible={handleVisible}
                            footer={true}
                            handleOk={handleOk}
                            maskClosable={false}
                            sequence={sequence} />
                    </div>


                    {/* <div className="sequence-board-info-talk-mentor">
                        {!mentorImage ? null : <Mentor src={mentorProfileImage[0].src} />}
                        <p className="hi-msg">{t('sequences:sequence-bmc')}
                        </p>
                    </div> */}

                    <div className="just-for-anime">
                        <h2>{selectLanguage()}</h2>
                        <div className="input-holder-text">
                            <label>{t('sequences:sequence-bmc-answer')}</label>
                            <TextArea className="text-area" placeholder={t('sequences:sequence-bmc-input')} onChange={onChange} value={inputValue} />
                        </div>
                    </div>
                    <div className="button-landing">
                        <Button
                            additionalStyle="buttonMakeAcc"
                            text={t('sequences:sequence-back')}
                            customBtn={true}
                            onClick={() => history.push('office')}
                        />
                        <button className="buttonMakeAcc" onClick={redirect} disabled={inputValue ? false : true}>{t('sequences:sequence-bmc-continue')}</button>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}

export default withRouter(inject('sequenceStore', 'languageStore', 'progressBarStore')(observer(SequenceBmc)))