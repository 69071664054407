import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import EsgService from '../../../../../services/EsgServices/esg.service';
import './chartAccelerator.component.style.less'
import { useTranslation } from "react-i18next"
import Download from './excel'

/**
 * ChartAccelerator component
 * @class
 * @returns This component is used in gameStats component to display 
 * all players data represeted in charts
 */
const ChartAccelerator = () => {
    const { t } = useTranslation("admin")
    const isReady = React.useRef(false);

    /**New variable esgService it is a new instacnce created from The EsgService() constructor for creating new
     * service where are esg services methods for calling api's stored.
     */
    const esgService = new EsgService()
    const [withAccelerator, setWithAccelerator] = useState(null)
    const [withoutAccelerator, setWithoutAccelerator] = useState(null)

    useEffect(() => {
        fetch();
        return () => {
            isReady.current = true;
        };
    }, [])


    /**
     *Function that is used in component did mount to get all necessary data that we need to display
    */
    const fetch = () => {
        esgService.getGeneralStats().then((res) => {
            if (!isReady.current) {
                const allStatsAcc = res.data.accelerator
                setWithAccelerator(allStatsAcc.yes)
                setWithoutAccelerator(allStatsAcc.no)
            }
        });
    }

    return (
        <div className='chart-comp-acc'>
            <h2>{t("admin:charts.accelerator")}</h2>
            <div style={{ display: 'flex' }}>
                <Chart
                    width={'100%'}
                    height={300}
                    className="accelerator-holder"
                    chartType="ColumnChart"
                    loader={<div>{t("admin:charts.loading")}</div>}
                    data={[
                        [t("admin:charts.accelerator"), t("admin:charts.with"), t("admin:charts.no")],
                        ["", withAccelerator, 0],
                        ["", 0, withoutAccelerator],
                    ]}
                    options={{
                        chartArea: { width: '50%' },
                        hAxis: {
                            minValue: 0,
                        },
                        colors: ['#7b35df', '#3498db']
                    }}
                    legendToggle
                />
            </div>
            <div>
                <Download
                    withAccelerator={withAccelerator}
                    withoutAccelerator={withoutAccelerator}
                />
            </div>
        </div>
    );
};

export default ChartAccelerator;
