import React from 'react';
import { withRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { message, Form, Input, InputNumber, Select } from 'antd';
import Button from '../../components/Button/button.component';
import Checkbox from '../../components/Checkbox/checkbox.component';
import IdentityService from '../../services/identity.service';
import './registration.style.less';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useState } from 'react';
import userStore from '../../store/user.store';

const { Option } = Select;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Register = ({ history , languageStore }) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(["registration", "termsAndConditions"]);
  const [form] = Form.useForm();
  const identityService = new IdentityService();

  const toggleLoader = value => {
    setLoader({ loader: value })
  }
  const refresh = () => {
    history.push('verify')
  }

  const redirectLogin = () => {
    history.push('/login')
  }

  const onFinish = ({ response }) => {
    form.validateFields().then((values) => {
      setLoader(!loader)
      const name = `${values.name} ${values.lastname}`;
      const { yearOfBirth, gender, password, email, city } = values;
      let tempValue = {
        name,
        yearOfBirth,
        gender,
        password,
        email,
        city,
      }
      let langBHS = {
        lang:'bhs'
      }
      let langEN = {
        lang:'en'
      }
      let langParam = languageStore.language === 'bhs' ? langBHS : langEN ;

      identityService.userRegistration(tempValue,langParam).then((res) => {
        if (res.data.status === "WAITING_APPROVAL") {
          history.push('verify')
        }
      }).catch((e) => {
        message.error({
          content: e.response.data.message,
          duration: 3,
          className: 'custom-error',
          style: {
            marginTop: '20vh',
          },
        }).then(() =>
          setLoader(loader),
          form.resetFields()
        )
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [terms, setTerms] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  function handleChange () {
    setTerms(!terms);
    setDisabled(terms);
  }

  return (
    <div className="forma-reg">

      <div className={loader ? 'spinner-back' : 'spinner-non-back'}>
        <Spin size="large" spinning={loader} />
      </div>

      <h1>{t('registration:registration')}</h1>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label={t('registration:first-name')}
          rules={[{
            required: true,
            message: t('registration:first-name-message')
          }]}
        >
          <Input placeholder={t('registration:first-name-message')} />
        </Form.Item>

        <Form.Item
          name="lastname"
          label={t('registration:last-name')}
          rules={[{
            required: true,
            message: t('registration:last-name-message')
          }]}
        >
          <Input placeholder={t('registration:last-name-message')} />
        </Form.Item>

        <Form.Item
          name="email"
          label={t('registration:email')}
          rules={[
            {
              required: true,
              type: 'email',
              message: t('registration:email-message')
            },
          ]}
        >
          <Input placeholder={t('registration:email-message')} />
        </Form.Item>

        <Form.Item
          label={t('registration:password')}
          name="password"
          rules={[{
            required: true,
            message: t('registration:password-message')
          }]}
        >
          <Input.Password placeholder={t('registration:password-message')} />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={t('registration:confirm-password')}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('registration:confirm-password-message')
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  t('registration:password-not-match')
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('registration:confirm-password-message')} />
        </Form.Item>

        <Form.Item
          name="city"
          label={t('registration:city')}
          rules={[{
            required: true,
            message: t('registration:city-message')
          }]}
        >
          <Input placeholder={t('registration:city-message')} />
        </Form.Item>

        <Form.Item
          name={'yearOfBirth'}
          label={t('registration:year-of-birth')}
          rules={[{
            required: true, type: 'number', min: 1920,
            message: t('registration:year-of-birth-message'), max: 2005
          }]}
        >
          <InputNumber placeholder={t('registration:year-of-birth-message')} />
        </Form.Item>

        <Form.Item
          name="gender"
          label={t('registration:gender.gender-head')}
          rules={[
            {
              required: true,
              message: t('registration:gender.gender-message')
            },
          ]}
        >
          <Select
            placeholder={t('registration:gender.gender-choose')}
            // onChange={onGenderChange}
            allowClear
          >
            <Option value="female">{t('registration:gender.gender-female')}</Option>
            <Option value="male">{t('registration:gender.gender-male')}</Option>
            <Option value="other">{t('registration:gender.gender-other')}</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox
            value="terms"
            checked = {terms}
            onChange={handleChange}>{t('termsAndConditions:accept')}</Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            disabled = {disabled}
            additionalStyle="signUp"
            text={t('registration:sign-up')}
            customBtn={true}
            onClick={onFinish}
            toggleLoader={toggleLoader}
          />
        </Form.Item>
      </Form>
      {/* <div className="sc-networks">
        <p className="sc-networks-p">{t('registration:existing-account')}</p>
        <div className="sc-networks-images">
          <img src={logo} alt="logo" />
          <img src={logo} alt="logo" />
          <img src={logo} alt="logo" />
        </div>
        <p className="sc-networks-p">
          {t('registration:have-account')} <span>{t('registration:sign-in')}</span>
        </p>
      </div> */}
      <p className="sc-networks-p already">
        {t('registration:have-account')} <span onClick={redirectLogin}>{t('registration:sign-in')}</span>
      </p>
    </div>
  );
};

export default withRouter(inject('userStore','languageStore')(observer(Register)));
