import "./termsAndConditions.less";
import React, { useState, useEffect } from "react";
import Button from "../../components/Button/button.component";
import { useTranslation } from "react-i18next";


/**
 * TermsAndConditions component
 * @class
 * @param {Object} properties (history)
 * @returns This component will display content for terms and conditions and two buttons, for accept and decline
 */
const TermsAndConditions = ({ history }) => {
    const [disableButton, setDisableButton] = useState(false);
    const { t } = useTranslation(["termsAndConditions", "common"]);

    /** function that will delete cookie on click **/
    const deleteCookie = (name) => {
        document.cookie = name + "=; Max-Age=-99999999;";
    };

    return (
        <div className="privacy-policy">
            <h3>{t("termsAndConditions:title")}</h3>

            <div className="content">
                <div dangerouslySetInnerHTML={{ __html: t("termsAndConditions:content"),}}></div>
                <div dangerouslySetInnerHTML={{ __html: t("termsAndConditions:content2"),}}></div>
                <div dangerouslySetInnerHTML={{ __html: t("termsAndConditions:content3"),}}></div>
                <div dangerouslySetInnerHTML={{ __html: t("termsAndConditions:content4"),}}></div>
                <div dangerouslySetInnerHTML={{ __html: t("termsAndConditions:content5"),}}></div>
            </div>

            <div className="buttons">
                <Button
                    additionalStyle="buttonMakeAcc decline"
                    text={t("common:decline")}
                    customBtn={true}
                    onClick={() => {
                        deleteCookie("bizVenture");
                        history.push("/");
                    }}
                />
                <Button
                    additionalStyle="buttonMakeAcc"
                    text={t("common:accept")}
                    customBtn={true}
                    disabled={disableButton}
                    onClick={() => {
                        setDisableButton(true);
                        history.goBack();
                    }}
                />
            </div>
        </div>
    );
};

export default TermsAndConditions;
