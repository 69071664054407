import React, {Suspense} from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import './app.style.less';

import Register from '../LogIn/registration.form';
import SignIn from '../LogIn/signIn.form';
import SocialLogin from '../LogIn/SocialLogin/SocialLogin.component'
import Home from '../Home/home.container';
import HeaderNavigation from '../Header/header.container';
import FooterNavigation from '../Footer/footer.container';
import Landing from '../LandingPage/landing.page';
import SelectAvatar from '..//UserSelectSection/AvatarSelect/avatars.component';
import CharacterName from '../UserSelectSection/ChaNameSelect/nameCharacter.component';
import IndustryType from '../UserSelectSection/IndustrySelect/industrySelect.component';
import CompanyName from '../UserSelectSection/CompanyNameSelect/companyName.component';
import AwardCoins from '../UserSelectSection/AwardCoins/awardCoins.component';
import VerifyEmail from '../LogIn/VerifyEmail/verifyEmail.component';
import SuccessVerify from '../LogIn/VerifyEmail/successVerify.component';
import ChooseEmployees from '../UserSelectSection/EmployeeSelect/employeeSelect.component';
import SelectMode from '../UserSelectSection/GameModeSelect/selectMode.component';
import SelectAccelerator from '../UserSelectSection/GameModeSelect/selectAccelerator.component';
import MentorSelect from '../UserSelectSection/MentorSelect/mentorSelect.component';
import BuyOffice from '../../containers/UserSelectSection/OfficeBuySelect/officeBuySelect.component';
import HardwerSelect from '../UserSelectSection/EquipmentSelect/HardwareEquipment/hardwareSelect.component'
import TablesSelect from '../UserSelectSection/EquipmentSelect/Tables/tablesSelect.component'
import ChairsSelect from '../UserSelectSection/EquipmentSelect/Chairs/chairsSelect.component'
import OtherEquipment from '../UserSelectSection/EquipmentSelect/OtherEquipment/otherEquipmentSelect.component'
import MeetMentor from '../../containers/UserSelectSection/MeetMentor/meetMentor.component';
import OnboardingInfo from '../UserSelectSection/OnboardingInfo/onboardingInfo.component';
import ChooseProfession from '../UserSelectSection/ChooseProfessions/chooseProfession.component'
import LegalAspects from '../UserSelectSection/LegalAspects/selectLegalAspects'
import SalaryGiveup from '../UserSelectSection/SalaryChoices/salaryGiveup.component'
import EquipmentPayment from '../UserSelectSection/PayEquipment/payEquipment.component'
import ModalMentor from '../../components/Modal/ModalMentor/modalMentor.component'
import ModalSequence from '../../components/Modal/ModalSequence/modalSequence.component'
import OnboardingSequence from '../Sequences/OnBoardingSequence/onboardingSequence.component'
import BonusChest from '../UserSelectSection/BonusChest/bonusChest.component'
import UserData from '../UserProfile/userProfile.component'
import AdminPanel from '../AdminPanel/admin.component'
import BusinessModelCanvas from '../UserSelectSection/BmcUser/bmcUser.component'
import ChartPie from '../UserSelectSection/ChartPie/chart.component'
import GameFinish from '../UserSelectSection/GameFinish/gameFinish.component'
import InitSurvey from '../UserSelectSection/Survey/initSurvey.component'
import EndSurvey from '../UserSelectSection/Survey/endSurvey.component '
import HowToStart from '../UserSelectSection/HowToStart/howToStart.component'
import Survey from '../UserSelectSection/Survey/survey.component'
import ModalOffline from '../../components/Modal/ModalOffline/modalOffline.component'
import StartupFile from '../UserSelectSection/StartupFile/startupFile.component'
import Office from '../../containers/Office/office.component'
import PrivacyPolicy from '../../containers/PrivacyPolicy/privacyPolicy.component'
import TermsAndConditions from '../../containers/TermsAndConditions/termsAndConditions.component'
import '../../i18n'

//TESTING SEQUENCE / QUIZ
import Sequence from '../../containers/Sequences/Sequence/sequence.component'

const App = () => {
  return (
    <Suspense fallback={null}>
    <Router>
      <Switch>
        <Route path="/" exact component={Landing}>
          <HeaderNavigation />
          <Layout className="app landing">
            <Landing />
          </Layout>
          <FooterNavigation />
        </Route>
        <Route path="/registration" exact component={Register}>
          <HeaderNavigation />
          <Layout className="app">
            <Register />
          </Layout>
          <FooterNavigation />
        </Route>
        <Route path="/login" exact component={SignIn}>
          <HeaderNavigation />
          <Layout className="app">
            <SignIn />
          </Layout>
          <FooterNavigation />
        </Route>

        <Route path="/oauth" exact component={SocialLogin}>
          <HeaderNavigation />
          <Layout className="app">
            <SocialLogin />
          </Layout>
          <FooterNavigation />
        </Route>

        <Route path="/confirm-verification" exact component={SuccessVerify}>
          <HeaderNavigation />
          <Layout className="app">
            <SuccessVerify />
          </Layout>
          <FooterNavigation />
        </Route>

        <Route path="/verify" exact component={VerifyEmail}>
          <HeaderNavigation />
          <Layout className="app">
            <VerifyEmail />
          </Layout>
          <FooterNavigation />
        </Route>



        <Route>
          <HeaderNavigation />
          <Layout className="app">
            <PrivateRoute path="/home" exact component={Home}></PrivateRoute>
            <PrivateRoute
              path="/avatars"
              exact
              component={SelectAvatar}
            ></PrivateRoute>
            <PrivateRoute
              path="/charctername"
              exact
              component={CharacterName}
            ></PrivateRoute>
            <PrivateRoute
              path="/industrytype"
              exact
              component={IndustryType}
            ></PrivateRoute>
            <PrivateRoute
              path="/companyname"
              exact
              component={CompanyName}
            ></PrivateRoute>
            <PrivateRoute
              path="/awardcoins"
              exact
              component={AwardCoins}
            ></PrivateRoute>
            <PrivateRoute
              path="/chooseemployees"
              exact
              component={ChooseEmployees}
            ></PrivateRoute>
            <PrivateRoute
              path="/selectmode"
              exact
              component={SelectMode}
            ></PrivateRoute>
            <PrivateRoute
              path="/selectaccelerator"
              exact
              component={SelectAccelerator}
            ></PrivateRoute>
            <PrivateRoute
              path="/mentorselect"
              exact
              component={MentorSelect}
            ></PrivateRoute>
            <PrivateRoute
              path="/officeselect"
              exact
              component={BuyOffice}
            ></PrivateRoute>
             <PrivateRoute
              path="/hardware"
              exact
              component={HardwerSelect}
            ></PrivateRoute>
            <PrivateRoute
              path="/tables"
              exact
              component={TablesSelect}
            ></PrivateRoute>
            <PrivateRoute
              path="/chaires"
              exact
              component={ChairsSelect}
            ></PrivateRoute>
            <PrivateRoute
              path="/otherequipment"
              exact
              component={OtherEquipment}
            ></PrivateRoute>
              <PrivateRoute
              path="/meetmentor"
              exact
              component={MeetMentor}
            ></PrivateRoute>
              <PrivateRoute
              path="/onboardinginfo"
              exact
              component={OnboardingInfo}
            ></PrivateRoute>
              <PrivateRoute
              path="/chooseproffesions"
              exact
              component={ChooseProfession}
            ></PrivateRoute>
            <PrivateRoute
              path="/legalaspects"
              exact
              component={LegalAspects}
            ></PrivateRoute>
            <PrivateRoute
              path="/salarychoices"
              exact
              component={SalaryGiveup}
            ></PrivateRoute>
            <PrivateRoute
              path="/equipmentpayment"
              exact
              component={EquipmentPayment}
            ></PrivateRoute>
            <PrivateRoute
              path="/initsurvey"
              exact
              component={InitSurvey}
            ></PrivateRoute>
            <PrivateRoute
              path="/endsurvey"
              exact
              component={EndSurvey}
            ></PrivateRoute>
            <PrivateRoute
              path="/startnow"
              exact
              component={HowToStart}
            ></PrivateRoute>
            
            <PrivateRoute path="/modalmentor" exact component={ModalMentor}>
            </PrivateRoute>
            <PrivateRoute path="/modalsequence" exact component={ModalSequence}>
            </PrivateRoute>
            <PrivateRoute path="/onboardingsequence" exact component={OnboardingSequence}>
            </PrivateRoute>
            <PrivateRoute path="/bonuschest" exact component={BonusChest}>
            </PrivateRoute>
            <PrivateRoute path="/userProfile" exact component={UserData}>
            </PrivateRoute>
            <PrivateRoute path="/game" exact component={Sequence}></PrivateRoute>
            <PrivateRoute path="/chart" exact component={ChartPie}></PrivateRoute>
            <PrivateRoute path="/admin" exact component={AdminPanel}></PrivateRoute>
            <PrivateRoute path="/businessModelCanvas" exact component={BusinessModelCanvas}></PrivateRoute>
            <PrivateRoute path="/chart" exact component={ChartPie}></PrivateRoute>
            <PrivateRoute path="/finish" exact component={GameFinish}></PrivateRoute>
            <PrivateRoute path="/howto" exact component={HowToStart}></PrivateRoute>
            <PrivateRoute path="/survey" exact component={Survey}></PrivateRoute>
            <PrivateRoute path="/offline" exact component={ModalOffline}></PrivateRoute>
            <PrivateRoute path="/startup" exact component={StartupFile}></PrivateRoute>
            <PrivateRoute path="/office" exact component={Office}></PrivateRoute>
            <Route path="/privacy-policy" exact component={PrivacyPolicy}></Route>
            <Route path="/terms-and-conditions" exact component={TermsAndConditions}></Route>

          </Layout>
          <FooterNavigation />
        </Route>
      </Switch>
    </Router>
    </Suspense>
  );
};

export default App;
